<template>
    <div class="list-news">
        <h5>Danh sách tin tức</h5>
        <div class="breadcrumb">
            <span>Quản lý tin tức</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh mục tin tức</span>
        </div>
        <el-row>
            <el-col :span="9">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Danh mục tin tức</p>
                        <button class="add-cate" @click="showForm('add','')">+ Tạo danh mục mới</button>
                    </div>
                    <ul class="list-cate">
                        <li :class="{'active' : keyActive == 0}" @click="keyCate = keyActive = 0, setPage(1)">Tất cả danh mục</li>
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listCateNews"
                            :key="index"
                            @click="keyCate = keyActive = item.id, setPage(1)"
                        >
                            {{ item.name }}
                            <div class="wp-btn">
                                <button @click.stop="showForm('edit', item)"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="deleteCateNews(item.id)"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="15">
                <div class="wp-list">
                    <div style="padding: 20px;">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="ttl">Danh sách tin tức</p>
                            <div class="d-flex align-items-center">
                                <button class="add-news" @click="$router.push('/quan-ly-tin-tuc/tao-moi')">+ Tạo tin tức mới</button>
                                <el-form
                                    ref="ruleFormRef"
                                    status-icon
                                    class="demo-ruleForm"
                                    @submit.prevent="setPage(1)"
                                >
                                    <img src="@/assets/icons/search.png" alt="">
                                    <el-form-item>
                                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <p class="count">Số tin tức trong danh sách: <span>{{ page.totalData }}</span></p>
                        <div class="dropdown">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ sort_txt }}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li @click="keyOrder = 'latest', sort_txt = 'Mới nhất', setPage(1)"><a class="dropdown-item" href="#">Mới nhất</a></li>
                                <li @click="keyOrder = 'oldest', sort_txt = 'Cũ nhất', setPage(1)"><a class="dropdown-item" href="#">Cũ nhất</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="wp-tbl">
                        <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                            <el-table-column header-align="center" align="center" fixed label="Ảnh" width="93">
                                <template #default="scope">
                                    <img :src="$storage + scope.row.image" alt="" style="width: 60px; height: 60px; border-radius: 15px; object-fit: cover;" @errpr="handleError">
                                </template>
                            </el-table-column>
                            <el-table-column header-align="center" prop="title" label="Tiêu đề tin tức" width="337">
                                <template #default="scope">
                                    <p style="margin-bottom: 5px;">{{ scope.row.title }}</p>
                                    <div class="table_code">#{{ scope.row.code }}</div>
                                </template>
                            </el-table-column>
                            <el-table-column header-align="center" align="center" prop="category_name" label="Danh mục" min-width="187"/>
                            <el-table-column header-align="center" align="center" prop="create_date" label="Ngày đăng" min-width="100">
                                <template #default="scope">
                                    <span>{{ formateDate(scope.row.create_at) }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column header-align="center" align="center" label="Action" width="124">
                                <template #default="scope">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <el-switch
                                            v-model="scope.row.view_status"
                                            :active-value="1"
                                            class="ml-2"
                                            style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                                        />
                                        <button style="padding: 0 12px;"><img src="@/assets/icons/pencil.png" alt=""></button>
                                        <button style="padding: 0;"><img src="@/assets/icons/trash.png" alt=""></button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="wp-paginate">
                            <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                            <Pagination
                                :page="page"
                                @set-page="setPage"
                                @setPerPage="setPerPage"
                            />
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-dialog
            v-model="dialogAddCate"
            width="553"
            class="dialog_add_cate"
            @close="dialogAddCate = false"
        >
            <p class="ttl" v-if="typeDialog == 'add'">Tạo danh mục tin tức</p>
            <p class="ttl" v-else>Chỉnh sửa danh mục tin tức</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
                @submit.prevent="typeDialog == 'add' ? addCateNews('formAdd') : editCateNews('formAdd')"
            >
                <el-form-item :label="typeDialog == 'add' ? 'Nhập tên danh mục mới' : 'Nhập tên danh mục'" prop="name">
                    <el-input v-model="formAdd.name" type="text" resize="none" placeholder="Nhập tên danh mục" />
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 40px;">
                <button class="btn_cancel" @click="dialogAddCate = false">Hủy bỏ</button>
                <button class="btn_add" @click="addCateNews('formAdd')" v-if="typeDialog == 'add'">Xác nhận</button>
                <button class="btn_add" @click="editCateNews('formAdd')" v-else>Xác nhận</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            keyOrder: 'latest',
            keyCate: 0,
            sort_txt: 'Sắp xếp',
            formAdd: {
                name: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên danh mục", trigger: ["blur", "change"]}],
            },
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 12,
                currentPage: this.$route.query.page || 1,
            },
            dialogAddCate: false,
            listCateNews: [],
            keyActive: 0,
            typeDialog: '',
            dataEdit: [],
        }
    },
    mounted() {
       this.getCateNews()
       this.getListNews()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListNews()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListNews()
        },
        async getCateNews() {
            this.listCateNews = await this.getListNewsCategory()
        },
        async addCateNews(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const res = await this.$axios.post('/news/createNewsCategory',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Thêm danh mục tin tức thành công!");
                        this.dialogAddCate = false
                        this.turnOffErrorMessage('formAdd')
                        this.getCateNews()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async editCateNews(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const data = {
                        'name': this.formAdd.name,
                        'id': this.dataEdit.id
                    }
                    const res = await this.$axios.put('/news/updateNewsCategory',data,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Sửa danh mục tin tức thành công!");
                        this.dialogAddCate = false
                        this.turnOffErrorMessage('formAdd')
                        this.getCateNews()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async deleteCateNews(id) {
            this.startLoading();
            await this.$axios.put('/news/deleteNewsCategory', {id: id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa danh mục tin tức thành công!");
                this.getCateNews()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        showForm(type, data) {
            this.dialogAddCate = true
            if(type == 'add') {
                this.typeDialog = 'add'
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
                this.formAdd.name = ''
            } else {
                this.typeDialog = 'edit'
                this.formAdd.name = data.name
                this.dataEdit = data
            }
        },
        async getListNews() {
            this.startLoading();
            await this.$axios.get('/news/getListNewsForAdmin',{
                params: {
                    title: this.keySearch,
                    order: this.keyOrder,
                    category: this.keyCate,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-tin-tuc/danh-sach/chi-tiet/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    .list-news {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate{
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-cate {
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-list p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    .wp-cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-cate .wp-btn button {
        padding: 0;
    }

    .wp-cate .wp-btn button:last-child {
        margin-left: 10px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-list {
        background-color: #FFF;
        border-radius: 20px;
    }

    .add-news {
        background: #F58E16;
        color: #fff;
    }

    p.count {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    p.count span {
        font-size: 16px;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .dropdown-toggle {
        border-radius: 10px;
        border: 1px solid #DBDBDB;
        color: #808080;
        font-size: 15px;
        font-weight: 500;
        width: 150px;
        height: 30px;
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .dropdown-menu .dropdown-item{
        color: #808080;
        font-size: 15px;
        font-weight: 500;
    }
    .dropdown-menu .dropdown-item:focus{
        background-color: #DBDBDB;
        color: #fff;
    }
    .dropdown-toggle:focus {
        box-shadow: none;
        border: 1px solid #DBDBDB;
    }

    .dropdown-toggle:hover, .dropdown-toggle:active {
        border: 1px solid #DBDBDB !important;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .table_code {
        color: #F58E16;
        font-weight: 600;
    }

</style>

<style>
    .list-news .el-input__wrapper,
    .list-news .el-input__wrapper:hover,
    .list-news .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .list-news .el-form-item{
        margin-bottom: 0;
    }

    .list-news .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .list-news .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .dialog_add_cate {
        border-radius: 20px !important;
    }

    .dialog_add_cate .el-dialog__header {
        display: none;
    }

    .dialog_add_cate p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .dialog_add_cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dialog_add_cate .el-form {
        margin: 0;
        width: 100%;
    }

    .dialog_add_cate .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 12px 10px !important;
        width: 100%;
        height: 70px !important;
    }

    .dialog_add_cate button {
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog_add_cate .btn_cancel {
        color: #F58E16;
        border: 1px solid #F58E16;
    }

    .dialog_add_cate .btn_add {
        color: #FFF;
        background: #F58E16;
    }
</style>