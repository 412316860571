<template>
    <div class="admin-layout">
        <el-container>
            <el-aside width="277px" style="height: 100vh; position: sticky; top: 0;"><Sidebar></Sidebar></el-aside>
            <el-container style="background: #f4f4f4;">
                <el-header style="margin-top: 25px;"><Header></Header></el-header>
                <el-main><router-view></router-view></el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Sidebar from '../pages/components/sidebar.vue'
import Header from '../pages/components/header.vue'
export default {
    components: {
        Sidebar,
        Header
    },
    name: "App",
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || "Admin";
            }
        },
    },
};
</script>

<style>
    .admin-layout {
        min-width: 1380px;
    }

    body {
        overflow-x: hidden;
        font-family: Roboto !important;
    }

    button {
        background: none;
        outline: none;
        border: none;
    }
</style>