<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="650"
        class="dialog_add_au"
        @close="emitDialogVisible(false)"
        title="Thêm phân quyền"
    >
        <h5 v-if="typeDialog == 'add'">Thêm phân quyền</h5>
        <h5 v-else>Chỉnh sửa phân quyền</h5>
        <el-form
            ref="formAdd"
            :model="formAdd"
            :rules="rules"
        >
            <el-form-item prop="role_name" label="Tên phân quyền">
                <el-input type="text" v-model="formAdd.role_name" placeholder="Nhập tên phân quyền"/>
            </el-form-item>
            <el-form-item prop="agency_id" label="Chi nhánh">
                <el-select clearable filterable placeholder="Chọn chi nhánh"
                    v-model="formAdd.agency_id"
                >
                    <el-option v-for="item in list_agency.result"
                        :key="item.id"
                        :label="item.agency_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="role_level" label="Phân cấp">
                <el-select clearable filterable placeholder="Chọn phân cấp"
                    v-model="formAdd.role_level"
                >
                    <el-option v-for="item in list_level"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <p class="label">Chức năng</p>
            <div class="choose_func" @click="dialogChoose = true">
                <span v-if="selectedItems.length == 0">Chọn chức năng</span>
                <span v-else>Đã chọn {{ selectedItems.length }} chức năng</span>
            </div>
        </el-form>
        <div class="wp-btn">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('formAdd', 'add')" v-if="typeDialog == 'add'">Xác nhận</button>
            <button class="btn_add" @click="confirm('formAdd', 'update')" v-else>Xác nhận</button>
        </div>

        <el-dialog
            v-model="dialogChoose"
            width="842"
            class="dialog_choose_au"
            @close="dialogChoose = false"
            title=""
        >
            <h5 class="choose_ttl">Chọn chức năng</h5>
            <div class="wp-func">
                <div class="wp-scroll">
                    <div class="item" style="width: 371px;">
                        <label class="wp-lable">
                            <p>Chọn tất cả chức năng</p>
                            <input type="checkbox" class="input-style" v-model="selectAll" @input="toggleAll">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="wp-detail">
                        <div class="item" v-for="(item, index) in list_detail_au" :key="index">
                            <label class="wp-lable">
                                <p>{{ item.resource_name }}</p>
                                <input type="checkbox" class="input-style" v-model="selectedItems" :value="item.resource_id">
                                <span class="checkmark"></span>
                            </label>
                            <ul class="list">
                                <li :class="{'active' : act.status == 1}" v-for="(act, index) in item.action">{{ act.action_name }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="foot">
                    <span>Đã chọn: <span>{{ selectedItems.length }}</span> chức năng</span>
                    <div>
                        <button class="btn_cancel" @click="dialogChoose = false, selectedItems = [], selectAll = false">Hủy bỏ</button>
                        <button class="btn_add" @click="dialogChoose = false">Xác nhận</button>
                    </div>
                </div>
            </div>
        </el-dialog>

    </el-dialog>
</template>
<script>
export default {
    props: ['data'],
    data() {
        return {
            selectAll: false,
            selectedItems: [],
            dialogVisibleStatus: false,
            typeDialog: '',
            formAdd: {
                role_name: '',
                role_level: '',
                agency_id: '',
                resource: [],
            },
            rules: {
                role_name: [{required: true, message: "Vui lòng nhập tên phân quyền", trigger: ["blur", "change"]}],
                role_level: [{required: true, message: "Vui lòng chọn phân cấp", trigger: ["blur", "change"]}],
                agency_id: [{required: true, message: "Vui lòng chọn chi nhánh", trigger: ["blur", "change"]}],
            },
            dialogChoose: false,
            list_detail_au: [],
            list_agency: [],
            list_level: [
                {id: 1, name: 'Quản trị hệ thống'},
                {id: 2, name: 'Trưởng phòng'},
                {id: 3, name: 'Trưởng chi nhánh'},
                {id: 4, name: 'Phó phòng'},
                {id: 5, name: 'Trưởng nhóm'},
                {id: 6, name: 'Thành viên'},
            ]
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.data.dialogVisible;
        this.typeDialog = this.data.type;
        this.getAgency()
        this.getListResource()
    },
    watch: {
        data() {
            this.dialogVisibleStatus = this.data.dialogVisible;
            this.typeDialog = this.data.type;
            if(this.data.type == 'edit') {
                this.getDetail(this.data.id)
            } else {
                this.selectedItems = [];
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
            }
        },
    },
    methods: {
        toggleAll() {
            if(!this.selectAll) {
                this.selectAll = true
            } else {
                this.selectAll = false
            }
            if (this.selectAll) {
                this.selectedItems = this.list_detail_au.map(item => item.resource_id);
            } else {
                this.selectedItems = [];
            }
        },
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        confirm(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.formAdd.resource = this.selectedItems
                    this.dialogVisibleStatus = false
                    if(type == 'add') {
                        this.$emit("confirm", this.formAdd, 'add');
                    } else {
                        this.$emit("confirm", this.formAdd, 'edit');
                    }
                    this.turnOffErrorMessage(formName)
                } else {
                    return false;
                }
            });
        },
        async getListResource() {
            this.startLoading();
            await this.$axios.get('/permission/getListResource',{
                params: {
                    resource_name: '',
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_detail_au = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(id) {
            this.startLoading();
            await this.$axios.get('/permission/getRoleDetail',{
                params: {
                    role_id: id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.selectedItems = []
                this.formAdd = res.data
                res.data.resource.forEach(item => {
                    this.selectedItems.push(item.resource_id)
                });
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .wp-lable p {
        margin: 0 0 0 28px;
        color: #515151;
    }
    .wp-btn {
        margin-top: 26px;
        text-align: right;
    }

    button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        width: 200px;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #F58E16;
    }

    button.btn_add {
        color: #FFF;
        background: #F58E16;
        border: none;
        margin-left: 13px;
    }

    p.label {
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
        height: 32px;
        display: flex;
        align-items: center;
    }

    p.label::before {
        content: '*';
        color: var(--el-color-danger);
        margin-right: 4px;
    }

    .choose_func {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 10px 12px;
        box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
        color: #B4B4B4;
        cursor: pointer;
    }

    h5.choose_ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
        border-radius: 20px 20px 0px 0px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
        padding: 14px 0 13px 20px;
    }

    .wp-func {
        padding: 20px;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 19px;
        font-weight: 500;
        font-size: 16px;
    }

    .item:last-child {
        margin-bottom: 0;
    }

    ul.list {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
    }

    ul.list li {
        position: relative;
        min-width: 65px;
        font-size: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        font-weight: 400;
    }

    ul.list li::before {
        content: '';
        position: absolute;
        border: 1px solid #B4B4B4;
        width: 10px;
        height: 10px;
        border-radius: 4px;
        left: 0;
    }

    ul.list li.active::before {
        background-color: #F58E16;
        border: none;
    }

    .wp-scroll {
        max-height: 413px;
        overflow: auto;
    }

    .wp-scroll::-webkit-scrollbar {
        width: 0;
    }

    .foot {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
    }

    .foot span {
        color: #515151;
        font-size: 15px; 
    }

    .foot span > span {
        font-weight: 500;
    }
</style>

<style>
    .dialog_add_au {
        border-radius: 20px !important;
    }

    .dialog_add_au .el-dialog__header {
        display: none;
    }

    .dialog_add_au .el-form-item__label {
        font-weight: 500;
        font-size: 15px;
        color: #515151;
        padding-left: 0;
    }

    .dialog_add_au .el-select {
        width: 100%;
    }

    .dialog_add_au .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 10px;
    }

    .el-input__inner::placeholder {
        color: #B4B4B4;
    }
    
    .dialog_choose_au {
        border-radius: 20px !important;
        min-height: 410px;
    }

    .dialog_choose_au .el-dialog__body {
        padding: 0;
    }
</style>