<template>
    <div class="add-news">
        <h5 v-if="!editPage">Tạo tin tức mới</h5>
        <h5 v-else>Chỉnh sửa tin tức</h5>
        <div class="d-flex align-items-center justify-content-between">
            <div class="breadcrumb">
                <span>Quản lý tin tức</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span>Danh sách tin tức</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span class="active" v-if="!editPage">Tạo tin tức mới</span>
                <span class="active" v-else>Chỉnh sửa tin tức</span>
            </div>

            <div class="d-flex align-items-center justify-content-between">
                <button class="cancel" @click="$router.push('/quan-ly-tin-tuc/danh-sach')">Hủy bỏ</button>
                <button class="add-new" @click="submitForm('formAdd')" v-if="!editPage">Tạo tin tức</button>
                <button class="add-new" @click="updateNews('formAdd')" v-else>Chỉnh sửa tin tức</button>
            </div>
        </div>

        <el-form
            ref="formAdd"
            :model="formAdd"
            :rules="rules"
            status-icon
            style="margin-top: 25px;"
        >
            <el-row>
                <el-col :span="17">
                    <div class="wp-ttl">
                        <el-form-item prop="category" label="Danh mục tin tức">
                            <el-select clearable filterable placeholder="Chọn danh mục"
                                v-model="formAdd.category"
                            >
                            <el-option v-for="item in list_cate"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item prop="title" label="Tiêu đề tin tức">
                            <el-input id="name" type="text" v-model="formAdd.title" placeholder="Nhập tiêu đề" clearable/>
                            <span class="note_input">Tối thiểu 30 ký tự</span>
                        </el-form-item>

                        <el-form-item prop="news_content" label="Bài viết tin tức">
                            <CKeditor
                                :editorData="formAdd.news_content"
                                @getData="getDataEditor"
                            />
                        </el-form-item>
                    </div>    
                </el-col>
                <el-col :span="7">
                    <div class="wp-img">
                        <p class="ttl">Hình ảnh hiển thị</p>
                        <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
                        <div class="wp-upload" @click="$refs.fileInput.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop">
                            <div v-if="!image_url">
                                <img src="@/assets/icons/Collectibles.png" alt="">
                                <p>Bấm để chọn ảnh cần tải lên</p>
                                <p class="des">hoặc kéo thả ảnh vào đây</p>
                            </div>
                            <img :src="$storage + image_url" alt="" v-else style="max-width: 220px; max-height: 141px;">
                        </div>
                        <span v-if="!formAdd.image && showErr" class="error">Vui lòng tải lên hình ảnh tin tức</span>
                    </div>

                    <div class="wp-lnk">
                        <p class="ttl">Thông tin tin tức</p>
                        <el-form-item prop="link" label="Link nguồn tin tức" style="margin-bottom: 0;">
                            <el-input id="name" type="text" v-model="formAdd.link" placeholder="Nhập link nguồn"/>
                        </el-form-item>
                        <el-form-item prop="description" label="Mô tả bất động sản">
                            <textarea name="des" class="form-control" :style="{ height: this.calculatedHeight('description') }" ref="description" v-model="formAdd.description" placeholder="Nhập mô tả tin tức" id="des" @input="this.adjustTextareaHeight"></textarea>
                            <el-input type="textarea" v-model="formAdd.description" class="d-none"></el-input>
                            <div class="small">Tối đa 300 ký tự</div>
                        </el-form-item>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
export default {
    components: {CKeditor},
    data() {
        return {
            formAdd: {
                category: '',
                title: '',
                news_content: '',
                description: '',
                image: '',
                link: ''
            },
            rules: {
                category: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
                title: [
                    {required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]},
                    {min: 30, message: "Tiêu đề tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                    {max: 300, message: "Tiêu đề tối đa 300 ký tự", trigger: ["blur", "change"]},
                ],
                news_content: [{required: true, message: "Vui nhập nội dung bài viết", trigger: ["blur", "change"]}],
                description: [
                    {required: true, message: "Vui nhập mô tả bài viết", trigger: ["blur", "change"]},
                    {max: 300, message: "Mô tả tối đa 300 ký tự", trigger: ["blur", "change"]},
                ],
            },
            image_url: '',
            showErr: false,
            list_cate: [],
            editPage: false
        }
    },
    watch: {
        "$route.path": function(newUrl, oldUrl) {
            if(newUrl.includes('/quan-ly-tin-tuc/danh-sach/chinh-sua')) {
                this.editPage = true
                this.getDetail()
            } else {
                this.editPage = false
                this.image_url = this.formAdd.image = ''
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
            }
        }
    },
    mounted() {
       this.getListCate()
       if(this.$route.path.includes('/quan-ly-tin-tuc/danh-sach/chinh-sua')) {
            this.editPage = true
            this.getDetail()
        } else {
            this.editPage = false
            this.image_url = this.formAdd.image = ''
            if(this.$refs.formAdd) {
                this.turnOffErrorMessage('formAdd')
            }
        }
    },
    methods: {
        getDataEditor(data) {
            this.formAdd.news_content = data
        },
        async getListCate() {
            this.list_cate = await this.getListNewsCategory()
        },
        async handleFileChange(event) {
            let url_image = await this.uploadFile('news/image/', event.target.files[0])
            this.image_url = url_image.path_tam
            this.formAdd.image = url_image.path
        },
        async handleDrop(e) {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            let url_image = await this.uploadFile('news/image/', file)
            this.image_url = url_image.path_tam
            this.formAdd.image = url_image.path
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAdd.image) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    await this.$axios.post('/news/createNews', this.formAdd, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thêm tin tức thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.image_url = this.formAdd.image = ''
                        this.$refs.fileInput.value = ''
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
        updateNews(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAdd.image) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    await this.$axios.put('/news/updateNews', this.formAdd, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Chỉnh sửa tin tức thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.image_url = this.formAdd.image = ''
                        this.$refs.fileInput.value = ''
                        this.$router.push('/quan-ly-tin-tuc/danh-sach/chi-tiet/' + this.$route.params.id)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/news/getNewsDetail',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formAdd = res.data
                this.image_url = res.data.image
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .add-news {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .add-new {
        background: #F58E16;
        color: #FFF;
    }

    .cancel {
        color: #EE3439;
        border: 1px solid #EE3439;
        margin-right: 15px;
    }

    .wp-ttl, .wp-img, .wp-lnk {
        background-color: #FFF;
        border-radius: 20px;
        padding: 21px 20px;
    }

    .wp-ttl {
        margin-right: 20px;
    }

    .wp-img .ttl, .wp-lnk .ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 22px;
    }

    .wp-img {
        margin-bottom: 20px;
    }

    .wp-upload {
        text-align: center;
        border-radius: 20px;
        background: #F8F8F8;
        padding: 25px 10px 17px;
        cursor: pointer;
    }

    .wp-upload p {
        margin-bottom: 0;
        font-size: 15px;
    }
    
    .wp-upload p:last-child {
        color: #B4B4B4;
        font-size: 13px;
    }

    .note_input {
        color: #808080;
        font-size: 14px;
        padding-left: 15px;
    }

    textarea.form-control {
        padding: 12px;
        border-radius: 15px;
        background: #F6F6F6;
        resize: none;
        color: #606266;
        box-shadow: none;
        font-size: 14px;
    }

    textarea.form-control::placeholder {
        color: #606266;
        font-weight: 400;
    }

    textarea.form-control::-webkit-scrollbar {
        width: 0;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>

<style>
    .add-news .el-form-item__label {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
    }

    .add-news .el-input__wrapper {
        padding: 12px;
        max-height: 40px;
        border-radius: 15px;
        background: #F6F6F6;
        width: 100%;
    }

    .add-news .el-select {
        width: 100%;
    }

    .add-news .wp-lnk .el-input__wrapper {
        height: 70px;
        max-height: 70px;
    }

    .add-news .wp-lnk .el-form-item__label {
        color: #686868;
        font-size: 14px;
        font-weight: 400;
    }
</style>