<template>
    <div class="cz-product-gallery">
        <div class="cz-preview">
            <button type="button" class="btn-modal-gallery" v-if="!showVideo">
                <img class="show-imag img-responsive" v-if="selectedImage && !show360" :src="selectedImage" alt="Product image"
                    @error="handleError">
                <iframe :key="iframeKey" style="border-style:none; width: 100%; height: 500px; z-index: 2; position: relative;" v-if="show360" allowfullscreen :src="'https://cdn.pannellum.org/2.5/pannellum.htm#panorama='+$storage + image360"></iframe>
            </button>
             <div class="mr-1" v-if="this.videoUrl !== '' && showVideo">
                <iframe style="width: 100%; height: 500px; z-index: 2; position: relative;" :src="'https://www.youtube.com/embed/' + this.videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="cz-slide-thumnail d-xl-block">
            <div class="mr-1" :class="{'mb-1': key != list_images.length - 1 }" v-for="(photo, key) in list_images">
                <a class="pr-0 cz-thumblist-item ">
                    <img :src="$storage + photo.path" alt="Product thumb" @click="showImage(key, photo), showVideo = false" @error="handleError">
                </a>
            </div>
            <div class="mr-1 mt-1" v-if="this.thumbnailUrl">
                <a class="pr-0 cz-thumblist-item ">
                    <img :src="this.thumbnailUrl" alt="Product thumb" @click="showVideo = true" @error="handleError">
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['list_images', 'carouselKey', 'video', 'thumbnail'],
    data() {
        return {
            selectedImage: '',
            galleryKey: 0,
            dataImg: this.list_images,
            videoUrl: '',
            thumbnailUrl: '',
            showVideo: false,
            image360: '',
            show360: false,
            iframeKey: 0
        }
    },
    mounted() {
        this.dataImg = this.list_images
        this.videoUrl = this.video
        // this.selectedImage = this.$storage + this.dataImg[0].path
    },
    watch: {
        list_images() {
            this.dataImg = this.list_images;
            if(this.dataImg.length !== 0) {
                if(this.dataImg[0].is_360 == 1) {
                    this.image360 = this.dataImg[0].path
                    this.show360 = true
                } else {
                    this.selectedImage = this.$storage + this.dataImg[0].path
                    this.show360 = false
                }
            }
        },
        video() {
            if(this.video) {
                if(this.video.includes('https://youtu.be/')) {
                    const parts = this.video.split("https://youtu.be/");
                    if (parts.length > 1) {
                        this.videoUrl = parts[1];
                    }
                } else if(this.video.includes('/shorts')) {
                    this.videoUrl = this.video.split("/shorts/")[1]
                } else {
                    const equalSignIndex = this.video.indexOf("?v=");
                    if (equalSignIndex !== -1) {
                        this.videoUrl = this.video.substring(equalSignIndex + 3);
                    }
                }
            }
        },
        thumbnail() {
            this.thumbnailUrl = this.thumbnail
        }
    },
    methods: {
        showImage(key, item) {
            this.galleryKey = key
            this.selectedImage = event.target.src;
            if(item.is_360 == 1){
                this.image360 = item.path
                this.show360 = true
                this.iframeKey += 1;
            } else {
                this.show360 = false
            }
        },
    },
}
</script>
<style scoped lang="css">
    .cz-preview {
        width: 87%;
    }

    .cz-preview img {
        width: 100%;
        border-radius: 5px;
        min-height: 500px;
    }

    .cz-thumblist-item {
        display: block;
        cursor: pointer;
    }

    .btn-modal-gallery {
        border: unset;
        background: none;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .btn-modal-gallery img {
        height: 239px;
        width: 100%;

    }

    .cz-slide-thumnail {
        overflow: auto;
        height: 500px;
        width: 13%;
    }

    .cz-slide-thumnail img {
        width: 100%;
        height: 72px;
        border-radius: 5px;
        object-fit: cover;
    }

    .cz-slide-thumnail::-webkit-scrollbar {
        width: 0;
    }

    .cz-product-gallery {
        display: flex;
        /* flex-wrap: wrap; */
        width: 100%;
        /* justify-content: center; */
    }

    @media (min-width: 576px) {
        img.show-imag {
            width: 97%;
            height: 400px;
            object-fit: cover;
        }
    }
    @media (min-width: 992px) {
        
        .cz-slide-thumnail {
            overflow: auto;
            margin-left: 0.5rem;
        }

        .cz-slide-thumnail img {
            width: 100%;
            height: 97px;
        }

        .cz-preview img {
            width: 100%;
            height: 500px;
            /* object-fit: fill; */
        }
    }

    @media (min-width: 1280px) {
        .cz-slide-thumnail {
            margin-left: 0.25rem;
            height: 500px;
        }

        .cz-product-gallery {
            flex-wrap: nowrap;
            width: 100%;
        }
    }
</style>
<style>
    .swiper-button-prev:after,
    .swiper-button-next:after {
        content: '';
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 48px;
    }

    .swiper-button-prev:after {
        background-image: url('@/assets/icons/prev_page.png');
        width: 48px;
        height: 48px;
    }

    .swiper-button-next:after {
        background-image: url('@/assets/icons/next_page.png');
        width: 48px;
        height: 48px;
    }

    .swiper-pagination-bullet-active {
        background: #fff !important;
    }

    .swiper-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0 !important;
    }
</style>