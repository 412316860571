<template>
    <div class="detail kpi-self">
        <h5>KPI cá nhân</h5>
        <el-row class="justify-content-between">
            <el-col :span="8">
                <div class="info">
                    <img src="@/assets/icons/avatar.png" alt="">
                    <div>
                        <p>Nguyễn Văn A</p>
                        <p>Leader</p>
                    </div>
                </div>
                <el-progress type="circle" :percentage="80" status="warning">
                    <template #default="{ percentage }">
                        <span class="percentage-label">Hoàn thành</span>
                        <span class="percentage-value">{{ percentage }}%</span>
                    </template>
                </el-progress>
                <div class="wp-btn">
                    <button>+ Add KPI</button>
                    <button class="act_btn"><img src="@/assets/icons/pencil.png" alt=""></button>
                    <button class="act_btn"><img src="@/assets/icons/trash.png" alt=""></button>
                </div>
            </el-col>
            <el-col :span="16">
                <h6>Tháng 03</h6>
                <div class="item">
                    <div class="ico">
                        <img src="@/assets/icons/bar_chart.png" alt="">
                        <p>KPI doanh số: </p>
                    </div>
                    <div class="content">
                        <p><span class="stt--success">120.000.000</span> / 120.000.000 VNĐ</p>
                    </div>
                    <p class="stt stt--success">100%</p>
                </div>
                <div class="item">
                    <div class="ico">
                        <img src="@/assets/icons/bar_chart.png" alt="">
                        <p>KPI công việc: </p>
                    </div>
                    <div class="content">
                        <p>4/5 bất động sản mới</p>
                        <p>2/2 giao dịch mới</p>
                        <p>22/20 lượt dẫn khách</p>
                    </div>
                    <p class="stt stt--fail">90%</p>
                </div>
                <div class="item">
                    <div class="ico">
                        <img src="@/assets/icons/bar_chart.png" alt="">
                        <p>KPI nhân sự: </p>
                    </div>
                    <div class="content">
                        <p>2/2 đại lý mới</p>
                    </div>
                    <p class="stt stt--success">100%</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

export default {

}
</script>
<style lang="css" scoped>
    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 842px;
        margin: 0 auto;
    }

    h5 {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
    }

    .info {
        display: flex;
        align-items: center;
    }

    .info img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        object-fit: cover;
        margin-right: 10px
    }

    .info p {
        color: #515151;
        font-weight: 500;
        line-height: 23.821px;
        letter-spacing: 0.16px;
        margin: 0
    }

    .el-progress {
        margin: 20px 0;
    }

    .percentage-label {
        color: #515151;
        text-align: center;
        font-size: 21.069px;
        font-weight: 500;
        line-height: 38.606px;
        letter-spacing: 0.211px;
        display: block;
    }

    .percentage-value {
        color: #515151;
        text-align: center;
        font-size: 45.379px;
        font-weight: 500;
        line-height: 38.606px;
        letter-spacing: 0.454px;
    }

    .wp-btn {
        display: flex;
        align-items: center;
    }

    .wp-btn button:first-child {
        border-radius: 15px;
        background: #F58E16;
        padding: 10px 14px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-btn button.act_btn {
        border-radius: 23px;
        background: #F58E16;
        padding: 10px;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
    }

    .wp-btn button.act_btn img {
        filter: brightness(20);
    }

    .wp-btn button.act_btn:last-child {
        background: #EE3439;
    }

    h6 {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 30px;
    }

    .item, .item .ico {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item .ico {
        flex-basis: 30%;
        justify-content: flex-start;
    }

    .item p.stt {
        flex-basis: 10%
    }

    .item .content {
        flex-basis: 50%;
    }

    .ico img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }

    .ico p {
        color: #515151;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin: 0;
        font-weight: 400;
    }
    
    .item {
        margin-bottom: 20px;
    }

    .item p {
        margin: 0;
        color: #515151;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.16px;
    }

    .item .stt--success {
        color: #00B569;
    }

    .item .stt--fail {
        color: #EE3439;
    }
</style>

<style>
    .kpi-self .el-progress-circle {
        width: 190px !important;
        height: 100% !important;
    }
</style>