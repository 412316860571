<template>
    <div class="wp-confirm">
        <h5>Quản lý thông báo</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quản lý thông báo</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <p style="margin: 0;">Số thông báo: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></p>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1), status_txt = 'Tất cả trạng thái'"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
                <button class="btn_add" @click="$router.push('/he-thong/quan-ly-thong-bao/tao-moi')">Tạo thông báo mới</button>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="sp_tbl" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="85"/>
                    <el-table-column header-align="center" prop="title" label="Tiêu đề thông báo" min-width="870" />
                    <el-table-column header-align="center" align="center" prop="create_at" label="Ngày tạo" min-width="130">
                        <template #default="scope">
                            <span v-if="scope.row.created_at">{{ formateDate(scope.row.created_at) }}</span>
                            <span v-else>----</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="send_at" label="Ngày gửi" min-width="130">
                        <template #default="scope">
                            <span v-if="scope.row.push_at">{{ formateDate(scope.row.push_at) }}</span>
                            <span v-else>----</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="noti_status" label="Trạng thái" width="130">
                        <template #default="scope">
                            <span v-if="scope.row.noti_status == 2" style="color: #00B569;">Đã gửi</span>
                            <span v-else-if="scope.row.noti_status == 1" style="color: #F03030;">Chưa gửi</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="124">
                        <template #default="scope">
                            <button><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Tất cả trạng thái',
            stt_key: ''
        }
    },
    mounted() {
        this.getListNoti()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.getListNoti()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.getListNoti()
        },
        async getListNoti() {
            this.startLoading();
            await this.$axios.get('/notification/getListNotificationManager',{
                params: {
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/he-thong/quan-ly-thong-bao/chi-tiet/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px 0 0;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    button.btn_add {
        font-family: 'Public Sans', sans-serif;
        width: 160px;
        height: 40px;
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
    }
</style>

<style>
    .sp_tbl tbody tr {
        height: 65px;
    }
</style>