<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_utilities"
        @close="emitDialogVisible(false)"
        title="Chọn tiện ích dự án"
    >
        <div class="d-flex align-items-center justify-content-between">
            <label class="wp-lable" style="margin-bottom: 0;">
                <p>Chọn tất cả</p>
                <input type="checkbox" class="input-style" v-model="selectAll" @input="toggleAll">
                <span class="checkmark"></span>
            </label>
            <div class="wp-btn">
                <button class="btn_del" @click="statusDelete = true">Xóa tiện ích</button>
                <button class="btn_add" @click="dialogAdd = true">+ Thêm tiện ích mới</button>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <label class="wp-lable">
                    <div class="d-flex align-items-center">
                        <img :src="$storage + item.icon" alt="">
                        <p>{{ item.name }}</p>
                    </div>
                    <input type="checkbox" class="input-style" :value="item" v-model="selectedItems">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
        <template #footer>
            <div class="dialog-footer">
                <button class="btn_confirm" @click="confirm">Xác nhận tiện ích</button>
            </div>
        </template>

        <el-dialog
            v-model="dialogAdd"
            width="553"
            class="dialog_add_utilities"
            @close="dialogAdd = false"
            align-center
        >
            <p class="ttl">Thêm tiện ích mới</p>
            <el-form
                ref="formAddUti"
                :model="formAddUti"
                :rules="rules"
                class="demo-ruleForm"
                status-icon
                @submit.prevent="addUtilities('formAddUti')"
            >
                <el-form-item label="Nhập icon và tên tiện ích" prop="name">
                    <div style="width: 70px; margin-right: 15px; text-align: center;">
                        <img src="@/assets/images/add_icon.png" alt="" class="icon" v-if="!icon_url" @click="$refs.fileInput.click()">
                        <img :src="$storage + icon_url" alt="" class="icon" v-else @click="$refs.fileInput.click()">
                    </div>
                    <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
                    <el-input v-model="formAddUti.name" type="text" placeholder="Nhập tên tiện ích" />
                </el-form-item>
            </el-form>
            <div class="wp-btn-uti" style="margin-top: 40px;">
                <button class="btn_cancel" @click="closeDialog">Hủy bỏ</button>
                <button class="btn_add" @click="addUtilities('formAddUti')">Xác nhận</button>
            </div>
        </el-dialog>

        <Dialog 
            :dialogVisible="statusDelete"
            @handleCloseDialog="listenDialog"
            @confirm="confirmDelete"
            title="Xóa tiện ích"
            content="Bạn đang yêu cầu xóa bỏ các tiện ích"
            content_success="Bạn đã xóa danh mục thành công"
        />
    </el-dialog>
</template>
<script>
import Dialog from '../../components/dialog.vue'
export default {
    props: ['status'],
    components: {Dialog},
    data() {
        return {
            formAddUti: {
                icon: '',
                name: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên tiện ích", trigger: ["blur", "change"]}],
            },
            dialogVisibleStatus: false,
            list: [],
            statusDelete: false,
            dialogAdd: false,
            icon_url: '',
            selectAll: false,
            selectedItems: [],
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.getListUtilities()
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
    },
    methods: {
        toggleAll() {
            if(!this.selectAll) {
                this.selectAll = true
            } else {
                this.selectAll = false
            }
            if (this.selectAll) {
                this.selectedItems = this.list;
            } else {
                this.selectedItems = [];
            }
        },
        listenDialog(dialogStatus) {
            this.statusDelete = dialogStatus;
        },
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.selectedItems = [];
            this.selectAll = false
        },
        closeDialog() {
            this.dialogAdd = false
            this.turnOffErrorMessage('formAddUti')
            this.icon_url = this.formAddUti.icon = ''
        },
        async confirmDelete() {
            this.startLoading();
            await this.$axios.put('/project/deleteUtilities', {id: this.selectedItems.map(item => item.id)}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tiện ích thành công!");
                this.getListUtilities()
                this.selectAll = false
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        },
        confirm() {
            this.dialogVisibleStatus = false
            this.$emit("confirm", this.selectedItems);
        },
        async handleFileChange(event) {
            let url_image = await this.uploadFile('utilities/icon/', event.target.files[0])
            this.icon_url = url_image.path_tam
            this.formAddUti.icon = url_image.path
        },
        async getListUtilities() {
            this.startLoading();
            await this.$axios.get('/project/getListUtilities', this.formAddUti, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        addUtilities(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAddUti.icon) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    await this.$axios.post('/project/createUtilities', this.formAddUti, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thêm tiện ích thành công!");
                        this.turnOffErrorMessage('formAddUti')
                        this.icon_url = this.formAddUti.icon = this.$refs.fileInput.value = ''
                        this.dialogAdd = false
                        this.getListUtilities()
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .wp-lable p {
        margin: 0 0 0 28px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
    }

    .wp-btn button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        padding: 3.5px 14px;
        border-radius: 15px;
    }

    button {
        outline: none;
    }

    .wp-btn button.btn_del {
        color: #EE3439;
        border: 1px solid #EE3439;
    }

    .wp-btn button.btn_add {
        color: #F58E16;
        border: 1px solid #F58E16;
        margin-left: 10px;
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
        max-height: 400px;
        overflow: auto;
    }

    .list::-webkit-scrollbar {
        width: 0;
    }

    .list .item {
        flex-basis: 50%;
    }

    .list .item p {
        margin-left: 0;
    }

    .list .item img {
        margin: 0 15px 0 32px;
    }

    .list span.checkmark {
        top: 8px;
    }

    button.btn_confirm {
        font-family: 'Public Sans', sans-serif;
        color: #FFF;
        background: #F58E16;
        border: none;
        font-size: 14px;
        font-weight: 600;
        padding: 9.5px 15px;
        border-radius: 15px;
        margin-top: 18px;
    }

    p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .icon {
        cursor: pointer;
        max-width: 70px;
        max-height: 70px;
        border-radius: 15px;
    }

    .wp-btn-uti button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 0.905px solid #F58E16;
        background-color: #fff;
        width: 48%;
        height: 40px;
    }

    .wp-btn-uti button.btn_add {
        margin-left: 13px;
        color: #FFF;
        background-color: #F58E16;
    }
</style>

<style>
    .dialog_utilities {
        border-radius: 20px !important;
    }

    .dialog_utilities .el-dialog__title {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
    }

    .dialog_utilities .el-dialog__header {
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px 20px 0px 0px;
        margin-right: 0;
    }

    .dialog_utilities .el-dialog__footer {
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 20px 20px;
    }

    .dialog_add_utilities .el-dialog__header {
        display: none;
    }

    .dialog_add_utilities {
        border-radius: 20px !important;
    }

    .dialog_add_utilities .el-dialog__body {
        padding: 11px 16px 16px;
    }

    .dialog_add_utilities .el-input {
        width: 83%;
    }

    .dialog_add_utilities .el-input__wrapper {
        height: 70px !important;
    }
</style>