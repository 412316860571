<template>
    <div class="form-demand">
        <h5>Chi tiết khách hàng</h5>
        <div class="breadcrumb">
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Khách hàng quản lý</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết khách hàng</span>
        </div>
        <div class="wp-content">
            <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                <div>
                    <span class="ttl" v-if="!editPage">Tạo mới nhu cầu</span>
                    <span class="ttl" v-else>Chỉnh sửa nhu cầu</span>
                </div>
                <div>
                    <button class="btn-del" @click="$router.go(-1)">Hủy bỏ</button>
                    <button class="btn-add" @click="submitForm('ruleForm')">
                        <span v-if="editPage">Lưu chỉnh sửa</span>
                        <span v-else>Tạo mới</span>
                    </button>
                </div>
            </div>
            <el-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                status-icon
                @submit.prevent="submitForm('ruleForm')"
            >
                <div class="list-btn">
                    <button :class="{'active' : btn_active == 1}" type="button" @click="activePage(1, 1)" :disabled="editPage">Rao bán</button>
                    <button :class="{'active' : btn_active == 2}" type="button" @click="activePage(2, 1)" :disabled="editPage">Cho thuê</button>
                    <button :class="{'active' : btn_active == 3}" type="button" @click="activePage(3, 2)" :disabled="editPage">Mua</button>
                    <button :class="{'active' : btn_active == 4}" type="button" @click="activePage(4, 2)" :disabled="editPage">Thuê</button>
                </div>
                <el-form-item prop="title" label="Tiêu đề" class="set-height">
                    <el-input type="text" placeholder="Nhập tiêu đề" v-model="ruleForm.title"/>
                    <span class="note">Tối thiểu 15 ký tự, tối đa 99 ký tự</span>
                </el-form-item>

                <div v-if="typePage == 1">
                    <p class="label">Ảnh</p>
                    <div class="mb-3">
                        <el-upload
                            drag
                            :accept="'image/png, image/jpeg, image/jpg'"
                            multiple
                            :show-file-list="false"
                            :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList)"
                            :action="$API + '/common/upload360image'"
                            :data="{path : 'job/' }"
                            list-type="picture"
                            :on-progress="handleUploadProgress"
                        >
                            <img src="@/assets/icons/Collectibles.png" alt="">
                            <div class="el-upload__text">Bấm để chọn ảnh cần tải lên</div>
                            <div class="el-upload__tip" slot="tip">hoặc kéo thả ảnh vào đây</div>
                        </el-upload>
                        <!-- <span v-if="ruleForm.image.length==0 && showErr" class="error">Vui lòng thêm hình ảnh</span> -->
                    </div>
                    
                    <div class="col-12 d-flex flex-wrap" >
                        <draggable class="list-img w-full d-flex flex-wrap" :list="ruleForm.image">
                            <div class="wp-img" v-for="(item, index) in ruleForm.image">
                                <img :src="this.$storage + item.path_tam" alt="" @error="handleError" v-if="item.path_tam">
                                <img :src="this.$storage + item.path" alt="" @error="handleError" v-else>
                                <div class="d-flex align-items-center justify-content-between">
                                    <span :class="{'hidden': index !== 0}">Ảnh đại diện</span>
                                    <button type="button" @click="ruleForm.image.splice(index, 1)">
                                        <img src="@/assets/icons/Trash_Can.png" alt="" >
                                    </button>
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <!-- <span v-if="ruleForm.image.length !== 0 && ruleForm.image.length < 4 && showErr" class="error">Vui lòng đăng tối thiểu 4 hình ảnh</span> -->
                    <!-- <span v-else-if="ruleForm.image.length !== 0 && ruleForm.image.length > 20 && showErr" class="error">Vui lòng đăng tối đa 20 hình ảnh</span> -->
                </div>
                
                <el-form-item prop="category_id" label="Chọn loại bất động sản">
                    <el-select
                        filterable
                        placeholder="Chọn loại bất động sản"
                        style="width: 100%"
                        v-model="ruleForm.category_id"
                        v-if="typePage == 1"
                    >
                        <el-option
                            v-for="(item, index) in dataCate"
                            :key="index"
                            :label="item.category_name"
                            :value="item.id"
                        />
                    </el-select>
                    <el-select
                        multiple
                        clearable
                        placeholder="Chọn loại bất động sản"
                        style="width: 100%"
                        v-model="ruleForm.category_id"
                        v-else
                    >
                        <el-option
                            v-for="(item, index) in dataCate"
                            :key="index"
                            :label="item.category_name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <p class="label" v-if="typePage == 1">Khu vực</p>
                <p class="label" v-else>Khu vực mong muốn</p>
                <el-form-item prop="province_id">
                    <el-select
                        filterable
                        clearable
                        placeholder="Tỉnh / Thành phố"
                        style="width: 100%"
                        v-model="ruleForm.province_id"
                        @change="changeCity('change')"
                    >
                        <el-option v-for="item in dataCity"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="district_id">
                    <el-select
                        filterable
                        clearable
                        placeholder="Quận / Huyện"
                        style="width: 100%"
                        v-model="ruleForm.district_id"
                        @change="changeDistrict('change')"
                        v-if="typePage == 1"
                    >
                        <el-option v-for="item in dataDistricts"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                    <el-select
                        multiple
                        filterable
                        clearable
                        placeholder="Quận / Huyện"
                        style="width: 100%"
                        v-model="ruleForm.district_id"
                        @change="changeDistricts('change')"
                        v-else
                    >
                        <el-option v-for="item in dataDistricts"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="ward_id">
                    <el-select
                        filterable
                        clearable
                        placeholder="Phường / Xã"
                        style="width: 100%"
                        v-model="ruleForm.ward_id"
                        v-if="typePage == 1"
                    >
                        <el-option v-for="item in dataWard"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                    <el-select
                        multiple
                        filterable
                        clearable
                        placeholder="Phường / Xã"
                        style="width: 100%"
                        v-model="ruleForm.ward_id"
                        v-else
                    >
                        <el-option v-for="item in dataWard"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="address" label="Địa chỉ chi tiết" v-if="typePage == 1">
                    <el-input type="text" placeholder="Nhập địa chỉ chi tiết" v-model="ruleForm.address"></el-input>
                </el-form-item>
                <el-form-item prop="content" label="Nhu cầu">
                    <el-input type="text" placeholder="Nhập nhu cầu" v-model="ruleForm.content"></el-input>
                </el-form-item>
                <hr>
                <el-form-item prop="acreage" label="Diện tích">
                    <el-input type="text" placeholder="Nhập diện tích" v-model="ruleForm.acreage" v-if="typePage == 1"></el-input>
                    <el-select
                        multiple
                        clearable
                        placeholder="Chọn diện tích"
                        style="width: 100%"
                        v-model="ruleForm.acreage"
                        v-else
                    >
                        <el-option
                            v-for="(item, index) in params.acreage_filter"
                            :key="index"
                            :label="item.text"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="price" label="Khoảng giá">
                    <el-input type="text" placeholder="Nhập khoảng giá" v-model="ruleForm.price" v-if="typePage == 1"></el-input>
                    <el-select
                        multiple
                        clearable
                        placeholder="Chọn khoảng giá"
                        style="width: 100%"
                        v-model="ruleForm.price"
                        v-else
                    >
                        <el-option
                            v-for="(item, index) in params.price_filter"
                            :key="index"
                            :label="item.text"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="commission" label="Hoa hồng" v-if="typePage == 1">
                    <el-input type="text" placeholder="Nhập hoa hồng" v-model="ruleForm.commission" @input="ruleForm.commission = ruleForm.commission.replace(/[^0-9.]/g, '')"></el-input>
                </el-form-item>
                <el-form-item prop="direction" label="Hướng ban công" v-if="typePage == 2">
                    <el-select
                        multiple
                        clearable
                        placeholder="Chọn hướng ban công"
                        style="width: 100%"
                        v-model="ruleForm.direction"
                        v-if="params.direction_filter"
                    >
                        <el-option
                            v-for="(item, index) in params.direction_filter.value"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="bedroom" label="Phòng ngủ" v-if="typePage == 2">
                    <el-select
                        multiple
                        clearable
                        placeholder="Chọn số phòng ngủ"
                        style="width: 100%"
                        v-model="ruleForm.bedroom"
                        v-if="params.bedroom_filter"
                    >
                        <el-option
                            v-for="(item, index) in params.bedroom_filter.value"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="description" label="Mô tả BĐS">
                    <textarea name="title" v-model="ruleForm.description" :style="{ height: this.calculatedHeight('bds-des') }" ref="bds-des" class="form-control" @input="this.adjustTextareaHeight"></textarea>
                    <el-input type="textarea" class="d-none"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next'
export default {
    components: { draggable: VueDraggableNext },
    data() {
        return {
            dataDetail: {
                view_status: ''
            },
            ruleForm: {
                type: 1,
                title: '',
                image: [],
                category_id: '',
                province_id: '',
                district_id: '',
                ward_id: '',
                address: '',
                content: '',
                acreage: '',
                price: '',
                commission: '',
                direction: '',
                bedroom: '',
                description: '',
                customer_id: this.decode(this.$route.params.cusId)
            },
            rules: {
                title: [
                    {required: true, message: "Vui lòng nhập tên tiêu đề", trigger: ["blur", "change"]},
                    {min: 15, message: "Vui lòng nhập trên 15 ký tự", trigger: ["blur", "change"]},
                    {max: 99, message: "Vui lòng không nhập quá 99 ký tự", trigger: ["blur", "change"]},
                ],
                // province_id: [{required: true, message: "Vui lòng chọn tỉnh / thành phố", trigger: ["blur", "change"]}],
                // district_id: [{required: true, message: "Vui lòng chọn quận / huyện", trigger: ["blur", "change"]}],
                // ward_id: [{required: true, message: "Vui lòng chọn phường / xã", trigger: ["blur", "change"]}],
                // category_id: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
                // demand: [{required: true, message: "Vui lòng nhập nhu cầu", trigger: ["blur", "change"]}],
                // acreage: [{required: true, message: "Vui lòng nhập diện tích", trigger: ["blur", "change"]}],
                // price: [{required: true, message: "Vui lòng nhập khoảng giá", trigger: ["blur", "change"]}],
                // commission: [{required: true, message: "Vui lòng nhập % hoa hồng", trigger: ["blur", "change"]}],
            },
            showErr: false,
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            dataCate: [],
            btn_active: 1,
            typePage: 1, // 1 rao bán + cho thuê, 2 mua + thuê
            params: [],
            editPage: false
        }
    },
    mounted() {
        this.getDataCity()
        this.getDataCate()
        this.getParams()
        if(this.$route.path.includes('nhu-cau/chinh-sua/')) {
            this.editPage = true
            this.getDetail()
        } else {
            this.editPage = false
        }
    },
    methods: {
        activePage(active, type) {
            this.btn_active = active
            this.typePage = type
            this.ruleForm.type = active
            this.$refs.ruleForm.resetFields()
        },
        uploadFileImage(res, file, fileList) {
            this.endLoading()
            let fileItem = {
                path : res.list_result[0].path,
                path_tam : res.list_result[0].path_tam,
                is_360 : false,
                check360: res.list_result[0].is_360
            }
            this.ruleForm.image.push(fileItem)
        },
        handleUploadProgress(event, file, fileList) {
            this.startLoading()
        },
        async changeCity(type) {
            this.dataDistricts = await this.callApiDistrict(this.ruleForm.province_id)
            if(type == 'change') {
                this.ruleForm.district_id = this.ruleForm.ward_id = ''
                this.dataWard = []
            }
        },
        async changeDistricts(type) {
            const res = await this.$axios.get('/common/getWardByDistricts?district_id=' + this.ruleForm.district_id)
            if (res.status == 200) {
                this.dataWard = res.data.ward
            }
            if(type == 'change') {
                this.ruleForm.ward_id = ''
            }
        },
        async changeDistrict(type) {
            this.dataWard = await this.callApiWard(this.ruleForm.district_id)
            if(type == 'change') {
                this.ruleForm.ward_id = ''
            }
        },
        async getDataCate() {
            var data = await this.getListCategory()
            this.dataCate = data.result
        },
        async getParams() {
            await this.$axios.get('/real_estate/getParamsSearch',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.params = res.data
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    // || this.ruleForm.image.length == 0 || this.ruleForm.image.length < 4 || this.ruleForm.image.length > 20
                    // this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    if(this.ruleForm.type == 3 || this.ruleForm.type == 4) {
                        this.ruleForm.image = []
                    }
                    if(!this.editPage) {
                        var methods = 'post'
                        var lnk = 'createCustomerNeeds'
                        var mss = 'Tạo nhu cầu mới thành công!'
                    } else {
                        var methods = 'put'
                        var lnk = 'updateCustomerNeeds'
                        var mss = 'Chỉnh sửa nhu cầu thành công!'
                    }
                    this.startLoading();
                    await this.$axios[methods]('/job/' + lnk, this.ruleForm, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", mss);
                        this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId + '/nhu-cau')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/job/getDetailCustomerNeeds',{
                params: {
                    id: this.decode(this.$route.params.demandId)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.ruleForm = res.data
                this.btn_active = res.data.type
                if(res.data.type == 1 || res.data.type == 2) {
                    this.typePage = 1
                    this.ruleForm.category_id = res.data.category[0].id
                    this.ruleForm.province_id = res.data.province[0].code
                    this.ruleForm.district_id = res.data.district[0].code
                    this.ruleForm.ward_id = res.data.ward[0].code
                    this.changeCity('get')
                    this.changeDistrict('get')
                } else {
                    this.ruleForm.category_id = res.data.category.map(item => item.id)
                    this.ruleForm.acreage = res.data.acreage.map(item => item.id)
                    this.ruleForm.price = res.data.price.map(item => item.id)
                    this.ruleForm.direction = res.data.direction.map(item => item.id)
                    this.ruleForm.bedroom = res.data.bedroom.map(item => item.id)
                    this.ruleForm.province_id = res.data.province[0].code
                    this.ruleForm.district_id = res.data.district.map(item => item.code)
                    this.ruleForm.ward_id = res.data.ward.map(item => item.code)
                    this.changeCity('get')
                    this.changeDistricts('get')
                    this.typePage = 2
                }
            })
            this.endLoading()
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 0 auto;
    }

    h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    span.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px; 
    }

    .btn-add {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        background: #F58E16;
        width: 132px;
        height: 40px;
    }

    .btn-del {
        color: #EE3439;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        width: 132px;
        height: 40px;
        border: 1px solid #EE3439;
        margin: 0 10px;
    }

    .list-btn {
        margin-bottom: 20px;
        border-radius: 55px;
        border: 1px solid #B4B4B4;
        width: max-content;
        padding: 5px;
    }

    .list-btn button {
        color: #515151;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.075px;
        padding: 6px 23px;
    }

    .list-btn button.active {
        color: #F58E16;
        border-radius: 41px;
        background: #FCE3C5;
    }

    .wp-img {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 2.119px 0px rgba(0, 0, 0, 0.25);
        padding: 5px;
        margin: 0 8px 8px 0;
    }

    .wp-img button img {
        height: 24px;
        width: 20px;
    }

    .wp-img:nth-child(3n+3) {
        margin-right: 0;
    }

    .wp-img img {
        width: 240px;
        height: 152px;
        border-radius: 5px;
        object-fit: cover;
    }

    .wp-img span {
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 11.211px;
        background: rgba(89, 89, 89, 0.66);
        width: 114px;
        height: 22.33px;
        display: block;
        margin: 10px 0;
    }

    span.note {
        color: #808080;
        font-size: 14px;
        line-height: 180%;
    }

    textarea {
        resize: none;
        box-shadow: none !important;
        padding: 10px;
        border-radius: 20px;
        background: #F6F6F6 !important;
    }

    textarea::-webkit-scrollbar {
        width: 0;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 16px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .hidden {
        opacity: 0;
    }
</style>

<style>
    .form-demand .el-form-item__label, .form-demand p.label {
        color: #515151;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
        margin: 0;
    }

    .form-demand .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 10px;
    }

    .form-demand .set-height .el-input__wrapper {
        padding: 20px 10px;
    }

    .form-demand .el-upload-dragger {
        border: none;
        border-radius: 20px;
        background: #F6F6F6;
        padding: 20px 10px;
    }

    .form-demand .el-select__tags .el-tag--info {
        border-radius: 41px;
        background: #FCE3C5;
    }

    .form-demand .el-select__tags .el-select__tags-text {
        color: #515151;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
    }

    .form-demand .el-select__tags i, .form-demand .el-select__tags svg {
        width: 15px;
        height: 15px;
    }
</style>