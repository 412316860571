import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
        adminInfo: JSON.parse(localStorage.getItem('admin')),
    };
  },
  mutations: {
      login(state, adminInfo) {
          state.adminInfo = adminInfo;
      },
      logout(state) {
          state.adminInfo = null;
          localStorage.removeItem('admin');
      },
  },
  actions: {
      loginAdmin({ commit }, adminInfo) {
          commit('login', adminInfo);
          localStorage.setItem('admin', JSON.stringify(adminInfo));
      },
      logoutAdmin({ commit }) {
          commit('logout');
      },
  },
  getters: {
      getAdminInfo: state => state.adminInfo
  }
});

export default store;