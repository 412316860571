<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice.png" alt="">
        <p class="ttl">Yêu cầu không chấp nhận KPI</p>
        <p>Nhân viên đã gửi yêu cầu không chấp nhận KPI <br>Bạn hãy xem xét và giải quyết</p>
        <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do từ chối:</p>
        <p class="content">Team A đang thiếu sale nên mức KPI tháng 4 quá cao so với năng lực team hiện tại, mong muốn cấp trên giảm bớt KPI của team xuống. Xin cảm ơn</p>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="rejectVisible = true">TỪ CHỐI</button>
            <button class="btn_add" @click="confirm">CHẤP NHẬN</button>
        </div>
    </el-dialog>

    <RejectDialog 
        :rejectVisible="rejectVisible"
        @handleCloseDialog="rejectDialog"
        @confirm="cancelRequest"
        :title="'Xác nhận từ chối'"
        :content="'Bạn xác nhận từ chối yêu cầu đổi KPI của nhân viên?'"
        :customer="'nhân viên'"
    />
</template>
<script>
import RejectDialog from '../../../components/rejectDialog.vue'
export default {
    props: ['complainDialog'],
    emits: ['handleCloseDialog', 'confirm'],
    components: {
        RejectDialog,
    },
    data() {
        return {
            dialogVisibleStatus: false,
            rejectVisible: false,
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.complainDialog
    },
    watch: {
        complainDialog() {
            this.dialogVisibleStatus = this.complainDialog;
        },
    },
    methods: {
        emitDialogVisible(complainDialog) {
            this.$emit("handleCloseDialog", complainDialog);
        },
        rejectDialog(rejectStatus) {
            this.rejectVisible = rejectStatus;
        },
        confirm() {
            this.$emit("confirm");
        },
        cancelRequest() {
            this.dialogVisibleStatus = false
        }
    },
}
</script>
<style lang="css" scoped>

    p.ttl {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        color: #444;
    }

    p.success {
        font-size: 18px;
        color: #00B569;
        font-weight: 500;
        margin: 8px 0 20px;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #EE3439;
    }

    button.btn_add {
        color: #FFF;
        background: #EE3439;
        border: none;
        margin-left: 13px;
    }

    p.content {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 15px;
        background: #F6F6F6;
        padding: 12px;
        text-align: justify;
    }
</style>