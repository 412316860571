<template>
    <div class="wp-confirm wp-support">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Tiếp nhận thông tin khách hàng</h5>
                <div class="breadcrumb">
                    <span>Hỗ trợ</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Tiếp nhận thông tin khách hàng</span>
                </div>
            </div>
            <button class="btn_add" @click="dialogAdd = true">Tạo thông tin khách hàng</button>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <p style="margin: 0;">Số yêu cầu: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></p>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ type_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn loại hỗ trợ</p>
                        <li><a class="dropdown-item" href="#" @click="type = 0, setPage(1), type_txt = 'Tất cả loại hỗ trợ'">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="type = 1, setPage(1), type_txt = 'Bất động sản'">Bất động sản</a></li>
                        <li><a class="dropdown-item" href="#" @click="type = 2, setPage(1), type_txt = 'Ngoài bất động sản'">Ngoài bất động sản</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                        <li><a class="dropdown-item" href="#" @click="stt_key = '', setPage(1), status_txt = 'Tất cả trạng thái'">Tất cả trạng thái</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 0, setPage(1), status_txt = 'Chưa xem'">Chưa xem</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 1, setPage(1), status_txt = 'Đã tiếp nhận'">Đã tiếp nhận</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 2, setPage(1), status_txt = 'Đã phản hồi'">Đã phản hồi</a></li>
                    </ul>
                </div>

                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1), status_txt = 'Tất cả trạng thái'"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Số điện thoại"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="sp_tbl" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="85"/>
                    <el-table-column header-align="center" align="center" prop="member_name" label="Họ và tên khách hàng" width="295" />
                    <el-table-column header-align="center" align="center" prop="member_email" label="Mail" min-width="350"/>
                    <el-table-column header-align="center" align="center" prop="member_phone" label="Số điện thoại" min-width="135"/>
                    <el-table-column header-align="center" align="center" prop="real_estate_code" label="Mã tin BĐS" width="200">
                        <template #default="scope">
                            <span class="code" v-if="scope.row.real_estate_code">#{{ scope.row.real_estate_code }}</span>
                            <span class="code" v-else>---</span>
                            <!-- {{ this.tableData.length - scope.$index }} -->
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="created_at" label="Ngày nhận" min-width="208">
                        <template #default="scope">
                            <span>{{ formateDate(scope.row.created_at) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="support_status" label="Trạng thái" width="120">
                        <template #default="scope">
                            <span v-if="scope.row.support_status == 2" style="color: #F58E16;">Đã phản hồi</span>
                            <span v-else-if="scope.row.support_status == 1" style="color: #00B569;">Đã tiếp nhận</span>
                            <span v-else-if="scope.row.support_status == 0" style="color: #F03030;">Chưa xem</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="100">
                        <template #default="scope">
                            <button @click.stop="dialogVisible = true, id = scope.row.id"><img src="@/assets/icons/trash.png" alt=""></button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
        <Dialog 
            :dialogVisible="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="deleteSup"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ hỗ trợ"
            content_success="Bạn đã xóa hỗ trợ thành công"
        />
        <el-dialog
            v-model="dialogAdd"
            width="842"
            class="dialog_add_sup"
            @close="turnOffErrorMessage('formAdd')"
            align-center
        >
            <p class="ttl">Tạo thông tin khách hàng</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                @submit.prevent="addSupport('formAdd')"
            >
                <el-form-item prop="name" label="Tên khách hàng">
                    <el-input v-model="formAdd.name" type="text" placeholder="Nhập tên khách hàng"/>
                </el-form-item>
                <el-form-item prop="phone" label="Số điện thoại">
                    <el-input v-model="formAdd.phone" type="text" maxlength="10" @input="formAdd.phone = formAdd.phone.replace(/[^\d]/g, '')" placeholder="Nhập số điện thoại"/>
                </el-form-item>
                <el-form-item prop="email" label="Email">
                    <el-input v-model="formAdd.email" type="text" placeholder="Nhập email"/>
                </el-form-item>
                <el-form-item prop="content" label="Nội dung yêu cầu">
                    <textarea :style="{ height: this.calculatedHeight('content') }" ref="content" v-model="formAdd.content" class="form-control" placeholder="Nội dung yêu cầu" @input="this.adjustTextareaHeight"></textarea>
                    <el-input type="textarea" v-model="formAdd.content" class="d-none"></el-input>
                </el-form-item>

                <el-form-item prop="agency_id" label="Chi nhánh phụ trách">
                    <el-select clearable v-model="formAdd.agency_id" placeholder="Chi nhánh phụ trách">
                        <el-option v-for="(item, index) in listAgency" :value="parseInt(item.id)" :label="item.agency_name" :key="index">{{ item.agency_name }}</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="category_id" label="Mảng phụ trách">
                    <el-select clearable v-model="formAdd.category_id" placeholder="Mảng phụ trách">
                        <el-option v-for="(item, index) in listCate" :value="parseInt(item.id)" :label="item.category_name" :key="index">{{ item.category_name }}</el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 20px; text-align: right;">
                <button class="btn_cancel" @click="dialogAdd = false">Hủy bỏ</button>
                <button class="btn_add" @click="addSupport('formAdd')">Tạo thông tin</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
import Dialog from '../components/dialog.vue'
export default {
    components: {
        Pagination,
        Dialog
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Tất cả trạng thái',
            type_txt: 'Tất cả loại hỗ trợ',
            stt_key: '',
            type: 0,
            dialogVisible: false,
            dialogAdd: false,
            id: '',
            formAdd: {
                name: '',
                phone: '',
                email: '',
                content: '',
                agency_id: '',
                category_id: '',
            },
            listAgency: [],
            listCate: [],
            listUser: [],
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên khách hàng", trigger: ["blur", "change"]}],
                phone: [
                    {required: true, message: "Vui lòng nhập số điện thoại", trigger: ["blur", "change"]},
                    {min: 10, message: "Số điện thoại tối thiểu 10 ký tự", trigger: ["blur", "change"]},
                ],
                email: [
                    {required: true, message: "Vui lòng nhập email", trigger: ["blur", "change"]},
                    { type: "email", message: "Vui lòng nhập email đúng định dạng", trigger: ["blur", "change"] },
                ],
                content: [{required: true, message: "Vui lòng nhập nội dung", trigger: ["blur", "change"]}],
                agency_id: [{required: true, message: "Vui lòng chọn chi nhánh", trigger: ["blur", "change"]}],
                category_id: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
            },
        }
    },
    mounted() {
        this.getListSup()
        this.getCate()
        this.getAgency()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListSup()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListSup()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getListSup() {
            this.startLoading();
            await this.$axios.get('/support/getListSupport',{
                params: {
                    status: this.stt_key,
                    type: this.type,
                    phone: this.keySearch,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/ho-tro/chi-tiet/' + this.encode(row.id))
        },
        async deleteSup() {
            this.startLoading();
            await this.$axios.put('/support/deleteSupport', {id: this.id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa hỗ trợ thành công!");
                this.getListSup()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getCate() {
            var data = await this.getListCategory()
            this.listCate = data.result
        },
        async getAgency() {
            var data = await this.getListAgency()
            this.listAgency = data.result
        },
        addSupport(formName) {
            this.$refs[formName].validate(async (valid) => {
                if(!valid) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    const res = await this.$axios.post('/support/supportMember',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Thêm thông tin khách hàng thành công!");
                        this.dialogAdd = false
                        this.getListSup()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                }
            });
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown {
        margin-right: 16px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    span.code {
        color: #F58E16;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
    }

    .btn_add {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        width: 200px;
        height: 40px;
    }

    .btn_cancel {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #FFF;
        width: 200px;
        height: 40px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        margin-right: 15px;
    }

    .dialog_add_sup p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
        text-align: center;
    }

    textarea.form-control {
        resize: none;
        box-shadow: none !important;
        padding: 12px 10px !important;
        border: none !important;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }
</style>

<style>
    .sp_tbl tbody tr {
        height: 65px;
    }

    .dialog_add_sup {
        border-radius: 20px !important;
    }

    .dialog_add_sup .el-form {
        width: 100%;
    }

    .dialog_add_sup .el-dialog__header {
        display: none;
    }

    .dialog_add_sup .el-dialog__body {
        padding: 20px;
    }

    .dialog_add_sup .el-select {
        width: 100%;
    }

    .dialog_add_sup .el-form .el-form-item {
        margin-bottom: 18px;
    }

    .dialog_add_sup .el-form-item__label {
        color: #ABABAB;
        font-size: 15px;
        font-weight: 400;
        line-height: 180%;
    }

    .dialog_add_sup .el-input__wrapper {
        padding: 12px 10px !important;
        border-radius: 15px;
        background: #F6F6F6;
        border: none !important;
    }

    .dialog_add_sup .el-input__inner {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .dialog_add_sup .el-form-item.is-error textarea.form-control {
        box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
    }

    .dialog_add_sup .el-input__inner::placeholder {
        font-size: 15px;
        font-weight: 500;
    }
</style>