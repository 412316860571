<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice_org.png" alt="">
        <p class="ttl">Yêu cầu xác nhận</p>
        <p>Hãy chọn 1 trong 3 loại báo cáo dưới đây</p>
        <button type="button" class="choose" @click="normalForm = true, dialogVisibleStatus = false">Đầu việc bình thường</button>
        <button type="button" class="choose" @click="brokerForm = true, dialogVisibleStatus = false">Ký hợp đồng môi giới</button>
        <button type="button" class="choose" @click="moneyForm = true, dialogVisibleStatus = false">Đặt cọc, công chứng, thu tiền</button>
        <button @click="dialogVisibleStatus = false" style="margin-top: 20px;">Hủy bỏ</button>
    </el-dialog>
    <!-- --- -->
    <el-dialog
        v-model="normalForm"
        width="553"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice_org.png" alt="">
        <p class="ttl">Xác nhận hoàn thành</p>
        <p>Bạn xác nhận <b>đã hoàn thành</b> đầu việc trên? <br>Bấm xác nhận và chờ cấp trên kiểm tra và xác nhận hoàn thành đầu việc</p>
        <div class="wp-btn">
            <button @click="normalForm = false, dialogVisibleStatus = true" v-if="!formNormal">Quay lại</button>
            <button @click="normalForm = false" v-else>Quay lại</button>
            <button class="btn_confirm" @click="confirm('normalForm')">Gửi xác nhận</button>
        </div>
    </el-dialog>
    <!-- --- -->
    <el-dialog
        v-model="brokerForm"
        width="553"
        class="dialog_reject"
        @close="brokerForm = false"
        align-center
    >
        <img src="@/assets/icons/notice_org.png" alt="">
        <p class="ttl">Xác nhận hoàn thành</p>
        <p>Hãy tải lên hình ảnh hợp đồng môi giới và các tài liệu liên quan</p>
        <div style="margin: 15px 0;">
            <p style="font-size: 16px; font-weight: 500; color: #515151; text-align: left; margin-bottom: 5px;">File đính kèm:</p>
            <input type="file" ref="file_input" multiple accept="image/jpeg,image/jpg,image/png,application/pdf" style="display: none;" @change="upLoad">
            <div class="btn_file" @click="$refs.file_input.click()">
                <button>Chọn file</button>
                <span>Tải lên file giấy tờ</span>
            </div>
            <ul class="list_file">
                <li v-for="(item, index) in listFile" :key="index">
                    <Fancybox>
                        <a v-if="item" data-fancybox="gallery" :href="$storage + item.path_tam">
                            <div>
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item.path_tam.substring(item.path_tam.lastIndexOf('-') + 1) }}
                            </div>
                        </a>
                    </Fancybox>
                    <button class="btn_del_file" type="button" @click="listFile.splice(index, 1)">
                        <img src="@/assets/icons/cross.png" alt="">
                    </button>
                </li>
            </ul>
        </div>
        <div class="wp-btn">
            <button @click="brokerForm = false, dialogVisibleStatus = true">Quay lại</button>
            <button class="btn_confirm" @click="confirm('brokerForm')">Gửi xác nhận</button>
        </div>
    </el-dialog>
    <!-- --- -->
    <el-dialog
        v-model="moneyForm"
        width="553"
        class="dialog_reject money_form"
        @close="moneyForm = false"
        align-center
    >
        <img src="@/assets/icons/notice_org.png" alt="">
        <p class="ttl">Xác nhận hoàn thành</p>
        <p class="mb-0">Hãy tải lên hình ảnh chụp hợp đồng đặt cọc, văn bản công chứng và tài liệu liên quan</p>
        <p>Tải lên bill chuyển khoản hoặc tin nhắn có kèm bill chuyển khoản của khách</p>
        <div style="margin: 15px 0;">
            <p style="font-size: 16px; font-weight: 500; color: #515151; text-align: left; margin-bottom: 5px;">Bất động sản liên quan</p>
            <div class="item" @click="chooseBds = true, moneyForm = false" v-if="!isEmptyCustom(real_estate)">
                <img class="thumbnail" :src="$storage + real_estate.thumbnail" alt="" @error="handleError">
                <div style="flex-basis: 100%;">
                    <p>{{ real_estate.real_estate_name }}</p>
                    <span>#{{ real_estate.real_estate_code }}</span>
                </div>
                <img src="@/assets/icons/Forward.png" alt="">
            </div>
            <div class="item" @click="chooseBds = true, moneyForm = false" v-else>
                Chọn bất động sản liên quan
                <img src="@/assets/icons/Forward.png" alt="">
            </div>
        </div>
        <div style="margin: 15px 0;">
            <p style="font-size: 16px; font-weight: 500; color: #515151; text-align: left; margin-bottom: 5px;">File tài liệu:</p>
            <input type="file" ref="file_input_contract" multiple accept="image/jpeg,image/jpg,image/png,application/pdf" style="display: none;" @change="upLoadContractFile">
            <div class="btn_file" @click="$refs.file_input_contract.click()">
                <button>Chọn file</button>
                <span>Tải lên file giấy tờ</span>
            </div>
            <ul class="list_file">
                <li v-for="(item, index) in contractFile" :key="index">
                    <Fancybox>
                        <a v-if="item" data-fancybox="gallery" :href="$storage + item.path_tam">
                            <div>
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item.path_tam.substring(item.path_tam.lastIndexOf('-') + 1) }}
                            </div>
                        </a>
                    </Fancybox>
                    <button class="btn_del_file" type="button" @click="contractFile.splice(index, 1)">
                        <img src="@/assets/icons/cross.png" alt="">
                    </button>
                </li>
            </ul>
        </div>
        <p style="font-size: 16px; font-weight: 500; color: #515151; text-align: left; margin-bottom: 10px;">Thanh toán:</p>
        <el-form-item prop="money" label="Số tiền nhận">
            <el-input type="text" v-model="ruleForm.money" @input="inputPrice" name="money" maxlength="15" placeholder="Nhập số tiền"/>
            <span class="tag">VNĐ</span>
        </el-form-item>
        <div style="margin: 15px 0;">
            <input type="file" ref="file_input_money" multiple accept="image/jpeg,image/jpg,image/png,application/pdf" style="display: none;" @change="upLoadBillFile">
            <div class="btn_file" @click="$refs.file_input_money.click()">
                <button>Chọn file</button>
                <span>Tải lên file giấy tờ</span>
            </div>
            <ul class="list_file">
                <li v-for="(item, index) in billFile" :key="index">
                    <Fancybox>
                        <a v-if="item" data-fancybox="gallery" :href="$storage + item.path_tam">
                            <div>
                                <img src="@/assets/icons/Note.png" alt="">
                                {{ item.path_tam.substring(item.path_tam.lastIndexOf('-') + 1) }}
                            </div>
                        </a>
                    </Fancybox>
                    <button class="btn_del_file" type="button" @click="billFile.splice(index, 1)">
                        <img src="@/assets/icons/cross.png" alt="">
                    </button>
                </li>
            </ul>
        </div>
        <div class="wp-btn">
            <button @click="moneyForm = false, dialogVisibleStatus = true">Quay lại</button>
            <button class="btn_confirm" @click="confirm('moneyForm')">Gửi xác nhận</button>
        </div>
    </el-dialog>
    <!-- --- -->
    <el-dialog
        v-model="chooseBds"
        width="553"
        class="dialog_reject"
        @close="chooseBds = false"
    >
        <h5 class="ttl">Chọn bất động sản liên quan</h5>
        <el-form-item class="input_search">
            <el-input v-model="keySearch" type="text" placeholder="Search" @blur="getDataRealEstate"/>
        </el-form-item>
        <div class="list-item">
            <div class="item" v-for="(item, index) in dataRealEstate" :class="{'active' : real_estate_id == item.id}" :key="index" @click="real_estate = item, real_estate_id = item.id">
                <img class="thumbnail" :src="$storage + item.thumbnail" alt="" @error="handleError">
                <div style="flex-basis: 100%;">
                    <p>{{ item.real_estate_name }}</p>
                    <span>#{{ item.real_estate_code }}</span>
                </div>
            </div>
        </div>
        <div class="wp-btn" style="margin-top: 20px;">
            <button @click="moneyForm = true, chooseBds = false, real_estate = [], real_estate_id = ''">Hủy bỏ</button>
            <button class="btn_confirm" @click="chooseBds = false, moneyForm = true">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
import Fancybox from '@/pages/components/fancy.vue'
export default {
    props: ['confirmSuccessDialog', 'formNormal'],
    emits: ['handleCloseDialog', 'confirm'],
    components: {
        Fancybox
    },
    data() {
        return {
            dialogVisibleStatus: false,
            normalForm: false,
            brokerForm: false,
            moneyForm: false,
            chooseBds: false,
            listFile: [],
            contractFile: [],
            billFile: [],
            ruleForm: {
                money: '',
            },
            keySearch: '',
            dataRealEstate: [],
            real_estate_id: '',
            real_estate: [],
            jobId: this.decode(this.$route.params.jobId),
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.confirmSuccessDialog
        this.normalForm = this.formNormal
        this.getDataRealEstate()
    },
    watch: {
        confirmSuccessDialog() {
            this.dialogVisibleStatus = this.confirmSuccessDialog;
        },
        formNormal() {
            this.normalForm = this.formNormal;
        },
    },
    methods: {
        emitDialogVisible(confirmSuccessDialog) {
            this.$emit("handleCloseDialog", confirmSuccessDialog);
        },
        async getDataRealEstate() {
            this.startLoading();
            await this.$axios.get('/job/getListRealEstateForPerson', {
                params: {
                    page: 1,
                    per_page: 10000,
                    name: this.keySearch
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataRealEstate = res.data.result
            })
            this.endLoading();
        },
        async upLoad(event) {
            if(event.target.files) {
                for (let index = 0; index < event.target.files.length; index++) {
                    let item = await this.uploadFile('job/', event.target.files[index])
                    this.listFile.push(item)
                }
            }
        },
        async upLoadContractFile(event) {
            if(event.target.files) {
                for (let index = 0; index < event.target.files.length; index++) {
                    let item = await this.uploadFile('job/', event.target.files[index])
                    this.contractFile.push(item)
                }
            }
        },
        async upLoadBillFile(event) {
            if(event.target.files) {
                for (let index = 0; index < event.target.files.length; index++) {
                    let item = await this.uploadFile('job/', event.target.files[index])
                    this.billFile.push(item)
                }
            }
        },
        confirm(form) {
            if(form == 'normalForm') {
                var data = {
                    success_type: 1,
                    job_status: 1,
                    id: this.jobId,
                }
                this[form] = false
                this.$emit("confirm", data);
            } else if (form == 'brokerForm') {
                if(!this.isEmptyCustom(this.listFile)) {
                    var data = {
                        success_type: 2,
                        job_files: this.listFile.map(item => item.path),
                        job_status: 1,
                        id: this.jobId,
                    }
                    this[form] = false
                    this.$emit("confirm", data);
                } else {
                    this.showNotify("warning", "Lưu ý", "Vui lòng chọn file đính kèm!");
                }
            } else {
                if(this.isEmptyCustom(this.real_estate_id)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng chọn bất động sản liên quan!");
                } else if(this.isEmptyCustom(this.contractFile)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng chọn file tài liệu!");
                } else if(this.isEmptyCustom(this.ruleForm.money)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập số tiền!");
                } else if(this.isEmptyCustom(this.billFile)) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng chọn file thanh toán!");
                } else {
                    var data = {
                        success_type: 3,
                        job_files: this.contractFile.map(item => item.path),
                        bill_pay: this.billFile.map(item => item.path),
                        deposits: this.ruleForm.money.replace(/[^\d]/g, ''),
                        job_status: 1,
                        real_estate_id: this.real_estate_id,
                        id: this.jobId,
                    }
                    this[form] = false
                    this.$emit("confirm", data);
                }
            }
        }
    },
}
</script>
<style lang="css" scoped src="../assets/confirmSuccess.css"></style>

<style>
    .money_form .el-form-item__label {
        text-align: left;
        font-weight: 400;
        font-size: 15px;
        color: #515151;
    }

    .money_form .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 50px 5px 10px;
    }

    .input_search .el-input__wrapper {
        border-radius: 15px;
        padding: 5px 12px;
    }
</style>