<template>
    <div class="update-event">
        <h5>Chương trình</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Chương trình</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chỉnh sửa chương trình</span>
        </div>
        <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            status-icon
            @submit.prevent="submitForm('ruleForm')"
        >
            <div class="wp-detail">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <p class="ttl">Thông tin chương trình</p>
                    <div class="d-flex">
                        <button class="add-cate" @click="$router.push('/he-thong/chuong-trinh')">Hủy bỏ</button>
                        <button class="btn_save">Lưu chỉnh sửa</button>
                    </div>
                </div>
                <el-form-item prop="name" label="Tên chương trình">
                    <el-input type="text" v-model="ruleForm.name" placeholder="Nhập tên chương trình"/>
                </el-form-item>
                <p class="lb">Thời gian bắt đầu chương trình</p>
                <div class="d-flex justify-content-between">
                    <el-form-item class="input_space" prop="timeStart" style="flex-basis: 50%;">
                        <el-time-picker
                            v-model="ruleForm.timeStart"
                            placeholder="Chọn thời gian bắt đầu"
                            value-format="HH:mm"
                            format="HH:mm"
                        />
                    </el-form-item>
                    <el-form-item class="input_space input_space--end" prop="dateStart" style="flex-basis: 50%;">
                        <el-date-picker
                            v-model="ruleForm.dateStart"
                            type="date"
                            popper-class="datePicker"
                            placeholder="Chọn ngày bắt đầu"
                            value-format="YYYY-MM-DD"
                            format="DD-MM-YYYY"
                        />
                    </el-form-item>
                </div>
                <p class="lb">Thời gian kết thúc chương trình</p>
                <div class="d-flex justify-content-between">
                    <el-form-item class="input_space" prop="timeEnd" style="flex-basis: 50%;">
                        <el-time-picker
                            v-model="ruleForm.timeEnd"
                            placeholder="Chọn thời gian kết thúc"
                            value-format="HH:mm"
                            format="HH:mm"
                        />
                    </el-form-item>
                    <el-form-item class="input_space input_space--end" prop="dateEnd" style="flex-basis: 50%;">
                        <el-date-picker
                            v-model="ruleForm.dateEnd"
                            type="date"
                            popper-class="datePicker"
                            placeholder="Chọn ngày kết thúc"
                            value-format="YYYY-MM-DD"
                            format="DD-MM-YYYY"
                        />
                    </el-form-item>
                </div>
                <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Icon chương trình</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="event_icon" @change="changeFile('ruleForm','event_icon')">
                <div class="ico_upload" @click="$refs.event_icon.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','event_icon')">
                    <div v-if="!urlImage.event_icon && !ruleForm.event_icon">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.event_icon && ruleForm.event_icon" :src="$storage + ruleForm.event_icon" alt="" style="max-height: 765px; max-width: 150px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.event_icon" alt="" style="max-height: 150px; max-width: 150px;" @error="handleError">
                </div>
                <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Ảnh bìa web</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="webBanner" @change="changeFile('ruleForm','cover_image_web')">
                <div class="ico_upload" @click="$refs.webBanner.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','cover_image_web')">
                    <div v-if="!urlImage.cover_image_web && !ruleForm.cover_image_web">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.cover_image_web && ruleForm.cover_image_web" :src="$storage + ruleForm.cover_image_web" alt="" style="max-height: 560px; max-width: 765px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.cover_image_web" alt="" style="max-height: 560px; max-width: 765px;" @error="handleError">
                </div>
                <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Ảnh bìa mobile</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="mobileBanner" @change="changeFile('ruleForm','cover_image_app')">
                <div class="ico_upload" @click="$refs.mobileBanner.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','cover_image_app')">
                    <div v-if="!urlImage.cover_image_app && !ruleForm.cover_image_app">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.cover_image_app && ruleForm.cover_image_app" :src="$storage + ruleForm.cover_image_app" alt="" style="max-height: 620px; max-width: 286px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.cover_image_app" alt="" style="max-height: 620px; max-width: 286px;" @error="handleError">
                </div>
                <!-- <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Vòng quay</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="spinner" @change="changeFile('ruleForm','spinner')">
                <div class="ico_upload" @click="$refs.spinner.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','spinner')">
                    <div v-if="!urlImage.spinner && !ruleForm.spinner">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.spinner && ruleForm.spinner" :src="$storage + ruleForm.spinner" alt="" style="max-height: 362px; max-width: 362px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.spinner" alt="" style="max-height: 362px; max-width: 362px;" @error="handleError">
                </div> -->
                <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Vector mũi tên</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="arrow_vector" @change="changeFile('ruleForm','arrow_vector')">
                <div class="ico_upload" @click="$refs.arrow_vector.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','arrow_vector')">
                    <div v-if="!urlImage.arrow_vector && !ruleForm.arrow_vector">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.arrow_vector && ruleForm.arrow_vector" :src="$storage + ruleForm.arrow_vector" alt="" style="max-height: 100px; max-width: 72px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.arrow_vector" alt="" style="max-height: 100px; max-width: 72px;" @error="handleError">
                </div>
                <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Vector quay</p>
                <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="spin_vector" @change="changeFile('ruleForm','spin_vector')">
                <div class="ico_upload" @click="$refs.spin_vector.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('ruleForm','spin_vector')">
                    <div v-if="!urlImage.spin_vector && !ruleForm.spin_vector">
                        <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                        <div class="el-upload__text">
                            Drag and drop image here, or click add image <br>
                        </div>
                        <button class="add_img" type="button">Add Image</button>
                    </div>
                    <img v-else-if="!urlImage.spin_vector && ruleForm.spin_vector" :src="$storage + ruleForm.spin_vector" alt="" style="max-height: 100px; max-width: 72px;" @error="handleError">
                    <img v-else :src="$storage + urlImage.spin_vector" alt="" style="max-height: 100px; max-width: 72px;" @error="handleError">
                </div>
                <el-form-item prop="count_reward" label="Số ô giải thưởng trong vòng quay">
                    <el-input-number v-model="ruleForm.count_reward" placeholder="Nhập số ô giải thưởng"/>
                </el-form-item>
                <el-form-item prop="reset_turn" label="Số ngày làm mới lượt quay">
                    <el-input-number v-model="ruleForm.reset_turn" :max="30" placeholder="Nhập số ngày"/>
                </el-form-item>
                <p class="lb">Thể lệ chương trình</p>
                <CKeditor
                    class="rules"
                    :editorData="ruleForm.rules || ''"
                    @getData="getDataRules"
                />
                <p class="lb mt-3">Giải thưởng</p>
                <CKeditor
                    class="reward"
                    :editorData="ruleForm.reward || ''"
                    @getData="getDataReward"
                />
                <p class="lb mt-3">Hướng dẫn nhận giải thưởng</p>
                <CKeditor
                    class="instructions"
                    :editorData="ruleForm.instructions || ''"
                    @getData="getDataInstructions"
                />
            </div>

            <div class="wp-detail" style="margin-top: 20px;">
                <p class="ttl mb-3">Chi tiết giải thưởng và tỉ lệ</p>
                <div class="item-prize" v-for="(reward, index) in ruleForm.list_reward">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Giải thưởng {{ index + 1 }}</p>
                        <button type="button" class="del_reward" @click="deleteReward(reward.id)">
                            <img src="@/assets/icons/trash_redd.png" alt="">
                        </button>
                    </div>
                    <el-form-item label="Tên giải thưởng">
                        <el-input type="text" v-model="reward.name" placeholder="Nhập tên giải thưởng"/>
                    </el-form-item>
                    <el-form-item label="Mô tả giải thưởng">
                        <el-input type="text" v-model="reward.description" placeholder="Nhập mô tả giải thưởng"/>
                    </el-form-item>
                    <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Hình ảnh giải thưởng</p>
                    <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" :ref="'image_prize-'+index" @change="changeFileSub(index, 'list_reward')">
                    <div class="ico_upload" @click="$refs['image_prize-'+index][0].click()" @dragover.prevent @dragenter.prevent @drop="handleDropSub(index, 'list_reward')">
                        <div v-if="!urlImage.list_reward[index].image && !reward.image">
                            <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                            <div class="el-upload__text">
                                Drag and drop image here, or click add image <br>
                            </div>
                            <button class="add_img" type="button">Add Image</button>
                        </div>
                        <img v-else-if="!urlImage.list_reward[index].image && reward.image" :src="$storage + reward.image" alt="" style="max-height: 156px; max-width: 156px;" @error="handleError">
                        <img v-else :src="$storage + urlImage.list_reward[index].image" alt="" style="max-height: 156px; max-width: 156px;" @error="handleError">
                    </div>
                    <el-form-item label="Giá trị">
                        <el-input type="text" v-model="reward.price" @input="formatInputPrice(reward.price, index)" placeholder="Nhập giá trị"/>
                        <span class="unit">VNĐ</span>
                    </el-form-item>
                    <div class="d-flex align-items-center justify-content-between">
                        <el-form-item prop="count_item" label="Số lượng giải" style="flex-basis: 24%;">
                            <el-input-number v-model="reward.count_item" placeholder="Nhập lượng giải"/>
                        </el-form-item>
                        <el-form-item prop="max_reward" label="Số lượng trúng" style="flex-basis: 24%;">
                            <el-input-number v-model="reward.max_reward" placeholder="Nhập lượng trúng"/>
                        </el-form-item>
                        <el-form-item prop="rotation_percent" label="Tỷ lệ quay" style="flex-basis: 24%;">
                            <el-input-number v-model="reward.rotation_percent" placeholder="Nhập tỷ lệ quay" :max="100"/>
                            <span style="position: absolute;right: 15px;font-weight: 600;">%</span>
                        </el-form-item>
                        <el-form-item prop="reward_type" label="Loại giải thưởng" style="flex-basis: 24%;">
                            <el-select
                                placeholder="Chọn loại giải thưởng"
                                v-model="reward.reward_type"
                            >
                                <el-option
                                    key="0"
                                    label="Thường"
                                    :value="0"
                                />
                                <el-option
                                    key="1"
                                    label="Phần thưởng nạp tiền"
                                    :value="1"
                                />
                                <el-option
                                    key="2"
                                    label="Giải khuyến khích"
                                    :value="2"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="text-end">
                    <button type="button" class="add_prize" @click="addItemPrize"> + Thêm giải thưởng</button>
                </div>
            </div>

            <div class="wp-detail" style="margin-top: 20px;">
                <p class="ttl mb-3">List vòng quay</p>
                <div class="item-prize" v-for="(spinner, index) in ruleForm.list_spiner">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Vòng quay {{ index + 1 }}</p>
                        <button type="button" class="del_reward" @click="deleteSpiner(spinner.id, index)">
                            <img src="@/assets/icons/trash_redd.png" alt="">
                        </button>
                    </div>
                    <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Vòng quay</p>
                    <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" :ref="'image_spiner-'+index" @change="changeFileSub(index, 'list_spiner')">
                    <div class="ico_upload" @click="$refs['image_spiner-'+index][0].click()" @dragover.prevent @dragenter.prevent @drop="handleDropSub(index, 'list_spiner')">
                        <div v-if="!urlImage.list_spiner[index].image && !spinner.image">
                            <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                            <div class="el-upload__text">
                                Drag and drop image here, or click add image <br>
                            </div>
                            <button class="add_img" type="button">Add Image</button>
                        </div>
                        <img v-else-if="!urlImage.list_spiner[index].image && spinner.image" :src="$storage + spinner.image" alt="" style="max-height: 362px; max-width: 362px;" @error="handleError">
                        <img v-else :src="$storage + urlImage.list_spiner[index].image" alt="" style="max-height: 362px; max-width: 362px;" @error="handleError">
                    </div>
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div v-for="i in ruleForm.count_reward" style="flex-basis: 24%;">
                            <el-form-item :label="'Ô số ' + i">
                                <el-select
                                    filterable
                                    placeholder="Chọn giải thưởng"
                                    style="width: 100%"
                                    v-model="spinner.reward_ids[i-1]"
                                >
                                    <el-option
                                        v-for="(item, index) in ruleForm.list_reward"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="text-end">
                    <button type="button" class="add_prize" @click="addItemSpinner"> + Thêm vòng quay</button>
                </div>
            </div>
        </el-form>
        <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">
    </div>
</template>
<script>
import CKeditor from '../../components/ckeditor.vue'
export default {
    components: { CKeditor },
    data() {
        return {
            dataDetail: [],
            id: '',
            ruleForm: {
                name: '',
                timeStart: '',
                dateStart: '',
                start_time: '',
                timeEnd: '',
                dateEnd: '',
                end_time: '',
                event_icon: '',
                cover_image_web: '',
                cover_image_app: '',
                // spinner: '',
                arrow_vector: '',
                spin_vector: '',
                count_reward: 0,
                rules: '',
                reward: '',
                instructions: '',
                list_spiner: [],
                list_reward: [],
                reset_turn: 0
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên chương trình", trigger: ["blur", "change"]}],
                timeStart: [{required: true, message: "Vui lòng chọn thời gian bắt đầu", trigger: ["blur", "change"]}],
                dateStart: [{required: true, message: "Vui lòng chọn ngày bắt đầu", trigger: ["blur", "change"]}],
                timeEnd: [{required: true, message: "Vui lòng chọn thời gian kết thúc", trigger: ["blur", "change"]}],
                dateEnd: [{required: true, message: "Vui lòng chọn ngày kết thúc", trigger: ["blur", "change"]}],
            },
            urlImage: {
                event_icon: '',
                cover_image_web: '',
                cover_image_app: '',
                // spinner: '',
                arrow_vector: '',
                spin_vector: '',
                list_spiner: [],
                list_reward: []
            },
            hidden: '',
        }
    },
    mounted() {
        this.getDetail('all')
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        getDataRules(data) {
            this.ruleForm.rules = data
        },
        getDataReward(data) {
            this.ruleForm.reward = data
        },
        getDataInstructions(data) {
            this.ruleForm.instructions = data
        },
        async addItemPrize() {
            this.startLoading();
            await this.$axios.post('/system/addReward',{event_id: this.decode(this.$route.params.eventId)},{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.getDetail('reward')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        addItemSpinner() {
            this.ruleForm.list_spiner.push({
                image: '',
                reward_ids: []
            })
            this.urlImage.list_spiner.push({
                image: '',
            })
        },
        async changeFile(form, type) {
            if(event.target.files[0]) {
                let url_image = await this.uploadFile('system/', event.target.files[0])
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        async handleDrop(form, type) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if(file) {
                let url_image = await this.uploadFile('system/', file)
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        async changeFileSub(index, type) {
            if(event.target.files[0]) {
                let url_image = await this.uploadFile('system/', event.target.files[0])
                this.urlImage[type][index].image = url_image.path_tam
                this.ruleForm[type][index].image = url_image.path
            }
        },
        async handleDropSub(index, type) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if(file) {
                let url_image = await this.uploadFile('system/', file)
                this.urlImage[type][index].image = url_image.path_tam
                this.ruleForm[type][index].image = url_image.path
            }
        },
        async getDetail(type) {
            this.startLoading();
            await this.$axios.get('/system/getEventDetail',{
                params: {
                    id: this.decode(this.$route.params.eventId)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                if(type == 'all') {
                    this.ruleForm = res.data
                    if(res.data.start_time) {
                        this.ruleForm.timeStart = this.$moment.utc(res.data.start_time).format('HH:mm')
                        this.ruleForm.dateStart = this.$moment.utc(res.data.start_time).format('YYYY-MM-DD')
                    }
                    if(res.data.end_time) {
                        this.ruleForm.timeEnd = this.$moment.utc(res.data.end_time).format('HH:mm')
                        this.ruleForm.dateEnd = this.$moment.utc(res.data.end_time).format('YYYY-MM-DD')
                    }
                    this.ruleForm.list_reward.forEach(element => {
                        element.price = parseInt(element.price).toLocaleString('vi')
                    });
                    this.urlImage.list_reward = res.data.list_reward.map(item => {
                        return {
                            image: item.image,
                        };
                    })
                    this.urlImage.list_spiner = res.data.list_spiner.map(item => {
                        return {
                            image: item.image,
                        };
                    })
                } else if(type == 'reward') {
                    this.ruleForm.list_reward = res.data.list_reward
                    this.urlImage.list_reward = res.data.list_reward.map(item => {
                        return {
                            image: item.image,
                        };
                    })
                } else if(type == 'spiner') {
                    this.ruleForm.list_spiner = res.data.list_spiner
                    this.urlImage.list_spiner = res.data.list_spiner.map(item => {
                        return {
                            image: item.image,
                        };
                    })
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid && this.checkImgReward() && this.checkSpiner()) {
                    this.ruleForm.start_time = this.ruleForm.dateStart + ' ' + this.ruleForm.timeStart
                    this.ruleForm.end_time = this.ruleForm.dateEnd + ' ' + this.ruleForm.timeEnd
                    this.ruleForm.list_reward.forEach(element => {
                        element.price = element.price.replace(/[^\d]/g, '')
                    });
                    this.startLoading();
                    await this.$axios.put('/system/updateEvent', this.ruleForm, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", 'Chỉnh sửa nội dung thành công');
                        this.getDetail('all')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                } else if(!valid) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                }
            });
        },
        checkImgReward() {
            for (let index = 0; index < this.ruleForm.list_reward.length; index++) {
                if(!this.ruleForm.list_reward[index].name) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập tên giải thưởng " + (index + 1) + '!');
                    return false
                } else if(!this.ruleForm.list_reward[index].description) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập mô tả giải thưởng " + (index + 1) + '!');
                    return false
                } else if(!this.ruleForm.list_reward[index].image) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng thêm hình ảnh giải thưởng " + (index + 1) + '!');
                    return false
                } else if(!this.ruleForm.list_reward[index].price) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng nhập giá trị giải thưởng " + (index + 1) + '!');
                    return false
                }
            }
            return true
        },
        checkSpiner() {
            for (let index = 0; index < this.ruleForm.list_spiner.length; index++) {
                if(!this.ruleForm.list_spiner[index].image) {
                    this.showNotify("warning", "Lưu ý", "Vui lòng thêm hình ảnh cho vòng quay " + (index + 1) + '!');
                    return false
                } else {
                    for (let i = 0; i < this.ruleForm.list_spiner[index].reward_ids.length; i++) {
                        if (!this.ruleForm.list_spiner[index].reward_ids[i]) {
                            this.showNotify("warning", "Lưu ý", "Vui lòng chọn đủ giải thưởng cho vòng quay " + (index + 1) + '!');
                            return false
                        }
                    }
                }
            }
            return true
        },
        formatInputPrice(value, index) {
            const cleanedValue = value.replace(/[^\d]/g, '');
            const number = parseInt(cleanedValue, 10);
            this.ruleForm.list_reward[index].price = number.toLocaleString('vi');
            if(this.ruleForm.list_reward[index].price == 'NaN') {
                this.ruleForm.list_reward[index].price = ''
            }
        },
        async deleteReward(id) {
            this.startLoading();
            await this.$axios.put('/system/deleteReward', {id: id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                // this.showNotify("success", "Thành công", 'Xóa giải thưởng thành công');
                this.getDetail('reward')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteSpiner(id, index) {
            if(!id) {
                this.ruleForm.list_spiner.splice(index, 1)
            } else {
                this.startLoading();
                await this.$axios.put('/system/deleteSpiner', {id: id}, {
                    headers: {
                        'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                    }
                }).then(res => {
                    this.getDetail('spiner')
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
                this.endLoading();
            }
        }
    }
}
</script>
<style lang="css" scoped>
    .update-event {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .wp-detail p.lb {
        font-weight: 500;
        margin-bottom: 5px;
        font-size: 15px;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
        max-width: 1090px;
        margin: 0 auto;
    }

    .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .item-prize {
        border-radius: 20px;
        border: 1px solid #999;
        padding: 20px;
        margin-bottom: 10px;
    }

    .item-prize .item {
        flex-basis: 24%;
    }

    .ico_upload {
        border-radius: 20px;
        border: 1px dashed var(--neutral-gray-gray-100, #E0E2E7);
        background: #EBEBEB;
        padding: 24px 12px;
        box-shadow: none;
        text-align: center;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .el-icon--upload {
        border-radius: 8px;
        background: #F58E16;
        padding: 8px;
        width: 44px;
        height: 44px;
        margin-bottom: 0 !important;
    }

    .el-upload__text {
        margin: 16px 0;
    }

    .add_img {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
        display: unset !important;
    }

    button.add_prize {
        display: unset;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        padding: 10px 14px;
        width: 170px;
    }

    span.unit {
        position: absolute;
        right: 25px;
        font-weight: 500;
    }

    button.del_reward {
        width: max-content;
    }
</style>

<style>
    .update-event .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .update-event .el-input__wrapper, .update-event .el-textarea__inner {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 12px;
    }

    .update-event .el-date-editor .el-input__wrapper {
        height: 40px;
    }

    .update-event .el-date-editor {
        height: 100% !important;
        flex-basis: 97%;
    }

    .update-event .el-select {
        width: 100%;
    }

    .update-event .input_space--end .el-form-item__content {
        justify-content: flex-end
    }

    .update-event .input_space--end .el-form-item__content .el-form-item__error {
        left: 25px;
    }

    .update-event .el-textarea__inner {
        resize: none;
        min-height: 100px !important;
    }

    .update-event .el-textarea__inner::-webkit-scrollbar {
        width: 0;
    }

    .update-event .el-input-number {
        width: 100%;
    }

    .update-event .el-input-number__decrease,
    .update-event .el-input-number__increase {
        display: none;
    }

    .update-event .el-input-number .el-input__inner {
        text-align: left;
    }
    .update-event .el-input-number .el-input__wrapper {
        padding: 5px 12px;
    }
</style>