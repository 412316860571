<template>
    <div class="mobie" v-if="dataReview.length !== 0">
        <nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '-'; margin-bottom: 18px; margin-top: 17px;"
            class="nav-md d-flex align-items-center justify-content-between position-relative">
            <ol class="breadcrumb align-items-center mb-0">
                <li class="breadcrumb-item"><a><img src="@/assets/icons/home.png" alt=""></a></li>
                <li class="breadcrumb-item" aria-current="page">{{ category_name }}</li>
                <li class="breadcrumb-item active" aria-current="page">{{ dataReview.name }}</li>
            </ol>
        </nav>
        <div class="col-12 mb-3 position-relative">
            <div class="cz-product-gallery ">
                <div class="cz-preview py-1">
                    <button type="button" class="btn-modal-gallery">
                        <swiper :loop="true" :spaceBetween="6" :navigation="true" :thumbs="{ swiper: bigthumbsSwiper }"
                            :modules="modules" class="mySwiper2" style="width: 100%;">
                            <swiper-slide v-for="(image, key) in list_images" :key="carouselKey"  v-if="!showVideo">
                                <img v-if="!image.is_360 && !image.path_tam" :src="$storage + image.path" class="big-image" alt="" />
                                <img v-else-if="!image.is_360 && image.path_tam" :src="$storage + image.path_tam" class="big-image" alt="" />
                                <iframe v-else style="border-style:none; width: 100%; height: 239px;" allowfullscreen :src="'https://cdn.pannellum.org/2.5/pannellum.htm#panorama='+$storage + image.path_tam"></iframe>
                            </swiper-slide>
                            <iframe v-else style="width: 100%; height: 239px;" :src="'https://www.youtube.com/embed/' + this.videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </swiper>
                    </button>
                </div>
                <div class="cz-slide-thumnail">
                    <swiper @swiper="setBigThumbsSwiper" :loop="true" :spaceBetween="6" :slidesPerView="3" :freeMode="true"
                        :watchSlidesProgress="true" :modules="modules" class="mySwiper">
                        <swiper-slide v-for="(image, key) in list_images" :key="carouselKey">
                            <img :src="$storage + image.path" class="small-image" alt="" @click="showVideo = false" v-if="!image.path_tam"/>
                            <img :src="$storage + image.path_tam" class="small-image" alt="" @click="showVideo = false" v-else/>
                        </swiper-slide>
                        <swiper-slide v-if="thumbnailUrl">
                            <img :src="thumbnailUrl" class="small-image" alt="" @click="showVideo = true"/>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="base_params d-flex justify-content-between align-items-center mt-3">
                <div class="price col-6">
                    <img src="@/assets/icons/Price-Tag.png" alt="">
                    <div class="text-price">
                        <p v-if="dataReview.unit_id != 5">{{ formatPrice(parseInt(dataReview.price.replace(/[^\d]/g, ''))) }}</p>
                        <p v-else style="font-size: 18px;">Thỏa thuận</p>
                        <p class="text--bl" v-if="dataReview.unit_id == 2">~ {{ formatPrice((dataReview.price / dataReview.acreage).toFixed(1)) }} /m2</p>
                        <p class="text--bl" v-else-if="dataReview.unit_id != 5">{{ dataReview.unit_name }}</p>
                    </div>
                </div>
                <div class="area col-6">
                    <img src="@/assets/icons/Torrent.png" alt="">
                    <div class="text-price">
                        <p>{{ dataReview.acreage }} m2</p>
                    </div>
                </div>
            </div>
            
            <div>
                <div class="product-name">
                    {{ dataReview.name }}
                </div>
                <div class="address" v-if="dataReview.district && dataReview.ward && dataReview.province">
                    {{ dataReview.address }}, {{ JSON.parse(dataReview.ward).name }}, {{ JSON.parse(dataReview.district).name }}, {{ JSON.parse(dataReview.province).name }}
                </div>
                <div class="title">Đặc điểm thông số bất động sản</div>
                <div class="">
                    <div class="params">
                        <div class="col-12" v-for="(item, index) in detail_cate.describe" :key="index">
                            <div v-for="data in describe" v-show="!data.description && data.value && data.value != 0">
                                <div class="items" v-if="item.describe_id == data.describe_id">
                                    <div class="icon-width">
                                        <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                                    </div>
                                    <div class="title-width">{{ item.describe_name }}:</div>
                                    <div v-for="ele in item.value" v-if="item.info_type == 4">
                                        <strong v-if="ele.properties_id == data.value">{{ ele.properties_name }}</strong>
                                    </div>
                                    <strong v-else>{{ data.value }} {{ data.describe_unit }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-for="(item, index) in detail_cate.describe" :key="index">
                            <div v-for="data in describe" v-show="data.description && data.value && data.value != 0">
                                <div class="items" v-if="item.describe_id == data.describe_id">
                                    <div class="icon-width">
                                        <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                                    </div>
                                    <div class="title-width">{{ item.describe_name }}:</div>
                                    <div v-for="ele in item.value">
                                        <strong v-if="ele.properties_id == data.value">{{ ele.properties_name }}</strong>
                                    </div>
                                    <div class="discript" style="margin-top: 10px;">
                                        {{ data.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">Thông tin mô tả chi tiết</div>
                <div class="discription">
                    <p v-html="formatDes"></p>
                </div>
                <div class="title">Mặt bằng và thiết kế</div>
                <div class="discription">
                    <p v-html="formatDesign"></p>
                    <div v-for="item in dataReview.design_file">
                        <img :src="$storage + item.replace('real_estate/design_file','fileUpload')" alt="" style="margin-bottom: 10px; max-width: 100%;">
                    </div>
                </div>
                <!-- <div class="title">Thông tin dự án</div> -->
                <!-- <div class="title">Xem trên bản đồ</div> -->
                <!-- <img src="@/assets/images/maps.png" alt="" style="width: 100%;"> -->
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
export default {
    props: ['data', 'cate_name', 'cate_des' ,'desProps'],
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            selectedImage: '',
            carouselKey:0,
            galleryKey: 0,
            list_images: [],
            dataReview: [],
            category_name: '',
            detail_cate: [],
            formatDes: '',
            formatDesign: '',
            videoUrl: '',
            thumbnailUrl: '',
            showVideo: false,
            describe: []
        }
    },
    mounted(){
        this.selectedImage = this.list_images[0]
        this.dataReview = this.data
        this.category_name = this.cate_name
        this.detail_cate = this.cate_des
        this.list_images = this.data.image
        this.describe = this.desProps
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                if(this.data) {
                    this.dataReview = this.data
                    this.formatDes = this.dataReview.description.replaceAll('\n', '</br>')
                    if(this.dataReview.design) {
                        this.formatDesign = this.dataReview.design.replaceAll('\n', '</br>')
                    }
                    this.list_images = this.data.image
                    if(this.data.video) {
                        const value = 'https://img.youtube.com/vi/'
                        if(this.data.video.includes('https://youtu.be/')) {
                            const parts = this.data.video.split("https://youtu.be/");
                            if (parts.length > 1) {
                                this.videoUrl = parts[1];
                            }
                        } else if(this.data.video.includes('/shorts')) {
                            this.videoUrl = this.data.video.split("/shorts/")[1]
                        } else {
                            const equalSignIndex = this.data.video.indexOf("?v=");
                            if (equalSignIndex !== -1) {
                                this.videoUrl = this.data.video.substring(equalSignIndex + 3);
                            }
                        }
                        this.thumbnailUrl = value + this.videoUrl + '/0.jpg';
                    }
                }
            },
            deep: true
        },
        cate_name() {
            this.category_name = this.cate_name
        },
        cate_des() {
            this.detail_cate = this.cate_des
        },
        desProps() {
            this.describe = this.desProps
        }
    },
    setup() {
        const thumbsSwiper = ref(null);
        const bigthumbsSwiper = ref(null);

        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        const setBigThumbsSwiper = (swiper) => {
            bigthumbsSwiper.value = swiper;
        };

        return {
            thumbsSwiper,
            bigthumbsSwiper,
            setThumbsSwiper,
            setBigThumbsSwiper,
            modules: [FreeMode, Navigation, Thumbs],
        };
    },
}
</script>
<style scoped>
.mobie {
    max-width: 355px;
    max-height: 700px;
    overflow: auto;
    border-radius: 40px;
    border: 4px solid #DFDFDF;
    background: #FFF;
    padding: 0px 5px;
}

.mobie::-webkit-scrollbar {
    display: none;
}


.breadcrumb-item.active {
    color: #F13A3A;
    font-size: 15px;
    font-weight: 500;
}

.breadcrumb {
    flex-wrap: nowrap;
    padding-right: 1rem;
    font-size: 15px;
    font-weight: 500;
    color: #515151;
}

.breadcrumb>* {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 45%;
}
.modal {
    background-color: #000;
    z-index: 2000;
}

.show-imag,
.big-image,
.small-image {
    border-radius: 5px;
}

.cz-preview img {
    width: 100%;
}

.btn-modal-gallery {
    border: unset;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
img.big-image {
    height: 239px;
    width: 100%;

}

.cz-slide-thumnail {
    overflow: auto;
}

.cz-slide-thumnail img {
    width: 117px;
    height: 72px;
    border-radius: 5px;
    object-fit: cover;
}

.cz-slide-thumnail::-webkit-scrollbar {
    width: 0;
}

.thumb-slide2 .swiper-slide-thumb-active img {
    opacity: 1;
    border: 1px solid #fff;
    border-radius: 5px;
}

.thumb-slide2 {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    padding-bottom: 34px;
}
.cz-preview .swiper-slide, .cz-slide-thumnail .swiper-slide{
    padding-bottom: 0;
}
.btn-modal-gallery .swiper-slide img {
    width: 100%;
    object-fit: cover;
}
.cz-slide-thumnail .swiper-slide{
    width: min-content !important;
}
.cz-slide-thumnail .swiper-slide img{
    min-width: 117px;
    height: 72px;
}

.show-imag,
.big-image,
.cz-preview img {
    width: 100%;
    border-radius: 5px;
}
.btn-modal-gallery img {
    height: 239px;
    width: 100%;
}
.thumb-slide2 .swiper-slide img.small-image {
    border-radius: 5px;
    width: 115.468px;
    height: 70.714px;
    flex-shrink: 0;
}

.breadcrumb-item.active {
    color: #F13A3A;
    font-size: 15px;
    font-weight: 500;
}
.breadcrumb{
    flex-wrap: nowrap;
    padding-right: 1rem;
    font-size: 15px;
    font-weight: 500;
    color: #515151;
}
.breadcrumb>*{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 45%;
}
.price, .area{
    width: 165px;
    height: 68.312px;
    border-radius: 49.526px;
    background: #FFF;
    box-shadow: 0px 0px 4.26947px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 12px;
}
.price img, .area img{
    width: 40px;
    height: 38px;
}
.text-price{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #808080;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    width: 120px;
}
.text-price p{
    color: #F13A3A;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
}
.product-name{
    color: #515151;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 0 5px 0;
    line-height: normal;
}
.address{
    color: #808080;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 31px;
}
.title{
    color: #515151;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 0;
}
.params{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 13px;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
}
.params .items{
    display: flex;
    padding: 13px 0;
    border-bottom: #E5E5E5 1px solid;
    flex-wrap: wrap;
    align-items: center;
}
.params .icon-width{
    min-width: 46px;
    width: 46px;
    display: flex;
    align-items: center;
}
.params .title-width{
    min-width: 113px;
    width: 113px;
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}
.params strong{
    color: #515151;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    min-width: 100px;
    min-width: 35%;
    /* width: min-content; */
    padding: 0 5px;
}
.params .discript{
    color: #515151;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 100%;
    width: auto;
}
.discription{
    color: #7E7E7E;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 20px;
}
.creater{
    border-radius: 69px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 65px;
    display: flex;
    align-items: center;
    padding: 5px 18px 5px 5px;
}
.creater .avatar{
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.creater img{
    width: 54px;
    height: 54px;
    border-radius: 54px;
}
.creater .name{
    padding-left: 25px;
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.name strong{
    color: #515151;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.contact{
    margin-top: 20px;
    padding: 0px 12px 12px 12px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.broker{
    display: flex;
    align-items: center;
}
.broker .name{
    padding-left: 10px;
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 25px;
}
.broker img{
    width: 67px;
    height: 67px;
    border-radius: 54px;
}
.contact button{
    height: 53px;
    border-radius: 27px;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 17px;
    margin-top: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.contact .btn-phone{
    color: #FFF;
    background: #F13A3A;
}
.contact .btn-mail{
    background: #F58E16;
    color: #FFF;
    font-size: 14px;
}
.contact .btn-contact{
    background: #FFF;
    color: #515151;
    border: 1px solid #515151;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.nsx{
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}
.nsx strong{
    color: #515151;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.count_gallery{
    position: absolute;
    top: 424px;
    right: 256px;
    color: #FFF;
    text-shadow: 0px 0px 1.2021247148513794px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 15.538px;
    font-weight: 600;
}
.count_gallery .video{
    width: 61px;
}
.list-search {
    border-radius: 10px;
    border: 1px solid #ABABAB;
    background: #FFF;
    padding: 0px 20px 10px 20px;
    line-height: 32px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
.list-search a{
    color: #515151;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5px 0;
}
.list-search .title{
    border-bottom: #EFEFEF 1px solid;
    padding: 10px 0 5px 0;
}
.list-search .view-more{
    color: #F13A3A;
    font-size: 14px;
    font-weight: 500;
}

.text-price p.text--bl {
    color: #808080;
    font-size: 13.5px;
    font-weight: 500;
}
</style>