<template>
    <div class="detail_da">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết dự án</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Dự án bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết dự án</span>
                </div>
            </div>
            <div class="wp-btn">
                <span class="show_web">Hiển thị trên web
                    <el-switch
                        v-model="dataDetail.view_status"
                        :active-value="1"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                        @change="updateViewStatus"
                    />
                </span>
                <button @click="$router.push('/quan-ly-bds/du-an/chinh-sua/' + encode(id))">Chỉnh sửa thông tin dự án</button>
                <button class="btn_delete" @click="dialogDelete = true">Xóa dự án</button>
            </div>
        </div>
        <el-row>
            <el-col :span="18">
                <div class="detail">
                    <h5>Thông tin chi tiết dự án</h5>
                    <DetailDa :data="dataDetail"/>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="status_bds">
                    <p style="color: #686868; font-size: 14px; font-weight: 500; margin-bottom: 0;">Trạng thái dự án</p>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" ref="btn_dropdown" type="button" id="status" data-bs-toggle="dropdown" aria-expanded="false"
                            :class="{'txt--org' : status_id == 1 || status_id == 2, 'txt--green' : status_id == 3, 'txt--red' : status_id == 5}">
                            {{ status_txt }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="status">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Trạng thái</p>
                            <li v-for="item in list_status">
                                <a class="dropdown-item" href="#" @click="getName(item.txt, item.id)"
                                    :class="{'txt--org' : item.id == 1 || item.id == 2, 'txt--green' : item.id == 3, 'txt--red' : item.id == 5}"
                                >{{ item.txt }}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="group">
                    <img :src="$storage + dataDetail.icon" alt="">
                    <p>{{ dataDetail.owner }}</p>
                </div>

                <div class="info">
                    <h5>Thông tin cơ bản</h5>
                    <p>Mô tả chung</p>
                    <p class="info_txt">{{ dataDetail.meta_description }}</p>
                    <p>Địa chỉ chi tiết dự án</p>
                    <p class="info_txt">{{ dataDetail.address }}, {{ dataDetail.ward_name }}, {{ dataDetail.district_name }}, {{ dataDetail.province_name }}</p>
                    <p>Tổng diện tích dự án</p>
                    <p class="info_txt">{{ dataDetail.acreage }} {{ dataDetail.unit }}</p>
                    <p>Tổng số bất động sản trong hệ thống Rakhoi</p>
                    <p class="info_txt">{{ dataDetail.count_real_estate }}</p>
                    <p>Ngày tạo tin</p>
                    <p class="info_txt">{{ formateDate(dataDetail.create_at) }}</p>
                </div>

                <div class="button-view">
                    <button class="utilities" @click="dialogVisible = true">Tiện ích dự án</button>
                    <button class="view_all" @click="$router.push('/quan-ly-bds/danh-sach-tin-bds')">Xem bất động sản dự án >></button>
                </div>

                <el-dialog v-model="dialogVisible" title="Tiện ích dự án" width="30%">
                    <div class="list_utilities">
                        <div class="item" v-for="(item, index) in list_uti" :key="index">
                            <img :src="$storage + item.icon" alt="">
                            <span>{{ item.name }}</span>
                        </div>
                    </div>
                </el-dialog>
            </el-col>

            <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">
        </el-row>

        <Dialog 
            :dialogVisible="dialogDelete"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="deleteProject"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ dự án"
            content_success="Bạn đã xóa dự án thành công"
        />
    </div>
</template>

<script>
import DetailDa from '../components/detail_da.vue'
import Dialog from '../../components/dialog.vue'
export default {
    components: {DetailDa, Dialog},
    data() {
        return {
            list_status: [
                {id: 1,txt: 'Chưa mở bán'},
                {id: 2,txt: 'Sắp mở bán'},
                {id: 3,txt: 'Mở bán'},
                {id: 4,txt: 'Đang cập nhật'},
                {id: 5,txt: 'Đã bán'},
            ],
            status: true,
            status_txt: '',
            status_id: '',
            dialogVisible: false,
            dialogDelete: false,
            successDialog: false,
            hidden: '',
            id: '',
            dataDetail: [],
            list_uti: []
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.handleScroll()
        this.getDetail()
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async getName(name, id) {
            this.$refs.btn_dropdown.click()
            this.status_txt = name
            this.status_id = id
            const data = {
                'project_id' : this.id,
                'project_status' : id
            }
            this.startLoading();
            await this.$axios.put('/project/updateProjectStatus', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa trạng thái thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        listenDialog(dialogStatus) {
            this.dialogDelete = dialogStatus;
        },
        async deleteProject(successDialog) {
            const data = {
                'project_id' : this.id,
            }
            this.startLoading();
            await this.$axios.put('/project/deleteProject', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.successDialog = successDialog;
                this.showNotify("success", "Thành công", "Xóa dự án thành công!");
                this.$router.push('/quan-ly-bds/du-an')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/project/getProjectDetailForAdmin',{
                params: {
                    id: this.id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
                if(res.data.project_status == 1) {
                    this.status_txt = 'Chưa mở bán'
                } else if (res.data.project_status == 2) {
                    this.status_txt = 'Sắp mở bán'
                } else if (res.data.project_status == 3) {
                    this.status_txt = 'Mở bán'
                } else if (res.data.project_status == 4) {
                    this.status_txt = 'Đang cập nhật'
                } else {
                    this.status_txt = 'Đã bán'
                }
                this.status_id = res.data.project_status
                document.title = `Chi tiết dự án - ${this.dataDetail.name}`;
                this.getListUti()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListUti() {
            this.startLoading();
            await this.$axios.get('/project/getListUtilities',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_uti = res.data
                this.list_uti = this.list_uti.filter(item => this.dataDetail.utilities.includes(item.id));
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateViewStatus() {
            if(!this.dataDetail.view_status) {
                this.dataDetail.view_status = 0
            }
            const data = {
                "project_id": this.id,
                "view_status": this.dataDetail.view_status
            }
            this.startLoading();
            await this.$axios.put('/project/updateProjectViewStatus',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.getDetail()
                this.showNotify("success", "Thành công", "Thay đổi trạng thái hiển thị thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-btn button {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #515151;
        background: none;
    }


    .wp-btn button.btn_delete {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #EE3439;
        margin-left: 16px;
    }

    .dropdown button {
        width: 100%;
        height: 50px;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 0 4px;
        color: #515151;
        font-weight: 500;
        letter-spacing: 0.07px;
        border: none;
        margin-bottom: 20px;
    }

    .dropdown-toggle:active {
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 100%;
        padding: 10px 12px 15px;
        margin-top: 8px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .dropdown-toggle::after {
        right: 26px;
        position: absolute;
        top: 21px;
        margin-left: 2.255em;
        color: #515151 !important;
    }

    .info, .group {
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .group {
        text-align: center;
    }

    .group img {
        max-width: 100%;
        height: auto;
    }

    .group p {
        color: #515151;
        font-weight: 500;
        margin: 8px 0 0;
    }

    .info h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .info p {
        margin: 0;
        font-size: 14px;
        color: #686868;
    }


    p.info_txt {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 9px 13px;
        margin-bottom: 10px;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
    }

    .detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    .show_web {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        margin-right: 16px;
    }

    .show_web .el-switch {
        margin-left: 8px;
    }

    .txt--org {
        color: #F58E16 !important;
    }

    .txt--red {
        color: #EE3439 !important;
    }

    .txt--green {
        color: #00B569 !important;
    }

    .utilities {
        position: relative;
        border-radius: 15px;
        border: 1px solid #515151;
        background: #FFF;
        color: #515151;
        font-weight: 500;
        padding: 7px 40px;
        min-width: 100%;
    }

    .view_all {
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        background: #F58E16;
        border: none;
        padding: 9.5px 0;
        /* min-width: 49%; */
        min-width: 100%;
        margin-top: 20px;
    }

    .utilities::after {
        content: '';
        position: absolute;
        background-image: url('@/assets/icons/arrow.png');
        width: 16px;
        height: 15px;
        right: 8px;
        top: 12px;
        transform: rotate(90deg);
    }

    .button-view {
        text-align: center;
    }

    .list_utilities {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-height: 500px;
        overflow: auto;
    }
    
    .list_utilities::-webkit-scrollbar {
        width: 0;
    }

    .list_utilities .item {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
    }

    .list_utilities .item span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-left: 24px;
    }

    @media (min-width: 1700px) {
        .button-view .utilities {
            margin-right: 13px;
        }

        .view_all {
            margin-top: 0;
            min-width: 49%;
        }

        .utilities {
            min-width: 47%;
        }
    }
</style>
<style>
    .detail_da .el-dialog {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    }

    .detail_da .el-dialog__header {
        margin-right: 0;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
        border-radius: 20px 20px 0 0;
    }

    .detail_da .el-dialog__header span {
        font-size: 18px;
        font-weight: 600;
        color: #515151;
    }
</style>