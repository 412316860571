<template>
   <div id="map" style="width: 100%; height: 400px;"></div>
</template>
  
<script>
import goongjs from '@goongmaps/goong-js';
goongjs.accessToken = 'voqvRF3dZKDYFEBC8TA8PHKyeiAa1hD8jIQpFTcI'
export default ({
    props:['lng','lat'],
    data() {
        return {
            longtitude: '',
            latitude: '',
            map: null,
            marker: null
        }
    },
    watch: {
        lng() {
            this.longtitude = this.lng
            this.updateLocation()
        },
        lat() {
            this.latitude = this.lat
            this.updateLocation()
        }
    },
    mounted() {
        this.createMaps()
    },
    methods: {
        createMaps() {
            this.map = new goongjs.Map({
                container: 'map',
                center: [105.83469914358027, 21.036959799692973 ],
                style: 'https://tiles.goong.io/assets/goong_map_web.json',
                zoom: 15
            });
            this.marker = new goongjs.Marker()
                .setLngLat([105.83469914358027, 21.036959799692973])
                .addTo(this.map);
            this.map.addControl(new goongjs.FullscreenControl());
        },
        updateLocation() {
            if(!this.isEmptyCustom(this.longtitude) && !this.isEmptyCustom(this.latitude)) {
                this.marker.setLngLat([this.longtitude, this.latitude])
            }
            this.map.flyTo({ center: [this.longtitude, this.latitude], essential: true });
        }
    }
});
</script>