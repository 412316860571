<template>
    <el-row class="sidebar">
        <el-col :span="24">
            <el-menu default-active="2" style="border-right: none;">
                <el-menu-item style="margin-bottom: 20px;">
                    <router-link to="/">
                        <img src="@/assets/icons/logo_adm.png" alt="">
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path == '/'}">
                    <router-link to="/" class="d-flex align-items-center">
                        <img src="@/assets/icons/home.png" alt="">
                        <span>Trang chủ</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/quan-ly-chi-nhanh')}">
                    <router-link to="/quan-ly-chi-nhanh" class="d-flex align-items-center">
                        <img src="@/assets/icons/agency.png" alt="">
                        <span>Quản lý chi nhánh</span>
                    </router-link>
                </el-menu-item>

                <el-sub-menu index="1" :class="{'active' : $route.path.includes('quan-ly-bds')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/list.png" alt="">
                            <span>Quản lý bất động sản</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/quan-ly-bds/danh-muc')" 
                        :class="{'active_sub' : $route.path.includes('danh-muc')}"
                    >Danh mục bất động sản</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-bds/duyet-tin-bds')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-bds/duyet-tin')}"
                    >Duyệt tin bất động sản </el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-bds/danh-sach-tin-bds')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-bds/danh-sach')}"
                    >Danh sách tin bất động sản</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-bds/du-an')"
                        :class="{'active_sub' : $route.path.includes('du-an')}"
                    >Dự án bất động sản </el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-bds/kho-bds')"
                        :class="{'active_sub' : $route.path.includes('kho-bds')}"
                    >Kho bất động sản </el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-bds/thiet-lap-tin')"
                        :class="{'active_sub' : $route.path.includes('thiet-lap-tin')}"
                    >Thiết lập tin đăng </el-menu-item>
                </el-sub-menu>

                <el-sub-menu index="2" :class="{'active' : $route.path.includes('quan-ly-nhan-vien')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/employee.png" alt="">
                            <span>Quản lý nhân viên</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/quan-ly-nhan-vien/them-moi')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-nhan-vien/them-moi')}">Thêm mới nhân viên</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-nhan-vien/danh-sach')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-nhan-vien/danh-sach')}">Danh sách nhân viên</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-nhan-vien/duyet-nhan-vien')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-nhan-vien/duyet-nhan-vien')}">Duyệt nhân viên</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-nhan-vien/phan-quyen')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-nhan-vien/phan-quyen')}">Phân quyền nhân viên</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-nhan-vien/quan-ly-nhan-su')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-nhan-vien/quan-ly-nhan-su')}">Quản lý nhân sự</el-menu-item>
                    <!-- <el-menu-item>Quản lý công việc</el-menu-item> -->
                </el-sub-menu>

                <el-sub-menu index="3" :class="{'active' : $route.path.includes('quan-ly-khach-hang')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/customer.png" alt="">
                            <span>Quản lý khách hàng</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/quan-ly-khach-hang/xac-minh-khach-hang')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-khach-hang/xac-minh-khach-hang')}">Xác minh khách hàng</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-khach-hang/danh-sach')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-khach-hang/danh-sach')}">Danh sách khách hàng</el-menu-item>
                </el-sub-menu>

                <el-sub-menu index="4" :class="{'active' : $route.path.includes('quan-ly-tin-tuc')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/news.png" alt="">
                            <span>Quản lý tin tức</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/quan-ly-tin-tuc/tao-moi')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-tin-tuc/tao-moi')}">Tạo mới tin tức</el-menu-item>
                    <el-menu-item @click="$router.push('/quan-ly-tin-tuc/danh-sach')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-tin-tuc/danh-sach')}">Danh sách tin tức</el-menu-item>
                </el-sub-menu>

                <el-sub-menu index="5" :class="{'active' : $route.path.includes('quan-ly-tai-chinh')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/finance.png" alt="">
                            <span>Quản lý tài chính</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/quan-ly-tai-chinh/lich-su-giao-dich')"
                        :class="{'active_sub' : $route.path.includes('/quan-ly-tai-chinh/lich-su-giao-dich')}">Lịch sử giao dịch</el-menu-item>
                </el-sub-menu>

                <el-sub-menu index="6" :class="{'active' : $route.path.includes('cong-viec')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/work.png" alt="" style="width: 23px; height: 23px;">
                            <span>Công việc</span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/cong-viec/khach-hang-quan-ly')"
                        :class="{'active_sub' : $route.path.includes('/cong-viec/khach-hang-quan-ly')}">Khách hàng quản lý</el-menu-item>
                    <el-menu-item @click="$router.push('/cong-viec/quan-ly-cong-viec')"
                        :class="{'active_sub' : $route.path.includes('/cong-viec/quan-ly-cong-viec')}">Quản lý công việc</el-menu-item>
                    <el-menu-item @click="$router.push('/cong-viec/quan-ly-kpi')"
                        :class="{'active_sub' : $route.path.includes('/cong-viec/quan-ly-kpi')}">Quản lý KPI</el-menu-item>
                    <el-menu-item @click="$router.push('/cong-viec/quan-ly-nhan-su/quan-ly-nhan-su-chi-nhanh/' + encode(agency_id))"
                        :class="{'active_sub' : $route.path.includes('/cong-viec/quan-ly-nhan-su')}">Quản lý nhân sự</el-menu-item>
                </el-sub-menu>

                <el-menu-item>
                    <router-link to="/" class="d-flex align-items-center">
                        <img src="@/assets/icons/reports.png" alt="">
                        <span>Báo cáo chung</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item :class="{'active' : $route.path.includes('/ho-tro')}">
                    <router-link to="/ho-tro" class="d-flex align-items-center">
                        <img src="@/assets/icons/support.png" alt="" style="width: 23px; height: 23px;">
                        <span>Hỗ trợ</span>
                    </router-link>
                </el-menu-item>

                <el-sub-menu index="7" :class="{'active' : $route.path.includes('he-thong')}">
                    <template #title>
                        <a class="d-flex align-items-center">
                            <img src="@/assets/icons/settings.png" alt="">
                            <span>Hệ thống </span>
                        </a>
                    </template>
                    <el-menu-item @click="$router.push('/he-thong/quan-ly-chung')"
                        :class="{'active_sub' : $route.path.includes('/he-thong/quan-ly-chung')}">Quản lý chung</el-menu-item>
                    <el-menu-item @click="$router.push('/he-thong/quan-ly-thong-bao')"
                        :class="{'active_sub' : $route.path.includes('/he-thong/quan-ly-thong-bao')}">Quản lý thông báo</el-menu-item>
                    <el-menu-item @click="$router.push('/he-thong/quy-che-dieu-khoan')"
                        :class="{'active_sub' : $route.path.includes('/he-thong/quy-che-dieu-khoan')}">Quy chế và điều khoản</el-menu-item>
                    <el-menu-item @click="$router.push('/he-thong/chuong-trinh')"
                        :class="{'active_sub' : $route.path.includes('/he-thong/chuong-trinh')}">Chương trình</el-menu-item>
                </el-sub-menu>
            </el-menu>
        </el-col>
    </el-row>
</template>
<script>
export default {
    data() {
        return {
            agency_id: ''
        }
    },
    mounted() {
        this.agency_id = JSON.parse(localStorage.getItem('admin')).agency_id
    }
}
</script>
<style lang="css" scoped>
    .sidebar a {
        text-decoration: none;
        flex-basis: 100%;
    }

    .sidebar a span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        margin-left: 17px;
    }

    .el-menu-item {
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        padding-left: 16px !important;
    }

    .el-sub-menu .el-menu-item {
        margin: 0 11px 0 42px !important;
        border-left: none;
    }

    .el-menu-item:hover {
        background-color: transparent;
    }

    .el-menu-item.active:hover {
        background: rgba(245, 142, 22, 0.25);
    }

    .el-menu-item.active {
        border-radius: 0px 10px 10px 0px;
        border-left: 5px solid #F58E16 !important;
        background: rgba(245, 142, 22, 0.25);
    }

    .el-menu-item.active_sub {
        border-radius: 20px;
        background: rgba(245, 142, 22, 0.25);
    }

    .el-sub-menu, .el-menu-item {
        border-left: 5px solid transparent;
    }

    .el-sub-menu.active {
        border-left: none;
    }
</style>

<style>
    .sidebar .el-sub-menu__title:hover {
        background-color: transparent;
    }

    .sidebar .el-sub-menu__title {
        padding-left: 16px !important;
    }

    aside.el-aside::-webkit-scrollbar {
        width: 0;
    }

    .el-sub-menu.active .el-sub-menu__title {
        border-radius: 0px 10px 10px 0px;
        border-left: 5px solid #F58E16 !important;
        background: rgba(245, 142, 22, 0.25);
        margin-bottom: 11px;
    }

    .sidebar .el-icon svg {
        width: 15px;
        height: 15px;
    }

    body::-webkit-scrollbar {
        width: 3px;
    }

    body::-webkit-scrollbar-thumb {
        background-color: #F58E16;
    }
</style>