<template>
    <div class="wp-confirm wp-support">
        <div>
            <h5>Danh sách trúng giải</h5>
            <div class="breadcrumb">
                <span>Hệ thống</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span>Chương trình</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span class="active">Danh sách trúng giải</span>
            </div>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <p style="margin: 0;">Số người trúng: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></p>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                        <li><a class="dropdown-item" href="#" @click="type = '', setPage(1), status_txt = 'Tất cả'">Tất cả</a></li>
                        <li><a class="dropdown-item" href="#" @click="type = '0', setPage(1), status_txt = 'Chưa gửi thưởng'">Chưa gửi thưởng</a></li>
                        <li><a class="dropdown-item" href="#" @click="type = 1, setPage(1), status_txt = 'Đã gửi thưởng'">Đã gửi thưởng</a></li>
                    </ul>
                </div>

                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ order_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                        <li><a class="dropdown-item" href="#" @click="order = 'latest', setPage(1), order_txt = 'Gần nhất'">Gần nhất</a></li>
                        <li><a class="dropdown-item" href="#" @click="order = 'oldest', setPage(1), order_txt = 'Cũ nhất'">Cũ nhất</a></li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="sp_tbl" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" type="index" fixed label="Stt" width="85"/>
                    <el-table-column header-align="center" align="center" prop="full_name" label="Họ và tên khách hàng" width="295" />
                    <el-table-column header-align="center" align="center" prop="member_code" label="Mã khách hàng" width="130">
                        <template #default="scope">
                            <span class="code">#{{ scope.row.member_code }}</span>
                        </template>    
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="reward_name" label="Giải thưởng" min-width="448"/>
                    <el-table-column header-align="center" align="center" prop="created_at" label="Ngày trúng" min-width="208">
                        <template #default="scope">
                            <span>{{ formateDate(scope.row.created_at) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="reward_status" label="Trạng thái" width="140">
                        <template #default="scope">
                            <span v-if="scope.row.reward_status == 0" style="color: #F58E16;">Chưa xác nhận</span>
                            <span v-else-if="scope.row.reward_status == 1" style="color: #F13A3A;">Chưa gửi thưởng</span>
                            <span v-else style="color: #00B569;">Đã gửi thưởng</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Note" min-width="100">
                        <template #default="scope">
                            <button @click.stop="getDetail(scope.row.id)">
                                <img src="@/assets/icons/important.png" alt="" v-if="scope.row.note" style="width: 30px; height: 30px;">
                                <img src="@/assets/icons/pencil.png" alt="" v-else>
                            </button>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="110">
                        <template #default="scope">
                            <button class="send" v-if="scope.row.reward_status == 1" @click.stop="reward_id = scope.row.id, confirmSend()">Gửi thưởng</button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
        <el-dialog
            v-model="dialogNote"
            width="518"
            class="dialog_note"
            @close="turnOffErrorMessage('formAdd')"
            align-center
        >
            <p class="ttl">Thông tin khách trúng giải</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                @submit.prevent="updateReward('formAdd', 'update')"
            >
                <el-form-item prop="phone_number_send" label="Số điện thoại nhận quà">
                    <el-input v-model="formAdd.phone_number_send" type="text" placeholder="Nhập số điện thoại" @input="formAdd.phone_number_send = formAdd.phone_number_send.phone.replace(/[^0-9]/g, '')"/>
                </el-form-item>
                <el-form-item prop="address_send" label="Địa chỉ nhận quà">
                    <el-input v-model="formAdd.address_send" type="textarea" placeholder="Nhập địa chỉ"/>
                </el-form-item>
                <el-form-item prop="note" label="Note">
                    <el-input v-model="formAdd.note" type="textarea" placeholder="Nhập ghi chú"/>
                    <button class="btn_del" type="button" @click="formAdd.note = '', updateReward('formAdd', 'delete')">
                        <img src="@/assets/icons/trash_redd.png" alt="">
                    </button>
                </el-form-item>
                <p class="label">Ảnh chụp màn hình</p>
                <img :src="$storage + item" alt="" v-for="(item, index) in formAdd.image" :key="index" style="width: 63px; height: 116px; margin-right: 10px; object-fit: cover;">
            </el-form>
            <div class="wp-btn" style="margin-top: 20px;">
                <button class="btn_cancel" @click="dialogNote = false">Thoát</button>
                <button class="btn_add" @click="updateReward('formAdd', 'update')">Xác nhận</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Tất cả trạng thái',
            order_txt: 'Sắp xếp',
            type: '',
            order: 'latest',
            dialogNote: false,
            reward_id: '',
            formAdd: {
                phone_number_send: '',
                address_send: '',
                note: '',
                image: [],
            },
        }
    },
    mounted() {
        this.getListWinner()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListWinner()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListWinner()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getListWinner() {
            this.startLoading();
            await this.$axios.get('/system/getEventHistoryForAdmin',{
                params: {
                    reward_status: this.type,
                    order: this.order,
                    event_id: this.decode(this.$route.params.eventId),
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        updateReward(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if(!valid && type == 'update') {
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    const data = {
                        id: this.reward_id,
                        address_send: this.formAdd.address_send,
                        phone_number_send: this.formAdd.phone_number_send,
                        note: this.formAdd.note
                    }
                    await this.$axios.put('/system/updateEventHistoryForAdmin',data,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", 'Chỉnh sửa thông tin thành công!');
                        this.dialogNote = false
                        this.getListWinner()
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        async confirmSend() {
            this.startLoading();
            const data = {
                id: this.reward_id,
                reward_status: 1
            }
            await this.$axios.put('/system/updateEventHistoryForAdmin',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", 'Xác nhận gửi thưởng thành công!');
                this.getListWinner()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(id) {
            this.startLoading();
            await this.$axios.get('/system/getEventHistoryDetail',{
                params: {
                    id: id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formAdd = res.data
                this.reward_id = id
                this.dialogNote = true
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-khach-hang/danh-sach/chi-tiet/' + this.encode(row.member_id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown {
        margin-right: 16px;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    span.code {
        color: #3FA5FF;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.075px;
    }

    .btn_del {
        position: absolute;
        right: 10px;
    }

    .btn_add {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        width: 71%;
        height: 40px;
    }

    .btn_cancel {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #FFF;
        width: 25%;
        height: 40px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        margin-right: 15px;
    }

    .dialog_note p.ttl {
        text-align: center;
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    textarea.form-control {
        resize: none;
        box-shadow: none !important;
        padding: 12px 10px !important;
        border: none !important;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    button.send {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background:#F58E16;
        padding: 3px 10px;
    }

    p.label {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 5px;
    }
</style>

<style>
    .dialog_note {
        border-radius: 20px !important;
    }

    .dialog_note .el-form {
        width: 100%;
    }

    .dialog_note .el-dialog__header {
        display: none;
    }

    .dialog_note .el-dialog__body {
        padding: 20px;
    }

    .dialog_note .el-form .el-form-item {
        margin-bottom: 18px !important;
    }

    .dialog_note .el-input__wrapper {
        padding: 12px 10px !important;
        background: #F6F6F6;
    }

    .dialog_note .el-textarea__inner{
        padding: 12px 10px !important;
        border-radius: 15px;
        background: #F6F6F6;
        border: none !important;
        color: #515151;
        font-size: 15px;
        resize: none;
        min-height: 70px !important;
    }
</style>