<template>
    <div class="web" v-if="dataReview.length !== 0">
        <nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '-'; margin-bottom: 18px; margin-top: 17px;" 
        class="nav-md d-flex align-items-center justify-content-between position-relative">
            <ol class="breadcrumb align-items-center mb-0">
                <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/icons/home.png" alt=""></router-link></li>
                <li class="breadcrumb-item" aria-current="page">{{ category_name }}</li>
                <li class="breadcrumb-item active" aria-current="page">{{ dataReview.name }}</li>
            </ol>
        </nav>
        <div class="col-12 mb-3 position-relative">
            <swiper :list_images="dataReview.image" :video="dataReview.video"/>
            <div class="base_params">
                <div class="price">
                    <img src="@/assets/icons/Price-Tag.png" alt="">
                    <div class="text-price">
                        <p v-if="dataReview.unit_id != 5">{{ formatPrice(parseInt(dataReview.price.replace(/[^\d]/g, ''))) }}</p>
                        <p v-else>Thỏa thuận</p>
                        <p class="text--bl" v-if="dataReview.unit_id == 2">~ {{ formatPrice((dataReview.price / dataReview.acreage).toFixed(1)) }} /m2</p>
                        <p class="text--bl" v-else-if="dataReview.unit_id != 5">{{ dataReview.unit_name }}</p>
                    </div>
                </div>
                <div class="area">
                    <img src="@/assets/icons/Torrent.png" alt="">
                    <div class="text-price">
                        <p>{{ dataReview.acreage }} m2</p>
                    </div>
                </div>
            </div>
            <!-- <div class="count_gallery d-none d-xl-flex">
                <div class="video">
                    <img src="@/assets/icons/Cinema_1.png" alt="">1
                </div>
                <div class="image">
                    <img src="@/assets/icons/Photo_Gallery_1.png" alt="">5
                </div>
            </div> -->
            <div class="pr-xl-3">
                <div class="product-name">
                    {{ dataReview.name }}
                </div>
                <div class="address" v-if="dataReview.district && dataReview.ward && dataReview.province">
                    <img class="d-none d-xl-flex" src="@/assets/icons/Visit.png" alt="">
                    {{ dataReview.address }}, {{ JSON.parse(dataReview.ward).name }}, {{ JSON.parse(dataReview.district).name }}, {{ JSON.parse(dataReview.province).name }}
                </div>
                <div class="title">Đặc điểm thông số bất động sản</div>
                <div class="">
                    <div class="params">
                        <div class="col-12 col-lg-6">
                            <div class="items">
                                <div class="icon-width">
                                    <img style="width: 28px; height: 28px;" src="@/assets/icons/Torrent.png" alt="">
                                </div>
                                <div class="title-width">Diện tích tổng:</div>
                                <strong>{{ dataReview.acreage }} m2</strong>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6" v-for="data in describe" v-show="!data.description && data.value && data.value != 0">
                            <div v-for="(item, index) in detail_cate.describe" :key="index" >
                                <div class="items" v-if="item.describe_id == data.describe_id">
                                    <div class="icon-width">
                                        <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                                    </div>
                                    <div class="title-width">{{ item.describe_name }}:</div>
                                    <div v-for="ele in item.value" v-if="item.info_type == 4">
                                        <strong v-if="ele.properties_id == data.value">{{ ele.properties_name }}</strong>
                                    </div>
                                    <strong v-else>{{ data.value }} {{ data.describe_unit }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-for="(item, index) in detail_cate.describe" :key="index">
                            <div v-for="data in describe" v-show="data.description">
                                <div class="items" v-if="item.describe_id == data.describe_id">
                                    <div class="icon-width">
                                        <img :src="$storage + item.icon" alt="" style="max-width: 32px; max-height: 32px;">
                                    </div>
                                    <div class="title-width">{{ item.describe_name }}:</div>
                                    <div v-for="ele in item.value">
                                        <strong v-if="ele.properties_id == data.value">{{ ele.properties_name }}</strong>
                                    </div>
                                    <div class="discript" style="margin-left: 20px;">
                                        {{ data.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="title">Thông tin mô tả chi tiết</div>
                <div class="discription">
                    <p v-html="formatDes"></p>
                </div>
                <div class="title">Mặt bằng và thiết kế</div>
                <div class="discription">
                    <p v-html="formatDesign"></p>
                    <div v-for="item in dataReview.design_file">
                        <img :src="$storage + item.replace('real_estate/design_file','fileUpload')" alt="" style="margin-bottom: 10px; max-width: 100%;">
                    </div>
                </div>
                <!-- <div class="title">Thông tin dự án</div>
                <div class="title">Xem trên bản đồ</div>
                <img src="@/assets/images/maps.png" alt="" style="width: 100%;"> -->
            </div>
        </div>
    </div>
</template>
<script>
import swiper from './swiperReview.vue';
export default {
    props: ['data', 'cate_name', 'cate_des', 'desProps'],
    components:{
        swiper
    },
    data() {
        return {
            list_images: [],
            listProduct: [],
            dataReview: [],
            category_name: '',
            detail_cate: [],
            formatDes: '',
            formatDesign: '',
            describe: []
        }
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                if(this.data) {
                    this.dataReview = this.data
                    this.formatDes = this.dataReview.description.replaceAll('\n', '</br>')
                    if(this.dataReview.design) {
                        this.formatDesign = this.dataReview.design.replaceAll('\n', '</br>')
                    }
                }
            },
            deep: true
        },
        cate_name() {
            this.category_name = this.cate_name
        },
        cate_des() {
            this.detail_cate = this.cate_des
        },
        desProps() {
            this.describe = this.desProps
        }
    },
    mounted() {
        this.dataReview = this.data
        this.category_name = this.cate_name
        this.detail_cate = this.cate_des
        this.describe = this.desProps
    },
}
</script>
<style scoped>
.web{
    max-height: 700px;
    overflow: auto;
    width: 100%;
}
.web::-webkit-scrollbar{
    display: none;
}
.breadcrumb-item.active {
    color: #F13A3A;
    font-size: 15px;
    font-weight: 500;
}
.breadcrumb{
    flex-wrap: nowrap;
    padding-right: 1rem;
    font-size: 15px;
    font-weight: 500;
    color: #515151;
}
.breadcrumb>*{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 45%;
}

.base_params{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.base_params .price{
    margin-bottom: 10px;
}
.price, .area{
    width: 182.796px;
    height: 68.312px;
    border-radius: 49.526px;
    background: #FFF;
    box-shadow: 0px 0px 4.26947px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 0 3.5px;
}
.price img, .area img{
    width: 44px;
    height: 42px;
}
.text-price{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #808080;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    width: 120px;
}
.text-price p{
    color: #F13A3A;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 150%;
}
.product-name{
    color: #515151;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 0 5px 0;
    line-height: normal;
}
.address{
    color: #808080;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 31px;
}
.title{
    color: #515151;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 0;
}
.params{
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 13px;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
}
.params .items{
    display: flex;
    padding: 13px 0;
    border-bottom: #E5E5E5 1px solid;
    flex-wrap: wrap;
    align-items: center;
}
.params .icon-width{
    min-width: 46px;
    width: 46px;
    display: flex;
    align-items: center;
}
.params .title-width{
    min-width: 140px;
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}
.params strong{
    color: #515151;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    min-width: 100px;
    min-width: 35%;
    width: min-content;
    padding: 0 5px;
}
.params .discript{
    color: #515151;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 100%;
    width: auto;
}
.discription{
    color: #7E7E7E;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 20px;
}

.count_gallery{
    position: absolute;
    top: 424px;
    right: 256px;
    color: #FFF;
    text-shadow: 0px 0px 1.2021247148513794px rgba(0, 0, 0, 0.25);
    font-family: Roboto;
    font-size: 15.538px;
    font-weight: 600;
}
.count_gallery .video{
    width: 61px;
}

.text-price p.text--bl {
    color: #808080;
    font-size: 14px;
    font-weight: 500;
}
@media (min-width: 360px) {
    .params strong{
        min-width: 50%;
    }
    
}
@media (min-width: 576px) {
    .params strong{
        width: auto;
        min-width: 100px;
    }
    .breadcrumb>*{
        max-width: 80%;
    }
    .params .items{
        flex-wrap: nowrap;
    }
    .params .discript{
        min-width: unset;
    }
}
@media (min-width: 992px) {
    .params .items{
        min-height: 60px;
        height: 100%;
    }
    
}
@media (min-width: 1280px) {
    .items{
        min-height: 64px;
        height: 100%;
    }
    .base_params{
        position: absolute;
        top: 402px;
        left: 22px;
        align-items: flex-start;
    }
    .product-name{
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .address{
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
    }
    .address img{
        width: 28px;
        height: 28px;
    }
    .title{
        font-size: 18px;
    }
    .params .title-width, .params strong{
        font-size: 15px;
    }
    .params .discript{
        min-width: unset;
    }
    .params{
        margin-top: 0;
    }
}
</style>