<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_del"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice.png" alt="">
        <p class="ttl">{{ title }}</p>
        <p>{{ content }} <br>Hãy xác nhận</p>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm(true)">Xác nhận</button>
        </div>
    </el-dialog>

    <el-dialog
        v-model="successDialog"
        width="553"
        class="dialog_del dialog_success"
        align-center
        @close="confirm(false)"
    >
        <img src="@/assets/icons/ok.png" alt="" style="width: 63px; height: 63px;">
        <p class="success">Thành công</p>
        <p style="color: #515151; font-size: 15px; margin-bottom: 0;">{{ content_success }}</p>
    </el-dialog>
</template>
<script>
export default {
    props: ['dialogVisible', 'success', 'title', 'content', 'content_success'],
    data() {
        return {
            dialogVisibleStatus: false,
            successDialog: false
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogVisible
    },
    watch: {
        dialogVisible() {
            this.dialogVisibleStatus = this.dialogVisible;
            this.successDialog = this.success;
        },
    },
    emits: ['handleCloseDialog', 'confirm'],
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        confirm(successDialog) {
            this.dialogVisibleStatus = false
            this.$emit("confirm", successDialog);
        }
    },
}
</script>
<style lang="css" scoped>

    p.ttl {
        font-size: 20.96px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        color: #444;
    }

    p.success {
        font-size: 18px;
        color: #00B569;
        font-weight: 500;
        margin: 8px 0 20px;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #EE3439;
    }

    button.btn_add {
        color: #FFF;
        background: #EE3439;
        border: none;
        margin-left: 13px;
    }
</style>

<style>
    .dialog_del {
        width: 373px !important;
        border-radius: 20px !important;
    }

    .dialog_success {
        width: 273px !important;
    }

    .dialog_del .el-dialog__header {
        display: none;
    }

    .dialog_del .el-dialog__body {
        text-align: center;
    }
</style>