<template>
    <div class="wp-add-proj">
        <h5>Chỉnh sửa dự án bất động sản</h5>
        <div class="d-flex align-items-center justify-content-between">
            <div class="breadcrumb">
                <span>Quản lý bất động sản</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span>Dự án bất động sản</span>
                <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                <span class="active">Chỉnh sửa thông tin dự án</span>
            </div>
            <button class="btn_add" @click="updateProject('formAdd')">Lưu chỉnh sửa</button>
        </div>

        <div class="wp-form-add">
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
            >
                <p class="title">Thông tin chi tiết dự án</p>
                <el-form-item prop="name" label="Tên dự án bất động sản" class="set_height">
                    <el-input id="name" type="text" v-model="formAdd.name" placeholder="Nhập tên dự án. Ví dụ: Vinhomes Ocean Park Gia Lâm" clearable/>
                    <span class="validate">Tối thiểu 30 ký tự, tối đa 99 ký tự</span>
                </el-form-item>
                <el-form-item prop="owner" label="Chủ đầu tư" class="set_height owner">
                    <input type="file" @change="handleFileChange" accept="image/*" style="display: none" ref="fileInput">
                    <img src="@/assets/images/add_logo.png" alt="" style="cursor: pointer;" @click="$refs.fileInput.click()" v-if="!logo_url">
                    <img :src="$storage + logo_url" alt="" style="cursor: pointer; max-width: 66px; max-height: 66px;" @click="$refs.fileInput.click()" v-else @error="handleError">
                    <el-input id="name" type="text" v-model="formAdd.owner" placeholder="Nhập tên cá nhân, công ty chủ đầu tư" clearable/>
                </el-form-item>

                <el-form-item prop="project_category" label="Chọn danh mục dự án">
                    <el-select clearable filterable placeholder="Chọn danh mục"
                        v-model="formAdd.project_category"
                    >
                        <el-option
                            v-for="item in list_cate.result"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            @click="getCateDetail('change',item.id)"
                        />
                    </el-select>
                </el-form-item>

                <!-- <el-form-item prop="full_address" label="Chọn nhanh địa chỉ">
                    <el-select clearable filterable placeholder="Chọn vị trí theo bản đồ"
                        v-model="formAdd.full_address"
                    >
                        <el-option value="1">1</el-option>
                        <el-option value="2">2</el-option>
                    </el-select>
                </el-form-item> -->
                <div class="select_add">
                    <el-form-item prop="province" label="Tỉnh / thành phố">
                        <el-select filterable placeholder="Chọn Tỉnh / thành phố"
                            v-model="formAdd.province" @change="changeCity('change')"
                        >
                            <el-option v-for="item in dataCity"
                                :key="item.code"
                                :label="item.name"
                                :value="JSON.stringify(item)">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="district" label="Quận / huyện">
                        <el-select filterable placeholder="Chọn Quận / huyện"
                            v-model="formAdd.district" @change="changeDistrict('change')"
                        >
                            <el-option v-for="item in dataDistricts"
                                :key="item.code"
                                :label="item.name"
                                :value="JSON.stringify(item)">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="select_add">
                    <el-form-item prop="ward" label="Phường / xã / thị trấn">
                        <el-select clearable filterable placeholder="Chọn Phường / xã / thị trấn"
                            v-model="formAdd.ward"
                        >
                            <el-option v-for="item in dataWard"
                                :key="item.code"
                                :label="item.name"
                                :value="JSON.stringify(item)">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="address" label="Địa chỉ">
                        <el-input id="name" type="text" v-model="formAdd.address" @blur="getMaps" :disabled="!formAdd.ward" placeholder="Nhập địa chỉ cụ thể" clearable/>
                    </el-form-item>
                </div>
                <p style="font-weight: 500; margin-bottom: 5px;">Vị trí trên bản đồ</p>
                <GMAPS :lng="formAdd.longtitude" :lat="formAdd.latitude"/>
                <hr>
                <h6>Hình ảnh & video của dự án</h6>
                <ul class="verifi-img">
                    <li>Đăng tối thiểu 4 hình ảnh , tối đa 20 hình ảnh </li>
                    <li>Hãy dùng ảnh thật , không trùng ,
                        không chèn SĐT hoặc địa chỉ chi tiết vào hình ảnh</li>
                    <li>Hình ảnh tối thiểu 200 x 200 px , tối đa 15MB</li>
                    <li>Hình ảnh đuôi png , jpg</li>
                    <li>Sắp xếp vị trí hình ảnh hơp lý , vị trí đầu tiên sẽ là ảnh đại diện</li>
                </ul>
                <el-upload class="upload-demo"
                    drag
                    :accept="'image/png, image/jpeg, image/jpg'"
                    multiple
                    :show-file-list="false"
                    :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList)"
                    :action="$API +'/common/upload360image'"
                    :data="{path : 'project/image/' }"
                    list-type="picture"
                    :on-progress="handleUploadProgress"
                >
                    <img src="@/assets/icons/Collectibles.png" alt="">
                    <div class="el-upload__text">Bấm để chọn ảnh cần tải lên</div>
                    <div class="el-upload__tip" slot="tip">hoặc kéo thả ảnh vào đây</div>
                </el-upload>
                <span v-if="formAdd.image.length==0 && showErr" class="error">Vui lòng thêm hình ảnh</span>

                <draggable class="dragArea w-full d-flex flex-wrap" :list="formAdd.image">
                    <div class="card-image" v-for="(item, index) in formAdd.image">
                        <img class="main" :src="this.$storage + item.path_tam" alt="" @error="handleError" v-if="item.path_tam">
                        <img class="main" :src="this.$storage + item.path" alt="" @error="handleError" v-else>
                        <span class="img_ava" v-if="index == 0">Ảnh đại diện</span>
                        <div class="foot">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.is_360" :disabled="item.check360 == 0">
                                <span>360</span>
                            </div>
                            <button type="button" @click="formAdd.image.splice(index, 1)">
                                <img src="@/assets/icons/Trash_Can.png" alt="" >
                            </button>
                        </div>
                    </div>
                </draggable>
                <span v-if="formAdd.image.length !== 0 && formAdd.image.length < 4 && showErr" class="error">Vui lòng đăng tối thiểu 4 hình ảnh</span>
                <span v-else-if="formAdd.image.length !== 0 && formAdd.image.length > 20 && showErr" class="error">Vui lòng đăng tối đa 20 hình ảnh</span>

                <a class="youtube" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <div>
                        <img src="@/assets/icons/Video.png" alt="">
                        <span>Thêm video từ Youtube</span>
                    </div>
                    <img src="@/assets/icons/Forward.png" alt="">
                </a>
                <div class="collapse link-youtube" id="collapseExample">
                    <input v-model="formAdd.video" @input="this.checkYoutube(formAdd.video)">
                    <span>Link:</span>
                </div>
                <span v-if="formAdd.video && !this.checkYoutube(formAdd.video)" class="error">Vui lòng nhập đúng link video Youtube</span>
                <hr>

                <h6>Thông tin bất động sản</h6>
                <el-form-item prop="meta_description" label="Mô tả chung">
                    <textarea name="title" v-model="formAdd.meta_description" :style="{ height: this.calculatedHeight('meta_des') }" ref="meta_des" class="form-control" placeholder="Nhập mô tả chung" id="ttl" @input="this.adjustTextareaHeight"></textarea>
                    <el-input type="textarea" v-model="formAdd.meta_description" class="d-none"></el-input>
                    <span class="validate">Tối thiểu 30 ký tự</span>
                </el-form-item>

                <el-form-item prop="description" label="Tổng quan dự án">
                    <CKeditor
                        :editorData="formAdd.description"
                        @getData="getDataDes"
                    />
                    <span class="validate">Tối thiểu 30 ký tự</span>
                </el-form-item>

                <el-form-item prop="design" label="Mặt bằng và thiết kế">
                    <CKeditor
                        :editorData="formAdd.design"
                        @getData="getDataDesign"
                    />
                    <span class="validate">Tối thiểu 30 ký tự</span>
                </el-form-item>

                <h6 class="detail_info">Thông tin chi tiết</h6>
                <div class="d-flex align-items-center justify-content-between">
                    <el-form-item prop="acreage" label="Diện tích" style="flex-basis: 69%;">
                        <el-input id="name" type="text" v-model="formAdd.acreage" @input="formAdd.acreage = formAdd.acreage.replace(/[^0-9.]/g, '')" placeholder="Nhập diện tích" clearable/>
                    </el-form-item>
                    <el-form-item prop="unit" label="Đơn vị" style="flex-basis: 30%;">
                        <el-input id="name" type="text" v-model="formAdd.unit" placeholder="Nhập đơn vị" clearable/>
                    </el-form-item>
                </div>
                <el-form-item prop="juridical" label="Giấy tờ pháp lý" class="juridical" v-if="list_juridical">
                    <button type="button" v-for="(item, index) in list_juridical" :key="index" @click="addJuridical(item.id)" :class="{'active' : formAdd.juridical.includes(item.id)}">{{ item.name }}</button>
                </el-form-item>

                <p class="ttl" v-if="formAdd.project_describe.length !== 0">Thông tin mô tả bất động sản</p>
                <div class="list-des" v-if="formAdd.project_describe.length !== 0">
                    <div class="des-item" v-for="(item, index) in list_des" :key="index" v-show="list_des_cate.includes(item.id)">
                        <div class="ico">
                            <img :src="$storage + item.icon" alt="">
                            <span>{{ item.name }}</span>
                        </div>
                        <el-input type="text" v-model="formAdd.project_describe[index].value" clearable/>
                    </div>
                </div>

                <p class="ttl">Tiện ích dự án</p>
                <button type="button" class="btn_utilities" @click="dialogVisible = true">Chọn tiện ích dự án</button>
                <div class="list_utilities">
                    <div class="item" v-for="(item, index) in listUtilities" :key="index">
                        <img :src="$storage + item.icon" alt="">
                        <span>{{ item.name }}</span>
                    </div>
                </div>
            </el-form>
        </div>

        <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">

        <listUtilities
            :status="dialogVisible"
            @handleCloseDialog="listenDialog"
            @confirm="getDataDialog"
        />
    </div>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next'
import CKeditor from '../../components/ckeditor.vue'
import listUtilities from '../modal/list_utilities.vue'
import GMAPS from "../../components/goong_maps.vue"
import axios from 'axios';
export default {
    components: { draggable: VueDraggableNext, CKeditor, listUtilities, GMAPS },
    data() {
        return {
            formAdd: {
                project_id: '',
                name: '',
                owner: '',
                icon: '',
                project_category: '',
                // full_address: '',
                province:'',
                district: '',
                ward: '',
                province_id:'',
                district_id: '',
                ward_id: '',
                province_name:'',
                district_name: '',
                ward_name: '',
                address: '',
                image: [],
                video: '',
                description: '',
                design: '',
                acreage: '',
                unit: '',
                juridical: [],
                utilities: [],
                longtitude: "",
                latitude: "",
                project_describe: [],
                meta_description: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên dự án", trigger: ["blur", "change"]}],
                owner: [{required: true, message: "Vui lòng nhập tên chủ đầu tư", trigger: ["blur", "change"]}],
                project_category: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
                province: [{required: true, message: "Vui lòng chọn tỉnh thành", trigger: ["blur", "change"]}],
                district: [{required: true, message: "Vui lòng chọn quận huyện", trigger: ["blur", "change"]}],
                ward: [{required: true, message: "Vui lòng chọn phường xã", trigger: ["blur", "change"]}],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                description: [
                    {required: true, message: "Vui nhập tổng quan dự án", trigger: ["blur", "change"]},
                    {min: 30, message: "Vui lòng nhập tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                ],
                meta_description: [
                    {required: true, message: "Vui nhập mô tả chung", trigger: ["blur", "change"]},
                    {min: 30, message: "Vui lòng nhập tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                ],
                design: [
                    {required: true, message: "Vui nhập mặt bằng và thiết kế", trigger: ["blur", "change"]},
                    {min: 30, message: "Vui lòng nhập tối thiểu 30 ký tự", trigger: ["blur", "change"]}
                ],
                acreage: [{required: true, message: "Vui nhập diện tích", trigger: ["blur", "change"]}],
                unit: [{required: true, message: "Vui nhập đơn vị", trigger: ["blur", "change"]}],
                juridical: [{required: true, message: "Vui chọn giấy tờ pháp lý", trigger: ["blur", "change"]}],
            },
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            list_cate: [],
            logo_url: '',
            list_des: null,
            dialogVisible: false,
            listUtilities: [],
            hidden: '',
            list_juridical: null,
            list_des_cate: [],
            showErr: false,
        }
    },
    mounted() {
        this.getDataCity()
        this.getCate()
        this.handleScroll()
        this.getProjectDetail()
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        getDataDes(data) {
            this.formAdd.description = data
        },
        getDataDesign(data) {
            this.formAdd.design = data
        },
        handleUploadProgress(event, file, fileList) {
            this.startLoading()
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        getDataDialog(data) {
            this.listUtilities = data
            this.formAdd.utilities = data.map(item => item.id);
        },
        addJuridical(key) {
            const index = this.formAdd.juridical.indexOf(key);
            if (index === -1) {
                this.formAdd.juridical.push(key);
            } else {
                this.formAdd.juridical.splice(index, 1);
            }
        },
        async changeCity(type) {
            this.dataDistricts = await this.callApiDistrict(JSON.parse(this.formAdd.province).code)
            if(type == 'change') {
                this.formAdd.district = this.formAdd.ward = ''
                this.dataWard = []
            }
        },
        async changeDistrict(type) {
            this.dataWard = await this.callApiWard(JSON.parse(this.formAdd.district).code)
            if(type == 'change') {
                this.formAdd.ward = ''
            }
        },
        async handleFileChange(event) {
            let url_image = await this.uploadFile('project/icon/', event.target.files[0])
            this.logo_url = url_image.path_tam
            this.formAdd.icon = url_image.path
        },
        async getCate() {
            this.list_cate = await this.getListProjCate()
        },
        uploadFileImage(res, file, fileList) {
            this.endLoading()
            let fileItem = {
                path : res.list_result[0].path,
                path_tam : res.list_result[0].path_tam,
                is_360 : false,
                check360: res.list_result[0].is_360
            }
            this.formAdd.image.push(fileItem)
        },
        updateProject(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.formAdd.image.length == 0 || this.formAdd.image.length < 4 || this.formAdd.image.length > 20) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.formAdd.project_id = this.decode(this.$route.params.id)
                    this.formAdd.province_name = JSON.parse(this.formAdd.province).name
                    this.formAdd.district_name = JSON.parse(this.formAdd.district).name
                    this.formAdd.ward_name = JSON.parse(this.formAdd.ward).name
                    this.formAdd.province_id = JSON.parse(this.formAdd.province).code
                    this.formAdd.district_id = JSON.parse(this.formAdd.district).code
                    this.formAdd.ward_id = JSON.parse(this.formAdd.ward).code
                    this.formAdd.acreage = parseFloat(this.formAdd.acreage)
                    this.startLoading();
                    await this.$axios.put('/project/updateProject', this.formAdd, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Chỉnh sửa dự án thành công!");
                        this.$router.go(-1)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        async getCateDetail(type, id) {
            this.startLoading();
            await this.$axios.get('/project/getProjectCategoryDetail',{
                params: {
                    project_category_id: id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_juridical = res.data.juridical
                if(type == 'change') {
                    this.formAdd.project_describe = res.data.describe.map(project_describe_id => ({ project_describe_id, value: '' }));
                    this.formAdd.juridical = []
                }
                this.list_des_cate = res.data.describe
                this.getListDes(res.data.describe)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListDes(data) {
            this.startLoading();
            await this.$axios.get('/project/getListProjectDescribe',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_des = res.data.filter(item => data.includes(item.id));
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getProjectDetail() {
            this.startLoading();
            await this.$axios.get('/project/getProjectDetailForAdmin',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formAdd = res.data
                this.formAdd.province = JSON.stringify({
                    code: res.data.province_id,
                    name: res.data.province_name,
                    slug: res.data.province_slug
                })
                this.formAdd.district = JSON.stringify({
                    code: res.data.district_id,
                    name: res.data.district_name,
                    province_code: res.data.province_id,
                    slug: res.data.district_slug,
                })
                this.formAdd.ward = JSON.stringify({
                    code: res.data.ward_id,
                    name: res.data.ward_name,
                    district_code: res.data.district_id,
                    slug: res.data.ward_slug,
                })
                this.logo_url = res.data.icon
                this.changeCity('new');
                this.changeDistrict('new')
                this.getCateDetail('new', res.data.project_category)
                this.getListUti()
                document.title = `Chỉnh sửa dự án - ${this.dataDetail.name}`;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListUti() {
            this.startLoading();
            await this.$axios.get('/project/getListUtilities',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listUtilities = res.data.filter(item => this.formAdd.utilities.includes(item.id))
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getMaps() {
            let address = this.formAdd.address + ', ' + JSON.parse(this.formAdd.ward).name + ', ' + JSON.parse(this.formAdd.district).name + ', ' + JSON.parse(this.formAdd.province).name
            await this.$axios.get('/common/callAPIGoong',{
                params: {
                    address: address,
                },
            }).then(res => {
                this.formAdd.longtitude = res.data.results[0].geometry.location.lng
                this.formAdd.latitude = res.data.results[0].geometry.location.lat
            })
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    h6 {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
    }

    h6.detail_info {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .btn_add {
        border-radius: 15px;
        background: #F58E16;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border: none;
        color: #FFF;
        padding: 10px 14px;
    }

    .wp-form-add {
        max-width: 842px;
        margin: 0 auto;
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    p.title {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    span.validate {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
    }

    .select_add {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .select_add > div {
        width: 49.5%;
    }

    .verifi-img {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        padding-left: 1.5rem;
    }

    .el-upload__text {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
    }

    .el-upload__tip {
        color: #B4B4B4;
        font-size: 13px;
        font-weight: 400;
    }

    .youtube{
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        background: #F8F8F8;
        height: 63px;
        color: #515151;
        justify-content: space-between;
        text-decoration: none;
        font-weight: 500;
        margin-top: 10px;
    }

    .youtube img{
        margin: 0 13px;
    }

    .link-youtube{
        position: relative;
    }
    .link-youtube input{
        height: 48px;
        border-radius: 20px;
        border: 1px solid #B7B7B7;
        background: #F8F8F8;
        width: 100%;
        padding: 0 0 0 60px;
        color: #515151;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        margin-top: 10px;
    }

    .link-youtube input:focus-visible {
        box-shadow: none;
        outline: none;
    }
    .link-youtube span{
        color: #B4B4B4;
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        left: 10px;
        top: 37%;
    }

    .card-image{
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 2.11856px 0px rgba(0, 0, 0, 0.25);
        padding: 5px;
        margin: 6px;
        position: relative;
    }

    .card-image .img_ava {
        position: absolute;
        right: 8px;
        bottom: 57px;
        color: #FFF;
        font-size: 11.959px;
        font-weight: 500;
        border-radius: 11.211px;
        background: rgba(89, 89, 89, 0.66);
        backdrop-filter: blur(2px);
        padding: 3px 24px;
    }

    .card-image img.main{
        width: 240.408px;
        height: 152.831px;
        border-radius: 5.412px;
        border-radius: 10px;
        object-fit: cover;
    }
    .card-image .foot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
    .card-image button{
        border: unset;
        background: unset;
        padding: 0;
    }

    p.ttl {
        color: #515151;
        font-weight: 500;
        margin: 20px 0 10px;
    }

    .des-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .des-item:last-child {
        margin-bottom: 0;
    }

    .des-item .ico {
        flex-basis: 50%;
    }

    .des-item .ico span {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
    }

    .des-item .ico img {
        width: 30px;
        max-height: 30px;
        margin-right: 10px;
    }

    .btn_utilities {
        border-radius: 15px;
        border: 1px solid #515151;
        background: #FFF;
        color: #515151;
        font-weight: 500;
        width: 199px;
        height: 40px;
    }

    .list_utilities {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 16.5px;
    }
    
    .list_utilities .item {
        flex-basis: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
    }

    .list_utilities .item span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-left: 24px;
    }

    textarea.form-control {
        resize: none;
        box-shadow: none;
        min-height: 100px;
        border-radius: 15px;
        background: #F6F6F6;
        color: #606266;
        font-size: 15px;
    }

    textarea.form-control::placeholder {
        color: #606266;
    }

    textarea.form-control::-webkit-scrollbar {
        width: 0;
    }
</style>

<style>
    .wp-add-proj .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        height: 46px;
    }

    .wp-add-proj .set_height .el-input__wrapper {
        height: 70px;
    }

    .owner .el-input {
        width: 89%;
    }

    .owner .el-form-item__content {
        justify-content: space-between;
    }

    .wp-add-proj .el-select {
        width: 100%;
    }

    .wp-add-proj .ck-content {
        min-height: 200px;
    }

    .ck-powered-by {
        display: none;
    }

    .wp-add-proj .juridical button {
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        border: none;
        width: 190px;
        height: 36px;
        margin: 10px 16px 0 0;
    }

    .wp-add-proj .juridical button.active {
        background: #FFA8A8;
    }

    /* .wp-add-proj .juridical button:first-child {
        margin-left: 0;
    } */

    .wp-add-proj .el-form-item__label {
        font-size: 16px;
    }

    .wp-add-proj .el-upload-dragger {
        background: #f8f8f8;
        border-radius: 20px;
        border: none;
    }
</style>