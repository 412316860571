<template>
    <div class="wp-confirm">
        <h5>Danh sách chi nhánh</h5>
        <div class="breadcrumb">
            <span>Quản lý chi nhánh</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách chi nhánh</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12"></el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <button class="add-new" @click="$router.push('/quan-ly-chi-nhanh/them-moi')">Tạo chi nhánh mới +</button>
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="main_agency" class="main_agency" style="width: 100%;" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed width="124">
                        <template #default="scope">
                            <img :src="$storage + scope.row.image" alt="" style="width: 80px; height: 80px; border-radius: 15px;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="agency_name" width="287" />
                    <el-table-column header-align="center" align="center" width="129">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.agency_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="address" min-width="296"/>
                    <el-table-column header-align="center" align="center" prop="manage" min-width="195" />
                    <el-table-column header-align="center" align="center" prop="phone" width="135"/>
                    <el-table-column header-align="center" align="center" prop="count_staff" min-width="148" />
                </el-table>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh" width="124">
                        <template #default="scope">
                            <img :src="$storage + scope.row.image" alt="" style="width: 80px; height: 80px; border-radius: 15px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="agency_name" label="Tên chi nhánh" width="287" />
                    <el-table-column header-align="center" align="center" label="Mã chi nhánh" width="129">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.agency_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="address" label="Địa chỉ" min-width="296"/>
                    <el-table-column header-align="center" align="center" prop="manage" label="Giám đốc chi nhánh" min-width="195" />
                    <el-table-column header-align="center" align="center" prop="phone" label="Số điện thoại" width="135"/>
                    <el-table-column header-align="center" align="center" prop="count_staff" label="Số nhân viên" min-width="148" />
                    <el-table-column header-align="center" align="center" label="Action" min-width="124">
                        <template #default="scope">
                            <div class="d-flex align-items-center">
                                <el-switch
                                    v-model="scope.row.view_status"
                                    :active-value="1"
                                    class="ml-2"
                                    style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                                />
                                <button style="padding: 0 12px;"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 12,
                currentPage: this.$route.query.page || 1,
            },
            main_agency: []
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getList()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getList()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getList() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/agency/getListAgencyForadmin',{
                params: {
                    name: this.keySearch,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result.filter( item => item.id != 1);
                this.main_agency = res.data.result.filter( item => item.id == 1);
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-chi-nhanh/chi-tiet/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .add-new {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        padding: 10px 14px;
    }

    .add-new img {
        filter: brightness(10);
    }

    .list-cate {
        padding: 15px 12px 0;
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        position: absolute;
        top: 48px;
        z-index: 10;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .list-cate .ttl {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 16px;
    }
    
    .list-cate ul {
        list-style: none;
        padding: 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .list-cate ul::-webkit-scrollbar {
        width: 0;
    }

    .list-cate ul li {
        border-radius: 32px;
        background: #F4F4F4;
        height: 35px;
        margin-bottom: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        cursor: pointer;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .main_agency {
        border-radius: 20px;
        margin-bottom: 20px;
    }
</style>

<style>
    .main_agency thead tr {
        display: none;
    }
</style>