<template>
    <div class="wp-detail">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết khách hàng</h5>
                <div class="breadcrumb">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Khách hàng quản lý</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết khách hàng</span>
                </div>
            </div>
            <div class="wp-btn">
                <button style="margin-right: 10px;" @click="dialogVisible = true">Xóa khách hàng</button>
                <button @click="$router.push('/cong-viec/khach-hang-quan-ly/chinh-sua/' + this.$route.params.id)">Chỉnh sửa</button>
            </div>
        </div>
        <div class="wp-content">
            <h6>Thông tin cá nhân khách hàng</h6>
            <div class="text-center">
                <img :src="$storage + dataDetail.avatar" alt="" @error="handleError" style="width: 227px; height: 227px; object-fit: cover; border-radius: 114px;">
                <p class="type">Loại khách hàng</p>
                <div class="dropdown" style="margin-bottom: 20px;">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        <span v-if="dataDetail.customer_type == 1" style="color: #00B569;">Khách tiềm năng</span>
                        <span v-else-if="dataDetail.customer_type == 2" style="color: #F58E16;">Khách thường</span>
                        <span v-else-if="dataDetail.customer_type == 3" style="color: #EE3439;">Khách khó</span>
                        <span v-else-if="dataDetail.customer_type == 4" style="color: #00B569;">Khách dễ tính</span>
                        <span v-else-if="dataDetail.customer_type == 5" style="color: #F58E16;">Khách mua</span>
                        <span v-else-if="dataDetail.customer_type == 6" style="color: #F58E16;">Khách bán</span>
                        <span v-else-if="dataDetail.customer_type == 7" style="color: #1AD4ED;">Khách môi giới</span>
                        <span v-else-if="dataDetail.customer_type == 8" style="color: #1AD4ED;">Đại lý</span>
                        <span v-else>Loại khách hàng</span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <li v-for="(item, index) in listType" :key="index">
                            <a class="dropdown-item" href="#" :style="{'color': item.color}" @click="updateType(item.id)">
                                {{ item.txt }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="d-flex justify-content-center" style="margin-bottom: 20px;">
                    <button class="btn-act" @click="changeRoute('nhu-cau')">
                        <div class="wp-img">
                            <img src="@/assets/icons/favorite.png" alt="" style="filter: brightness(0.4);">
                        </div>
                        <p>Nhu cầu</p>
                    </button>
                    <button class="btn-act" @click="changeRoute('dau-viec')">
                        <div class="wp-img">
                            <img src="@/assets/icons/job.png" alt="">
                        </div>
                        <p>Đầu việc</p>
                    </button>
                </div>
            </div>
            <div class="info">
                <p>Tên khách hàng:</p>
                <p>{{ dataDetail.full_name }}</p>
            </div>
            <div class="info">
                <p>Giới tính:</p>
                <p v-if="dataDetail.gender == 1">Nam</p>
                <p v-else>Nữ</p>
            </div>
            <div class="info">
                <p>Ngày sinh:</p>
                <p>{{ formateDate(dataDetail.birthday) }}</p>
            </div>
            <div class="info">
                <p>Số điện thoại liên hệ:</p>
                <p>{{ dataDetail.phone }}</p>
            </div>
            <div class="info">
                <p>Email:</p>
                <p>{{ dataDetail.email }}</p>
            </div>
            <div class="info">
                <p>Địa chỉ:</p>
                <p>{{ dataDetail.address }}</p>
            </div>
            <div class="info">
                <p>Nguồn:</p>
                <p v-if="dataDetail.system == 1">Hệ thống</p>
                <p v-else>Cá nhân</p>
            </div>
            <div class="info">
                <p>Chi nhánh phụ trách:</p>
                <p>{{ dataDetail.agency_name }}</p>
            </div>
            <div class="info">
                <p>Người phụ trách:</p>
                <div>
                    <span>{{ dataDetail.sale_name }}</span>
                    <img :src="$storage + dataDetail.sale_avatar" alt="" @error="handleError">
                </div>
            </div>
            <p class="note_ttl">Ghi chú về khách hàng:</p>
            <p class="note">{{ dataDetail.note }}</p>
        </div>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="deleteCustomer"
        title="Xóa"
        content="Bạn đang yêu cầu xóa bỏ khách hàng"
    />
</template>
<script>
import Dialog from '../../components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dataDetail: [],
            listType: [
                {id: 1, txt: 'Khách tiềm năng', color: '#00B569'},
                {id: 2, txt: 'Khách thường', color: '#F58E16'},
                {id: 3, txt: 'Khách khó', color: '#EE3439'},
                {id: 4, txt: 'Khách dễ tính', color: '#00B569'},
                {id: 5, txt: 'Khách mua', color: '#F58E16'},
                {id: 6, txt: 'Khách bán', color: '#F58E16'},
                {id: 7, txt: 'Khách môi giới', color: '#1AD4ED'},
                {id: 8, txt: 'Đại lý', color: '#1AD4ED'},
            ],
            dialogVisible: false,
        }
    },
    mounted() {
        this.getDataDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async deleteCustomer() {
            this.startLoading();
            await this.$axios.put('/job/deleteCustomer',{id: this.decode(this.$route.params.id)},{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa khách hàng thành công!");
                this.$router.push('/cong-viec/khach-hang-quan-ly')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataDetail() {
            this.startLoading();
            await this.$axios.get('/job/getDetailCustomer',{
                params: {
                    id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateType(type) {
            const data = {
                id: this.decode(this.$route.params.id),
                customer_type: type
            }
            this.startLoading();
            await this.$axios.put('/job/updateCustomer',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Cập nhật loại khách hàng thành công!");
                this.getDataDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        changeRoute(type) {
            this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.id +'/' + type)
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .dropdown button {
        width: 230px;
        height: 34px;
        border-radius: 20px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        padding: 0 4px;
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 230px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .wp-btn button {
        width: 200px;
        height: 40px;
        color: #F13A3A;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        border: 1px solid #EE3439;
    }

    .wp-btn button:last-child {
        color: #515151;
        border: 1px solid #515151;
    }

    .wp-content {
        width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 0 auto;
    }

    h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    p.type {
        margin: 20px 0 0;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 180%;
    }

    .btn-act {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .btn-act .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-act:first-child {
        margin-right: 30px;
    }

    .btn-act img {
        width: 30px;
        height: 30px;
    }

    .btn-act p {
        margin: 5px 0 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #B4B4B4;
        margin-bottom: 15px;
    }

    .info p {
        margin: 5px 0;
        color: #515151;
        font-size: 14px;
        line-height: 180%;
    }

    .info p:last-child {
        font-size: 16px;
        font-weight: 500;
        flex-basis: 80%;
        text-align: right;
    }

    .info > div span {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-right: 10px;
    }

    .info > div img {
        width: 50px;
        height: 50px;
        border-radius: 34px;
        object-fit: cover;
    }

    p.note_ttl {
        color: #515151;
        font-size: 14px;
        margin-bottom: 5px;
    }

    p.note {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        padding: 10px;
        border-radius: 20px;
        background: #F9F9FC;
    }
</style>