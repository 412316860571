<template>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border-radius: 20px;">
                <div class="modal-header">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist" style="border-bottom: none;">
                        <h5 class="modal-title" id="exampleModalLabel">Xem trước giao diện</h5>
                        <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-web" type="button" role="tab" 
                        aria-controls="nav-web" aria-selected="true">
                            <img src="@/assets/icons/Monitor_red.png" alt="">
                        </button>
                        <button class="nav-link" id="nav-mobie-tab" data-bs-toggle="tab" data-bs-target="#nav-mobie" 
                        type="button" role="tab" aria-controls="nav-mobie" aria-selected="false">
                            <img src="@/assets/icons/iPhone14Pro.png" alt="">
                        </button>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-web" role="tabpanel" aria-labelledby="nav-web-tab">
                        <ReviewWeb :data="dataProp" :cate_name="category_name" :cate_des="cate_detail" :desProps="listDes"/>
                    </div>
                    <div class="tab-pane fade" id="nav-mobie" role="tabpanel" aria-labelledby="nav-mobie-tab">
                        <ReviewMobie :data="dataProp" :cate_name="category_name" :cate_des="cate_detail" :desProps="listDes"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ReviewMobie from './reviewMB.vue';
import ReviewWeb from './reviewPC.vue';

export default {
    props: ['dataReview', 'cate', 'detail_cate', 'list_des'],
    components: { ReviewWeb, ReviewMobie },
    data() {
        return {
            dataProp: [],
            category_name: '',
            cate_detail: [],
            listDes: []
        }
    },
    watch: {
        dataReview() {
            this.dataProp = this.dataReview
        },
        cate() {
            this.category_name = this.cate
        },
        detail_cate() {
            this.cate_detail = this.detail_cate
        },
        list_des() {
            this.listDes = this.list_des
        }
    },
    mounted() {
        this.dataProp = this.dataReview
        this.category_name = this.cate
        this.cate_detail = this.detail_cate
        this.listDes = this.list_des
    },
}
</script>
<style scoped>
.modal {
    padding-right: 0 !important;
}
.modal-header button{
    border: unset;
    background-color: unset;
    filter: grayscale(1);
    margin-left: 15px;
    padding: 0;
}
.modal-header h5{
    color: #515151;
    font-size: 18px;
    font-weight: 600;
}
.modal-header button.active{
    filter: unset;
}
#account-setting .modal-header .nav-tabs{
    border-bottom: 0;
    height: auto;
}
.tab-content>.active{
    display: flex;
    justify-content: center;
}
.modal-dialog {
    max-width: 997px;
}
</style>