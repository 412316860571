<template>
    <div class="wp-lender">
        <div class="calendar-container is-light-mode">
            <Qalendar
                :selected-date="new Date()"
                :events="dataCalender"
                :config="config"
                @datetime-was-clicked="hanldeClick"
                @date-was-clicked="hanldeClick"
                @updated-period="changeMonth"
                ref="calender"
            />
        </div>
        <div class="wp-head">
            <p>Số đầu việc: <span>{{ data.total_element }}</span></p>
            <div class="d-flex align-items-center">
                <button class="kpi" :class="{'active' : formFilter.kpi}" @click="formFilter.kpi = !formFilter.kpi, emitData()" v-if="type != 3">KPI</button>
                <el-form-item prop="user_id" style="margin: 0 10px 0 0;" v-if="type == 2">
                    <el-select clearable placeholder="Nhân viên" v-model="formFilter.user_id" @change="emitData">
                        <el-option v-for="item in listUser"
                            :key="item.user_id"
                            :label="item.full_name +' - '+ item.user_code"
                            :value="item.user_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="job_status" style="margin: 0 10px 0 0;" v-if="type != 3">
                    <el-select clearable placeholder="Trạng thái" v-model="formFilter.job_status" @change="emitData">
                        <el-option v-for="item in listStatus"
                            :key="item.id"
                            :label="item.text"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="active_id" style="margin: 0 10px 0 0;" v-if="type != 3">
                    <el-select clearable placeholder="Đầu việc" v-model="formFilter.active_id" @change="emitData">
                        <el-option v-for="item in listActive"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="level" style="margin: 0 10px 0 0;">
                    <el-select clearable placeholder="Mức độ ưu tiên" v-model="formFilter.level" @change="emitData">
                        <el-option v-for="item in listLevel"
                            :key="item.id"
                            :label="item.text"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        
        <el-row class="item_job" v-for="(item, index) in data.result" :key="index">
            <el-col :span="3">
                <div class="time">
                    <p>{{ $moment(item.date_time).format('dddd') }}</p>
                    <p>{{ $moment(item.date_time).format('Do') }}</p>
                    <button><img src="@/assets/icons/add_work.png" alt=""></button>
                </div>
            </el-col>
            <el-col :span="21" v-if="type != 3">
                <div v-for="item_user in item.list_user">
                    <div>
                        <p class="job" v-if="type == 1"><img src="@/assets/icons/private.png" alt=""> Công việc cá nhân</p>
                        <p class="job" v-else><img :src="$storage + item_user.user_avatar" alt="" @error="handleError" class="avatar_user"> {{ item_user.user_name }}</p>
                        <p class="job_count">Số đầu việc: <span>{{ item_user.count_job_user }}</span></p>
                    </div>
                    <div class="list-item-work">
                        <div class="wp-item" v-for="item_job in item_user.list_job" @click="$router.push('/cong-viec/quan-ly-cong-viec/chi-tiet-cong-viec/' + encode(item_job.id))">
                            <div class="d-flex align-items-center justify-content-between">
                                <p class="ttl">{{ item_job.title }}</p>
                                <span v-for="status in listStatus" v-show="status.id == item_job.job_status" :style="{ color: status.color }" style="font-weight: 600;">{{ status.text }}</span>
                            </div>
                            <div class="info">
                                <img src="@/assets/icons/clock.png" alt="">
                                <span>{{ $moment.utc(item_job.start_date).format('HH[h]mm') }} ({{ $moment.utc(item_job.start_date).format('DD/MM/YY') }}) - {{ $moment.utc(item_job.end_date).format('HH[h]mm') }} ({{ $moment.utc(item_job.end_date).format('DD/MM/YY') }})</span>
                            </div>
                            <div class="info">
                                <img src="@/assets/icons/task.png" alt="">
                                <span>{{ item_job.active_name }}</span>
                            </div>
                            <div class="info">
                                <img src="@/assets/icons/group.png" alt="">
                                <span>{{ item_job.user_name }}</span>
                            </div>
                            <div class="info">
                                <img src="@/assets/icons/address.png" alt="">
                                <span>{{ item_job.address }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="info" v-if="item_job.reminder_time" style="margin-right: 15px;">
                                        <img src="@/assets/icons/alarm.png" alt="">
                                        <span class="txt--red">{{ $moment.utc(item_job.reminder_time).format('HH[h]mm') }}</span>
                                    </div>
                                    <div class="info" style="margin-right: 15px;">
                                        <img src="@/assets/icons/bookmark_ico.png" alt="">
                                        <span v-for="level in listLevel" v-show="level.id == item_job.level" :style="{ color: level.color }">{{ level.text }}</span>
                                    </div>
                                    <div class="info" style="margin-right: 15px;" v-if="item_job.kpi">
                                        <img src="@/assets/icons/flag.png" alt="">
                                        <span class="txt--red">KPI</span>
                                    </div>
                                </div>
                                <div class="wp-btn">
                                    <button @click.stop="$router.push('/cong-viec/quan-ly-cong-viec/chinh-sua-cong-viec/' + encode(item_job.id))" v-if="item_job.job_status == 0"><img src="@/assets/icons/pencil.png" alt=""></button>
                                    <button @click.stop="dialogVisible = true, id = item_job.id"><img src="@/assets/icons/trash.png" alt=""></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="end_el">
                </div>
            </el-col>
            <el-col :span="21" v-else>
                <div class="list-item-work">
                    <div class="wp-item" v-for="item_job in item.list_job" @click="$router.push('/cong-viec/quan-ly-cong-viec/chi-tiet-cong-viec/' + encode(item_job.id))">
                        <div class="d-flex align-items-center justify-content-between">
                            <p class="ttl">{{ item_job.title }}</p>
                            <span v-for="status in listStatus" v-show="status.id == item_job.job_status" :style="{ color: status.color }" style="font-weight: 600;">{{ status.text }}</span>
                        </div>
                        <div class="info">
                            <img src="@/assets/icons/clock.png" alt="">
                            <span>{{ $moment.utc(item_job.start_date).format('HH[h]mm') }} ({{ $moment.utc(item_job.start_date).format('DD/MM/YY') }}) - {{ $moment.utc(item_job.end_date).format('HH[h]mm') }} ({{ $moment.utc(item_job.end_date).format('DD/MM/YY') }})</span>
                        </div>
                        <div class="info">
                            <img src="@/assets/icons/task.png" alt="">
                            <span>{{ item_job.active_name }}</span>
                        </div>
                        <div class="info">
                            <img src="@/assets/icons/group.png" alt="">
                            <span>{{ item_job.user_name }}</span>
                        </div>
                        <div class="info">
                            <img src="@/assets/icons/address.png" alt="">
                            <span>{{ item_job.address }}</span>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="info" v-if="item_job.reminder_time" style="margin-right: 15px;">
                                    <img src="@/assets/icons/alarm.png" alt="">
                                    <span class="txt--red">{{ $moment.utc(item_job.reminder_time).format('HH[h]mm') }}</span>
                                </div>
                                <div class="info" style="margin-right: 15px;">
                                    <img src="@/assets/icons/bookmark_ico.png" alt="">
                                    <span v-for="level in listLevel" v-show="level.id == item_job.level" :style="{ color: level.color }">{{ level.text }}</span>
                                </div>
                                <div class="info" style="margin-right: 15px;" v-if="item_job.kpi">
                                    <img src="@/assets/icons/flag.png" alt="">
                                    <span class="txt--red">KPI</span>
                                </div>
                            </div>
                            <div class="wp-btn">
                                <button @click.stop="$router.push('/cong-viec/quan-ly-cong-viec/chinh-sua-cong-viec/' + encode(item_job.id))" v-if="item_job.job_status == 0"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="dialogVisible = true, id = item_job.id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Xóa"
        content="Bạn đang yêu cầu xóa bỏ đầu việc"
        content_success="Bạn đã xóa đầu việc thành công"
    />
</template>
<script>
import { Qalendar } from "qalendar";
import Dialog from '../components/dialog.vue'
export default {
    props: ['data', 'dataCalender', 'type', 'teamId'],
    emits: ['deleteSuccess', 'filterChange', 'selectDay'],
    components: {
        Qalendar,
        Dialog
    },
    data() {
        return {
            config: {
                locale: "vi-VN",
                defaultMode: "month",
            },
            listStatus: [
                {id: 0, text: 'Đang xử lý', color: '#F58E16'},
                {id: 1, text: 'Đã hoàn thành - chờ xác nhận', color: '#F58E16'},
                {id: 2, text: 'Hoàn thành', color: '#00B569'},
                {id: 3, text: 'Không hoàn thành', color: '#F13A3A'},
                {id: 4, text: 'Quá hạn', color: '#F13A3A'},
            ],
            listLevel: [
                {id: 1, text: 'Không quan trọng', color: '#515151'},
                {id: 2, text: 'Bình thường', color: '#00B569'},
                {id: 3, text: 'Quan trọng', color: '#F58E16'},
                {id: 4, text: 'Rất quan trọng', color: '#EE3439'},
            ],
            dialogVisible: false,
            id: '',
            listActive: [],
            listUser: [],
            formFilter: {
                kpi: '',
                job_status: '',
                active_id: '',
                user_id: '',
                level: '',
                start_date: '',
                end_date: '',
            },
            start_date: '',
            end_date: '',
        }
    },
    mounted() {
        this.getListActive()
        if(this.teamId) {
            this.getListUser(this.teamId)
        }
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async confirm() {
            this.startLoading();
            await this.$axios.put('/job/deleteJob', {id: this.id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa đầu việc thành công!");
                this.$emit('deleteSuccess', this.start_date, this.end_date)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListActive() {
            await this.$axios.get('/job/getListActive', {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listActive = res.data
            })
        },
        async getListUser(id) {
            await this.$axios.get('/job/getUserTeam', {
                params: {
                    page: 1,
                    per_page: 1000,
                    id: id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listUser = res.data.result
            })
        },
        emitData() {
            if(this.formFilter.kpi) {
                this.formFilter.kpi = 1
            } else {
                this.formFilter.kpi = ''
            }
            this.$emit('filterChange', this.formFilter)
        },
        hanldeClick(event) {
            const itemLists = document.querySelectorAll('.calendar-month__week');
            itemLists.forEach(function(div) {
                const items = div.querySelectorAll('.calendar-month__weekday');
                items.forEach(function(item) {
                    itemLists.forEach(function(div) {
                        div.querySelectorAll('.calendar-month__weekday').forEach(function(item) {
                            item.classList.remove('active');
                        });
                    });
                    document.querySelector('#day-' + event).classList.add('active');
                });
            });
            this.$emit('selectDay', event)
        },
        changeMonth(event) {
            this.formFilter.start_date = this.$moment(event.start).format('YYYY-MM-DD')
            this.start_date = this.$moment(event.start).format('YYYY-MM-DD')
            this.formFilter.end_date = this.$moment(event.end).format('YYYY-MM-DD')
            this.end_date = this.$moment(event.end).format('YYYY-MM-DD')
            this.$emit('filterChange', this.formFilter)
        },
    }
}
</script>
<style lang="css" scoped>
    .wp-head {
        margin: 20px 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-head p {
        color: #515151;
        font-family: Roboto;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin: 0;
    }

    .wp-head p span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.18px;
    }

    .time {
        text-align: center;
    }

    .time p:first-child {
        color: #515151;
        font-size: 15px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.15px;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    .time p:nth-child(2) {
        border-radius: 10px;
        background: #EE3439;
        color: #FFF;
        font-size: 36px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.36px;
        padding: 10px;
        margin: 3px auto;
        max-width: 63px;
    }

    .time button {
        padding: 5px;
    }

    .time button img {
        width: 30px;
        height: 30px;
    }

    .list-item-work {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .wp-item {
        border-radius: 20px;
        background: #F4F4F4;
        padding: 10px 15px;
        margin-bottom: 20px;
        flex-basis: 49%;
        cursor: pointer;
    }

    .wp-item img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    p.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .info {
        display: flex;
        align-items: center;
        margin: 6px 0;
    }

    .info span {
        color: #515151;
        font-size: 15px;
        font-weight: 600;
    }

    p.job {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 5px;
    }

    p.job img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    p.job_count {
        color: #515151;
        margin-bottom: 10px;
    }

    p.job_count span {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
    }

    hr.end_el {
        margin: 0 0 20px 0;
        color: #ABABAB;
        opacity: 1;
    }

    .item_job {
        border-bottom: 1px solid #B4B4B4;
        margin-top: 40px;
    }

    button.kpi {
        color: #515151;
        text-align: center;
        font-family: "Public Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        border-radius: 15px;
        box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
        width: 80px;
        height: 38px;
        margin-right: 10px;
    }

    button.kpi.active {
        background-color: #EE3439;
        color: #fff;
    }

    img.avatar_user {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        object-fit: cover;
    }
</style>
<style lang="css">
    .wp-lender .el-select {
        width: 193px;
    }

    .wp-lender .el-input__wrapper {
        border-radius: 15px;
        padding: 4px 10px;
    }

    .wp-lender .el-input__inner {
        color: #515151;
        font-family: "Public Sans";
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .wp-lender .el-input__inner::placeholder {
        color: #515151;
    }

    .calendar-root {
        border-radius: 20px !important;
    }

    .calendar-month__weekday {
        min-height: 60px !important;
        font-size: 16px !important;
        color: #515151 !important;
        font-weight: 500 !important;
        font-family: Roboto;
    }

    .calendar-header {
        border-radius: 20px 20px 0px 0px !important;
        background: #686868;
    }

    .calendar-header__period-name {
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.16px;
    }

    .calendar-header__mode-picker {
        border: none !important;
    }

    .calendar-header__mode-value {
        border-radius: 10px !important;
        background: #FFF;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        font-family: Roboto;
    }

    .calendar-header__chevron-arrows .calendar-header__chevron-arrow {
        color: #FFF !important;
    }

    .calendar-month__day-name {
        height: 40px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 0 !important;
        font-size: 15px !important;
        color: #515151 !important;
        font-weight: 500 !important;
        font-family: Roboto;
    }

    .fa-calendar-day {
        color: #FFF;
        font-size: 30px !important;
    }

    .date-picker__value-display-text {
        display: none !important;
    }

    .date-picker__value-display {
        border: none !important;
        padding: 0 !important;
    }

    .mode-is-month .date-picker {
        min-width: 4rem !important;
    }

    .calendar-month__week:last-child .calendar-month__weekday {
        border-bottom: none;
    }

    .calendar-month__day_events {
        display: none !important;
    }

    .calendar-month__weekday.is-today .calendar-month__day-date {
        padding: 4px 10px !important;
        background-color: transparent !important;
    }

    .calendar-month__weekday.is-today {
        box-shadow: inset 0 0 0 3px #F58E16 !important;
    }

    .calendar-month__weekday.active {
        background-color: #F58E16 !important;
    }

    .calendar-month__day-date, .calendar-month__event-title, .calendar-month__event-time {
        font-size: 14px !important;
        font-weight: 600;
        color: #515151 !important;
    }

    .calendar-root-wrapper {
        font-family: Roboto !important;
        --51d82805: Roboto !important;
    }

    .date-picker__value-display {
        pointer-events: none;
    }

    /* .qalendar-is-small .calendar-month__weekday.is-selected {
        box-shadow: unset !important
    } */
</style>