<template>
    <div class="wp-confirm">
        <h5>Quản lý thông báo</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Quản lý thông báo</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết thông báo</span>
        </div>
        <div class="wp-detail">
            <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 15px;">
                <h5>Chi tiết thông báo</h5>
                <div class="wp-btn">
                    <button class="delete" @click="dialogVisible = true">Xóa thông báo</button>
                    <button class="edit" @click="$router.push('/he-thong/quan-ly-thong-bao/chinh-sua/' + $route.params.id)" v-if="dataDetail.status == 1">Chỉnh sửa</button>
                    <button class="send" @click="sendNoti" v-if="dataDetail.status == 1">Gửi thông báo</button>
                </div>
            </div>
            <p>Ngày tạo: <span>{{ formateDate(dataDetail.created_at) }}</span></p>
            <p>Ngày chỉnh sửa: <span>{{ formateDate(dataDetail.updated_at) }}</span></p>
            <p>Ngày gửi thông báo: 
                <span class="status status--send" v-if="dataDetail.push_at">Đã gửi <span style="color: #515151; font-weight: 400;">({{ formateDate(dataDetail.push_at) }})</span></span> 
                <span class="status" v-else>Chưa gửi</span>
            </p>
            <p class="lb">Tiêu đề</p>
            <p class="info">{{ dataDetail.title }}</p>
            <p class="lb">Mô tả chi tiết</p>
            <p class="info info--des ck-content" v-html="dataDetail.description"></p>
        </div>

        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="deleteNoti"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ thông báo"
            content_success="Bạn đã xóa thông báo thành công"
        />
    </div>
</template>

<script>
import Dialog from '../../components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            id: '',
            dataDetail: []
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async deleteNoti() {
           this.startLoading();
            await this.$axios.put('/notification/deleteNotification', {id: this.id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa thông báo thành công!");
                this.$router.push('/he-thong/quan-ly-thong-bao')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/notification/getNotificationSystemDetail',{
                params: {
                    id: this.id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
                document.title = `Chi tiết thông báo - ${this.dataDetail.title}`;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async sendNoti() {
            const data = {
                id: this.id,
                title: this.dataDetail.title,
                description: this.dataDetail.description,
                push_status: 1 
            }
            this.startLoading();
            await this.$axios.put('/notification/updateNotification', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Gửi thông báo thành công!");
                this.$router.push('/he-thong/quan-ly-thong-bao')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn button {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        width: 160px;
        height: 38px;
        border-radius: 15px;
        color: #FFF;
    }

    .wp-btn button.delete {
        border: 1px solid #F13A3A;
        color: #F13A3A;
    }

    .wp-btn button.edit {
        background: #F58E16;
        margin: 0 10px;
    }

    .wp-btn button.send {
        background: #F13A3A;
    }

    .wp-detail {
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin-top: 15px;
    }

    .wp-detail p {
        margin-bottom: 10px;
        color: #515151;
        font-size: 15px;
    }

    .wp-detail p:last-child {
        margin-bottom: 0;
    }

    .wp-detail p.lb {
        margin-bottom: 5px;
        font-weight: 500;
        line-height: 180%;
        font-size: 16px;
    }

    .wp-detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
        margin-bottom: 0;
    }

    .wp-detail p span {
        font-weight: 500;
        font-size: 15px;
    }
  
    .wp-detail p.info {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 8px 10px;
        font-weight: 500;
        font-size: 16px;
    }

    .wp-detail p.info--des {
        padding: 20px 12px;
        font-weight: unset;
    }

    span.status {
        color: #F13A3A;
        line-height: 150%;
    }

    span.status--send {
        color: #00B569;
        line-height: 150%;
    }
</style>