export default {
    methods: {
        startLoading() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },

        endLoading() {
            if (!this.isEmptyCustom(this.loading)) {
                this.loading.close();
            }
        },

        isEmptyCustom(value) {
            // Array
            if (Array.isArray(value)) {
                // eslint-disable-next-line eqeqeq
                return value.length == 0;
            }

            // Object
            if (
                value &&
                typeof value === "object" &&
                value.constructor === Object
            ) {
                // eslint-disable-next-line eqeqeq
                return Object.keys(value).length == 0;
            }

            // string
            if (typeof value === "string" || value instanceof String) {
                // eslint-disable-next-line eqeqeq
                return value.trim().length == 0;
            }

            // Null & undefined
            if (value === null || typeof value === "undefined") {
                return true;
            }

            // Number
            if (value === 0) {
                return true;
            }
            return false;
        },

        turnOffErrorMessage(formName) {
            this.$refs[formName].clearValidate()
            this.$refs[formName].resetFields()
        },

        showNotify(type, title, message) {
            this.$notify({
                title,
                message,
                type,
                duration: 5000,
            });
        },

        encode(qId) {
            return btoa(qId);
        },

        decode(qId) {
            return parseInt(atob(qId));
        },

        handleError() {
            event.target.src = require('@/assets/images/error_img.png');
        },

        handleCheckTokenAuth(
            tokenAuth = "authAdm",
            pathLogin = "/dang-nhap"
        ) {
            window.localStorage.removeItem(tokenAuth);
            this.showNotify("error", "Thất bại", "Phiên đăng nhập đã hết, vui lòng đăng nhập lại!");
            this.$router.push({ path: pathLogin }).catch(() => {});
            this.endLoading();
        },

        adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        },

        calculatedHeight(refName) {
            if(this.$refs[refName]) {
                return this.$refs[refName].scrollHeight + 'px'
            }
        },

        formateDate(time){
            return this.$moment.utc(time).format("DD/MM/YYYY")
        },

        checkYoutube(url) {
            const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
            if(url) {
                return youtubeRegex.test(url);
            } else {
                return true
            }
        },

        //get dataCity
        async getDataCity() {
            await this.$axios.get('/common/getListProvince')
            .then(res => {
                this.dataCity = res.data.province
            })
        },

        //CALL API DISTRICT
        async callApiDistrict(id) {
            const res = await this.$axios.get('/common/getDistrictByProvince?province_id=' + id)
            if (res.status == 200) {
                return res.data.district
            }
        },

        //CALL API WARD
        async callApiWard(id) {
            const res = await this.$axios.get('/common/getWardByDistrict?district_id=' + id)
            if (res.status == 200) {
                return res.data.ward
            }
        },

        //FormatPrice
        inputPrice() {
            let name = event.target.name
            const cleanedValue = this.ruleForm[name].replace(/[^\d]/g, '');
            this.number = parseInt(cleanedValue, 10);
            this.ruleForm[name] = this.number.toLocaleString('vi');
            if(this.ruleForm[name] == 'NaN') {
                this.ruleForm[name] = ''
            }
        },

        formatPrice(number) {
            if (number >= 1000000000) {
                const billion = number / 1000000000;
                return Math.round((billion + Number.EPSILON) * 1000) / 1000 + ' tỷ';
            } else if (number >= 1000000) {
                const million = number / 1000000;
                return Math.round((million + Number.EPSILON) * 1000) / 1000 + ' triệu';
            } else {
                return number.toString();
            }
        },

        async uploadFile(path, files) {
            this.startLoading();
            const res = await this.$axios.post('/common/uploadFile', {
                file: files,
                path: path,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            if(res.status == 200) {
                this.endLoading();
                return res.data.list_result[0]
            } else {
                this.endLoading();
                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                return true
            }
        },

        async getListUnit() {
            const res = await this.$axios.get('/real_estate/getListUnit')
            if(res.status == 200) {
                return res.data
            }
        },

        async getCateDetail(id) {
            const res = await this.$axios.get('/real_estate/getCategoryDetail?category_id=' + id)
            if(res.status == 200) {
                return res.data
            }
        },

        async getListCategory() {
            const res = await this.$axios.get('/real_estate/getListCategory?type&page=1&per_page=100&name=')
            if(res.status == 200) {
                return res.data
            }
        },
        
        //Get project category
        async getListProjCate() {
            const res = await this.$axios.get('/project/getListProjectCategory?name&page=1&per_page=100&')
            if(res.status == 200) {
                return res.data
            }
        },

        async getListAgency() {
            const res = await this.$axios.get('/agency/getListAgencyForadmin?page=1&per_page=100&name=')
            if(res.status == 200) {
                return res.data
            }
        },

        //API danh mục tin tức
        async getListNewsCategory() {
            const res = await this.$axios.get('/news/getListNewsCategory')
            if(res.status == 200) {
                return res.data
            }
        },
        
        //getListRank
        async getListRank() {
            const res = await this.$axios.get('/real_estate/getListRank')
            if(res.status == 200) {
                return res.data
            }
        },

        //getListParamSearch
        async getListParamSearch() {
            const res = await this.$axios.get('/real_estate/getParamsSearch')
            if(res.status == 200) {
                return res.data
            }
        },

        //getListProject
        async getListProject() {
            const res = await this.$axios.get('/project/getListProjectForAdmin',{
                params: {
                    name: '',
                    project_status: '',
                    province_id: '',
                    district_id: '',
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            })
            if(res.status == 200) {
                return res.data
            }
        },

        //ScrollTOP
        scrollTop() {
            window.scrollTo(0, 0)
        },
        handleScroll() {
            if (window.scrollY <= 200) {
                this.hidden = true
            } else {
                this.hidden = false
            }
        },
    },
};
