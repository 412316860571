<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice.png" alt="">
        <p class="ttl">Xác nhận không hoàn thành</p>
        <p>Bạn xác nhận <b>Không hoàn thành</b> đầu việc trên? <br>Nếu xác nhận sẽ không thẻ hoàn tác và bạn phải chịu hoàn toàn trách nhiệm nếu xảy ra vấn đề.</p>
        <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do:</p>
        <el-form action="" id="formNews" class="form" :rules="rules" :model="ruleForm" ref="ruleForm">
            <el-form-item prop="reason">
                <el-input type="textarea" v-model="ruleForm.reason" placeholder="Nhập lý do"></el-input>
            </el-form-item>
        </el-form>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('ruleForm')">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['confirmFailDialog'],
    data() {
        return {
            dialogVisibleStatus: false,
            ruleForm: {
                reason: ''
            },
            rules: {
                reason: [{required: true, message: "Vui lòng nhập lý do", trigger: ["blur", "change"]}],
            }
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.confirmFailDialog
    },
    watch: {
        confirmFailDialog() {
            this.dialogVisibleStatus = this.confirmFailDialog;
        },
    },
    emits: ['handleCloseDialog', 'confirm'],
    methods: {
        emitDialogVisible(confirmFailDialog) {
            this.$emit("handleCloseDialog", confirmFailDialog);
            this.$refs.ruleForm.clearValidate()
            this.$refs.ruleForm.resetFields()
        },
        confirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit("confirm", this.ruleForm.reason);
                } else {
                    return true
                }
            });
        }
    },
}
</script>
<style lang="css" scoped>

    p.ttl {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        color: #444;
    }

    p.success {
        font-size: 18px;
        color: #00B569;
        font-weight: 500;
        margin: 8px 0 20px;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #EE3439;
    }

    button.btn_add {
        color: #FFF;
        background: #EE3439;
        border: none;
        margin-left: 13px;
    }
</style>