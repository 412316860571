<template>
    <div class="add_bds">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Tạo bất động sản mới</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Kho bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Tạo bất động sản mới</span>
                </div>
            </div>
            <div class="wp-btn">
                <button class="btn_add" @click="submitForm('ruleForm')" v-if="$route.path.includes('kho-bds/tao-moi')">Tạo bất động sản</button>
                <button class="btn_add" @click="submitForm('ruleForm')" v-else>Lưu chỉnh sửa</button>
            </div>
        </div>
        <el-row>
            <el-col :span="24">
                <el-form :rules="rules" :model="ruleForm" ref="ruleForm">
                    <div class="content">
                        <div class="content-tab form">
                            <el-tabs v-model="activeName" class="storage_add">
                                <el-tab-pane label="Bất động sản" name="first">
                                    <!-- TAB 1 -->
                                        <div class="content-form">
                                            <div class="mb-3 d-flex flex-wrap">
                                                <button class="news-type me-3" :class="{ 'active': ruleForm.type == 1 }" @click="getListCate(1)" type="button">Bất
                                                    động sản bán</button>
                                                <button class="news-type" :class="{ 'active': ruleForm.type == 2 }" @click="getListCate(2)" type="button">Bất động
                                                    sản cho thuê</button>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <el-form-item prop="category_id" label="Chọn loại bất động sản">
                                                    <el-select clearable v-model="ruleForm.category_id" filterable placeholder="Chọn loại danh mục" class="form-control">
                                                        <el-option v-for="item in listCate" :key="item.id" :label="item.category_name" :value="item.id" @click="changeCate(item.id, 'change'), cate_name = item.category_name"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <el-form-item prop="province" label="Tỉnh / thành phố">
                                                    <el-select v-model="ruleForm.province" filterable placeholder="Tỉnh/Thành phố" class="form-control"
                                                    @change="changeCity('change')" id="city">
                                                        <el-option v-for="item in dataCity"
                                                            :key="item.code"
                                                            :label="item.name"
                                                            :value="JSON.stringify(item)">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <el-form-item prop="district" label="Quận / huyện">
                                                    <el-select v-model="ruleForm.district" filterable placeholder="Quận/Huyện" class="form-control"
                                                    @change="changeDistrict('change')" id="district">
                                                        <el-option v-for="item in dataDistricts"
                                                            :key="item.code"
                                                            :label="item.name"
                                                            :value="JSON.stringify(item)">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <el-form-item prop="ward" label="Phường / xã / thị trấn">
                                                    <el-select v-model="ruleForm.ward" filterable placeholder="Xã /Phường" class="form-control" id="ward" @change="chooseWard">
                                                        <el-option v-for="item in dataWard"
                                                            :key="item.code"
                                                            :label="item.name"
                                                            :value="JSON.stringify(item)">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <el-form-item prop="address" label="Địa chỉ">
                                                    <el-input id="name" type="text" v-model="ruleForm.address" placeholder="Nhập địa chỉ" @blur="getMaps" class="form-control"/>
                                                </el-form-item>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <label for="project" class="form-label">Chọn dự án </label>
                                                <el-select clearable v-model="ruleForm.project_id" filterable
                                                    placeholder="Chọn dự án nếu bất động sản của bạn nằm trong dự án" class="form-control" @change="changeProj(ruleForm.project_id)" id="project">
                                                    <el-option v-for="item in list_project"
                                                        :key="item.project_id"
                                                        :label="item.name"
                                                        :value="item.project_id">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="mb-3 col-12">
                                                <p class="form-label" style="font-weight: 500; color: #515151">Vị trí trên bản đồ</p>
                                                <GMAPS :lng="ruleForm.longtitude" :lat="ruleForm.latitude"/>
                                            </div>
                                        </div>
                                        <div class="content-form" :class="{'content-form--radius' : cateDetail.length == 0}">
                                            <div class="mb-3 col-12">
                                                <div class="title-2">Hình ảnh & video bất động sản</div>
                                                <ul class="verifi-img">
                                                    <li>Đăng tối thiểu 4 hình ảnh , tối đa 20 hình ảnh </li>
                                                    <li>Hãy dùng ảnh thật , không trùng ,
                                                        không chèn SĐT hoặc địa chỉ chi tiết vào hình ảnh</li>
                                                    <li>Hình ảnh tối thiểu 200 x 200 px , tối đa 15MB</li>
                                                    <li>Hình ảnh đuôi png , jpg</li>
                                                    <li>Sắp xếp vị trí hình ảnh hơp lý , vị trí đầu tiên sẽ là ảnh đại diện</li>
                                                </ul>

                                                <div class="mb-3">
                                                    <el-upload
                                                        drag
                                                        :accept="'image/png, image/jpeg, image/jpg'"
                                                        multiple
                                                        :show-file-list="false"
                                                        :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'image')"
                                                        :action="$API + '/common/upload360image'"
                                                        :data="{path : 'real_estate/image/' }"
                                                        list-type="picture"
                                                        :on-progress="handleUploadProgress"
                                                    >
                                                        <img src="@/assets/icons/Collectibles.png" alt="">
                                                        <div class="el-upload__text">Bấm để chọn ảnh cần tải lên</div>
                                                        <div class="el-upload__tip" slot="tip">hoặc kéo thả ảnh vào đây</div>
                                                    </el-upload>
                                                    <span v-if="ruleForm.image.length==0 && showErr" class="error">Vui lòng thêm hình ảnh</span>
                                                </div>
                                                
                                                <div class="col-12 d-flex flex-wrap" >
                                                    <draggable class="dragArea w-full d-flex flex-wrap" :list="ruleForm.image">
                                                        <div class="card-image" v-for="(item, index) in ruleForm.image">
                                                            <img class="main" :src="this.$storage + item.path_tam" alt="" @error="handleError" v-if="item.path_tam">
                                                            <img class="main" :src="this.$storage + item.path" alt="" @error="handleError" v-else>
                                                            <span class="img_ava" v-if="index == 0">Ảnh đại diện</span>
                                                            <div class="foot">
                                                                <div class="form-check form-switch">
                                                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.is_360" :disabled="item.check360 == 0">
                                                                    <span>360</span>
                                                                </div>
                                                                <button type="button" @click="ruleForm.image.splice(index, 1)">
                                                                    <img src="@/assets/icons/Trash_Can.png" alt="" >
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </draggable>
                                                </div>
                                                <span v-if="ruleForm.image.length !== 0 && ruleForm.image.length < 4 && showErr" class="error">Vui lòng đăng tối thiểu 4 hình ảnh</span>
                                                <span v-else-if="ruleForm.image.length !== 0 && ruleForm.image.length > 20 && showErr" class="error">Vui lòng đăng tối đa 20 hình ảnh</span>
                                                <p>
                                                    <a class="youtube" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                        <div>
                                                            <img src="@/assets/icons/Video.png" alt="">
                                                            <span>Thêm video từ Youtube</span>
                                                        </div>
                                                        <img src="@/assets/icons/Forward.png" alt="">
                                                    </a>
                                                </p>
                                                <div class="collapse link-youtube" id="collapseExample">
                                                    <input v-model="ruleForm.video" @input="this.checkYoutube(ruleForm.video)">
                                                    <span>Link:</span>
                                                </div>
                                                <span v-if="ruleForm.video && !this.checkYoutube(ruleForm.video)" class="error">Vui lòng nhập đúng link video Youtube</span>
                                            </div>
                                            <div class="col-12">
                                                <div class="title-2">Thông tin bất động sản</div>
                                                <el-form-item prop="name" label="Tiêu đề bất động sản">
                                                    <textarea name="title" :style="{ height: this.calculatedHeight('real_name') }" ref="real_name" v-model="ruleForm.name" class="form-control" placeholder="Nhập tiêu đề" id="ttl" @input="this.adjustTextareaHeight"></textarea>
                                                    <el-input type="textarea" v-model="ruleForm.name" class="d-none"></el-input>
                                                    <div class="small">Tối thiểu 30 ký tự, tối đa 99 ký tự</div>
                                                </el-form-item>
                                                <el-form-item prop="description" label="Mô tả bất động sản">
                                                    <textarea name="des" :style="{ height: this.calculatedHeight('description') }" ref="description" class="form-control" v-model="ruleForm.description" placeholder="Nhập mô tả về bất động sản của bạn" id="des" @input="this.adjustTextareaHeight"></textarea>
                                                    <el-input type="textarea" v-model="ruleForm.description" class="d-none"></el-input>
                                                    <div class="small">Tối thiểu 30 ký tự</div>
                                                </el-form-item>
                                            </div>
                                            <div class="col-12">
                                                <el-form-item prop="design" label="Mặt bằng và thiết kế">
                                                    <textarea name="degisn" v-model="ruleForm.design" class="form-control" placeholder="Nhập mô tả về Mặt bằng và thiết kế" id="degisn" @input="this.adjustTextareaHeight"></textarea>
                                                    <el-input type="textarea" v-model="ruleForm.design" class="d-none"></el-input>
                                                </el-form-item>

                                                <label for="image" class="form-label title-3">Thêm hình ảnh mặt bằng và thiết kế</label>
                                                <div class="choose-file">
                                                    <el-upload
                                                        ref="upLoad"
                                                        v-model:file-list="old_file_design"
                                                        :action="$API + '/common/uploadFile'"
                                                        list-type="picture-card"
                                                        multiple
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        :data="{path : 'real_estate/design_file/' }"
                                                        :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'design_file')"
                                                        :on-remove="handleRemove"
                                                        :on-progress="handleUploadProgress"
                                                    >
                                                    </el-upload>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-form content-form--radius" v-if="cateDetail.length !== 0">
                                            <div class="title-2">Thông tin chi tiết bất động sản</div>
                                            <div class="col-12 position-relative">
                                                <el-form-item prop="acreage" label="Diện tích">
                                                    <el-input type="text" class="form-control" v-model="ruleForm.acreage" name="acreage" @input="ruleForm.acreage = ruleForm.acreage.replace(/[^0-9.]/g, '')" id="acreage" placeholder="Nhập diện tích"/>
                                                    <span class="unit">m2</span>
                                                </el-form-item>
                                            </div>
                                            <div class="col-8 px-1">
                                                <el-form-item prop="price" label="Mức giá" v-if="ruleForm.unit_id != 5">
                                                    <el-input type="text" class="form-control" maxlength="15" name="price" id="price" @input="inputPrice" v-model="ruleForm.price" placeholder="Nhập mức giá"/>
                                                    <p v-if="ruleForm.price && ruleForm.unit_id != 5" class="text_price">{{ numberInWords }} đồng</p>
                                                </el-form-item>
                                                <div v-else>
                                                    <label class="el-form-item__label">Mức giá</label>
                                                    <span>Thỏa thuận</span>
                                                </div>
                                            </div>
                                            <div class="col-4 px-1">
                                                <el-form-item prop="unit_id" label="Đơn vị">
                                                    <el-select clearable class="form-control" id="unit" v-model="ruleForm.unit_id" placeholder="Chọn đơn vị">
                                                        <el-option v-for="item in listUnit"
                                                            :key="item.id"
                                                            :label="item.name"
                                                            :value="parseInt(item.id)">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="col-12 position-relative mb-2">
                                                <p class="form-label title-4" style="min-width: 40px; display: inline-block;">Giấy tờ pháp lý</p>
                                                <span><img src="@/assets/icons/Starburst_Shape.png" alt="" style="margin: 0 0 10px 7px;"></span>
                                                <div class="w-100 d-flex flex-wrap">
                                                    <button type="button" v-for="item in cateDetail.juridical" class="btn-option-1" @click="uploadReview(item.juridical_id)" :class="{'active' : keyActivePaper == item.juridical_id}">
                                                        {{ item.juridical_name }}
                                                    </button>
                                                </div>
                                                <span v-if="!ruleForm.juridical_file.juridical_id && showErr" class="error">Vui lòng chọn giấy tờ pháp lý</span>
                                            </div>
                                            <div class="col-12 position-relative mb-2">
                                                <p class="form-label title-3" style="min-width: 40px;">
                                                    Tải lên tài liệu pháp lý để quản trị viên kiểm duyệt
                                                </p>
                                                <div class="choose-file">
                                                    <div class="choose-file">
                                                        <el-upload
                                                            v-model:file-list="old_file_juridical"
                                                            ref="upLoad"
                                                            :action="$API + '/common/uploadFile'"
                                                            list-type="picture-card"
                                                            multiple
                                                            accept="image/png, image/jpeg, image/jpg"
                                                            :data="{path : 'real_estate/juridical_file/' }"
                                                            :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'juridical_file')"
                                                            :on-remove="removeJuridical"
                                                            :on-progress="handleUploadProgress"
                                                        >
                                                        </el-upload>
                                                    </div>
                                                </div>
                                                <span v-if="ruleForm.juridical_file.path.length == 0 && showErr" class="error">Vui lòng tải lên file giấy tờ</span>
                                            </div>
                                            <div class="col-12 position-relative mb-2">
                                                <p class="form-label" style="font-size: 18px; font-weight: 600;">Mô tả bổ sung</p>
                                                <div class="p-md-2 pr-0">
                                                    <div v-for="(item, index) in cateDetail.describe" :key="index" v-if="listDescribe.length !== 0">
                                                        <div class="d-flex flex-wrap justify-content-between mb-2" v-if="item.info_type == 1">
                                                            <div class="tt">
                                                                <div class="tt_img">
                                                                    <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                                </div>
                                                                <span>{{ item.describe_name }}</span>
                                                            </div>
                                                            <el-input-number :min="0" :max="10000" v-model="listDescribe[index].value"></el-input-number>
                                                        </div>
                                                        <div class="d-flex flex-wrap justify-content-between mb-2" v-else-if="item.info_type == 2">
                                                            <div class="tt">
                                                                <div class="tt_img">
                                                                    <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                                </div>
                                                                <span>{{ item.describe_name }}</span>
                                                            </div>
                                                            <el-input type="text" class="form-control" maxlength="250" style="width: 67%;" v-model="listDescribe[index].value" placeholder="Nhập"/>
                                                        </div>
                                                        <div class="d-md-flex justify-content-between mb-2" v-else-if="item.info_type == 3">
                                                            <div class="tt" style="align-items: flex-start;">
                                                                <div class="tt_img">
                                                                    <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                                </div>
                                                                <span>{{ item.describe_name }}</span>
                                                            </div>
                                                            <div class="d-flex flex-wrap" style="width: 67%;">
                                                                <button type="button" v-for="data in item.value" class="btn-option-1" style="width: 150px;"
                                                                    @click="listDescribe[index].value = data.properties_id"
                                                                    :class="{'active' : listDescribe[index].value == data.properties_id}"
                                                                >
                                                                    {{ data.properties_name }}
                                                                </button>
                                                                <textarea name="title" class="form-control" placeholder="Nhập mô tả"
                                                                    @input="this.adjustTextareaHeight"
                                                                    v-model="listDescribe[index].description"
                                                                    maxlength="250"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="d-md-flex justify-content-between" v-else>
                                                            <div class="tt">
                                                                <div class="tt_img">
                                                                    <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                                </div>
                                                                <span>{{ item.describe_name }}</span>
                                                            </div>
                                                            <el-form-item prop="direction" style="width: 67%;">
                                                                <el-select filterable clearable class="form-control" v-model="listDescribe[index].value" placeholder="Chọn">
                                                                    <el-option v-for="data in item.value"
                                                                        :key="data.properties_id"
                                                                        :label="data.properties_name"
                                                                        :value="data.properties_id">
                                                                    </el-option>
                                                                </el-select>
                                                            </el-form-item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content-form content-form--space">
                                            <div class="col-12">
                                                <el-form-item prop="note" label="Ghi chú về bất động sản" style="margin-bottom: 0;">
                                                    <span style="color: #686868;">Ghi chú</span>
                                                    <textarea name="cus_note" v-model="ruleForm.note" class="form-control" placeholder="Ghi chú về bất động sản" id="cus_note" @input="this.adjustTextareaHeight"></textarea>
                                                    <el-input type="textarea" v-model="ruleForm.note" class="d-none"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                </el-tab-pane>
                                <el-tab-pane label="Quản lý và liên hệ" name="second">
                                    <!-- TAB 2 -->
                                    <div class="content-form">
                                        <h5 style="font-size: 20px; margin-bottom: 20px;">Quản lý bất động sản</h5>
                                        <div class="col-12">
                                            <el-form-item prop="system" label="Nguồn bất động sản">
                                                <el-select v-model="ruleForm.system" filterable placeholder="Nguồn bất động sản" class="form-control">
                                                    <el-option
                                                        label="Hệ thống"
                                                        :value="1">
                                                    </el-option>
                                                    <el-option
                                                        label="Cá nhân"
                                                        :value="2">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="col-12">
                                            <el-form-item prop="agency_id" label="Chi nhánh quản lý">
                                                <el-select v-model="ruleForm.agency_id" filterable placeholder="Chi nhánh quản lý" class="form-control" @change="getListUser('change')">
                                                    <el-option v-for="(item, index) in list_agency.result" :key="index"
                                                        :label="item.agency_name"
                                                        :value="item.id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="col-12">
                                            <el-form-item prop="broker" label="Sale ký hợp đồng môi giới">
                                                <el-select v-model="ruleForm.broker" filterable placeholder="Sale ký hợp đồng môi giới" class="form-control">
                                                    <el-option v-for="(item, index) in list_user" :key="index"
                                                        :label="item.full_name + ' - ' + item.user_code"
                                                        :value="item.user_id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <div class="col-12">
                                            <el-form-item prop="sale" label="Sale quản lý">
                                                <el-select v-model="ruleForm.sale" filterable placeholder="Sale quản lý" class="form-control">
                                                    <el-option v-for="(item, index) in list_user" :key="index"
                                                        :label="item.full_name + ' - ' + item.user_code"
                                                        :value="item.user_id">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <label class="label_file label_file--req">File hợp đồng môi giới</label>
                                        <div class="mb-3 choose-file">
                                            <el-upload
                                                ref="upLoad"
                                                :action="$API + '/common/uploadFile'"
                                                list-type="picture-card"
                                                multiple
                                                accept="image/png, image/jpeg, image/jpg"
                                                :data="{path : 'real_estate/brokerage_contract/' }"
                                                :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'contract_file')"
                                                :on-remove="handleRemove"
                                                :on-progress="handleUploadProgress"
                                            >
                                            </el-upload>
                                        </div>
                                        <div class="col-12">
                                            <el-form-item prop="" label="Hoa hồng / sản phẩm">
                                                <el-input type="text" v-model="ruleForm.commission" placeholder="Hoa hồng / sản phẩm" class="form-control"/>
                                                <span style="position: absolute; right: 30px; font-size: 16px; font-weight: 500;">%</span>
                                            </el-form-item>
                                        </div>
                                        <div class="col-12">
                                            <el-form-item prop="" label="Hoa hồng cho sale / doanh thu">
                                                <el-input type="text" v-model="ruleForm.sale_commission" placeholder="Hoa hồng cho sale / doanh thu" class="form-control"/>
                                                <span style="position: absolute; right: 30px; font-size: 16px; font-weight: 500;">%</span>
                                            </el-form-item>
                                        </div>
                                        <h5 style="font-size: 20px; margin: 20px 0;">Thông tin chủ bất động sản</h5>
                                        <div class="col-12 form-owner">
                                            <el-tabs @tab-click="handleClick">
                                                <el-tab-pane label="Nhập thông tin">
                                                    <label class="label_file">Ảnh chủ bất động sản</label>
                                                    <button class="add_ava" type="button" @click="$refs.avatar_owner.click()" v-if="!avaOwner">+</button>
                                                    <img :src="$storage + avaOwner" alt="" v-else @click="$refs.avatar_owner.click()" style="width: 80px; height: 80px; border-radius: 20px; cursor: pointer;">
                                                    <input type="file" ref="avatar_owner" class="d-none" @change="uploadAvaOwner('avatarOwner')">
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_name" label="Tên chủ bất động sản">
                                                            <el-input type="text" v-model="ruleForm.owner_name" placeholder="Tên chủ bất động sản" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_phone" label="Số điện thoại">
                                                            <el-input type="text" v-model="ruleForm.owner_phone" maxlength="10" placeholder="Số điện thoại" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_email" label="Email">
                                                            <el-input type="text" v-model="ruleForm.owner_email" placeholder="Email" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_address" label="Địa chỉ khách hàng">
                                                            <el-input type="text" v-model="ruleForm.owner_address" placeholder="Địa chỉ khách hàng" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_note" label="Ghi chú về khách hàng">
                                                            <textarea name="note" v-model="ruleForm.owner_note" class="form-control" placeholder="Ghi chú về khách hàng" id="note" @input="this.adjustTextareaHeight"></textarea>
                                                            <el-input type="textarea" v-model="ruleForm.owner_note" class="d-none"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_id_number" label="Số CMT/CCCD">
                                                            <el-input type="text" v-model="ruleForm.owner_id_number" placeholder="Nhập số CMT / CCCD" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_id_date" label="Ngày cấp">
                                                            <el-date-picker
                                                                v-model="ruleForm.owner_id_date"
                                                                type="date"
                                                                placeholder="Chọn ngày cấp"
                                                                popper-class="datePicker"
                                                                :disabled-date="disabledDate"
                                                                value-format="YYYY-MM-DD"
                                                            />
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <el-form-item prop="owner_id_place" label="Nơi cấp">
                                                            <el-input type="text" v-model="ruleForm.owner_id_place" placeholder="Nhập nơi cấp" class="form-control"/>
                                                        </el-form-item>
                                                    </div>
                                                    <div class="col-12">
                                                        <label class="label_file">Ảnh CMT/CCCD</label>
                                                        <div class="d-flex align-items-center mt-3">
                                                            <div class="front" @click="$refs.font_id.click()">
                                                                <span>Mặt trước</span>
                                                                <img src="@/assets/images/front_id.png" alt="" v-if="!frontImg">
                                                                <img :src="$storage + frontImg" alt="" v-else style="width: 258px; height: 200px; object-fit: cover; border-radius: 15px; cursor: pointer;">
                                                                <input type="file" ref="font_id" class="d-none" @change="uploadAvaOwner('front_id')">
                                                            </div>
                                                            <div class="back" @click="$refs.back_id.click()">
                                                                <span>Mặt sau</span>
                                                                <img src="@/assets/images/back_id.png" alt="" v-if="!backImg">
                                                                <img :src="$storage + backImg" alt="" v-else style="width: 258px; height: 200px; object-fit: cover; border-radius: 15px; cursor: pointer;">
                                                                <input type="file" ref="back_id" class="d-none" @change="uploadAvaOwner('back_id')">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-tab-pane>
                                                <el-tab-pane label="Chọn khách hàng">
                                                    <div @click="dialogVisible = true">
                                                        <label class="label_file">Chọn chủ bất động sản</label>
                                                        <button class="add_ava" type="button">+</button>
                                                    </div>
                                                    <div>
                                                        <label class="label_file" v-if="ruleForm.owner_avatar">Ảnh chủ bất động sản</label>
                                                        <img :src="$storage + ruleForm.owner_avatar" alt="" style="width: 80px;">
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_name" label="Tên chủ bất động sản">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_name" placeholder="Tên chủ bất động sản" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_phone" label="Số điện thoại">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_phone" placeholder="Số điện thoại" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_email" label="Email">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_email" placeholder="Email" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_address" label="Địa chỉ khách hàng">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_address" placeholder="Địa chỉ khách hàng" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_id_number" label="Số CMT/CCCD">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_id_number" placeholder="Số CMT / CCCD" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_id_date" label="Ngày cấp">
                                                                <el-date-picker
                                                                    v-model="ruleForm.owner_id_date"
                                                                    type="date"
                                                                    placeholder="Ngày cấp"
                                                                    popper-class="datePicker"
                                                                    :disabled-date="disabledDate"
                                                                    value-format="YYYY-MM-DD"
                                                                    disabled
                                                                />
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <el-form-item prop="owner_id_place" label="Nơi cấp">
                                                                <el-input type="text" disabled v-model="ruleForm.owner_id_place" placeholder="Nơi cấp" class="form-control"/>
                                                            </el-form-item>
                                                        </div>
                                                        <div class="col-12">
                                                            <label class="label_file">Ảnh CMT/CCCD</label>
                                                            <div class="d-flex align-items-center mt-3">
                                                                <div class="front">
                                                                    <span>Mặt trước</span>
                                                                    <img src="@/assets/images/front_id.png" alt="" v-if="!frontImg">
                                                                    <img :src="$storage + frontImg" alt="" @error="handleError" v-else style="width: 258px; height: 200px; object-fit: cover; border-radius: 15px; cursor: pointer;">
                                                                </div>
                                                                <div class="back">
                                                                    <span>Mặt sau</span>
                                                                    <img src="@/assets/images/back_id.png" alt="" v-if="!backImg">
                                                                    <img :src="$storage + backImg" alt="" @error="handleError" v-else style="width: 258px; height: 200px; object-fit: cover; border-radius: 15px; cursor: pointer;">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mt-3">
                                                        <el-form-item prop="owner_note" label="Ghi chú về khách hàng">
                                                            <textarea name="note" v-model="ruleForm.owner_note" class="form-control" placeholder="Ghi chú về khách hàng" id="note" @input="this.adjustTextareaHeight"></textarea>
                                                            <el-input type="textarea" v-model="ruleForm.owner_note" class="d-none"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </el-tab-pane>
                                            </el-tabs>
                                        </div>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </el-form>
            </el-col>

            <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">
        </el-row>
    </div>

    <ListOwner
        :status="dialogVisible"
        @handleCloseDialog="listenDialog"
        @dataCustomer="getDataProps"
    />
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import GMAPS from "../../components/goong_maps.vue"
import axios from 'axios';
import ListOwner from './modal/listOwner.vue'
import number2vn from 'number2vn';
export default defineComponent ({
    components: { draggable: VueDraggableNext, GMAPS, ListOwner },
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate,
        }
    },
    data() {
        return {
            ruleForm: {
                category_id: '',
                type: '',
                price: "",
                district: "",
                district_id: "",
                district_name: "",
                ward: "",
                ward_id: "",
                ward_name: "",
                province: "",
                province_id: "",
                province_name: "",
                address: '',
                project_id: '',
                name: '',
                description: '',
                design: '',
                acreage: '',
                unit_id: '',
                longtitude: '',
                latitude: '',
                video:'',
                publish_status: 1,
                image: [],
                design_file: [],
                juridical_file: {
                    'juridical_id': '',
                    'path': []
                },
                describe: [],
                rank: 1,
                fee: 1,
                system: '',
                note: '',
                owner_address: '',
                owner_avatar: '',
                owner_email: '',
                owner_name: '',
                owner_phone: '',
                owner_note: '',
                agency_id: '',
                broker: '',
                sale: '',
                commission: '',
                sale_commission: '',
                brokerage_contract: [],
                listDescribe: [],
                owner_id_number: '',
                owner_id_date: '',
                owner_id_place: '',
                owner_id_front: '',
                owner_id_back: '',
                owner_id: ''
            },
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            rules: {
                category_id: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
                province: [{required: true, message: "Vui lòng chọn tỉnh/thành phố", trigger: ["blur", "change"]}],
                district: [{required: true, message: "Vui lòng chọn quận/huyện", trigger: ["blur", "change"]}],
                ward: [{required: true, message: "Vui lòng chọn phường/xã", trigger: ["blur", "change"]}],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                name: [
                    {required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]},
                    {min: 30, message: "Tiêu đề tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                    {max: 99, message: "Tiêu đề tối đa 99 ký tự", trigger: ["blur", "change"]},
                ],
                description: [
                    {required: true, message: "Vui lòng nhập mô tả", trigger: ["blur", "change"]},
                    {min: 30, message: "Mô tả tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                ],
                acreage: [{required: true, message: "Vui lòng nhập diện tích", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập giá", trigger: ["blur", "change"]}],
                unit_id: [{required: true, message: "Vui lòng chọn đơn vị", trigger: ["blur", "change"]}],
                system: [{required: true, message: "Vui lòng chọn nguồn bất động sản", trigger: ["blur", "change"]}],
                agency_id: [{required: true, message: "Vui lòng chọn chi nhánh", trigger: ["blur", "change"]}],
                broker: [{required: true, message: "Vui lòng chọn sale ký hợp đồng môi giới", trigger: ["blur", "change"]}],
                sale: [{required: true, message: "Vui lòng chọn sale quản lý", trigger: ["blur", "change"]}],
                owner_name: [{required: true, message: "Vui lòng nhập tên chủ bất động sản", trigger: ["blur", "change"]}],
            },
            keyActivePaper: null,
            listCate: [],
            showErr: false,
            listUnit: [],
            cateDetail: [],
            cate_name: '',
            hidden: '',
            list_rank: [],
            list_package: [],
            list_project: [],
            listDescribe: [],
            list_user: [],
            activeName: 'first',
            list_agency: [],
            avaOwner: '',
            frontImg: '',
            backImg: '',
            dialogVisible: false,
            id: '',
            old_file_design: [],
            old_file_juridical: [],
            owner_id: ''
        }
    },
    computed: {
        numberInWords() {
            return number2vn(parseInt(this.ruleForm.price.replace(/[^\d]/g, '')));
        },
    },
    mounted(){
        this.getDataCity()
        this.getUnit()
        this.getAgency()
        this.handleScroll()
        this.getListProject()
        if(this.$route.path.includes('/kho-bds/chinh-sua') && this.$route.params.id) {
            this.id = this.decode(this.$route.params.id)
            this.getDetail()
        }
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('/kho-bds/chinh-sua') && url.params.id) {
                this.id = this.decode(url.params.id)
                this.getDetail();
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        uploadFileImage(res, file, fileList, type) {
            this.endLoading()
            let fileItem = {
                path : res.list_result[0].path,
                path_tam : res.list_result[0].path_tam,
                is_360 : false,
                check360: res.list_result[0].is_360
            }
            if(type == 'image') {
                this.ruleForm.image.push(fileItem)
            } else if(type == 'design_file') {
                this.ruleForm.design_file.push(res.list_result[0].path)
            } else if(type == 'juridical_file') {
                this.ruleForm.juridical_file.path.push(res.list_result[0].path)
            } else {
                this.ruleForm.brokerage_contract.push(res.list_result[0].path)
            }
        },
        async uploadReview(id){
            this.keyActivePaper = id
            this.ruleForm.juridical_file.juridical_id = id
        },
        async changeCity(type) {
            this.dataDistricts = await this.callApiDistrict(JSON.parse(this.ruleForm.province).code)
            if(type == 'change') {
                this.ruleForm.district = this.ruleForm.ward = ''
                this.dataWard = []
            }
        },
        async changeDistrict(type) {
            this.dataWard = await this.callApiWard(JSON.parse(this.ruleForm.district).code)
            if(type == 'change') {
                this.ruleForm.ward = ''
            }
        },
        async getUnit() {
            this.listUnit = await this.getListUnit()
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.ruleForm.image.length == 0 || this.ruleForm.image.length < 4 || this.ruleForm.image.length > 20 || !this.ruleForm.juridical_file.juridical_id || this.ruleForm.juridical_file.path.length == 0 || !this.checkYoutube(this.ruleForm.video)) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.ruleForm.province_name = JSON.parse(this.ruleForm.province).name
                    this.ruleForm.district_name = JSON.parse(this.ruleForm.district).name
                    this.ruleForm.ward_name = JSON.parse(this.ruleForm.ward).name
                    this.ruleForm.province_id = JSON.parse(this.ruleForm.province).code
                    this.ruleForm.district_id = JSON.parse(this.ruleForm.district).code
                    this.ruleForm.ward_id = JSON.parse(this.ruleForm.ward).code
                    if(this.ruleForm.unit_id == 5) {
                        this.ruleForm.price = ''
                    } else {
                        this.ruleForm.price = this.ruleForm.price.replace(/[^\d]/g, '')
                    }
                    this.ruleForm.describe = this.listDescribe.filter(item => !this.isEmptyCustom(item.value))
                    if(this.$route.path.includes('/kho-bds/tao-moi')) {
                        var lnk = 'createRealEstate'
                        var method = 'post'
                        var mss = 'Tạo tin bất động sản thành công!'
                        var back = '/quan-ly-bds/kho-bds'
                    } else {
                        var lnk = 'updateRealEstate'
                        var method = 'put'
                        var mss = 'Chỉnh sửa tin bất động sản thành công!'
                        var back = '/quan-ly-bds/kho-bds/chi-tiet/' + this.$route.params.id
                        this.ruleForm.id = this.decode(this.$route.params.id)
                    }
                    this.startLoading();
                    await this.$axios[method]('/real_estate/' + lnk, this.ruleForm, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", mss);
                        this.$router.push(back)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        handleUploadProgress(event, file, fileList) {
            this.startLoading()
        },
        handleRemove(file) {
            this.ruleForm.design_file = this.ruleForm.design_file.filter( item => item !== file.response.list_result[0].path)
        },
        removeJuridical(file) {
            this.ruleForm.juridical_file.path = this.ruleForm.juridical_file.path.filter( item => item !== file.response.list_result[0].path)
        },
        async getListCate(params) {
            this.ruleForm.type = params
            this.startLoading();
            await this.$axios.get('/real_estate/getListCategory',{
                params: {
                    type: params,
                    name: '',
                    page: 1,
                    per_page: 100
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listCate = res.data.result;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async changeCate(id, type) {
            this.startLoading()
            this.cateDetail = await this.getCateDetail(id)
            this.endLoading()
            if(type == 'change') {
                this.listDescribe = []
                this.cateDetail.describe.forEach(e => {
                    if (e.info_type != 1) {
                        this.listDescribe.push({
                            describe_id: e.describe_id,
                            value: '',
                            description: ''
                        })
                    }else{
                        this.listDescribe.push({
                            describe_id: e.describe_id,
                            value: 0,
                            description: ''
                        })
                    }
                });
            } else {
                this.listDescribe = this.cateDetail.describe.map(item1 => {
                    const matchItem = this.listDescribe.find(item2 => item2.describe_id == item1.describe_id);
                    if (matchItem && matchItem.info_type != 1 || item1.info_type != 1) {
                        return {
                            describe_id: item1.describe_id,
                            describe_name: item1.describe_name,
                            icon: item1.icon,
                            info_type: item1.info_type,
                            description: matchItem ? matchItem.description : '',
                            value: matchItem ? matchItem.value : '',
                            value_id: matchItem ? matchItem.value_id : ''
                        };
                    } else {
                        return {
                            describe_id: item1.describe_id,
                            describe_name: item1.describe_name,
                            icon: item1.icon,
                            info_type: item1.info_type,
                            description: matchItem ? matchItem.description : '',
                            value: matchItem ? matchItem.value : 0,
                            value_id: matchItem ? matchItem.value_id : ''
                        };
                    }
                });
            }
        },
        async getListProject() {
            this.startLoading();
            await this.$axios.get('/project/getListProjectForMember',{
                params: {
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    name: '',
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_project = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async uploadAvaOwner(type) {
            if(event.target.files[0]) {
                if(type == 'avatarOwner') {
                    let url_image = await this.uploadFile('member/avatar/', event.target.files[0])
                    this.avaOwner = url_image.path_tam
                    this.ruleForm.owner_avatar = url_image.path
                } else if(type == 'front_id') {
                    let url_image = await this.uploadFile('member/avatar/', event.target.files[0])
                    this.frontImg = url_image.path_tam
                    this.ruleForm.owner_id_front = url_image.path
                } else {
                    let url_image = await this.uploadFile('member/avatar/', event.target.files[0])
                    this.backImg = url_image.path_tam
                    this.ruleForm.owner_id_back = url_image.path
                }
            }
        },
        async getListUser(type) {
            if(type == 'change') {
                this.list_user = []
                this.ruleForm.broker = ''
                this.ruleForm.sale = ''
            }
            this.startLoading();
            await this.$axios.get('/auth/getListUser',{
                params: {
                    agency_id: this.ruleForm.agency_id,
                    user_name: '',
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_user = res.data.result.filter(item => item.is_delete != 1)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading(); 
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/real_estate/getRealEstateDetailForAdmin',{
                params: {
                    id: this.decode(this.$route.params.id),
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.ruleForm = res.data
                this.ruleForm.name = res.data.real_estate_name
                this.ruleForm.province = JSON.stringify({
                    code: res.data.province_id,
                    name: res.data.province_name,
                    slug: res.data.province_slug
                })
                this.ruleForm.district = JSON.stringify({
                    code: res.data.district_id,
                    name: res.data.district_name,
                    province_code: res.data.province_id,
                    slug: res.data.district_slug,
                })
                this.ruleForm.ward= JSON.stringify({
                    code: res.data.ward_id,
                    name: res.data.ward_name,
                    district_code: res.data.district_id,
                    slug: res.data.ward_slug,
                })
                this.listDescribe = res.data.describe
                this.listDescribe.forEach(ele => {
                    if(ele.info_type == 1) {
                        ele.value = parseFloat(ele.value)
                    } else if(ele.info_type == 3 || ele.info_type == 4) {
                        ele.value = ele.value_id
                    }
                });
                this.ruleForm.price = parseInt(res.data.price).toLocaleString('vi')
                if(res.data.price == 'NaN') {
                    this.ruleForm.price = ''
                }
                this.ruleForm.design_file = res.data.design_image
                this.ruleForm.fee = res.data.fee_id
                this.keyActivePaper = res.data.juridical_file.juridical_id
                this.cate_name = res.data.category_name
                this.avaOwner = res.data.owner_avatar
                this.frontImg = res.data.owner_id_front
                this.backImg = res.data.owner_id_back
                res.data.juridical_file.path.forEach(element => {
                    let data = {
                        'url' : this.$storage + element,
                        'path': element
                    }
                    this.old_file_juridical.push(data)
                });
                res.data.design_image.forEach(element => {
                    let data = {
                        'url' : this.$storage + element,
                        'path': element
                    }
                    this.old_file_design.push(data)
                });
                res.data.image.forEach(element => {
                    if(element.is_360 == 1){
                        element.is_360 = true
                    } else {
                        element.is_360 = false
                    }
                });
                this.changeCity('new');
                this.changeDistrict('new')
                this.getListCate(res.data.type)
                this.changeCate(res.data.category_id, 'get')
                this.getListUser('new')
                this.chooseWard()
                this.getMaps()
            })
            this.endLoading();
        },
        getDataProps(data) {
            this.ruleForm.owner_id = data.id
            this.owner_id = data.id
            this.ruleForm.owner_name = data.full_name
            this.ruleForm.owner_avatar = data.avatar
            this.avaOwner = data.avatar
            this.ruleForm.owner_phone = data.phone
            this.ruleForm.owner_email = data.email
            this.ruleForm.owner_address = data.address
            this.ruleForm.owner_id_number = data.id_number
            this.ruleForm.owner_id_date = data.id_date
            this.ruleForm.owner_id_place = data.id_place
            this.ruleForm.owner_id_front = data.id_front
            this.ruleForm.owner_id_back = data.id_back
            this.frontImg = data.id_front
            this.backImg = data.id_back
        },
        handleClick(tab) {
            if(tab.paneName == 0) {
                this.ruleForm.owner_id = ''
            } else {
                this.ruleForm.owner_id = this.owner_id
            }
        },
        async getMaps() {
            let address = this.ruleForm.address + ', ' + JSON.parse(this.ruleForm.ward).name + ', ' + JSON.parse(this.ruleForm.district).name + ', ' + JSON.parse(this.ruleForm.province).name
            await this.$axios.get('/common/callAPIGoong',{
                params: {
                    address: address,
                },
            }).then(res => {
                this.ruleForm.longtitude = res.data.results[0].geometry.location.lng
                this.ruleForm.latitude = res.data.results[0].geometry.location.lat
            })
        },
        async chooseWard() {
            let address = JSON.parse(this.ruleForm.ward).name + ', ' + JSON.parse(this.ruleForm.district).name + ', ' + JSON.parse(this.ruleForm.province).name
            await this.$axios.get('/common/callAPIGoong',{
                params: {
                    address: address,
                },
            }).then(res => {
                this.ruleForm.ward_longtitude = res.data.results[0].geometry.location.lng
                this.ruleForm.ward_latitude = res.data.results[0].geometry.location.lat
            })
        },
        changeProj(pro_id) {
            if(pro_id) {
                this.list_project.forEach(element => {
                    if(pro_id == element.project_id) {
                        this.ruleForm.longtitude = element.longtitude
                        this.ruleForm.latitude = element.latitude
                    }
                });
            } else {
                this.getMaps()
            }
        },
    }
})
</script>

<style lang="css" scoped src="./assets/style.css"></style>
<style>
    .add_bds .form-control .el-input__wrapper,
    .add_bds .form-control .el-input,
    .add_bds .form-control .el-input.is-focus .el-input__wrapper {
        height: 100%;
        width: 100%;
        background-color: #00000000;
        border: unset;
        box-shadow: unset !important;
        font-size: normal;
        font-weight: normal;
    }

    .add_bds .form-control.el-select .el-input__wrapper.is-focus {
        box-shadow: none !important;
    }

    .add_bds .el-input-number__decrease,
    .add_bds .el-input-number__increase {
        border-radius: 15px;
        background: #C8C8C8;
        color: #515151;
        text-align: center;
        font-family: Roboto;
        font-weight: 700;
        width: 39px;
        height: 39px;
    }

    .add_bds .el-input-number {
        width: 220px;
    }

    .add_bds .el-input .el-input__wrapper {
        border: unset;
        box-shadow: unset !important;
    }

    .add_bds .el-input-number .el-input .el-input__inner {
        width: 132px;
        height: 39px;
        border-radius: 15px;
        background: #EEE;
    }

    .add_bds .el-input-number__increase:hover,
    .add_bds .el-input-number__decrease:hover {
        color: #515151;
    }

    .modal-backdrop {
        width: 100% !important;
        height: 100% !important;
    }

    .add_bds .el-upload-list__item.is-success {
        border-radius: 10px;
        border: 1px solid #515151;
        max-height: 80px;
        max-width: 125px;
        margin-top: 50px;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        object-fit: unset;
    }

    .add_bds .choose-file .el-upload {
        width: 100px;
        height: 32px;
        border-radius: 15px;
        background: rgb(68, 68, 68);
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        border: unset;
        justify-content: center;
        order: -1;
        position: relative;
    }

    .add_bds .choose-file .el-upload::before {
        content: 'Chọn File';
    }
    .add_bds .choose-file .el-upload::after {
        content: 'png, jpeg, jpg';
        right: -326px;
        position: absolute;
        width: 312px;
        height: 32px;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 16px;
        padding: 0 14px;
        display: flex;
        align-items: center;
        font-weight: 400;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-actions:hover span.el-upload-list__item-preview {
        display: none;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-actions:hover span.el-upload-list__item-delete {
        margin-left: 0;
    }

    .add_bds .el-upload-list__item.is-success:first-child {
        margin-left: -100px;
    }

    .add_bds .el-form-item__label {
        font-size: 16px;
        color: #515151;
    }

    .backtop {
        position: fixed;
        cursor: pointer;
        right: 56px;
        bottom: 20px;
    }

    .storage_add .el-tabs__header {
        margin-bottom: 0;
    }

    .storage_add .el-tabs__nav-wrap::after {
        content: unset;
    }

    .storage_add .el-tabs__item {
        color: #515151;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.18px;
        width: 220px;
        height: 48px;
        padding: 0;
    }

    .storage_add .el-tabs__item.is-active {
        color: #515151;
        background: #FFF;
        border-radius: 20px 20px 0 0;
    }

    .form-owner .el-tabs__item {
        font-size: 16px;
        width: max-content;
    }

    .form-owner .el-tabs__item:nth-child(2) {
        margin-right: 20px;
    }

    .form-owner .el-tabs__item.is-active {
        color: #fff !important;
        background: #515151;
        border-radius: 20px 20px 0 0;
        padding: 0 15px !important;
    }
    
    .form-owner .el-tabs__header {
        margin-bottom: 25px;
    }

    .storage_add .el-tabs__active-bar {
        display: none;
    }

    .storage_add .el-tabs__item:hover {
        color: #515151;
    }

    .storage_add .el-date-editor.el-input {
        width: 100%;
        height: 39px;
    }

    .storage_add .el-date-editor.el-input .el-input__wrapper {
        border-radius: 74px;
        background: #F6F6F6;
        border: none;
        height: 46px;
    }
</style>