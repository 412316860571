<template>
    <div class="event">
        <h5>Chương trình</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chương trình</span>
        </div>
        <el-row>
            <el-col :span="7">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Chương trình</p>
                        <button class="add-cate" @click="showForm('add','')">+ Thêm mới</button>
                    </div>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listEvent"
                            :key="index"
                            @click="keyActive = item.id, detail = true, getDetail(item.id)"
                        >
                            {{ item.name }}
                            <div class="wp-btn">
                                <button @click.stop="showForm('edit', item)"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="dialogVisible = true, id = item.id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="17" v-if="detail">
                <div class="wp-detail">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p class="ttl">Thông tin chương trình</p>
                        <div class="d-flex">
                            <button class="add-cate" @click="$router.push('/he-thong/chuong-trinh/danh-sach-trung-thuong/' + encode(dataDetail.id))">List trúng thưởng</button>
                            <button class="btn_save" @click="$router.push('/he-thong/chuong-trinh/chinh-sua/' + encode(dataDetail.id))">Chỉnh sửa</button>
                            <!-- <button class="btn_del" @click=""><img src="@/assets/icons/trash_redd.png" alt="" style="margin-right: 5px;"> Xóa</button> -->
                        </div>
                    </div>
                    <p class="lb">Tên chương trình</p>
                    <p class="txt">{{ dataDetail.name }}</p>
                    <p class="lb">Thời gian bắt đầu chương trình</p>
                    <p class="txt" v-if="dataDetail.start_time">{{ $moment.utc(dataDetail.start_time).format('HH:mm') }} - {{ $moment.utc(dataDetail.start_time).format('DD/MM/YYYY') }}</p>
                    <p class="txt" v-else></p>
                    <p class="lb">Thời gian kết thúc chương trình</p>
                    <p class="txt" v-if="dataDetail.end_time">{{ $moment.utc(dataDetail.end_time).format('HH:mm') }} - {{ $moment.utc(dataDetail.end_time).format('DD/MM/YYYY') }}</p>
                    <p class="txt" v-else></p>
                    <p class="lb">Icon chương trình</p>
                    <div class="txt">
                        <img v-if="dataDetail.event_icon" :src="$storage + dataDetail.event_icon" alt="" style="max-width: 150px;" @error="handleError">
                    </div>
                    <p class="lb">Ảnh bìa web</p>
                    <div class="txt">
                        <img v-if="dataDetail.cover_image_web" :src="$storage + dataDetail.cover_image_web" alt="" style="max-width: 765px;" @error="handleError">
                    </div>
                    <p class="lb">Ảnh bìa mobile</p>
                    <div class="txt">
                        <img v-if="dataDetail.cover_image_app" :src="$storage + dataDetail.cover_image_app" alt="" style="max-width: 286px;" @error="handleError">
                    </div>
                    <p class="lb">Vector quay</p>
                    <div class="txt">
                        <img v-if="dataDetail.spin_vector" :src="$storage + dataDetail.spin_vector" alt="" style="max-width: 72px;" @error="handleError">
                    </div>
                    <p class="lb">Vector mũi tên</p>
                    <div class="txt">
                        <img v-if="dataDetail.arrow_vector" :src="$storage + dataDetail.arrow_vector" alt="" style="max-width: 72px;" @error="handleError">
                    </div>
                    <p class="lb">Số ô giải thưởng trong vòng quay</p>
                    <p class="txt">{{ dataDetail.count_reward }}</p>
                    <p class="lb">Số ngày làm mới lượt quay</p>
                    <p class="txt">{{ dataDetail.reset_turn }}</p>
                    <div v-if="dataDetail.rules">
                        <p class="lb">Thể lệ chương trình</p>
                        <p class="txt" v-html="dataDetail.rules"></p>
                    </div>
                    <div v-if="dataDetail.reward">
                        <p class="lb">Giải thưởng</p>
                        <p class="txt" v-html="dataDetail.reward"></p>
                    </div>
                    <div v-if="dataDetail.instructions">
                        <p class="lb">Hướng dẫn nhận thưởng</p>
                        <p class="txt" v-html="dataDetail.instructions"></p>
                    </div>
                </div>

                <div class="wp-detail" style="margin-top: 20px;">
                    <p class="ttl">Chi tiết giải thưởng và tỉ lệ</p>
                    <div class="item-prize" v-for="(item, index) in dataDetail.list_reward">
                        <p class="ttl">Giải thưởng {{ index + 1 }}</p>
                        <p class="lb">Tên giải thưởng</p>
                        <p class="txt">{{ item.name }}</p>
                        <p class="lb">Hình ảnh giải thưởng</p>
                        <div class="txt">
                            <img :src="$storage + item.image" alt="" style="max-width: 156px;" @error="handleError">
                        </div>
                        <p class="lb">Giá trị</p>
                        <p class="txt">{{ parseInt(item.price).toLocaleString('vi') }} VNĐ</p>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="item">
                                <p class="lb">Số lượng giải</p>
                                <p class="txt">{{ item.count_item }}</p>
                            </div>
                            <div class="item">
                                <p class="lb">Số lượng trúng</p>
                                <p class="txt">{{ item.max_reward }}</p>
                            </div>
                            <div class="item">
                                <p class="lb">Tỷ lệ quay</p>
                                <p class="txt">{{ item.rotation_percent }}%</p>
                            </div>
                            <div class="item">
                                <p class="lb">Loại giải thưởng</p>
                                <p class="txt" v-if="item.reward_type == 0">Thường</p>
                                <p class="txt" v-else>Phần thưởng nạp tiền</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wp-detail" style="margin-top: 20px;">
                    <p class="ttl">List vòng quay</p>
                    <div class="item-prize" v-for="(spiner, index) in dataDetail.list_spiner">
                        <p class="ttl">Vòng quay {{ index+1 }}</p>
                        <p class="lb">Vòng quay</p>
                        <div class="txt">
                            <img :src="$storage + spiner.image" alt="" style="max-width: 376px;" @error="handleError">
                        </div>
                        <div class="d-flex align-items-center justify-content-between flex-wrap">
                            <div v-for="i in dataDetail.count_reward" style="flex-basis: 24%;">
                                <el-select
                                    filterable
                                    style="width: 100%"
                                    v-model="spiner.reward_ids[i-1]"
                                    disabled
                                >
                                    <el-option
                                        v-for="(item, index) in dataDetail.list_reward"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    />
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-dialog
            v-model="dialogAddEvent"
            width="553"
            class="dialog_add_cate"
            @close="dialogAddEvent = false"
        >
            <p class="ttl" v-if="typeDialog == 'add'">Tạo chương trình mới</p>
            <p class="ttl" v-else>Sửa chương trình</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
                @submit.prevent="typeDialog == 'add' ? submitEvent('formAdd', 'add') : submitEvent('formAdd', 'edit')"
            >
                <el-form-item :label="typeDialog == 'add' ? 'Nhập tên chương trình mới' : 'Tên chương trình mới'" prop="name">
                    <el-input v-model="formAdd.name" type="text" resize="none" placeholder="Nhập tên chương trình" />
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 40px;">
                <button class="btn_cancel" @click="dialogAddEvent = false">Hủy bỏ</button>
                <button class="btn_add" @click="submitEvent('formAdd', 'add')" v-if="typeDialog == 'add'">Xác nhận</button>
                <button class="btn_add" @click="submitEvent('formAdd', 'edit')" v-else>Xác nhận</button>
            </div>
        </el-dialog>

        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ quy chế"
            content_success="Bạn đã xóa danh mục thành công"
        />
    </div>
</template>

<script>
import Dialog from '../../components/dialog.vue'
export default {
    components: { Dialog },
    data() {
        return {
            formAdd: {
                name: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên chương trình", trigger: ["blur", "change"]}],
            },
            dialogAddEvent: false,
            typeDialog: '',
            dataEdit: [],
            dataDetail: [],
            listEvent: [],
            keyActive: 0,
            dialogVisible: false,
            successDialog: false,
            id: '',
            detail: false
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getList() {
            this.startLoading();
            await this.$axios.get('/system/getListEvent')
            .then(res => {
               this.listEvent = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        showForm(type, data) {
            this.dialogAddEvent = true
            if(type == 'add') {
                this.typeDialog = 'add'
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
                this.formAdd.name = ''
            } else {
                this.typeDialog = 'edit'
                this.formAdd.name = data.name
                this.dataEdit = data
            }
        },
        async submitEvent(formName, type) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    if(type == 'add') {
                        var method = 'post'
                        var lnk = '/system/createEvents'
                        var data = this.formAdd
                        var mss = 'Thêm chương trình thành công!'
                    } else {
                        var method = 'put'
                        var lnk = '/system/updateEvent'
                        var data = {
                            name: this.formAdd.name,
                            id: this.dataEdit.id
                        }
                        var mss = 'Sửa tên chương trình thành công!'
                    }
                    this.startLoading();
                    const res = await this.$axios[method](lnk, data, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", mss);
                        this.dialogAddEvent = false
                        this.turnOffErrorMessage('formAdd')
                        this.getList()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async confirm() {
            const data = {
                'id': this.id
            }
            this.startLoading();
            await this.$axios.put('/system/deleteEvent',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa chương trình thành công!");
                this.detail = false
                this.getList()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(id) {
            this.startLoading();
            await this.$axios.get('/system/getEventDetail',{
                params: {
                    id: id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    .event {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate{
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-cate {
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .wp-detail p.lb {
        font-weight: 500;
        margin-bottom: 5px;
        font-size: 15px;
    }

    .wp-detail img {
        width: 100%;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    .wp-cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-cate .wp-btn button {
        padding: 0;
    }

    .wp-cate .wp-btn button:last-child {
        margin-left: 10px;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-detail button.btn_del {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        background: #fff;
        margin-left: 10px;
        border: 1px solid #EE3439;
        width: 74px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .txt {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 8px 10px;
        text-align: center;
        margin-bottom: 10px
    }

    p.txt {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        text-align: left;
    }

    .txt img {
        padding: 20px 0;
    }

    .item-prize {
        border-radius: 20px;
        border: 1px solid #999;
        padding: 20px;
        margin-bottom: 10px;
    }

    .item-prize .item {
        flex-basis: 24%;
    }
</style>

<style>
    .event .el-select {
        margin-bottom: 10px;
    }

    .event .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 12px;
    }
</style>