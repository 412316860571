<template>
    <div class="wp-confirm">
        <h5>
            <img src="@/assets/icons/arrow.png" alt="" style="rotate: 180deg; cursor: pointer;" @click="$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + $route.params.cusId + '/nhu-cau/chi-tiet/' + $route.params.demandId)">
            Bất động sản phù hợp
        </h5>
        <div class="breadcrumb">
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Khách hàng quản lý</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Chi tiết khách hàng</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Nhu cầu</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <div class="d-flex">
                    <div class="dropdown me-3">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ agency_name }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                            <li>
                                <a class="dropdown-item" href="#" @click="agency_id = '', setPage(1), agency_name = 'Tất cả chi nhánh'">
                                    Tất cả chi nhánh
                                </a>
                            </li>
                            <li v-for="item in list_agency.result">
                                <a class="dropdown-item" href="#" @click="agency_id = item.id, setPage(1), agency_name = item.agency_name">
                                    {{ item.agency_name }}
                                </a>
                            </li>
                        </ul>
                    </div>
    
                    <!-- <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                            Đề xuất
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="agency">
                            <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn</p>
                            <li>
                                <a class="dropdown-item" href="#">
                                    Tất cả
                                </a>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectRoute">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh" width="93">
                        <template #default="scope">
                            <img :src="$storage + scope.row.thumbnail" alt="" style="width: 60px; height: 60px; border-radius: 15px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="real_estate_name" label="Tiêu đề tin đăng bất động sản" min-width="372">
                        <template #default="scope">
                            <p style="margin-bottom: 5px;">{{ scope.row.real_estate_name }}</p>
                            <div class="table_code" v-if="scope.row.real_estate_code">#{{ scope.row.real_estate_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="type" label="Loại" width="94">
                        <template #default="scope">
                            <span v-if="scope.row.type == 1">Rao bán</span>
                            <span v-else-if="scope.row.type == 2">Cho thuê</span>
                            <span v-else>Tất cả</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="category_name" label="Danh mục" min-width="162" />
                    <el-table-column header-align="center" align="center" prop="agency_name" label="Chi nhánh" min-width="112" />
                    <el-table-column header-align="center" align="center" prop="sale_name" label="Sale quản lý" min-width="235" />
                    <el-table-column header-align="center" align="center" label="Giá" min-width="130">
                        <template #default="scope">
                            <span>{{ formatPrice(scope.row.price) }} {{ scope.row.unit_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Trạng thái BĐS" width="135">
                        <template #default="scope">
                            <span v-if="scope.row.real_estate_status == 1" style="color: #00B569;">Mở bán</span>
                            <span v-if="scope.row.real_estate_status == 2" style="color: #EE3439;">Đã bán</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column header-align="center" align="center" label="Đề xuất" min-width="89">
                        <template #default="scope">
                            <button>
                                <img src="@/assets/icons/add_bookmark.png" alt="" style="width: 30px; height: 30px;">
                            </button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            dateSearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            agency_name: 'Tất cả chi nhánh',
            agency_id: '',
            list_agency: [],
        }
    },
    mounted() {
        this.getListRealEstate()
        this.getAgency()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            if(!this.dateSearch) {
                this.dateSearch = ''
            }
            this.getListRealEstate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            if(!this.dateSearch) {
                this.dateSearch = ''
            }
            this.getListRealEstate()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
        },
        async getListRealEstate() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/job/getListRealEstateCustomerNeed',{
                params: {
                    id: this.decode(this.$route.params.demandId),
                    agency_id: this.agency_id,
                    name: this.keySearch,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectRoute(row) {
            this.$router.push('/quan-ly-bds/danh-sach/chi-tiet/' + this.encode(row.id))
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    p.rank {
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        width: 90px;
        height: 26px;
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table_code {
        color: #F58E16;
        font-weight: 600;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        min-width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 15px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }
</style>