<template>
    <div class="wp-confirm">
        <h5>Danh sách khách hàng</h5>
        <div class="breadcrumb">
            <span>Quản lý khách hàng</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách khách hàng</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    start-placeholder="Từ ngày"
                    end-placeholder="Đến ngày"
                    format="DD-MM-YYYY"
                    value-format="YYYY-MM-DD"
                    @change="getDate"
                />
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ status_txt }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn trạng thái</p>
                        <li><a class="dropdown-item" href="#" @click="stt_key = '', setPage(1), status_txt = 'Tất cả trạng thái'">Tất cả trạng thái</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 0, setPage(1), status_txt = 'Chưa xác minh'">Chưa xác minh</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 1, setPage(1), status_txt = 'Chờ xác minh'">Chờ xác minh</a></li>
                        <li><a class="dropdown-item" href="#" @click="stt_key = 2, setPage(1), status_txt = 'Đã xác minh'">Đã xác minh</a></li>
                    </ul>
                </div>

                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1), status_txt = 'Tất cả trạng thái'"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh đại diện" width="124">
                        <template #default="scope">
                            <img :src="$storage + scope.row.avatar" alt="" style="width: 80px; height: 80px; border-radius: 62px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="full_name" label="Họ tên khách hàng" width="267" />
                    <el-table-column header-align="center" align="center" label="Mã khách hàng" width="150">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.member_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="address" label="Địa chỉ" min-width="350"/>
                    <el-table-column header-align="center" align="center" prop="phone" label="Số điện thoại" width="135"/>
                    <el-table-column header-align="center" align="center" prop="position" label="Trạng thái tài khoản" min-width="270">
                        <template #default="scope">
                            <span v-if="scope.row.approve_status == 2" style="color: #00B569;">Đã xác minh tài khoản</span>
                            <span v-else-if="scope.row.approve_status == 1" style="color: #F58E16;">Chờ xác minh tài khoản</span>
                            <span v-else style="color: #EE3439;">Chưa xác minh tài khoản</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action">
                        <template #default="scope">
                            <!-- <div class="d-flex align-items-center"> -->
                                <!-- <button style="padding: 0 12px;"><img src="@/assets/icons/pencil.png" alt=""></button> -->
                                <button><img src="@/assets/icons/trash.png" alt=""></button>
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            status_txt: 'Tất cả trạng thái',
            stt_key: '',
            date: '',
            start_date: '',
            end_date: ''
        }
    },
    mounted() {
        this.getListCustomer()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListCustomer()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListCustomer()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-khach-hang/danh-sach/chi-tiet/' + this.encode(row.id))
        },
        async getListCustomer() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/member/getListMember',{
                params: {
                    full_name: this.keySearch,
                    phone: '',
                    email: '',
                    approve_status: this.stt_key,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        getDate() {
            if(this.date) {
                this.start_date = this.date[0]
                this.end_date = this.date[1]
            } else {
                this.start_date = ''
                this.end_date = ''
            }
            this.setPage(1)
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }
</style>
<style lang="css">
    .wp-confirm .el-date-editor--daterange {
        height: 40px !important;
        padding-left: 40px !important;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }
</style>