<template>
    <div class="cate_project">
        <h5>Danh mục dự án</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh mục bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh mục dự án</span>
        </div>

        <el-row style="margin-top: 15px;">
            <el-col :span="10">
                <div class="wp-list">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5>List danh mục</h5>
                        <button @click="showAdd">+ Tạo danh mục mới</button>
                    </div>
                    <ul>
                        <li :class="{'active' : cate_id == item.id}" v-for="item in list_cate" @click="getDetailCate(item.id), cate_id = item.id">{{ item.name }}</li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="14" v-if="showDetail">
                <div class="wp-des">
                    <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 25px;">
                        <h5>Mô tả cho danh mục</h5>
                        <div>
                            <button class="btn-del" v-if="typePage == 'detail'" @click="deleteCate(cate_id)"><img src="@/assets/icons/trash_red.png" alt="">Xóa danh mục</button>
                            <button class="btn-edit" v-if="typePage == 'add'" @click="addCate('formAdd')"><img src="@/assets/icons/Edit.png" alt="">Lưu danh mục</button>
                            <button class="btn-edit" v-if="typePage == 'detail'" @click="typePage = 'edit'"><img src="@/assets/icons/Edit.png" alt="">Chỉnh sửa</button>
                            <button class="btn-edit" v-if="typePage == 'edit'" @click="updateCate('formAdd')"><img src="@/assets/icons/Edit.png" alt="">Lưu chỉnh sửa</button>
                        </div>
                    </div>

                    <el-form
                        ref="formAdd"
                        :model="formAdd"
                        :rules="rules"
                        label-width="120px"
                        class="demo-ruleForm"
                        status-icon
                        @submit.prevent="typePage == 'add' ? addCate('formAdd') : updateCate('formAdd')"
                    >
                        <el-form-item prop="project_category_name" label="Tên danh mục">
                            <el-input id="name" type="text" v-model="formAdd.project_category_name" :disabled="typePage == 'detail'" placeholder="Nhập tên danh mục"/>
                        </el-form-item>
                    </el-form>

                    <div class="list-des">
                        <p>Chọn các trường yêu cầu cho danh mục dự án</p>
                        <span v-if="formAdd.describe.length == 0 && showErr" class="error">Vui lòng thêm mô tả bổ sung</span>
                        <ul>
                            <li v-for="(item, index) in list_des" :key="index" @click="addItem(item.id)" :style="{ 'pointer-events': typePage == 'detail' ? 'none' : 'auto' }" :class="{'active' : formAdd.describe.includes(item.id)}">
                                <img :src="$storage + item.icon" alt="" @error="handleError" style="max-width: 32px; max-height: 32px;">
                                {{ item.name }}
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="wp-info">
                    <h5 style="margin-bottom: 15px;">Thông tin pháp lý yêu cầu</h5>
                    <ul>
                        <li v-for="(item, index) in list_file_required" :key="index">{{ item.name }}</li>
                    </ul>
                    <button @click="dialogVisible = true" v-if="typePage !== 'detail'"><img src="@/assets/icons/plus.png" alt=""> Thêm yêu cầu pháp lý</button>
                    <span v-if="!list_file_required && showErr" class="error">Vui lòng thêm thông tin pháp lý</span>
                </div>
            </el-col>
        </el-row>
        <listRequired 
            :dialogVisible="dialogVisible"
            :list_juridical="all_juridical"
            @handleCloseDialog="listenDialog"
            @confirm="getDataDialog"
        />
    </div>
</template>

<script>
import listRequired from '../../modal/list_required.vue'
export default {
    components: {
        listRequired
    },
    data() {
        return {
            formAdd: {
                project_category_name: '',
                describe: [],
                juridical: []
            },
            rules: {
                project_category_name: [{required: true, message: "Vui lòng nhập tên danh mục", trigger: ["blur", "change"]}],
            },
            list_cate: [],
            list_des: [],
            list_file_required: null,
            dialogVisible: false,
            all_juridical: [],
            showErr: false,
            typePage: '',
            showDetail: false,
            cate_id: ''
        }
    },
    mounted() {
        this.getListCate()
        this.getListDescribe()
    },
    methods: {
        addItem(key) {
            const index = this.formAdd.describe.indexOf(key);
            if (index === -1) {
                this.formAdd.describe.push(key);
            } else {
                this.formAdd.describe.splice(index, 1);
            }
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        showAdd(){
            this.typePage = 'add'
            this.showDetail = true
            this.formAdd.project_category_name = ''
            this.formAdd.describe = []
            this.formAdd.juridical = []
            this.list_file_required = null
            if(this.$refs.formAdd) {
                this.turnOffErrorMessage('formAdd')
            }
        },
        getDataDialog(data) {
            this.list_file_required = data
            this.formAdd.juridical = []
            data.forEach(el => {
                this.formAdd.juridical.push(el.id)
            });
        },
        async getListCate() {
            this.startLoading();
            await this.$axios.get('/project/getListProjectCategory',{
                params: {
                    name: '',
                    page: 1,
                    per_page: 100
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_cate = res.data.result;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListDescribe() {
            this.startLoading();
            await this.$axios.get('/project/getListProjectDescribe',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_des = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        addCate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.formAdd.describe.length == 0 || !this.list_file_required.length) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    const res = await this.$axios.post('/project/createProjectCategory',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Thêm danh mục thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.formAdd.describe = this.formAdd.juridical = this.all_juridical = []
                        this.list_file_required = null
                        this.showErr = false
                        this.getListCate()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                }
            });
        },
        async getDetailCate(id) {
            this.typePage = 'detail'
            this.showDetail = true
            this.startLoading();
            await this.$axios.get('/project/getProjectCategoryDetail',{
                params: {
                    project_category_id: id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formAdd = res.data
                this.list_file_required = res.data.juridical
                this.formAdd.juridical = res.data.juridical.map(item => item.id)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async deleteCate (id) {
            this.startLoading();
            await this.$axios.put('/project/deleteProjectCategory', {id: id},{

                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa danh mục thành công!");
                this.showDetail = false
                this.getListCate()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        updateCate(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.formAdd.describe.length == 0 || !this.list_file_required.length) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    const res = await this.$axios.put('/project/updateProjectCategory',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Sửa danh mục thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.showDetail = this.showErr = false
                        this.getListCate()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-list {
        margin-right: 20px;
    }

    .wp-list, .wp-des, .wp-info {
        border-radius: 20px;
        background: #FFF;
        font-family: Roboto;
        padding: 20px;
    }

    .wp-list h5, .wp-des h5, .wp-info h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
    }

    .wp-list button {
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #FFF;
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        padding: 8.5px 14px;
    }

    .wp-list ul, .list-des ul, .wp-info ul {
        list-style: none;
        padding-left: 0;
        margin: 30px 0 0;
    }

    .wp-list ul li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .wp-list ul li.active {
        background: #FCE3C5;
    }

    .wp-list ul li:last-child {
        margin-bottom: 0;
    }

    .wp-des button {
        padding: 10px 14px;
        font-size: 14px;
        font-weight: 500;
        max-height: 40px;
        border-radius: 15px;
    }

    .wp-des button img {
        margin-right: 10px;
    }

    .wp-des button.btn-del {
        border: 1px solid #EE3439;
        color: #EE3439;
        margin-right: 15px;
    }

    .wp-des button.btn-edit {
        background: #F58E16;
        color: #fff;
    }

    .list-des ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 0;
    }

    .list-des ul li {
        border-radius: 15px;
        border: 1px solid #515151;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        width: 30%;
        margin-bottom: 33px;
        padding: 4px 10px;
        cursor: pointer;
    }

    .list-des ul li img {
        margin-right: 10px;
    }

    .list-des ul li.active {
        background: #FCE3C5;
        border: none;
    }

    .list-des p {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin: 24px 0 10px;
    }

    .wp-info {
        margin-top: 20px;
    }

    .wp-info ul {
        margin-top: 0;
    }

    .wp-info ul li {
        border-radius: 15px;
        background: #FCE3C5;
        padding: 10px 14px;
        width: max-content;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .wp-info button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #F58E16;
    }

    .wp-info button img {
        margin-right: 5px;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>

<style>
    .cate_project .el-input__wrapper {
        border-radius: 15px;
        border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
        background: var(--neutral-gray-gray-25, #F9F9FC);
        box-shadow: none;
        padding: 4px 12px;
    }

    /* .cate_project .el-input__inner {
        color: #515151;
        font-size: 15px;
        font-weight: 600;
    } */
</style>