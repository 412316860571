<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_reject"
        @close="emitDialogVisible(false)"
        align-center
    >
        <img src="@/assets/icons/notice.png" alt="">
        <p class="ttl">{{ title }}</p>
        <p>{{ content }} <br>Hãy nhập lý do từ chối để gửi thông báo đến {{ customer }}</p>
        <p style="font-size: 15px; color: #515151; font-weight: 500; text-align: left; margin-bottom: 5px;">Lý do từ chối:</p>
        <el-form action="" id="formNews" class="form" :rules="rules" :model="ruleForm" ref="ruleForm">
            <el-form-item prop="reason">
                <el-input type="textarea" v-model="ruleForm.reason" placeholder="Nhập lý do"></el-input>
            </el-form-item>
        </el-form>
        <div class="wp-btn" style="margin-top: 20px;">
            <button class="btn_cancel" @click="dialogVisibleStatus = false">Hủy bỏ</button>
            <button class="btn_add" @click="confirm('ruleForm')">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['rejectVisible', 'title', 'content', 'customer'],
    data() {
        return {
            dialogVisibleStatus: false,
            ruleForm: {
                reason: ''
            },
            rules: {
                reason: [{required: true, message: "Vui lòng nhập lý do", trigger: ["blur", "change"]}],
            }
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.rejectVisible
    },
    watch: {
        rejectVisible() {
            this.dialogVisibleStatus = this.rejectVisible;
        },
    },
    emits: ['handleCloseDialog', 'confirm'],
    methods: {
        emitDialogVisible(rejectVisible) {
            this.$emit("handleCloseDialog", rejectVisible);
            this.$refs.ruleForm.clearValidate()
            this.$refs.ruleForm.resetFields()
        },
        confirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit("confirm", this.ruleForm.reason);
                } else {
                    return true
                }
            });
        }
    },
}
</script>
<style lang="css" scoped>

    p.ttl {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    p {
        color: #444;
    }

    p.success {
        font-size: 18px;
        color: #00B569;
        font-weight: 500;
        margin: 8px 0 20px;
    }

    button {
        color: #EE3439;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #EE3439;
    }

    button.btn_add {
        color: #FFF;
        background: #EE3439;
        border: none;
        margin-left: 13px;
    }
</style>

<style>
    .dialog_reject {
        width: 455px !important;
        border-radius: 15px !important;
    }

    .dialog_reject .el-dialog__header {
        display: none;
    }

    .dialog_reject .el-dialog__body {
        text-align: center;
    }

    .dialog_reject .el-textarea__inner {
        border-radius: 15px;
        background: #F4F4F4;
        resize: none;
        min-height: 130px !important;
    }
</style>