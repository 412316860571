<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Thêm khách hàng mới</h5>
                <div class="breadcrumb">
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Khách hàng quản lý</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Thêm khách hàng mới</span>
                </div>
            </div>
            <div class="wp-button">
                <button class="cancel" @click="$router.push('/cong-viec/khach-hang-quan-ly')"> Hủy bỏ</button>
                <button class="add-new" @click="updateCustomer('formAdd')" v-if="editPage"> Lưu chỉnh sửa</button>
                <button class="add-new" @click="submitForm('formAdd')" v-else> Xác nhận thêm</button>
            </div>
        </div>

        <div class="wp-detail">
            <div class="detail">
                <el-row class="justify-content-between">
                    <el-col :span="7" class="avatar text-center">
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile">
                        <img src="@/assets/images/add_ava.png" alt="" style="margin-bottom: 20px; cursor: pointer;" @click="$refs.inputFile.click()" v-if="!img_url">
                        <img :src="$storage + img_url" alt="" class="img_show" @click="$refs.inputFile.click()" v-else>
                        <span class="error" v-if="showErr && !this.formAdd.avatar">Vui lòng thêm hình ảnh nhân viên</span>
                    </el-col>
                    <el-col :span="16" class="info">
                        <el-form
                            ref="formAdd"
                            :model="formAdd"
                            :rules="rules"
                            label-width="120px"
                            class="demo-ruleForm"
                            status-icon
                        >
                            <div class="d-flex justify-content-between">
                                <p>Tên khách hàng:</p>
                                <el-form-item prop="full_name" class="formInput">
                                    <el-input v-model="formAdd.full_name" placeholder="Nhập họ và tên" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Giới tính:</p>
                                <el-radio-group v-model="formAdd.gender" class="formInput">
                                    <el-radio :label="1" size="large">Nam</el-radio>
                                    <el-radio :label="2" size="large">Nữ</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="d-flex justify-content-between" style="margin-top: 10px;">
                                <p>Ngày sinh:</p>
                                <el-form-item prop="birthday" class="formInput">
                                    <el-date-picker
                                        v-model="formAdd.birthday"
                                        type="date"
                                        placeholder="Chọn ngày sinh"
                                        format="DD-MM-YYYY"
                                        value-format="YYYY-MM-DD"
                                        class="formInput"
                                        :disabled-date="disabledDate"
                                    />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Số điện thoại liên hệ:</p>
                                <el-form-item prop="phone" class="formInput">
                                    <el-input v-model="formAdd.phone" placeholder="Nhập số điện thoại" maxlength="10" @input="formAdd.phone = formAdd.phone.replace(/[^0-9]/g, '')"/>
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Email:</p>
                                <el-form-item prop="email" class="formInput">
                                    <el-input v-model="formAdd.email" type="email" placeholder="Nhập email" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Địa chỉ:</p>
                                <el-form-item prop="address" class="formInput">
                                    <el-input v-model="formAdd.address" type="textarea" placeholder="Nhập địa chỉ" />
                                </el-form-item>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Nguồn:</p>
                                <p class="txt">Cá nhân</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Chi nhánh quản lý:</p>
                                <p class="txt">{{ userDetail.agency_name }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p>Người quản lý:</p>
                                <div class="d-flex align-items-center">
                                    <p class="txt me-3">{{ userDetail.full_name }}</p>
                                    <img :src="$storage + userDetail.avatar" alt="" class="manager_ava" @error="handleError">
                                </div>
                            </div>
                            <div>
                                <p class="mb-1">Ghi chú về khách hàng:</p>
                                <el-form-item prop="note">
                                    <textarea name="title" v-model="formAdd.note" :style="{ height: this.calculatedHeight('note-cus') }" ref="note-cus" class="form-control" @input="this.adjustTextareaHeight"></textarea>
                                    <el-input type="textarea" class="d-none"></el-input>
                                </el-form-item>
                            </div>
                        </el-form>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate
        };
    },
    data() {
        return {
            formAdd: {
                avatar: '',
                full_name: '',
                gender: 1,
                birthday: '',
                phone: '',
                email: '',
                address: '',
                note: ''
            },
            rules: {
                full_name: [{required: true, message: "Vui lòng nhập họ và tên", trigger: ["blur", "change"]}],
                birthday: [{required: true, message: "Vui lòng chọn ngày sinh", trigger: ["blur", "change"]}],
                phone: [
                    {required: true, message: "Vui lòng nhập số điện thoại", trigger: ["blur", "change"]},
                    {min: 10, message: "Số điện thoại tối thiểu 10 ký tự", trigger: ["blur", "change"]},
                ],
                email: [
                    {required: true, message: "Vui lòng nhập email", trigger: ["blur", "change"]},
                    { type: "email", message: "Vui lòng nhập email đúng định dạng", trigger: ["blur", "change"] },
                ],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
            },
            img_url: '',
            showErr: false,
            userDetail: [],
            editPage: false
        }
    },
    mounted() {
        this.getUserDetail()
        if(this.$route.path.includes('khach-hang-quan-ly/chinh-sua')) {
            this.editPage = true
            this.getCustomerDetail()
        } else {
            this.editPage = false
        }
    },
    methods: {
        async changeFile(event) {
            if(event.target.files[0]) {
                let url_img = await this.uploadFile('member/avatar/', event.target.files[0])
                this.img_url = url_img.path_tam
                this.formAdd.avatar = url_img.path
            }
        },
        async getUserDetail() {
            this.startLoading()
            await this.$axios.get('/auth/getUserProfile', {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.userDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAdd.avatar) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading()
                    await this.$axios.post('/job/createCustomer', this.formAdd, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Thêm mới khách hàng thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.img_url = ''
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
        async getCustomerDetail() {
            this.startLoading();
            await this.$axios.get('/job/getDetailCustomer',{
                params: {
                    id: this.decode(this.$route.params.id)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formAdd = res.data
                this.img_url = res.data.avatar
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        updateCustomer(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || !this.formAdd.avatar) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading()
                    await this.$axios.put('/job/updateCustomer', this.formAdd, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Chỉnh sửa khách hàng thành công!");
                        this.turnOffErrorMessage('formAdd')
                        this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.id)
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading()
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-button button {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Public Sans', sans-serif;
        padding: 10px 14px;
        height: 40px;
        border-radius: 15px;
    }

    .add-new {
        background: #F58E16;
        color: #FFF;
        width: 200px;
    }

    .wp-detail {
        width: 842px;
        margin: 15px auto 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .avatar p {
        margin-bottom: 0;
        color: #515151;
        font-size: 13px;
        font-weight: 400;
    }

    .avatar p:nth-child(odd) {
        font-size: 16px;
        font-weight: 500;
    }

    .info p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
    }

    .formInput {
        width: 60%;
    }

    .img_show {
        width: 227px;
        height: 227px;
        border-radius: 113px;
        cursor: pointer;
        object-fit: cover;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    p.txt {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        line-height: 180%;
        margin: 0;
    }

    img.manager_ava {
        width: 50px;
        height: 50px;
        border-radius: 33.5px;
        object-fit: cover
    }

    textarea {
        border-radius: 15px;
        background: var(--neutral-gray-gray-25, #F9F9FC) !important;
        resize: none;
        box-shadow: none !important;
        min-height: 71px !important;
    }

    textarea::-webkit-scrollbar {
        width: 0;
    }

    button.cancel {
        color: #F13A3A;
        width: 200px;
        border: 1px solid #EE3439;
        margin-right: 10px;
    }
</style>