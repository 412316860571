<template>
    <div class="cz-product-gallery d-flex">
        <div class="cz-preview py-1">
            <button type="button" class="btn-modal-gallery" v-if="!showVideo">
                <img class="show-imag img-responsive" v-if="selectedImage && !show360" :src="selectedImage" alt="Product image"
                    @error="handleError">
                <iframe :key="iframeKey" style="border-style:none; width: 100%; height: 453px; z-index: 11; position: relative;" v-if="show360" allowfullscreen :src="'https://cdn.pannellum.org/2.5/pannellum.htm#panorama='+$storage + image360"></iframe>
            </button>
             <div class="mr-1" v-if="this.videoUrl !== '' && showVideo">
                <iframe style="width: 100%; height: 453px; z-index: 11; position: relative;" :src="'https://www.youtube.com/embed/' + this.videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="cz-slide-thumnail d-block">
            <div class="mr-1 mr-xl-0" :class="{'mr-xl-3 mb-1': key != list_images.length - 1 }" v-for="(photo, key) in list_images">
                <a class="pr-0 cz-thumblist-item ">
                    <img :src="$storage + photo.path" alt="Product thumb" @click="showImage(key, photo), showVideo = false" @error="handleError">
                </a>
            </div>
            <div class="mr-1 mt-1" v-if="this.thumbnailUrl">
                <a class="pr-0 cz-thumblist-item ">
                    <img :src="this.thumbnailUrl" alt="Product thumb" @click="showVideo = true" @error="handleError">
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['list_images', 'carouselKey', 'video', 'thumbnail'],
    data() {
        return {
            selectedImage: '',
            galleryKey: 0,
            dataImg: this.list_images,
            videoUrl: '',
            thumbnailUrl: '',
            showVideo: false,
            image360: '',
            show360: false,
            iframeKey: 0
        }
    },
    mounted() {
        this.dataImg = this.list_images
        this.videoUrl = this.video
        // this.selectedImage = this.$storage + this.dataImg[0].path
    },
    watch: {
        list_images() {
            this.dataImg = this.list_images;
            if(this.dataImg.length !== 0) {
                if(this.dataImg[0].is_360 == 1) {
                    this.image360 = this.dataImg[0].path
                    this.show360 = true
                } else {
                    this.selectedImage = this.$storage + this.dataImg[0].path
                    this.show360 = false
                }
            }
        },
        video() {
            if(this.video) {
                if(this.video.includes('https://youtu.be/')) {
                    const parts = this.video.split("https://youtu.be/");
                    if (parts.length > 1) {
                        this.videoUrl = parts[1];
                    }
                } else if(this.video.includes('/shorts')) {
                    this.videoUrl = this.video.split("/shorts/")[1]
                } else {
                    const equalSignIndex = this.video.indexOf("?v=");
                    if (equalSignIndex !== -1) {
                        this.videoUrl = this.video.substring(equalSignIndex + 3);
                    }
                }
            }
        },
        thumbnail() {
            this.thumbnailUrl = this.thumbnail
        }
    },
    methods: {
        showImage(key, item) {
            this.galleryKey = key
            this.selectedImage = event.target.src;
            if(item.is_360 == 1){
                this.image360 = item.path
                this.show360 = true
                this.iframeKey += 1;
            } else {
                this.show360 = false
            }
        },
    },
}

</script>
<style scoped>
.cz-preview {
    width: 87%;
}

.cz-preview img {
    width: 814px;
    height: 453px;
    border-radius: 20px 0px 0px 20px;
}

.btn-modal-gallery {
    border: unset;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.cz-slide-thumnail {
    overflow: auto;
    height: 453px;
    margin: 0.25rem 0 0 15px;
    border-radius: 0px 20px 20px 0px;
    width: 22%;
}

.cz-slide-thumnail img {
    width: 100%;
    height: 143px;
    flex-shrink: 0;
    border-radius: unset;
    object-fit: cover;
}

.cz-slide-thumnail::-webkit-scrollbar {
    width: 0;
}

.cz-preview .swiper-slide {
    padding-bottom: 0;
}

.cz-preview img {
    width: 100%;
}

.btn-modal-gallery img {
    width: 100%;
    height: 453px;
    object-fit: cover;
}

.cz-product-gallery {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: nowrap;
}
</style>