<template>
    <div class="add_bds">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Tạo tin bất động sản mới</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách tin đăng bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Tạo tin bất động sản mới</span>
                </div>
            </div>
            <div class="wp-btn">
                <button data-bs-toggle="modal" data-bs-target="#exampleModal">Xem trước giao diện</button>
                <button class="btn_add" @click="submitForm('ruleForm')">Tạo tin bất động sản mới</button>
            </div>
        </div>
        <el-row>
            <el-col :span="24">
                <div class="content">
                    <div class="content-tab">
                        <el-form action="" id="formNews" class="form" :rules="rules" :model="ruleForm" ref="ruleForm">
                            <h5 style="padding-left: 20px;">Thông tin chi tiết bất động sản</h5>
                            <div class="content-form">
                                <div class="mb-3 d-flex flex-wrap">
                                    <button class="news-type me-3" :class="{ 'active': ruleForm.type == 1 }" @click="getListCate(1)" type="button">Bất
                                        động sản bán</button>
                                    <button class="news-type" :class="{ 'active': ruleForm.type == 2 }" @click="getListCate(2)" type="button">Bất động
                                        sản cho thuê</button>
                                </div>
                                <div class="mb-3 col-12">
                                    <el-form-item prop="category_id" label="Chọn loại bất động sản">
                                        <el-select clearable v-model="ruleForm.category_id" filterable placeholder="Chọn loại danh mục" class="form-control">
                                            <el-option v-for="item in listCate" :key="item.id" :label="item.category_name" :value="item.id" @click="changeCate(item.id), cate_name = item.category_name"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <!-- <div class="mb-3 col-12">
                                    <label for="" class="form-label">Chọn nhanh địa chỉ</label>
                                    <el-select v-model="category_id" filterable placeholder="Chọn vị trí theo bản đồ"
                                        class="form-control">
                                        <el-option v-for="item in 4" :key="item" :label="'TP Hà Nội' + item" :value="item">
                                        </el-option>
                                    </el-select>
                                </div> -->
                                <div class="mb-3 col-12">
                                    <el-form-item prop="province" label="Tỉnh / thành phố">
                                        <el-select v-model="ruleForm.province" filterable placeholder="Tỉnh/Thành phố" class="form-control"
                                        @change="changeCity" id="city">
                                            <el-option v-for="item in dataCity"
                                                :key="item.code"
                                                :label="item.name"
                                                :value="JSON.stringify(item)">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="mb-3 col-12">
                                    <el-form-item prop="district" label="Quận / huyện">
                                        <el-select v-model="ruleForm.district" filterable placeholder="Quận/Huyện" class="form-control"
                                        @change="changeDistrict" id="district">
                                            <el-option v-for="item in dataDistricts"
                                                :key="item.code"
                                                :label="item.name"
                                                :value="JSON.stringify(item)">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="mb-3 col-12">
                                    <el-form-item prop="ward" label="Phường / xã / thị trấn">
                                        <el-select v-model="ruleForm.ward" filterable placeholder="Xã /Phường" class="form-control" id="ward" @change="chooseWard">
                                            <el-option v-for="item in dataWard"
                                                :key="item.code"
                                                :label="item.name"
                                                :value="JSON.stringify(item)">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="mb-3 col-12">
                                    <el-form-item prop="address" label="Địa chỉ">
                                        <el-input id="name" type="text" v-model="ruleForm.address" :disabled="!ruleForm.ward" placeholder="Nhập địa chỉ" @blur="getMaps" class="form-control"/>
                                    </el-form-item>
                                </div>
                                <div class="mb-3 col-12">
                                    <label for="project" class="form-label">Chọn dự án </label>
                                    <el-select clearable v-model="ruleForm.project_id" filterable @change="changeProj(ruleForm.project_id)"
                                        placeholder="Chọn dự án nếu bất động sản của bạn nằm trong dự án" class="form-control" id="project">
                                        <el-option v-for="item in list_project"
                                            :key="item.project_id"
                                            :label="item.name"
                                            :value="item.project_id">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="mb-3 col-12">
                                    <p class="form-label" style="font-weight: 500; color: #515151">Vị trí trên bản đồ</p>
                                    <GMAPS :lng="ruleForm.longtitude" :lat="ruleForm.latitude"/>
                                </div>
                            </div>
                            <div class="content-form">
                                <div class="mb-3 col-12">
                                    <div class="title-2">Hình ảnh & video bất động sản</div>
                                    <ul class="verifi-img">
                                        <li>Đăng tối thiểu 4 hình ảnh, tối đa 20 hình ảnh </li>
                                        <li>Hãy dùng ảnh thật, không trùng,
                                            không chèn SĐT hoặc địa chỉ chi tiết vào hình ảnh</li>
                                        <li>Hình ảnh tối thiểu 200 x 200 px, tối đa 15MB</li>
                                        <li>Hình ảnh đuôi png, jpg</li>
                                        <li>Sắp xếp vị trí hình ảnh hơp lý, vị trí đầu tiên sẽ là ảnh đại diện</li>
                                    </ul>

                                    <div class="mb-3">
                                        <el-upload
                                            drag
                                            :accept="'image/png, image/jpeg, image/jpg'"
                                            multiple
                                            :show-file-list="false"
                                            :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'image')"
                                            :action="$API + '/common/upload360image'"
                                            :data="{path : 'real_estate/image/' }"
                                            list-type="picture"
                                            :on-progress="handleUploadProgress"
                                        >
                                            <img src="@/assets/icons/Collectibles.png" alt="">
                                            <div class="el-upload__text">Bấm để chọn ảnh cần tải lên</div>
                                            <div class="el-upload__tip" slot="tip">hoặc kéo thả ảnh vào đây</div>
                                        </el-upload>
                                        <span v-if="ruleForm.image.length==0 && showErr" class="error">Vui lòng thêm hình ảnh</span>
                                    </div>
                                    
                                    <div class="col-12 d-flex flex-wrap" >
                                        <draggable class="dragArea w-full d-flex flex-wrap" :list="ruleForm.image">
                                            <div class="card-image" v-for="(item, index) in ruleForm.image">
                                                <img class="main" :src="this.$storage + item.path_tam" alt="" @error="handleError">
                                                <span class="img_ava" v-if="index == 0">Ảnh đại diện</span>
                                                <div class="foot">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.is_360" :disabled="item.check360 == 0">
                                                        <span>360</span>
                                                    </div>
                                                    <button type="button" @click="ruleForm.image.splice(index, 1)">
                                                        <img src="@/assets/icons/Trash_Can.png" alt="" >
                                                    </button>
                                                </div>
                                            </div>
                                        </draggable>
                                    </div>
                                    <span v-if="ruleForm.image.length !== 0 && ruleForm.image.length < 4 && showErr" class="error">Vui lòng đăng tối thiểu 4 hình ảnh</span>
                                    <span v-else-if="ruleForm.image.length !== 0 && ruleForm.image.length > 20 && showErr" class="error">Vui lòng đăng tối đa 20 hình ảnh</span>
                                    <p>
                                        <a class="youtube" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                            <div>
                                                <img src="@/assets/icons/Video.png" alt="">
                                                <span>Thêm video từ Youtube</span>
                                            </div>
                                            <img src="@/assets/icons/Forward.png" alt="">
                                        </a>
                                    </p>
                                    <div class="collapse link-youtube" id="collapseExample">
                                        <input v-model="ruleForm.video" @input="this.checkYoutube(ruleForm.video)">
                                        <span>Link:</span>
                                    </div>
                                    <span v-if="ruleForm.video && !this.checkYoutube(ruleForm.video)" class="error">Vui lòng nhập đúng link video Youtube</span>
                                </div>
                                <div class="col-12">
                                    <div class="title-2">Thông tin bất động sản</div>
                                    <el-form-item prop="name" label="Tiêu đề bất động sản">
                                        <textarea name="title" v-model="ruleForm.name" class="form-control" placeholder="Nhập tiêu đề" id="ttl" @input="this.adjustTextareaHeight"></textarea>
                                        <el-input type="textarea" v-model="ruleForm.name" class="d-none"></el-input>
                                        <div class="small">Tối thiểu 30 ký tự, tối đa 99 ký tự</div>
                                    </el-form-item>
                                    <el-form-item prop="description" label="Mô tả bất động sản">
                                        <textarea name="des" class="form-control" v-model="ruleForm.description" placeholder="Nhập mô tả về bất động sản của bạn" id="des" @input="this.adjustTextareaHeight"></textarea>
                                        <el-input type="textarea" v-model="ruleForm.description" class="d-none"></el-input>
                                        <div class="small">Tối thiểu 30 ký tự</div>
                                    </el-form-item>
                                </div>
                                <div class="col-12">
                                    <el-form-item prop="design" label="Mặt bằng và thiết kế">
                                        <textarea name="degisn" v-model="ruleForm.design" class="form-control" placeholder="Nhập mô tả về Mặt bằng và thiết kế" id="degisn" @input="this.adjustTextareaHeight"></textarea>
                                        <el-input type="textarea" v-model="ruleForm.design" class="d-none"></el-input>
                                    </el-form-item>

                                    <label for="image" class="form-label title-3">Thêm hình ảnh mặt bằng và thiết kế</label>
                                    <div class="choose-file">
                                        <el-upload
                                            ref="upLoad"
                                            :action="$API + '/common/uploadFile'"
                                            list-type="picture-card"
                                            multiple
                                            accept="image/png, image/jpeg, image/jpg"
                                            :data="{path : 'real_estate/design_file/' }"
                                            :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'design_file')"
                                            :on-remove="handleRemove"
                                            :on-progress="handleUploadProgress"
                                        >
                                        </el-upload>
                                    </div>
                                </div>
                            </div>
                            <div class="content-form" v-if="cateDetail.length !== 0">
                                <div class="title-2">Thông tin chi tiết bất động sản</div>
                                <div class="col-12 position-relative">
                                    <el-form-item prop="acreage" label="Diện tích">
                                        <el-input type="text" class="form-control" v-model="ruleForm.acreage" name="acreage" @input="ruleForm.acreage = ruleForm.acreage.replace(/[^0-9.]/g, '')" id="acreage" placeholder="Nhập diện tích"/>
                                        <span class="unit">m2</span>
                                    </el-form-item>
                                </div>
                                <div class="col-8 px-1">
                                    <el-form-item prop="price" label="Mức giá" v-if="ruleForm.unit_id != 5">
                                        <el-input type="text" class="form-control" name="price" id="price" maxlength="15" @input="inputPrice" v-model="ruleForm.price" placeholder="Nhập mức giá"/>
                                        <p v-if="ruleForm.price && ruleForm.unit_id != 5" class="text_price">{{ numberInWords }} đồng</p>
                                    </el-form-item>
                                    <div v-else>
                                        <label class="el-form-item__label">Mức giá</label>
                                        <span>Thỏa thuận</span>
                                    </div>
                                </div>
                                <div class="col-4 px-1">
                                    <el-form-item prop="unit_id" label="Đơn vị">
                                        <el-select clearable class="form-control" id="unit" v-model="ruleForm.unit_id" placeholder="Chọn đơn vị">
                                            <el-option v-for="item in listUnit"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="col-12 position-relative mb-2">
                                    <p class="form-label title-4" style="min-width: 40px; display: inline-block;">Giấy tờ pháp lý</p>
                                    <span><img src="@/assets/icons/Starburst_Shape.png" alt="" style="margin: 0 0 10px 7px;"></span>
                                    <div class="w-100 d-flex flex-wrap">
                                        <button type="button" v-for="item in cateDetail.juridical" class="btn-option-1" @click="uploadReview(item.juridical_id)" :class="{'active' : keyActivePaper == item.juridical_id}">
                                            {{ item.juridical_name }}
                                        </button>
                                    </div>
                                    <span v-if="!ruleForm.juridical_file.juridical_id && showErr" class="error">Vui lòng chọn giấy tờ pháp lý</span>
                                </div>
                                <div class="col-12 position-relative mb-2">
                                    <p class="form-label title-3" style="min-width: 40px;">
                                        Tải lên tài liệu pháp lý để quản trị viên kiểm duyệt
                                    </p>
                                    <div class="choose-file">
                                        <div class="choose-file">
                                            <el-upload
                                                ref="upLoad"
                                                :action="$API + '/common/uploadFile'"
                                                list-type="picture-card"
                                                multiple
                                                accept="image/png, image/jpeg, image/jpg"
                                                :data="{path : 'real_estate/juridical_file/' }"
                                                :on-success="(response, file, fileList) => uploadFileImage(response, file, fileList, 'juridical_file')"
                                                :on-remove="removeJuridical"
                                                :on-progress="handleUploadProgress"
                                            >
                                            </el-upload>
                                        </div>
                                    </div>
                                    <span v-if="ruleForm.juridical_file.path.length == 0 && showErr" class="error">Vui lòng tải lên file giấy tờ</span>
                                </div>
                                <div class="col-12 position-relative mb-2">
                                    <p class="form-label" style="font-size: 18px; font-weight: 600;">Mô tả bổ sung</p>
                                    <div class="p-md-2 pr-0">
                                        <div v-for="(item, index) in cateDetail.describe" :key="index" v-if="listDescribe.length !== 0">
                                            <div class="d-flex flex-wrap justify-content-between mb-2" v-if="item.info_type == 1">
                                                <div class="tt">
                                                    <div class="tt_img">
                                                        <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                    </div>
                                                    <span>{{ item.describe_name }}</span>
                                                </div>
                                                <el-input-number :min="0" :max="10000" v-model="listDescribe[index].value"></el-input-number>
                                            </div>
                                            <div class="d-flex flex-wrap justify-content-between mb-2" v-else-if="item.info_type == 2">
                                                <div class="tt">
                                                    <div class="tt_img">
                                                        <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                    </div>
                                                    <span>{{ item.describe_name }}</span>
                                                </div>
                                                <el-input type="text" class="form-control" maxlength="250" style="width: 67%;" v-model="listDescribe[index].value" placeholder="Nhập"/>
                                            </div>
                                            <div class="d-md-flex justify-content-between mb-2" v-else-if="item.info_type == 3">
                                                <div class="tt" style="align-items: flex-start;">
                                                    <div class="tt_img">
                                                        <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                    </div>
                                                    <span>{{ item.describe_name }}</span>
                                                </div>
                                                <div class="d-flex flex-wrap" style="width: 67%;">
                                                    <button type="button" v-for="data in item.value" class="btn-option-1" style="width: 150px;"
                                                        @click="listDescribe[index].value = data.properties_id"
                                                        :class="{'active' : listDescribe[index].value == data.properties_id}"
                                                    >
                                                        {{ data.properties_name }}
                                                    </button>
                                                    <textarea name="title" class="form-control" placeholder="Nhập mô tả"
                                                        @input="this.adjustTextareaHeight"
                                                        v-model="listDescribe[index].description"
                                                        maxlength="250"
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div class="d-md-flex justify-content-between" v-else>
                                                <div class="tt">
                                                    <div class="tt_img">
                                                        <img :src="$storage + item.icon" style="width: 32px; height: 32px;" alt="" @error="handleError">
                                                    </div>
                                                    <span>{{ item.describe_name }}</span>
                                                </div>
                                                <el-form-item prop="direction" style="width: 67%;">
                                                    <el-select filterable clearable class="form-control" v-model="listDescribe[index].value" placeholder="Chọn">
                                                        <el-option v-for="data in item.value"
                                                            :key="data.properties_id"
                                                            :label="data.properties_name"
                                                            :value="data.properties_id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <reviewUI :dataReview="ruleForm" :cate="cate_name" :detail_cate="this.cateDetail" :list_des="listDescribe"/>
                </div>
            </el-col>

            <el-col :span="24">
                <div class="type_news">
                    <p class="ttl">Chọn loại tin đăng</p>
                    <div class="d-flex align-items-center flex-wrap" style="margin-bottom: 20px;">
                        <div class="type_news_item" 
                            :class="{'active' : ruleForm.rank == item.id}"
                            @click="ruleForm.rank = item.id"
                            v-for="(item, index) in list_rank" :key="index"
                            :style="{ 'background': item.background_color }"
                        >
                            <p>{{ item.name }}</p>
                            <p>từ {{ parseInt(item.price).toLocaleString('vi') }}đ/ngày</p>
                        </div>
                    </div>
                    <p class="ttl">Số ngày đăng</p>
                    <div class="d-flex align-items-center flex-wrap">
                        <div class="day_item" v-for="(item, index) in list_package" :key="index" 
                            :class="{'active' : ruleForm.fee == item.id}"
                            @click="ruleForm.fee = item.id"
                        >
                            <p>{{ item.day }} ngày</p>
                            <p>0đ/ngày</p>
                        </div>
                    </div>
                </div>
            </el-col>

            <img src="@/assets/icons/back_top_ico.png" alt="" class="backtop" @click="scrollTop" :class="{'d-none': this.hidden}">
        </el-row>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import reviewUI from "../components/reviewUI/review.vue";
import GMAPS from "../../components/goong_maps.vue"
import axios from 'axios';
import number2vn from 'number2vn';
export default defineComponent ({
    components: { draggable: VueDraggableNext, reviewUI, GMAPS },
    data() {
        return {
            ruleForm: {
                category_id: '',
                type: '',
                price: "",
                district: "",
                district_id: "",
                district_name: "",
                ward: "",
                ward_id: "",
                ward_name: "",
                province: "",
                province_id: "",
                province_name: "",
                address: '',
                project_id: '',
                name: '',
                description: '',
                design: '',
                acreage: '',
                unit_id: '',
                longtitude: '',
                latitude: '',
                ward_longtitude: '',
                ward_latitude: '',
                video:'',
                publish_status: 1,
                image: [],
                design_file: [],
                juridical_file: {
                    'juridical_id': '',
                    'path': []
                },
                describe: [],
                rank: 1,
                fee: 1
            },
            dataCity: [],
            dataDistricts: [],
            dataWard: [],
            rules: {
                category_id: [{required: true, message: "Vui lòng chọn danh mục", trigger: ["blur", "change"]}],
                province: [{required: true, message: "Vui lòng chọn tỉnh/thành phố", trigger: ["blur", "change"]}],
                district: [{required: true, message: "Vui lòng chọn quận/huyện", trigger: ["blur", "change"]}],
                ward: [{required: true, message: "Vui lòng chọn phường/xã", trigger: ["blur", "change"]}],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                name: [
                    {required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]},
                    {min: 30, message: "Tiêu đề tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                    {max: 99, message: "Tiêu đề tối đa 99 ký tự", trigger: ["blur", "change"]},
                ],
                description: [
                    {required: true, message: "Vui lòng nhập mô tả", trigger: ["blur", "change"]},
                    {min: 30, message: "Mô tả tối thiểu 30 ký tự", trigger: ["blur", "change"]},
                ],
                acreage: [{required: true, message: "Vui lòng nhập diện tích", trigger: ["blur", "change"]}],
                price: [{required: true, message: "Vui lòng nhập giá", trigger: ["blur", "change"]}],
                unit_id: [{required: true, message: "Vui lòng chọn đơn vị", trigger: ["blur", "change"]}],
            },
            keyActivePaper: null,
            listCate: [],
            showErr: false,
            listUnit: [],
            cateDetail: [],
            cate_name: '',
            hidden: '',
            list_rank: [],
            list_package: [],
            list_project: [],
            listDescribe: []
        }
    },
    mounted(){
        this.getDataCity()
        this.getUnit()
        this.getListRank()
        this.getPackage()
        this.handleScroll()
        this.getListProject()
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
        numberInWords() {
            return number2vn(parseInt(this.ruleForm.price.replace(/[^\d]/g, '')));
        },
    },
    methods: {
        uploadFileImage(res, file, fileList, type) {
            this.endLoading()
            let fileItem = {
                path : res.list_result[0].path,
                path_tam : res.list_result[0].path_tam,
                is_360 : false,
                check360: res.list_result[0].is_360
            }
            if(type == 'image') {
                this.ruleForm.image.push(fileItem)
            } else if(type == 'design_file') {
                this.ruleForm.design_file.push(res.list_result[0].path)
            } else {
                this.ruleForm.juridical_file.path.push(res.list_result[0].path)
            }
        },
        async uploadReview(id){
            this.keyActivePaper = id
            this.ruleForm.juridical_file.juridical_id = id
        },
        async changeCity() {
            this.dataDistricts = await this.callApiDistrict(JSON.parse(this.ruleForm.province).code)
            this.ruleForm.district = this.ruleForm.ward = ''
            this.dataWard = []
        },
        async changeDistrict() {
            this.dataWard = await this.callApiWard(JSON.parse(this.ruleForm.district).code)
            this.ruleForm.ward = ''
        },
        async getUnit() {
            this.listUnit = await this.getListUnit()
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.ruleForm.image.length == 0 || this.ruleForm.image.length < 4 || this.ruleForm.image.length > 20 || !this.ruleForm.juridical_file.juridical_id || this.ruleForm.juridical_file.path.length == 0 || !this.checkYoutube(this.ruleForm.video)) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.ruleForm.province_name = JSON.parse(this.ruleForm.province).name
                    this.ruleForm.district_name = JSON.parse(this.ruleForm.district).name
                    this.ruleForm.ward_name = JSON.parse(this.ruleForm.ward).name
                    this.ruleForm.province_id = JSON.parse(this.ruleForm.province).code
                    this.ruleForm.district_id = JSON.parse(this.ruleForm.district).code
                    this.ruleForm.ward_id = JSON.parse(this.ruleForm.ward).code
                    if(this.ruleForm.unit_id == 5) {
                        this.ruleForm.price = ''
                    } else {
                        this.ruleForm.price = this.ruleForm.price.replace(/[^\d]/g, '')
                    }
                    this.ruleForm.describe = this.listDescribe.filter(item => !this.isEmptyCustom(item.value))
                    this.startLoading();
                    await this.$axios.post('/real_estate/createRealEstate', this.ruleForm, {
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    }).then(res => {
                        this.showNotify("success", "Thành công", "Tạo tin bất động sản thành công!");
                        this.$router.push('/quan-ly-bds/danh-sach-tin-bds')
                    }).catch(err => {
                        if(err.response) {
                            if(err.response.status === 401) {
                                this.handleCheckTokenAuth();
                            } else {
                                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                            }
                        }
                    })
                    this.endLoading();
                }
            });
        },
        handleUploadProgress(event, file, fileList) {
            this.startLoading()
        },
        handleRemove(file) {
            this.ruleForm.design_file = this.ruleForm.design_file.filter( item => item !== file.response.list_result[0].path)
        },
        removeJuridical(file) {
            this.ruleForm.juridical_file.path = this.ruleForm.juridical_file.path.filter( item => item !== file.response.list_result[0].path)
        },
        async getListCate(params) {
            this.ruleForm.type = params
            this.startLoading();
            await this.$axios.get('/real_estate/getListCategory',{
                params: {
                    type: params,
                    name: '',
                    page: 1,
                    per_page: 100
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listCate = res.data.result;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async changeCate(id) {
            this.listDescribe = []
            this.startLoading()
            this.cateDetail = await this.getCateDetail(id)
            this.endLoading()
            this.cateDetail.describe.forEach(e => {
                if (e.info_type != 1) {
                    this.listDescribe.push({
                        describe_id: e.describe_id,
                        value: '',
                        description: ''
                    })
                }else{
                    this.listDescribe.push({
                        describe_id: e.describe_id,
                        value: 0,
                        description: ''
                    })
                }
            });
        },
        async getListRank() {
            this.startLoading();
            await this.$axios.get('/real_estate/getListRank',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_rank = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getPackage() {
            this.startLoading();
            await this.$axios.get('/real_estate/getListRankPackage',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_package = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListProject() {
            this.startLoading();
            await this.$axios.get('/project/getListProjectForMember',{
                params: {
                    order: 'latest',
                    page: '1',
                    per_page: '100000',
                    name: '',
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_project = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getMaps() {
            let address = this.ruleForm.address + ', ' + JSON.parse(this.ruleForm.ward).name + ', ' + JSON.parse(this.ruleForm.district).name + ', ' + JSON.parse(this.ruleForm.province).name
            await this.$axios.get('/common/callAPIGoong',{
                params: {
                    address: address,
                },
            }).then(res => {
                this.ruleForm.longtitude = res.data.results[0].geometry.location.lng
                this.ruleForm.latitude = res.data.results[0].geometry.location.lat
            })
        },
        async chooseWard() {
            let address = JSON.parse(this.ruleForm.ward).name + ', ' + JSON.parse(this.ruleForm.district).name + ', ' + JSON.parse(this.ruleForm.province).name
            await this.$axios.get('/common/callAPIGoong',{
                params: {
                    address: address,
                },
            }).then(res => {
                this.ruleForm.ward_longtitude = res.data.results[0].geometry.location.lng
                this.ruleForm.ward_latitude = res.data.results[0].geometry.location.lat
            })
        },
        changeProj(pro_id) {
            if(pro_id) {
                this.list_project.forEach(element => {
                    if(pro_id == element.project_id) {
                        this.ruleForm.longtitude = element.longtitude
                        this.ruleForm.latitude = element.latitude
                    }
                });
            } else {
                this.getMaps()
            }
        },
    }
})
</script>
<style scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-btn button.btn_add {
        background: #F58E16;
        margin-left: 16px;
        color: #fff;
        border: none;
    }

    .wp-btn button {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #515151;
        background: none;
    }

    .content-form {
        display: flex;
        flex-wrap: wrap;
        width: 832px;
        background: #FFF;
        padding: 15px 20px;
        margin: 0;
        border-bottom: 1px solid #E5E5E5;
    }

    .content-form:last-child {
        border-bottom: none;
    }

    .title-2 {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .form-label.title-4 {
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .form-label.title-3{
        font-weight: 400;
        font-size: 16px;
        color: #515151;
        padding: 5px 0;
    }
    .small {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        padding-left: 10px;
    }

    .news-type {
        border-radius: 15px;
        background: #EEE;
        min-width: 178.889px;
        height: 38px;
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        border: unset;
    }

    .news-type.active {
        background: #F58E16;
        color: #FFF;
    }

    .verifi-img {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        padding-left: 1.5rem;
    }

    button.img-360 {
        width: 226px;
        height: 40.39px;
        padding: 5px;
        border-radius: 27px;
        background: #515151;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        border: unset;
    }

    button.img-360 img {
        margin-right: 10px;

    }

    .el-upload__text {
        color: #515151;
        font-size: 15px;
        font-weight: 400;
    }

    .el-upload__tip {
        color: #B4B4B4;
        font-size: 13px;
        font-weight: 400;
    }

    .el-upload-dragger {
        border-radius: 20px;
        background: #F8F8F8;
    }
      .el-upload-dragger:hover {
        border-color: #515151;
    }
    .youtube{
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        background: #F8F8F8;
        height: 63px;
        color: #515151;
        justify-content: space-between;
        text-decoration: none;
        font-weight: 500;
    }
    .youtube img{
        margin: 0 13px;
    }
    .card-image{
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 2.11856px 0px rgba(0, 0, 0, 0.25);
        padding: 5px;
        margin: 6px;
        position: relative;
    }

    .card-image .img_ava {
        position: absolute;
        right: 8px;
        bottom: 57px;
        color: #FFF;
        font-size: 11.959px;
        font-weight: 500;
        border-radius: 11.211px;
        background: rgba(89, 89, 89, 0.66);
        backdrop-filter: blur(2px);
        padding: 3px 24px;
    }

    .card-image img.main{
        width: 240.408px;
        height: 152.831px;
        border-radius: 5.412px;
        border-radius: 10px;
        object-fit: cover;
    }
    .card-image .foot{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
    .card-image button{
        border: unset;
        background: unset;
        padding: 0;
    }
    .form-check-input:checked{
        background-color: #EE3439;
    }
    .form-check-input:focus, .form-check-input:focus-visible{
        border-color: unset;
        outline: 0;
        box-shadow: unset;
    }
    .form-switch .form-check-input{
        width: 40px;
        height: 20px;
    }
    .form-switch span{
        height: 20px;
        padding-left: 3px;
    }
    .form-switch{
        display: flex;
        align-items: center;
    }
    .link-youtube{
        position: relative;
    }
    .link-youtube input{
        height: 59px;
        border-radius: 20px;
        border: 1px solid #B7B7B7;
        width: 100%;
        padding: 0 0 0 60px;
        color: #515151;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
    }

    .link-youtube input:focus-visible {
        box-shadow: none;
        outline: none;
    }
    .link-youtube span{
        color: #B4B4B4;
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        left: 10px;
        top: 30%;
    }
    .choose-file{
        position: relative;
        display: flex;
    }
    .choose-file button{
        width: 100px;
        height: 32px;
        border-radius: 15px;
        background: #444;
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        border: unset;
        justify-content: center;
    }
    .choose-file input{
        margin-left: 7px;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 16px;
        font-weight: 400;
        height: 36px;
        padding: 5px 10px;
    }
    ::-webkit-file-upload-button {
    display: none;
    }
    .review-img{
        position: relative;
    }
    .review-img img{
        width: 125px;
        height: 80px;
        border-radius: 10px;
        border: 1px solid #515151;
        margin-right: 5px;
        margin-top: 5px;
    }
    .review-img span{
        position: absolute;
        right: 10px;
        top: 0px;
        color: #EE3439;
        cursor: pointer;
    }
    .unit{
        position: absolute;
        bottom: 7px;
        right: 12px;
    }
    .btn-option-1{
        width: 178.537px;
        height: 36px;
        border-radius: 15px;
        background: #f6f6f6;
        border: unset;
        margin-right: 15px;
        margin-bottom: 15px;
        color: #515151;
    }
    .btn-option-1.active{
        background: #FFA8A8;
    }

    .tt{
        color: #515151;
        font-size: 16px;
        font-weight: 500;
        width: auto;
        display: flex;
        align-items: center;
        min-width: 250px;
    }

    .tt .tt_img{
        width: 41px;
    }

    .form-control{
        border-radius: 15px;
    }
    
    h4 {
        color: #515151;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        padding: 15px 10px 10px 10px;
        margin: 0;
    }

    .content {
        display: flex;
        width: -webkit-fill-available;
        justify-content: center;
        background-color: #F6F6F6;
    }

    .form-control {
        border-radius: 74px;
        background: #F6F6F6;
        height: 46px;
        border: unset;
        color: #515151;
        font-size: 16px;
        font-weight: 500;
    }

    .form-control:disabled {
        background-color: #fff;
    }

    .form-control::placeholder {
        color: #B4B4B4;
        font-size: 14px;
        font-weight: 400;
    }

    .form-control:focus {
        box-shadow: 0 0 0 1px #00000000;
        color: #515151;
        background: #F6F6F6;
    }

    select.form-control {
        padding-left: 20px;
    }

    select.form-control::after {
        padding-left: 20px;
    }

    textarea.form-control {
        padding: 10px 20px;
        min-height: 81px;
        border-radius: 20px;
        resize: none;
        font-family: Roboto;
        color: #515151;
        font-size: 16px;
        font-weight: 500;
    }

    label.form-label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        /* min-width: 90px; */
    }

    .form-switch .form-check-input:focus {
        border: none;
    }

    .form-check-input:checked {
        border-color: #00000000
    }

    .type_news {
        width: 832px;
        margin: 0 auto;
        background: #FFF;
        border-radius: 20px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px 25px 32px;
    }

    .type_news .ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .type_news_item {
        background-repeat: no-repeat;
        background-size: cover;
        min-width: 190px;
        min-height: 107px;
        cursor: pointer;
        text-align: center;
        border-radius: 18px;
        margin-bottom: 10px;
        margin-right: 5px;
    }

    .type_news_item p {
        margin: 0;
        color: #515151;
    }

    .type_news_item p:last-child {
        font-weight: 500;
    }
    .type_news_item p:first-child {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        padding-top: 11px;
    }

    .day_item.active,
    .type_news_item.active {
        border: 2px solid #F13A3A;
    }

    .type_news_item.active {
        border-radius: 18px;
    }
    .day_item {
        text-align: center;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        width: 186px;
        padding: 6px;
        cursor: pointer;
        margin-right: 9px;
        margin-bottom: 10px;
    }

    .day_item p {
        margin-bottom: 0;
        color: #515151;
        font-size: 16px;
        font-weight: 600
    }

    .el-form-item.is-error .form-control {
        box-shadow: 0 0 0 1px var(--el-color-danger) inset;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }

    .text_price {
        text-transform: capitalize;
        font-size: 15px;
        color: #515151;
    }

@media (min-width: 678px) {
    .content {
        padding: 25px 16px;
    }

    h4 {
        font-size: 24px;
        padding: 20px 23px;
    }

    label.form-label {
        font-size: 16px;
    }
}
@media (min-width: 768px) {
    .col-md-6 .form-control {
        width: 98%;
    }

    .form {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        position: relative;
        padding-top: 20px;
    }
}
</style>
<style>
    .add_bds .form-control .el-input__wrapper,
    .add_bds .form-control .el-input,
    .add_bds .form-control .el-input.is-focus .el-input__wrapper {
        height: 100%;
        width: 100%;
        background-color: #00000000;
        border: unset;
        box-shadow: unset !important;
        font-size: normal;
        font-weight: normal;
    }

    .add_bds .form-control.el-select .el-input__wrapper.is-focus {
        box-shadow: none !important;
    }

    .add_bds .el-input-number__decrease,
    .add_bds .el-input-number__increase {
        border-radius: 15px;
        background: #C8C8C8;
        color: #515151;
        text-align: center;
        font-family: Roboto;
        font-weight: 700;
        width: 39px;
        height: 39px;
    }

    .add_bds .el-input-number {
        width: 220px;
    }

    .add_bds .el-input .el-input__wrapper {
        border: unset;
        box-shadow: unset !important;
    }

    .add_bds .el-input-number .el-input .el-input__inner {
        width: 132px;
        height: 39px;
        border-radius: 15px;
        background: #EEE;
    }

    .add_bds .el-input-number__increase:hover,
    .add_bds .el-input-number__decrease:hover {
        color: #515151;
    }

    .modal-backdrop {
        width: 100% !important;
        height: 100% !important;
    }

    .add_bds .el-upload-list__item.is-success {
        border-radius: 10px;
        border: 1px solid #515151;
        max-height: 80px;
        max-width: 125px;
        margin-top: 50px;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-thumbnail {
        object-fit: unset;
    }

    .add_bds .choose-file .el-upload {
        width: 100px;
        height: 32px;
        border-radius: 15px;
        background: rgb(68, 68, 68);
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        border: unset;
        justify-content: center;
        order: -1;
        position: relative;
    }

    .add_bds .choose-file .el-upload::before {
        content: 'Chọn File';
    }
    .add_bds .choose-file .el-upload::after {
        content: 'png, jpeg, jpg';
        right: -326px;
        position: absolute;
        width: 312px;
        height: 32px;
        border-radius: 15px;
        background: #F6F6F6;
        color: #515151;
        font-size: 16px;
        padding: 0 14px;
        display: flex;
        align-items: center;
        font-weight: 400;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-actions:hover span.el-upload-list__item-preview {
        display: none;
    }

    .add_bds .el-upload-list--picture-card .el-upload-list__item-actions:hover span.el-upload-list__item-delete {
        margin-left: 0;
    }

    .add_bds .el-upload-list__item.is-success:first-child {
        margin-left: -100px;
    }

    .add_bds .el-form-item__label {
        font-size: 16px;
        color: #515151;
    }

    .backtop {
        position: fixed;
        cursor: pointer;
        right: 56px;
        bottom: 20px;
    }
</style>