<template>
    <div class="wp-confirm">
        <h5>Danh sách trả giá bất động sản</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh sách tin bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Chi tiết tin đăng</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Danh sách trả giá bất động sản</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 15px;">
            <el-col :span="12">
                <span style="color: #515151;">Số lượt trả: <span style="font-size: 18px; font-weight: 600;">{{ page.totalData }}</span></span>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        {{ textOrder }}
                        <img src="@/assets/icons/caret-right.png" alt="">
                    </span>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="order = 'lowest', textOrder = 'Giá trả thấp đến cao', setPage(1)">Giá trả thấp đến cao</el-dropdown-item>
                        <el-dropdown-item @click="order = 'highest', textOrder = 'Giá trả cao đến thấp', setPage(1)">Giá trả cao đến thấp</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh đại diện" width="121">
                        <template #default="scope">
                            <img :src="$storage + scope.row.avatar" alt="" style="width: 50px; height: 50px; border-radius: 25px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="full_name" label="Họ tên khách hàng" width="339"/>
                    <el-table-column header-align="center" align="center" label="Mã khách hàng" width="166">
                        <template #default="scope">
                            <div class="table_code" v-if="scope.row.member_code">#{{ scope.row.member_code }}</div>
                            <div class="table_code" v-else>------</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="phone" label="Số điện thoại" min-width="178"/>
                    <el-table-column header-align="center" align="center" prop="email" label="Email" min-width="375" />
                    <el-table-column header-align="center" align="center" prop="price" label="Giá trả" width="238">
                        <template #default="scope">
                            <span class="price">{{ parseInt(scope.row.price).toLocaleString('vi') }} {{ scope.row.unit_name }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            order: 'highest',
            textOrder: 'Sắp xếp'
        }
    },
    mounted() {
        this.getListBid()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListBid()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListBid()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getListBid() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/real_estate/getListPaymentForAdmin',{
                params: {
                    real_estate_id: this.decode(this.$route.params.id),
                    order: this.order,
                    page: data.page,
                    per_page: data.per_page,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 15px 0 18px;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .btn_filters {
        border-radius: 15px;
        border: 1px solid #E0E2E7;
        background: #FFF;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        max-height: 40px;
        margin-left: 16px;
    }

    .btn_filters span {
        font-size: 14px;
        font-weight: 500;
        color: #515151;
        margin-left: 10px;
    }

    .filter {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11px 12.2px 17.6px 12px;
        position: absolute;
        top: 48px;
        z-index: 10;
    }

    .filter p {
        margin-bottom: 0;
        color: #686868;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .filter span {
        color: #515151;
    }

    .filter p.ttl {
        font-size: 18px;
        font-weight: 600;
        color: #515151;
        margin-bottom: 5px;
    }

    .filter button {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
        width: 295px;
        height: 39px;
        padding: 0 21px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .list-cate {
        padding: 15px 12px 0;
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        position: absolute;
        top: 48px;
        z-index: 10;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .list-cate .ttl {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 16px;
    }
    
    .list-cate ul {
        list-style: none;
        padding: 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .list-cate ul::-webkit-scrollbar {
        width: 0;
    }

    .list-cate ul li {
        border-radius: 32px;
        background: #F4F4F4;
        height: 35px;
        margin-bottom: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        cursor: pointer;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    p.rank {
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        width: 90px;
        height: 26px;
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p.rank--dm {
        background: #1DC3CE;
        color: #FFF;
        border: none;
    }
    p.rank--gd {
        background: #FF9838;
        color: #FFF;
        border: none;
    }

    p.rank--sl {
        background: #A1A1A1;
        color: #FFF;
        border: none;
    }

    .table_code {
        color: #2B97E9;
        font-weight: 600;
        font-size: 14px;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .el-dropdown {
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        width: 221px;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 14px;
    }

    .el-dropdown span {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .el-dropdown img {
        filter: brightness(0.5);
        rotate: 90deg;
    }

    span.price {
        color: #F13A3A;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
    }
</style>

<style>
    .wp-confirm .el-input__wrapper,
    .wp-confirm .el-input__wrapper:hover,
    .wp-confirm .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-confirm .el-form .el-form-item {
        margin-bottom: 0;
    }

    .filter .el-date-editor.el-input {
        width: 100%;
        height: 39px;
    }

    .filter .el-date-editor.el-input .el-input__wrapper {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
    }

    .wp-confirm .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-confirm .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .wp-paginate .el-pager li.is-active {
        background: #F58E16 !important;
        color: #FFF;
    }

    .wp-paginate .el-pager li {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
    }

    .wp-paginate .el-pager li:hover,
    .wp-paginate button.btn-prev:hover,
    .wp-paginate .is-last:hover {
        color: #F58E16;
    }

    .wp-paginate .is-last,
    .wp-paginate button.btn-prev {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
    }

    .datePicker .el-date-picker__header-label {
        z-index: -1;
        position: relative;
    }

    .datePicker .el-date-picker__header {
        z-index: 2;
        position: relative;
    }
</style>