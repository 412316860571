<template>
    <div class="Pagination" v-if="data.totalPage > 1">
        <el-pagination
            background
            layout="sizes, prev, pager, next"
            :pager-count="5"
            :page-count=data.totalPage
            :page-size=data.pageSize
            :total=data.totalData
            :current-page=data.currentPage
            :page-sizes="[50, 100, 150, 200]"
            @current-change="setPage"
            @size-change="handleSizeChange"
        >
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: ["page"],
    data() {
        return {
            data: this.page,
        };
    },
    methods : {
        setPage (val) {
            this.$emit("set-page", val);
        },
        handleSizeChange(val) {
            this.$emit("setPerPage", val);
        }
    }
};
</script>

<style>
    .Pagination .el-input__wrapper {
        padding: 10px !important;
    }
</style>