<template>
    <div>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <div>
                <h5>Quản lý công việc</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý công việc</span>
                </div>
            </div>
            <button class="btn_add" @click="dialogVisible = true">
                <img src="@/assets/icons/task.png" alt="">
                Danh sách tương tác
            </button>
        </div>

        <el-row>
            <el-col :span="24">
                <div class="type">
                    <p @click="selectTab(1)" :class="{'active' : typePage == 1}">Cá nhân</p>
                    <p @click="selectTab(2)" :class="{'active' : typePage == 2}" v-if="team_id">Team</p>
                    <p @click="typePage = 2" :class="{'active' : typePage == 2}" v-else>Team</p>
                </div>
                <div class="wp-job" v-if="typePage == 2 && !team_id">
                    <h5>Chọn team muốn xem</h5>
                    <ul class="list_team">
                        <li v-for="team in listTeam" @click="selectTeam(team.id, team.name)">{{ team.name }}</li>
                    </ul>
                </div>
                <div class="wp-job" v-if="typePage == 1 || (typePage == 2 && team_id)">
                    <button v-if="typePage == 2 && team_id" @click="team_id = '', team_name = ''">Quay lại</button>
                    <h5 v-if="typePage == 1">Đầu việc của bạn</h5>
                    <h5 v-else>Quản lý đầu việc của team</h5>
                    <h5 v-if="typePage == 2 && team_id">{{ team_name }}</h5>
                    <div class="text-end">
                        <button @click="redirectAdd">+ Thêm đầu việc mới</button>
                    </div>
                    <calender
                        :data="dataProps"
                        :dataCalender="dataCalender"
                        :type="typePage"
                        :teamId="team_id"
                        @deleteSuccess="deleteSuccess"
                        @filterChange="getDataFilter"
                        @selectDay="getDay"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
    <formInterac
        :status="dialogVisible"
        @handleCloseDialog="listenDialog"
    />
</template>

<script>
import calender from "@/pages/components/calender.vue"
import formInterac from "./dialog/interacList.vue"
export default {
    components: {
        calender,
        formInterac
    },
    data() {
        return {
            dialogVisible: false,
            typePage: 1,
            dataProps: [],
            dataCalender: [],
            dataFilter: {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            },
            team_id: '',
            team_name: '',
            listTeam: []
        }
    },
 
    mounted() {
       this.getDataJob()
       this.getDataCalender()
       this.getListTeam()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        selectTab(type) {
            this.typePage = type
            this.dataFilter = {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            }
            if(type == 1) {
                this.getDataJob()
            } else {
                this.getDataJobTeam()
            }
            this.getDataCalender()
        },
        selectTeam(id, name) {
            this.team_id = id
            this.team_name = name
            this.dataFilter = {
                kpi: '',
                job_status: '',
                active_id: '',
                level: '',
                start_date: '',
                end_date: '',
                user_id: ''
            }
            this.getDataJobTeam()
            this.getDataCalender()
        },
        redirectAdd() {
            if(this.typePage == 1) {
                this.$router.push('/cong-viec/quan-ly-cong-viec/tao-cong-viec-ca-nhan')
            } else {
                this.$router.push('/cong-viec/quan-ly-cong-viec/tao-cong-viec-team')
            }
        },
        deleteSuccess(start_date, end_date) {
            this.dataFilter.start_date = start_date
            this.dataFilter.end_date = end_date
            if(this.typePage == 1) {
                this.getDataJob()
            } else {
                this.getDataJobTeam()
            }
            this.getDataCalender()
        },
        getDataFilter(data) {
            this.dataFilter = data
            if(this.typePage == 1) {
                this.getDataJob()
            } else {
                this.getDataJobTeam()
            }
            this.getDataCalender()
        },
        getDay(data) {
            this.dataFilter.start_date = data
            this.dataFilter.end_date = data
            if(this.typePage == 1) {
                this.getDataJob()
            } else {
                this.getDataJobTeam()
            }
        },
        async getDataJob() {
            this.startLoading();
            await this.$axios.get('/job/getListJobForPerson',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataProps = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataJobTeam() {
            this.startLoading();
            await this.$axios.get('/job/getListJobForTeam',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    user_id: this.dataFilter.user_id,
                    team_id: this.team_id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataProps = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDataCalender() {
            this.startLoading();
            let user_id = JSON.parse(localStorage.getItem('admin')).id
            let team_id = this.team_id
            if(this.typePage == 1) {
                team_id = ''
            } else {
                user_id = ''
            }
            await this.$axios.get('/job/getListJob',{
                params: {
                    kpi: this.dataFilter.kpi,
                    job_status: this.dataFilter.job_status,
                    active_id: this.dataFilter.active_id,
                    level: this.dataFilter.level,
                    start_date: this.dataFilter.start_date || this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: this.dataFilter.end_date || this.$moment().endOf('month').format('YYYY-MM-DD'),
                    user_id: user_id,
                    team_id: team_id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataCalender = res.data.result
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getListTeam() {
            await this.$axios.get('/job/getListTeamForLeader',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listTeam = res.data
            })
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .btn_add {
        border-radius: 15px;
        background: #F58E16;
        font-family: 'Public Sans', sans-serif;
        height: 40px;
        width: 193px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .btn_add img {
        filter: brightness(20);
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    .type {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .type p {
        margin: 0;
        color: #515151;
        text-align: center;
        font-family: 'Public Sans', sans-serif;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.08px;
        width: 193px;
        cursor: pointer;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .type p.active {
        color: #F13A3A;
        background-color: #fff;
        border-radius: 20px 20px 0 0;
    }

    .wp-job {
        border-radius: 0px 20px 20px 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-job h5 {
        color: #515151;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.22px;
        text-align: center;
        margin-bottom: 20px;
    }

    .wp-job button {
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 20px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        padding: 8.5px 34px;
    }

    .list_team {
        list-style: none;
        margin: 0
    }

    .list_team li {
        cursor: pointer;
        font-size: 16px;
        color: #515151;
        font-weight: 500;
        padding: 20px;
        border-radius: 20px
    }

    .list_team li:hover {
        background-color: #FCE3C5;
    }
</style>