<template>
    <div class="wp-confirm">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Danh sách tin đăng bất động sản</h5>
                <div class="breadcrumb">
                    <span>Quản lý bất động sản</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Danh sách tin đăng bất động sản</span>
                </div>
            </div>
            <button class="add-new" @click="$router.push('/quan-ly-bds/danh-sach/tao-moi')"><img src="@/assets/icons/plus.png" alt=""> Tạo bất động sản mới</button>
        </div>
        <el-row class="align-items-center">
            <el-col :span="14" class="d-flex align-items-center flex-wrap" style="margin: 15px 0 18px;">
                <div class="wp-btn d-flex align-items-center">
                    <button :class="{'active' : btnActive == 0}" @click="type = 0, setPage(1), btnActive = 0">Tất cả</button>
                    <button :class="{'active' : btnActive == 1}" @click="type = 1, setPage(1), btnActive = 1">Rao bán</button>
                    <button :class="{'active' : btnActive == 2}" @click="type = 2, setPage(1), btnActive = 2">Cho thuê</button>
                </div>

                <div class="wp-btn d-flex align-items-center">
                    <button :class="{'active' : btnStatus == 0}" @click="news_status = -1, setPage(1), btnStatus = 0">Tất cả</button>
                    <button :class="{'active' : btnStatus == 1}" @click="news_status = 2, setPage(1), btnStatus = 1">Còn hạn</button>
                    <button :class="{'active' : btnStatus == 2}" @click="news_status = 3, setPage(1), btnStatus = 2">Hết hạn</button>
                </div>

                <div class="dropdown" style="margin-bottom: 10px;">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="agency" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ agency_name }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="agency">
                        <p style="font-size: 18px; font-weight: 500; margin-bottom: 14px; color: #515151;">Chọn chi nhánh</p>
                        <li>
                            <a class="dropdown-item" href="#" @click="agency_id = '', setPage(1), agency_name = 'Tất cả chi nhánh'">
                                Tất cả chi nhánh
                            </a>
                        </li>
                        <li v-for="item in list_agency.result">
                            <a class="dropdown-item" href="#" @click="agency_id = item.id, setPage(1), agency_name = item.agency_name">
                                {{ item.agency_name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="10" class="d-flex align-items-center justify-content-end" style="position: relative; margin-bottom: 10px;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>

                <button class="btn_filters" @click="actionFilter('showFilter')" ref="btnFilter">
                    <img src="@/assets/icons/filter.png" alt="">
                    <span>Filters</span>
                </button>
                <div class="filter" :class="{'d-none': !showFilter}" ref="formFilter">
                    <p class="ttl">Bộ lọc tin đăng</p>
                    <p>Chọn danh mục</p>
                    <button @click="actionFilter('showList')">
                        <span>{{ cate_name }}</span>
                        <img src="@/assets/icons/arrow.png" alt=""></button>
                    <p>Trạng thái BĐS</p>
                    <button @click="actionFilter('showListStatus')">
                        <span>{{ bds_status }}</span>
                        <img src="@/assets/icons/arrow.png" alt="">
                    </button>
                    <p>Hạng tin</p>
                    <button @click="actionFilter('showListRank')">
                        <span>{{ rank }}</span>
                        <img src="@/assets/icons/arrow.png" alt="">
                    </button>
                    <p>Loại hợp đồng</p>
                    <button @click="actionFilter('showListContract')">
                        <span>{{ contract }}</span>
                        <img src="@/assets/icons/arrow.png" alt="">
                    </button>
                    <p>Ngày bắt đầu</p>
                    <el-date-picker
                        v-model="start_date"
                        type="date"
                        placeholder="Chọn ngày"
                        popper-class="datePicker"
                        :disabled-date="disabledDate"
                        @change="setPage(1)"
                        value-format="YYYY-MM-DD"
                    />
                    <p>Ngày kết thúc</p>
                    <el-date-picker
                        v-model="end_date"
                        type="date"
                        placeholder="Chọn ngày"
                        popper-class="datePicker"
                        @change="setPage(1)"
                        value-format="YYYY-MM-DD"
                    />
                </div>
                <div class="list-cate" :class="{'d-none': !showList}">
                    <p class="ttl">Tất cả danh mục</p>
                    <ul>
                        <li @click="cate_id = '', setPage(1), cate_name = 'Tất cả'">Tất cả</li>
                        <li v-for="item in list_cate.result" :key="item.id" 
                            @click="cate_id = item.id, setPage(1), cate_name = item.category_name">
                            {{ item.category_name }}
                        </li>
                    </ul>
                </div>
                <div class="list-cate" :class="{'d-none': !showListStatus}">
                    <p class="ttl">Tất cả trạng thái</p>
                    <ul>
                        <li @click="status_news = '', setPage(1), bds_status = 'Tất cả'">Tất cả</li>
                        <li @click="status_news = 1, setPage(1),bds_status = 'Mở bán'">Mở bán</li>
                        <li @click="status_news = 2, setPage(1),bds_status = 'Đã bán'">Đã bán</li>
                    </ul>
                </div>
                <div class="list-cate" :class="{'d-none': !showListRank}">
                    <p class="ttl">Tất cả hạng tin</p>
                    <ul>
                        <li @click="rank_id = '', setPage(1), rank = 'Tất cả'">Tất cả</li>
                        <li @click="rank_id = item.id, setPage(1),rank = item.name" v-for="(item, index) in dataRank" :key="index">{{ item.name }}</li>
                    </ul>
                </div>
                <div class="list-cate" :class="{'d-none': !showListContract}">
                    <p class="ttl">Tất cả loại hợp đồng</p>
                    <ul>
                        <li @click="contract_type = '', setPage(1), contract = 'Tất cả'">Tất cả</li>
                        <li @click="contract_type = '1', setPage(1), contract = 'Ký trực tiếp'">Ký trực tiếp</li>
                        <li @click="contract_type = '2', setPage(1), contract = 'Liên kết'">Liên kết</li>
                    </ul>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh" width="93">
                        <template #default="scope">
                            <img :src="$storage + scope.row.thumbnail" alt="" style="width: 60px; height: 60px; border-radius: 15px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="real_estate_name" label="Tiêu đề tin đăng bất động sản" width="372">
                        <template #default="scope">
                            <p style="margin-bottom: 5px;">{{ scope.row.real_estate_name }}</p>
                            <div class="table_code" v-if="scope.row.real_estate_code">#{{ scope.row.real_estate_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="type" label="Loại" min-width="94">
                        <template #default="scope">
                            <span v-if="scope.row.type == 1">Rao bán</span>
                            <span v-else-if="scope.row.type == 2">Cho thuê</span>
                            <span v-else>Tất cả</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="category_name" label="Danh mục" min-width="162" />
                    <el-table-column header-align="center" align="center" prop="rank" label="Hạng tin" width="112" />
                    <el-table-column header-align="center" align="center" label="Bắt đầu" min-width="107">
                        <template #default="scope">
                            <span v-if="scope.row.start_date">{{ formateDate(scope.row.start_date) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Kết thúc" min-width="107">
                        <template #default="scope">
                           <span v-if="scope.row.end_date">{{ formateDate(scope.row.end_date) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Trạng thái tin" min-width="120">
                        <template #default="scope">
                            <p v-if="scope.row.news_status == 2 && scope.row.is_delete == 0" class="status-new">Còn hạn</p>
                            <p v-else-if="scope.row.news_status == 3 && scope.row.is_delete == 0" class="status-new status-new--expr">Hết hạn</p>
                            <p v-else-if="scope.row.is_delete == 1" class="status-new status-new--expr">Đã xóa</p>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Trạng thái BĐS" min-width="135">
                        <template #default="scope">
                            <!-- <p v-if="scope.row.real_estate_status == 0" style="color: #F58E16;">Chưa mở bán</p> -->
                            <span v-if="scope.row.real_estate_status == 1" style="color: #00B569;">Mở bán</span>
                            <span v-if="scope.row.real_estate_status == 2" style="color: #EE3439;">Đã bán</span>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" label="Action" min-width="124">
                        <template #default="scope">
                            <div class="d-flex align-items-center">
                                <el-switch
                                    v-model="scope.row.view_status"
                                    :active-value="1"
                                    class="ml-2"
                                    style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                                />
                                <button style="padding: 0 12px;"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    setup() {
        const disabledDate = (time) => {
            return time.getTime() > Date.now()
        }
        return {
            disabledDate,
        }
    },
    data() {
        return {
            btnActive: 0,
            btnStatus: 0,
            keySearch: "",
            news_status: -1,
            type: 0,
            status_news: '',
            agency_id: '',
            cate_id: '',
            rank_id: '',
            contract_type: '',
            start_date: '',
            end_date: '',
            list_cate: [],
            list_agency: [],
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            showFilter: false,
            showList: false,
            showListStatus: false,
            showListRank: false,
            showListContract: false,
            active: true,
            agency_name: 'Tất cả chi nhánh',
            cate_name: 'Tất cả',
            bds_status: 'Tất cả',
            rank: 'Tất cả',
            contract: 'Tất cả',
            dateStart: '',
            dateEnd: '',
            dataRank: []
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.getList()
        this.getAgency()
        this.getDataRank()
        this.dateStart = document.getElementsByClassName('datePicker')[0]
        this.dateEnd = document.getElementsByClassName('datePicker')[1]
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            if(!this.start_date) {
                this.start_date = ''
            }
            if(!this.end_date) {
                this.end_date = ''
            }
            this.getList()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            if(!this.start_date) {
                this.start_date = ''
            }
            if(!this.end_date) {
                this.end_date = ''
            }
            this.getList()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
        });
        },
        actionFilter(params) {
            if(params !== 'showFilter') {
                this.showFilter = false
            }
            this[params] = true
        },
        handleClickOutside() {
            if (!this.$refs.btnFilter.contains(event.target) && !this.$refs.formFilter.contains(event.target) && !this.dateStart.contains(event.target) && !this.dateEnd.contains(event.target)) {
                this.showFilter = this.showList = this.showListStatus = this.showListRank = this.showListContract = false
            }
        },
        redirectDetail(row) {
            // this.$router.push('/quan-ly-bds/danh-sach/chi-tiet/' + this.encode(row.id))
            window.open('/quan-ly-bds/danh-sach/chi-tiet/' + this.encode(row.id), '_blank');
        },
        async getList() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/real_estate/getListRealEstateForAdmin',{
                params: {
                    news_status: this.news_status,
                    type: this.type,
                    real_estate_status: this.status_news,
                    agency_id: this.agency_id,
                    category_id: this.cate_id,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    name: this.keySearch,
                    create_date: '',
                    rank: this.rank_id,
                    contract_type: this.contract_type,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getAgency() {
            this.list_agency = await this.getListAgency()
            this.list_cate = await this.getListCategory()
        },
        async getDataRank() {
            this.dataRank = await this.getListRank()
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .add-new {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        background: #F58E16;
        color: #FFF;
        padding: 10px 14px;
    }

    .add-new img {
        filter: brightness(10);
    }

    button {
        background: none;
        border: none;
    }

    .wp-btn {
        min-width: 320px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        margin: 0 10px 10px 0;
    }

    .wp-btn button {
        width: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #515151;
        height: 32px;
    }

    .wp-btn button.active {
        border-radius: 13px;
        background: rgba(245, 142, 22, 0.25);
        color: #F58E16;
    }

    .wp-btn--status button.active {
        color: #EE3439;
        background: rgba(241, 58, 58, 0.20);
    }

    .dropdown button {
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .btn_filters {
        border-radius: 15px;
        border: 1px solid #E0E2E7;
        background: #FFF;
        padding: 10px 14px;
        display: flex;
        align-items: center;
        max-height: 40px;
        margin-left: 16px;
    }

    .btn_filters span {
        font-size: 14px;
        font-weight: 500;
        color: #515151;
        margin-left: 10px;
    }

    .filter {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 11px 12.2px 17.6px 12px;
        position: absolute;
        top: 48px;
        z-index: 10;
    }

    .filter p {
        margin-bottom: 0;
        color: #686868;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .filter span {
        color: #515151;
    }

    .filter p.ttl {
        font-size: 18px;
        font-weight: 600;
        color: #515151;
        margin-bottom: 5px;
    }

    .filter button {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
        width: 295px;
        height: 39px;
        padding: 0 21px;
        font-size: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    .list-cate {
        padding: 15px 12px 0;
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        position: absolute;
        top: 48px;
        z-index: 10;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .list-cate .ttl {
        font-size: 18px;
        color: #515151;
        font-weight: 500;
        margin-bottom: 16px;
    }
    
    .list-cate ul {
        list-style: none;
        padding: 0;
        max-height: 500px;
        overflow-y: auto;
    }

    .list-cate ul::-webkit-scrollbar {
        width: 0;
    }

    .list-cate ul li {
        border-radius: 32px;
        background: #F4F4F4;
        height: 35px;
        margin-bottom: 8px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #515151;
        cursor: pointer;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    p.rank {
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        background: #FFF;
        width: 90px;
        height: 26px;
        margin-bottom: 0;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p.rank--dm {
        background: #1DC3CE;
        color: #FFF;
        border: none;
    }
    p.rank--gd {
        background: #FF9838;
        color: #FFF;
        border: none;
    }

    p.rank--sl {
        background: #A1A1A1;
        color: #FFF;
        border: none;
    }

    .status-new {
        border-radius: 15px;
        background: rgba(0, 181, 105, 0.20);
        width: 90px;
        height: 26px;
        margin-bottom: 0;
        margin-left: 10px;
        color: #00B569;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .status-new--expr {
        background: rgba(238, 52, 57, 0.20);
        color: #EE3439;
    }

    .table_code {
        color: #F58E16;
        font-weight: 600;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }
</style>

<style>
    .wp-confirm .el-input__wrapper,
    .wp-confirm .el-input__wrapper:hover,
    .wp-confirm .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .wp-confirm .el-form .el-form-item {
        margin-bottom: 0;
    }

    .filter .el-date-editor.el-input {
        width: 100%;
        height: 39px;
    }

    .filter .el-date-editor.el-input .el-input__wrapper {
        border-radius: 32px;
        background: #F4F4F4;
        border: none;
    }

    .wp-confirm .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .wp-confirm .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .wp-paginate .el-pager li.is-active {
        background: #F58E16 !important;
        color: #FFF;
    }

    .wp-paginate .el-pager li {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
    }

    .wp-paginate .el-pager li:hover,
    .wp-paginate button.btn-prev:hover,
    .wp-paginate .is-last:hover {
        color: #F58E16;
    }

    .wp-paginate .is-last,
    .wp-paginate button.btn-prev {
        border-radius: 8px;
        background: rgba(245, 142, 22, 0.19) !important;
        color: #F58E16;
    }
</style>