<template>
    <div class="wp-confirm">
        <h5>Duyệt nhân viên sale</h5>
        <div class="breadcrumb">
            <span>Quản lý nhân viên</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Duyệt nhân viên sale</span>
        </div>
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12"></el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" fixed label="Ảnh đại diện" width="124">
                        <template #default="scope">
                            <img :src="$storage + scope.row.avatar" alt="" style="width: 80px; height: 80px; border-radius: 62px; object-fit: cover;" @error="handleError">
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" prop="full_name" label="Họ tên nhân viên" min-width="250" />
                    <el-table-column header-align="center" align="center" label="Mã nhân viên" width="150">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.user_code }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="address" label="Địa chỉ" min-width="300"/>
                    <el-table-column header-align="center" align="center" prop="agency_name" label="Chi nhánh" min-width="170" />
                    <el-table-column header-align="center" align="center" prop="phone" label="Số điện thoại" width="135"/>
                    <el-table-column header-align="center" align="center" prop="position" label="Chức vụ" min-width="230"/>
                    <el-table-column header-align="center" align="center" label="Action" width="124">
                        <template #default="scope">
                            <div class="d-flex align-items-center justify-content-center">
                                <button><img src="@/assets/icons/trash.png" alt=""></button>
                                <button class="btn_approve">Duyệt</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
export default {
    components: {
        Pagination
    },
    data() {
        return {
            keySearch: '',
            agency_id: '',
            tableData: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
        }
    },
    mounted() {
        this.getListEmploy()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListEmploy()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListEmploy()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async getListEmploy() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/auth/getListUser',{
                params: {
                    user_name: this.keySearch,
                    agency_id: this.agency_id,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            this.$router.push('/quan-ly-nhan-vien/duyet-nhan-vien/chi-tiet/' + this.encode(row.user_id))
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .btn_approve {
        border-radius: 15px;
        background: #F58E16;
        padding: 10px 8px;
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-left: 5px;
    }
</style>