<template>
    <div class="wp-form-job">
        <h5>Quản lý công việc</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quản lý công việc</span>
        </div>

       <div class="wp-form">
           <el-form
               ref="formAdd"
               :model="formAdd"
               :rules="rules"
               status-icon
               @submit.prevent="submitForm('formAdd')"
           >
                <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                    <h6>Tạo đầu việc mới</h6>
                    <div>
                        <span class="check">Đầu việc tính KPI</span>
                        <el-switch
                            v-model="formAdd.add_kpi"
                            :active-value="1"
                            inactive-value="0"
                            class="ml-2"
                            style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                            :disabled="checkActive == 0"
                        />
                    </div>
                </div>
                <el-form-item prop="title" label="Tiêu đề">
                    <el-input type="text" v-model="formAdd.title" placeholder="Nhập tiêu đề"/>
                </el-form-item>
                <el-form-item prop="active_id" label="Đầu việc">
                    <el-select clearable v-model="formAdd.active_id" filterable placeholder="Chọn đầu việc" @change="changeJob">
                        <el-option
                            v-for="item in listActive"
                            :id="item.id"
                            :label="item.name"
                            :value="JSON.stringify(item)"
                            :disabled="item.validate_customer == 0 && typePage == 1"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="level" label="Mức độ ưu tiên">
                    <el-select clearable v-model="formAdd.level" filterable placeholder="Chọn mức độ ưu tiên">
                        <el-option id="1" label="Không quan trọng" value="1"></el-option>
                        <el-option id="2" label="Bình thường" value="2"></el-option>
                        <el-option id="3" label="Quan trọng" value="3"></el-option>
                        <el-option id="4" label="Rất quan trọng" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <p class="label label--req">Thời gian bắt đầu</p>
                <div class="d-flex justify-content-between">
                    <el-form-item class="input_space" prop="timeStart" style="flex-basis: 50%;">
                        <el-time-picker
                            v-model="formAdd.timeStart"
                            placeholder="Chọn thời gian bắt đầu"
                            value-format="HH:mm"
                            format="HH:mm"
                        />
                    </el-form-item>
                    <el-form-item class="input_space input_space--end" prop="dateStart" style="flex-basis: 50%;">
                        <el-date-picker
                            v-model="formAdd.dateStart"
                            type="date"
                            popper-class="datePicker"
                            placeholder="Chọn ngày bắt đầu"
                            value-format="YYYY-MM-DD"
                            format="DD-MM-YYYY"
                        />
                    </el-form-item>
                </div>
                <p class="label label--req">Thời gian kết thúc</p>
                <div class="d-flex justify-content-between">
                    <el-form-item class="input_space" prop="timeEnd" style="flex-basis: 50%;">
                        <el-time-picker
                            v-model="formAdd.timeEnd"
                            placeholder="Chọn thời gian kết thúc"
                            value-format="HH:mm"
                            format="HH:mm"
                        />
                    </el-form-item>
                    <el-form-item class="input_space input_space--end" prop="dateEnd" style="flex-basis: 50%;">
                        <el-date-picker
                            v-model="formAdd.dateEnd"
                            type="date"
                            popper-class="datePicker"
                            placeholder="Chọn ngày kết thúc"
                            value-format="YYYY-MM-DD"
                            format="DD-MM-YYYY"
                        />
                    </el-form-item>
                </div>
                <el-form-item prop="content" label="Nội dung">
                    <el-input type="textarea" v-model="formAdd.content" placeholder="Nhập nội dung"/>
                </el-form-item>
                <el-form-item prop="team_id" label="Team phụ trách" v-if="typePage == 3 && !isEmptyCustom(formAdd.active_id)">
                    <el-select clearable v-model="formAdd.team_id" filterable placeholder="Chọn team phụ trách" @change="changeTeam">
                        <el-option
                            v-for="item in listTeam"
                            :id="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <div style=" margin-bottom: 15px;" v-if="typePage == 1 || typePage == 2">
                    <p class="label label--req">Người phụ trách</p>
                    <div class="wp-user">
                        <div>
                            <img :src="$storage + userChoose.avatar" alt="" @error="handleError">
                            <span>{{ userChoose.full_name }}</span>
                            <span>-</span>
                            <span>{{ userChoose.user_code }}</span>
                        </div>
                    </div>
                </div>
                <div style=" margin-bottom: 15px;" v-else>
                    <p class="label label--req">Người phụ trách</p>
                    <div class="wp-user" @click="openDialog('user')" :class="{'wp-user--err' : showErr && isEmptyCustom(formAdd.user_id)}">
                        <span v-if="isEmptyCustom(userChoose)">Chọn người phụ trách</span>
                        <div v-else v-for="item_user in userChoose">
                            <img :src="$storage + item_user.avatar" alt="" @error="handleError">
                            <span>{{ item_user.full_name }}</span>
                            <span>-</span>
                            <span>{{ item_user.user_code }}</span>
                        </div>
                    </div>
                    <span class="error" v-if="showErr && isEmptyCustom(formAdd.user_id)">Vui lòng chọn người phụ trách</span>
                </div>
                <div v-if="(formAdd.active_id && JSON.parse(formAdd.active_id).validate_customer == 1) || typePage == 1">
                    <div style=" margin-bottom: 15px;" v-if="typePage == 1">
                        <p class="label label--req">Khách hàng</p>
                        <div class="wp-user">
                            <div>
                                <img :src="$storage + customerChoose.avatar" alt="" @error="handleError">
                                <span>{{ customerChoose.full_name }}</span>
                                <span>-</span>
                                <span>{{ customerChoose.phone }}</span>
                            </div>
                        </div>
                    </div>
                    <div style=" margin-bottom: 15px;" v-else>
                        <p class="label label--req">Khách hàng</p>
                        <div class="wp-user" @click="openDialog('customer')" :class="{'wp-user--err' : showErr && isEmptyCustom(formAdd.customer_id)}">
                            <span v-if="isEmptyCustom(customerChoose)">Chọn khách hàng</span>
                            <div v-else>
                                <img :src="$storage + customerChoose.avatar" alt="" @error="handleError">
                                <span>{{ customerChoose.full_name }}</span>
                                <span>-</span>
                                <span>{{ customerChoose.phone }}</span>
                            </div>
                        </div>
                        <span class="error" v-if="showErr && isEmptyCustom(formAdd.customer_id)">Vui lòng chọn khách hàng</span>
                    </div>
                </div>
                <el-form-item prop="address" label="Địa chỉ">
                    <el-input type="text" v-model="formAdd.address" placeholder="Nhập địa chỉ"/>
                </el-form-item>
                <div style="margin-bottom: 15px;">
                    <span class="check check--bold">Nhắc hẹn</span>
                    <el-switch
                        v-model="formAdd.reminder_status"
                        :active-value="1"
                        inactive-value="0"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                    />
                </div>
                <p class="label">Thời gian nhắc hẹn</p>
                <div class="d-flex justify-content-between">
                    <el-form-item class="input_space" prop="timeRemind" style="flex-basis: 50%;">
                        <el-time-picker
                            v-model="formAdd.timeRemind"
                            placeholder="Chọn thời gian nhắc hẹn"
                            value-format="HH:mm"
                            format="HH:mm"
                            :disabled="formAdd.reminder_status == 0"
                        />
                    </el-form-item>
                    <el-form-item class="input_space input_space--end" prop="dateRemind" style="flex-basis: 50%;">
                        <el-date-picker
                            v-model="formAdd.dateRemind"
                            type="date"
                            popper-class="datePicker"
                            placeholder="Chọn ngày nhắc hẹn"
                            value-format="YYYY-MM-DD"
                            format="DD-MM-YYYY"
                            :disabled="formAdd.reminder_status == 0"
                        />
                    </el-form-item>
                </div>
                <div class="wp-btn">
                    <button type="button" @click="$router.go(-1)">Hủy bỏ</button>
                    <button>Xác nhận</button>
                </div>
            </el-form>
        </div>
    </div>
    <ListUser
        :status="dialogVisible"
        :type="typeDialog"
        :data="dataProps"
        :active="formAdd.active_id"
        @handleCloseDialog="listenDialog"
        @pushData="getDataProps"
    />
</template>

<script>
import ListUser from './dialog/listUser.vue'
export default {
    components: {
        ListUser 
    },
    data() {
        return {
            formAdd: {
                add_kpi: '',
                title: '',
                active_id: '',
                customer_id: '',
                user_id: [],
                team_id: '',
                level: '',
                content: '',
                reminder_status: '',
                address: '',
                start_date: '',
                end_date: '',
                reminder_time: '',
                timeStart: '',
                dateStart: '',
                timeEnd: '',
                dateEnd: '',
                timeRemind: '',
                dateRemind: '',
                job_type: ''
            },
            rules: {
                title: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
                active_id: [{required: true, message: "Vui lòng chọn đầu việc", trigger: ["blur", "change"]}],
                team_id: [{required: true, message: "Vui lòng chọn team phụ trách", trigger: ["blur", "change"]}],
                level: [{required: true, message: "Vui lòng chọn mức độ ưu tiên", trigger: ["blur", "change"]}],
                content: [{required: true, message: "Vui lòng nhập nội dung", trigger: ["blur", "change"]}],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                timeStart: [{required: true, message: "Vui lòng chọn thời gian bắt đầu", trigger: ["blur", "change"]}],
                dateStart: [{required: true, message: "Vui lòng chọn ngày bắt đầu", trigger: ["blur", "change"]}],
                timeEnd: [{required: true, message: "Vui lòng chọn thời gian kết thúc", trigger: ["blur", "change"]}],
                dateEnd: [{required: true, message: "Vui lòng chọn ngày kết thúc", trigger: ["blur", "change"]}],
            },
            dialogVisible: false,
            listActive: [],
            typeDialog: '',
            dataProps: [],
            listUser: [],
            listCustomer: [],
            listTeam: [],
            userChoose: [],
            customerChoose: [],
            showErr: false,
            typePage: 0,
            checkActive: 0
        }
    },
    watch: {
        "$route": function (url) {
            if(url.path.includes('tao-cong-viec-khach-hang')) {
                this.typePage = 1
                this.formAdd.job_type = 1
                this.formAdd.customer_id = this.decode(url.params.cusId)
                this.getDetailCustomer()
            } else if(url.path.includes('tao-cong-viec-ca-nhan')) {
                this.typePage = 2
                this.formAdd.job_type = 2
                this.userChoose.avatar = JSON.parse(localStorage.getItem('admin')).avatar
                this.userChoose.user_code = JSON.parse(localStorage.getItem('admin')).username
                this.userChoose.full_name = JSON.parse(localStorage.getItem('admin')).full_name
                this.formAdd.user_id = JSON.parse(localStorage.getItem('admin')).id
                this.getListCustomer(this.formAdd.user_id)
            } else {
                this.typePage = 3
                this.formAdd.job_type = 3
                this.getListTeam()
            }
        },
    },
    mounted() {
        if(this.$route.path.includes('tao-cong-viec-khach-hang')) {
            this.typePage = 1
            this.formAdd.job_type = 1
            this.formAdd.customer_id = this.decode(this.$route.params.cusId)
            this.getDetailCustomer()
        } else if(this.$route.path.includes('tao-cong-viec-ca-nhan')) {
            this.typePage = 2
            this.formAdd.job_type = 2
            this.userChoose.avatar = JSON.parse(localStorage.getItem('admin')).avatar
            this.userChoose.user_code = JSON.parse(localStorage.getItem('admin')).username
            this.userChoose.full_name = JSON.parse(localStorage.getItem('admin')).full_name
            this.formAdd.user_id.push(JSON.parse(localStorage.getItem('admin')).id)
            this.getListCustomer(this.formAdd.user_id)
        } else {
            this.typePage = 3
            this.formAdd.job_type = 3
            this.getListTeam()
        }
        this.getListActive()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        changeTeam() {
            this.userChoose = []
            this.customerChoose = []
            this.formAdd.customer_id = ''
            this.formAdd.user_id = ''
            if(this.formAdd.team_id) {
                this.getUserTeam()
            } else {
                this.listUser = []
            }
        },
        async changeJob() {
            if(this.$route.path.includes('tao-cong-viec-team')) {
                this.userChoose = []
                this.customerChoose = []
                this.formAdd.customer_id = ''
                this.formAdd.user_id = ''
            }
            if(!this.isEmptyCustom(this.formAdd.active_id)) {
                this.startLoading();
                await this.$axios.get('/job/checkActiveKPI',{
                    params: {
                        active_id: JSON.parse(this.formAdd.active_id).id
                    },
                    headers: {
                        'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                    }
                }).then(res => {
                    this.checkActive = res.data.status
                }).catch(err => {
                    this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                })
                this.endLoading();
            }
        },
        getDataProps(type, data) {
            if(type == 'user') {
                this.userChoose = data
                this.formAdd.user_id = data.map(item => item.user_id)
                if(data.length == 1) {
                    this.getListCustomer(data[0].user_id)
                }
                this.customerChoose = []
                this.formAdd.customer_id = ''
            } else {
                this.customerChoose = data
                this.formAdd.customer_id = data.id
            }
        },
        openDialog(type) {
            this.dialogVisible = true
            this.typeDialog = type
            if(type == 'user') {
                this.dataProps = this.listUser
            } else {
                this.dataProps = this.listCustomer
            }
        },
        async getListActive() {
            await this.$axios.get('/job/getListActive', {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listActive = res.data
            })
        },
        async getUserTeam() {
            this.startLoading()
            await this.$axios.get('/job/getUserTeam',{
                params: {
                    id: this.formAdd.team_id,
                    name: '',
                    page: 1,
                    per_page: 1000,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listUser = res.data.result
            })
            this.endLoading()
        },
        async getListCustomer(id) {
            this.startLoading()
            await this.$axios.get('/job/getListCustomerForPerson',{
                params: {
                    full_name: '',
                    type: '',
                    system: '',
                    user_id: id,
                    page: 1,
                    per_page: 1000
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listCustomer = res.data.result
            })
            this.endLoading()
        },
        async getListTeam() {
            await this.$axios.get('/job/getListTeamForLeader',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listTeam = res.data
            })
        },
        async getDetailCustomer() {
            this.startLoading()
            await this.$axios.get('/job/getDetailCustomer',{
                params: {
                    id: this.formAdd.customer_id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.userChoose.avatar = res.data.sale_avatar
                this.userChoose.full_name = res.data.sale_name
                this.userChoose.user_code = res.data.sale_code
                this.formAdd.user_id = []
                this.formAdd.user_id.push(res.data.sale_id)
                this.customerChoose.avatar = res.data.avatar
                this.customerChoose.full_name = res.data.full_name
                this.customerChoose.phone = res.data.phone
            })
            this.endLoading()
        },
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid || this.isEmptyCustom(this.formAdd.user_id) || (this.isEmptyCustom(this.formAdd.customer_id) && JSON.parse(this.formAdd.active_id).validate_customer == 1)) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    if(this.formAdd.reminder_status != 0 && (this.isEmptyCustom(this.formAdd.timeRemind) || this.isEmptyCustom(this.formAdd.dateRemind))) {
                        this.showNotify("warning", "Lưu ý", "Vui lòng thêm thời gian nhắc hẹn!");
                    } else {
                        const data = {
                            address: this.formAdd.address,
                            add_kpi: this.formAdd.add_kpi,
                            active_id: JSON.parse(this.formAdd.active_id).id,
                            content: this.formAdd.content,
                            customer_id: this.formAdd.customer_id,
                            user_id: this.formAdd.user_id,
                            title: this.formAdd.title,
                            level: this.formAdd.level,
                            start_date: this.formAdd.dateStart + ' ' + this.formAdd.timeStart,
                            end_date: this.formAdd.dateEnd + ' ' + this.formAdd.timeEnd,
                            reminder_time: this.formAdd.dateRemind + ' ' + this.formAdd.timeRemind,
                            reminder_status: this.formAdd.reminder_status,
                            job_type: this.formAdd.job_type
                        }
                        if(data.reminder_status != 1) {
                            data.reminder_time = ''
                        }
                        this.startLoading();
                        await this.$axios.post('/job/createJob',data,{
                            headers: {
                                'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                            }
                        }).then(res => {
                            this.showNotify("success", "Thành công", "Tạo đầu việc mới thành công!");
                            this.turnOffErrorMessage(formName)
                            if(this.typePage == 3) {
                                this.customerChoose = []
                                this.userChoose = []
                                this.formAdd.user_id = ''
                                this.formAdd.customer_id = ''
                            } else if (this.typePage == 2) {
                                this.customerChoose = []
                                this.formAdd.customer_id = ''
                            }
                            this.formAdd.add_kpi = 0
                            this.formAdd.reminder_status = 0
                        }).catch(err => {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        })
                        this.endLoading();
                    }
                }
            });
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-form {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 840px;
        margin: 0 auto;
    }

    .wp-form h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 0;
    }

    span.check {
        color: #515151;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.075px;
        margin-right: 8px;
    }

    span.check--bold {
        font-weight: 500
    }

    p.label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 0;
    }

    p.label--req::before {
        content: "*";
        color: var(--el-color-danger);
        margin-right: 4px;
    }

    .wp-user {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 10px 12px;
        cursor: pointer;
        /* display: flex; */
        /* align-items: center; */
        /* margin-bottom: 15px; */
    }

    .wp-user--err {
        box-shadow: 0 0 0 1px var(--el-color-danger) inset;
    }

    .wp-user img {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        object-fit: cover;
        margin-right: 8px;
    }

    .wp-user span {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-right: 8px;
    }

    .wp-btn {
        text-align: end;
        margin-top: 22px;
    }

    .wp-btn button {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 200px;
        height: 38px;
        border-radius: 15px;
        background: #F58E16;
    }

    .wp-btn button:first-child {
        color: #F58E16;
        border: 1px solid #F58E16;
        background-color: transparent;
        margin-right: 15px;
    }
    
    span.error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding-top: 2px;
    }
</style>

<style>
    .wp-form-job .el-form-item__label {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 180%;
    }

    .wp-form-job .el-input__wrapper, .wp-form-job .el-textarea__inner {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 5px 12px;
    }

    .wp-form-job .el-date-editor .el-input__wrapper {
        height: 40px;
    }

    .wp-form-job .el-date-editor {
        height: 100% !important;
        flex-basis: 95%;
    }

    .wp-form-job .el-select {
        width: 100%;
    }

    .wp-form-job .input_space--end .el-form-item__content {
        justify-content: flex-end
    }

    .wp-form-job .input_space--end .el-form-item__content .el-form-item__error {
        left: 25px;
    }

    .wp-form-job .el-textarea__inner {
        resize: none;
        min-height: 100px !important;
    }

    .wp-form-job .el-textarea__inner::-webkit-scrollbar {
        width: 0;
    }
</style>