<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="553"
        class="dialog_required"
        @close="emitDialogVisible(false)"
        title="Thông tin pháp lý yêu cầu"
    >
        <div class="wp-item">
            <div v-for="item in data_list" :key="item.id" style="position: relative;">
                <label class="wp-lable">
                    <p>{{ item.name }}</p>
                    <input type="checkbox" class="input-style" :value="item" v-model="dataRequired">
                    <span class="checkmark"></span>
                </label>
                <span class="delete" @click="deleteJuridical(item.id)"><img src="@/assets/icons/cross-2.png" alt=""></span>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between" style="margin-left: 28px;">
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                label-width="120px"
                class="demo-ruleForm"
                status-icon
            >
                <el-form-item label="Thêm yêu cầu hồ sơ mới" prop="juridical_name">
                    <el-input v-model="formAdd.juridical_name" type="textarea" resize="none" placeholder="Nhập yêu cầu" />
                </el-form-item>
            </el-form>
            <button class="add" @click="createJuridical('formAdd')">Thêm</button>
        </div>
        <div class="wp-btn" style="margin-top: 42px;">
            <button class="btn_cancel" @click="closeDialog">Hủy bỏ</button>
            <button class="btn_add" @click="confirm">Xác nhận</button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['dialogVisible', 'list_juridical'],
    data() {
        return {
            formAdd: {
                juridical_name: '',
            },
            rules: {
                juridical_name: [{required: true, message: "Vui lòng nhập yêu cầu", trigger: ["blur", "change"]}],
            },
            dialogVisibleStatus: false,
            data_list: [],
            dataRequired: [],
        };
    },
    mounted() {
        this.dialogVisibleStatus = this.dialogVisible
        this.dataRequired = this.list_juridical
        this.getList()
    },
    watch: {
        dialogVisible() {
            this.dialogVisibleStatus = this.dialogVisible;
        },
        list_juridical() {
            this.dataRequired = this.list_juridical
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
        },
        confirm() {
            this.dialogVisibleStatus = false
            this.$emit("confirm", this.dataRequired);
        },
        closeDialog() {
            this.dialogVisibleStatus = false
            this.dataRequired = []
            this.turnOffErrorMessage('formAdd')
        },
        async getList() {
            this.startLoading();
            const res = await this.$axios.get('/real_estate/getListJuridical',{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            })
            if (res.status === 200) {
                this.data_list = res.data
            } else if(res.status === 401) {
                this.handleCheckTokenAuth();
            } else {
                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
            }
            this.endLoading();
        },
        async createJuridical(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const res = await this.$axios.post('/real_estate/createJuridical',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.getList()
                        this.showNotify("success", "Thành công", "Thêm yêu cầu thành công!");
                        this.turnOffErrorMessage('formAdd')
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async deleteJuridical(id) {
            const data = {
                'juridical_id' : id
            }
            this.startLoading();
            const res = await this.$axios.put('/real_estate/deleteJuridical',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            })
            if (res.status === 200) {
                this.getList()
                this.showNotify("success", "Thành công", "Xóa yêu cầu thành công!");
            } else if(res.status === 401) {
                this.handleCheckTokenAuth();
            } else {
                this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
            }
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .wp-lable p {
        margin: 0 0 0 28px;
        color: #515151;
    }

    span.delete {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }

    button {
        color: #F58E16;
        font-size: 14px;
        font-weight: 500;
        width: 48%;
        height: 38px;
        border-radius: 15px;
        background: none;
        border: 1px solid #F58E16;
    }

    button.btn_add {
        color: #FFF;
        background: #F58E16;
        border: none;
        margin-left: 13px;
    }

    button.add {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 99px;
        background: #F58E16;
        width: 54px;
        height: 54px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .el-form {
        width: 85%;
    }

    .wp-item {
        max-height: 500px;
        overflow: auto;
    }

    .wp-item::-webkit-scrollbar {
        width: 0;
    }
</style>

<style>
    .el-dialog.dialog_required {
        border-radius: 20px !important;
    }

    .dialog_required .el-dialog__body {
        padding-top: 10px;
    }

    .dialog_required .el-dialog__title {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
    }

    .dialog_required .el-form-item {
        display: block;
    }

    .dialog_required .el-form-item__label {
        color: #515151;
        font-size: 16px;
        /* margin-left: 28px; */
    }

    .dialog_required .el-textarea__inner {
        border-radius: 15px;
        background: rgb(238, 238, 238);
        /* margin-left: 28px; */
        /* width: 95%; */
        font-size: 16px;
        box-shadow: none;
    }

    .dialog_required .el-textarea__inner:focus,
    .dialog_required .el-textarea__inner:hover {
        box-shadow: none;
    }

    .dialog_required .el-form label {
        width: 100% !important;
        justify-content: flex-start;
    }

    .el-overlay-dialog::-webkit-scrollbar {
        width: 0;
    }
</style>