<template>
    <div class="wp-detail">
        <h5>Chi tiết khách hàng</h5>
        <div class="breadcrumb">
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Khách hàng quản lý</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết khách hàng</span>
        </div>
        <div class="wp-content">
            <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                <div>
                    <img src="@/assets/icons/arrow.png" alt="" style="rotate: 180deg; margin-right: 20px; cursor: pointer;" @click="$router.go(-1)">
                    <span class="ttl">Nhu cầu khách hàng</span>
                </div>
                <button class="btn-add" @click="addForm">+ Thêm nhu cầu mới</button>
            </div>
            <div class="d-flex justify-content-center" style="margin-bottom: 20px;">
                <button class="btn-act">
                    <div class="wp-img" style="background-color: #F58E16; border: none;">
                        <img src="@/assets/icons/favorite.png" alt="">
                    </div>
                    <p>Nhu cầu</p>
                </button>
                <button class="btn-act" @click="$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/dau-viec')">
                    <div class="wp-img">
                        <img src="@/assets/icons/job.png" alt="">
                    </div>
                    <p>Đầu việc</p>
                </button>
            </div>
            <div class="wp-demand">
                <div class="item" v-for="(item, index) in listDemand" :key="index" @click="getDetail(item.id)">
                    <div class="item-head" :class="{'item-head--yl' : item.type == 3 || item.type == 4, 'item-head--rd' : item.type != 3 && item.type != 4}">
                        <div>
                            <img src="@/assets/icons/job.png" alt="">
                            <span>{{ formateDate(item.create_at) }}</span>
                        </div>
                        <div>
                            <img src="@/assets/icons/picture_ico.png" alt="" v-if="item.count_image" style="margin-right: 15px;">
                            <img src="@/assets/icons/star.png" alt="" v-if="item.prioritize == 1">
                        </div>
                    </div>
                    <div class="item-body">
                        <h6>{{ item.title }}</h6>
                        <ul>
                            <li>
                                <img src="@/assets/icons/home_ico.png" alt="">
                                <span v-for="(cate, index) in item.category">{{ cate.category_name }}<span v-if="index < item.category.length - 1">, </span></span>
                            </li>
                            <li>
                                <img src="@/assets/icons/Torrent.png" alt="">
                                <span v-for="(cate, index) in item.acreage">{{ cate.text }}<span v-if="index < item.acreage.length - 1">, </span></span>
                            </li>
                            <!-- <li v-else>
                                <img src="@/assets/icons/Torrent.png" alt="">
                                <span>{{ item.acreage }}</span>
                            </li> -->
                            <li>
                                <img src="@/assets/icons/ques.png" alt="">
                                <span>{{ item.content }}</span>
                            </li>
                            <li>
                                <img src="@/assets/icons/Price-Tag.png" alt="">
                                <span v-for="(cate, index) in item.price">{{ cate.text }}<span v-if="index < item.price.length - 1">, </span></span>
                            </li>
                            <!-- <li v-else>
                                <img src="@/assets/icons/Price-Tag.png" alt="">
                                <span>{{ item.price }}</span>
                            </li> -->
                            <li>
                                <img src="@/assets/icons/address.png" alt="">
                                <span v-if="item.address">{{ item.address }}, </span>
                                <span v-for="ward in item.ward">
                                    <span v-for="district in item.district">
                                        <span v-for="province in item.province">
                                            <span v-if="ward.district_code == district.code && district.province_code == province.code">
                                                {{ ward.name }}, {{ district.name }}, {{ province.name }} <br/>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            listDemand: [],
        }
    },
    mounted() {
        this.getListDemand()
    },
    methods: {
        getDetail(id) {
            this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/nhu-cau/chi-tiet/' + this.encode(id))
        },
        addForm() {
            this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/nhu-cau/tao-moi')
        },
        async getListDemand() {
            this.startLoading();
            await this.$axios.get('/job/getListCustomerNeeds',{
                params: {
                    customer_id: this.decode(this.$route.params.cusId),
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listDemand = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 0 auto;
    }

    h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    .btn-act {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .btn-act .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-act:first-child {
        margin-right: 30px;
    }

    .btn-act img {
        width: 30px;
        height: 30px;
    }

    .btn-act p {
        margin: 5px 0 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px; 
    }

    .btn-add {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        background: #F58E16;
        width: 200px;
        height: 40px;
    }

    .wp-demand {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .item {
        border-radius: 20px;
        cursor: pointer;
        flex-basis: 49%;
        margin-bottom: 10px;
    }

    .item-head {
        padding: 0 15px;
        border-radius: 20px 20px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .item-head--yl {
        background-color: #F58E16;
    }

    .item-head--rd {
        background-color: #EE3439;
    }

    .item-head img {
        width: 20px;
        height: 20px;
        filter: brightness(20);
    }

    .item-head span {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.14px;
        margin-left: 10px;
    }

    .item-head div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-basis: 26%;
    }

    .item-body {
        border: solid #B4B4B4;
        border-width: 0 1px 1px;
        border-radius: 0 0 20px 20px;
        padding: 10px 20px 20px;
        height: 88%;
    }

    .item-body h6 {
        color: #515151;
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.16px;
        margin-bottom: 10px;
        word-break: break-word;
    }

    .item-body ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .item-body li {
        padding: 6px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .item-body li:first-child {
        padding-top: 0;
    }

    .item-body li span {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .item-body img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
</style>