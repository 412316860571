<template>
    <div class="col-12 mb-3 position-relative">
        <div class="list-tab-toggle scroll-mobie">
            <a class="tab-toggle " href="#overview" :class="{ 'active': hash == '#overview' || !hash }">
                <div><strong>Tổng quan dự án</strong></div>
                <div>Giới thiệu về dự án</div>
            </a>
            <a class="tab-toggle" href="#area" :class="{ 'active': hash == '#area' }">
                <div><strong>Mặt bằng & thiết kế</strong></div>
                <div>Tổng thể hạ tầng</div>
            </a>
            <a class="tab-toggle" href="#location" :class="{ 'active': hash == '#location' }">
                <div><strong>Vị trí</strong></div>
                <div>Bản đồ dự án</div>
            </a>
        </div>

        <slideView :list_images="data.image" :video="data.video" :thumbnail="data.video_thumbnail"/>
        <div class="base_params">
            <div class="area">
                <img src="@/assets/icons/Torrent.png" alt="">
                <div class="text-price">
                    <p>{{ data.acreage }} {{ data.unit }}</p>
                </div>
            </div>
        </div>
        <div class="">
            <div class="product-name">
                {{ data.name }}
            </div>
            <div class="address">
                <img class="d-none d-xl-flex" src="@/assets/icons/Visit.png" alt="">
                {{ data.address }}, {{ data.ward_name }}, {{ data.district_name }}, {{ data.province_name }}
            </div>
            <div class="title">Đặc điểm thông số bất động sản</div>
            <div class="d-flex col-12 align-items-center">
                <div class="params">
                    <div class="col-6" v-for="(item, index) in data.project_describe" :key="index" v-show="item.value">
                        <div class="items">
                            <div class="d-flex">
                                <div class="icon-width">
                                    <img :src="$storage + item.icon" style="width: 32px; height: 28px;" alt="">
                                </div>
                                <div class="title-width">{{ item.name }}:</div>
                            </div>
                            <strong>{{ item.value }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-wrap d-xl-flex">
                <div class="scrollspy-example col-12">
                    <div class="title scrollspy" id="overview">Tổng quan dự án</div>
                    <div class="scroll-content ck-content" v-html="data.description"></div>
                    <div class="title scrollspy" id="area">Mặt bằng & thiết kế</div>
                    <div class="scroll-content ck-content" v-html="data.design"></div>
                    <div class="title scrollspy" id="location">Vị trí dự án</div>
                    <div class="scroll-content">
                        <div class="title">Xem trên bản đồ</div>
                        <GMAPS :lng="data.longtitude" :lat="data.latitude"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import slideView from './slideView.vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import GMAPS from "../../components/goong_maps.vue"
import 'swiper/css';
export default {
    props: ['data'],
    components: { slideView, Swiper, SwiperSlide, GMAPS },
    data() {
        return {
            breadcrumb_ttl: 'Vinhomes Ocean Park Gia Lâm',
            category_name: 'Dự án BĐS',
            hash: '',
        }
    },
    watch: {
        "$route.hash": function (hash) {
            this.hash = hash
        }
    },
    mounted() {
        this.hash = this.$route.hash;
    },
}
</script>
<style scoped>
.nsx{
    color: #ABABAB;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 246px;
}
.nsx strong{
    color: #515151;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.base_params {
    display: flex;
    position: absolute;
    top: 445px;
    left: 22px;
    z-index: 10;
}

.price,
.area {
    border-radius: 50px;
    box-shadow: 0px 0px 2.58454px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 0 3.5px;
    width: 203px;
    height: 70px;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: rgba(133, 133, 133, 0.50);
    backdrop-filter: blur(2px);
}

.price img,
.area img {
    filter: brightness(3);
    width: 49px;
    height: 43px;
}

.text-price {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    width: 120px;
}

.text-price p {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    font-style: normal;
}

.product-name {
    color: #515151;
    padding: 14px 0;
    font-size: 20px;
    font-weight: 700;
}

.address {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    display: flex;
}

.title {
    color: #515151;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 12px 0;
}

.title.scrollspy {
    font-size: 22px;
    margin-top: 28px;
}

.params {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 0 13px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.params .items {
    display: flex;
    padding: 13px 0;
    border-bottom: #E5E5E5 1px solid;
    width: 100%;
    min-height: 64px;
    height: 100%;
    justify-content: flex-start;
}

.params .col-6:last-child .items {
    border-bottom: none;
}

.params .icon-width {
    width: 46px;
    display: flex;
    align-items: center;
    min-width: 46px;
}

.params .title-width {
    min-width: 157px;
    width: 147px;
    color: #A1A1A1;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}

.params strong {
    color: #515151;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
}
.list-tab-toggle {
    margin: 28px 0;
    display: flex;
    align-items: center;
    border-bottom: #E9E9E9 1px solid;
}

.tab-toggle {
    min-width: 192px;
    padding-bottom: 10px;
    color: #515151;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 16px;
    text-decoration: none;
}

.tab-toggle.active {
    border-bottom: #F13A3A 3px solid;
}

.list-tab-toggle strong {
    color: #515151;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4px;
}

.scroll-content {
    color: #747474;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
</style>

<style>
    .scroll-content img {
        max-width: 100%;
    }
</style>