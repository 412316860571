<template>
    <div class="regular">
        <h5>Quản lý chung</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quản lý chung</span>
        </div>
        <el-row>
            <el-col :span="7">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-center">
                        <p class="ttl">Hệ thống</p>
                    </div>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listSettings"
                            :key="index"
                            @click="keyActive = item.id, ttl = item.name, detailPage = true"
                        >
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="17" v-if="detailPage">
                <div class="wp-detail">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p class="ttl">{{ ttl }}</p>
                        <div class="d-flex">
                            <button class="add-cate" @click="detailPage = false, keyActive = 0, getListInfor()">Hủy bỏ</button>
                            <button class="btn_delete" @click="formPopup.popup_banner = '', updateForm()" v-if="keyActive == 5">Xóa ảnh banner</button>
                            <button class="btn_save" @click="updateForm">Lưu chỉnh sửa</button>
                        </div>
                    </div>

                    <!-- --REGULAR-- -->
                    <el-form
                        ref="formAdd"
                        status-icon
                        :model="formRegular"
                        v-if="keyActive == 1"
                    >
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Logo công ty</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formRegular','logo')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formRegular','logo')">
                            <div v-if="!urlImage.logo && !formRegular.logo">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.logo && formRegular.logo" :src="$storage + formRegular.logo" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.logo" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                        </div>

                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Logo trang home</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFileHome" @change="changeFile('formRegular','home_logo')">
                        <div class="ico_upload" @click="$refs.inputFileHome.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formRegular','home_logo')">
                            <div v-if="!urlImage.home_logo && !formRegular.home_logo">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.home_logo && formRegular.home_logo" :src="$storage + formRegular.home_logo" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.home_logo" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                        </div>

                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Logo bộ công thương</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFileHome" @change="changeFile('formRegular','gov_image')">
                        <div class="ico_upload" @click="$refs.inputFileHome.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formRegular','gov_image')">
                            <div v-if="!urlImage.gov_image && !formRegular.gov_image">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.gov_image && formRegular.gov_image" :src="$storage + formRegular.gov_image" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.gov_image" alt="" style="max-height: 106px; max-width: 530px;" @error="handleError">
                        </div>

                        <el-form-item prop="gov_url" label="Link bộ công thương">
                            <el-input type="text" v-model="formRegular.gov_url" placeholder="Nhập link bộ công thương"/>
                        </el-form-item>

                        <el-form-item prop="company_name" label="Tên doanh nghiệp">
                            <el-input type="text" v-model="formRegular.company_name" placeholder="Nhập tên doanh nghiệp"/>
                        </el-form-item>
                        
                        <el-form-item prop="email" label="Email">
                            <el-input type="email" v-model="formRegular.email" placeholder="Nhập email"/>
                        </el-form-item>
                       
                        <el-form-item prop="phone" label="Số điện thoại">
                            <el-input type="text" v-model="formRegular.phone" placeholder="Nhập số điện thoại" @input="formRegular.phone = formRegular.phone.replace(/[^\d.]/g, '')"/>
                        </el-form-item>
                       
                        <el-form-item prop="address" label="Địa chỉ">
                            <el-input type="text" v-model="formRegular.address" placeholder="Nhập địa chỉ"/>
                        </el-form-item>
                       
                        <el-form-item prop="tax_code" label="Mã số thuế">
                            <el-input type="text" v-model="formRegular.tax_code" placeholder="Nhập mã số thuế"/>
                        </el-form-item>
                       
                        <el-form-item prop="certificate" label="Giấy đăng ký kinh doanh">
                            <el-input type="text" v-model="formRegular.certificate" placeholder="Nhập giấy đăng ký kinh doanh"/>
                        </el-form-item>

                        <el-form-item prop="construction_registration" label="Giấy đăng ký sở xây dựng">
                            <el-input type="text" v-model="formRegular.construction_registration" placeholder="Nhập giấy đăng ký sở xây dựng"/>
                        </el-form-item>
                       
                        <el-form-item prop="website" label="Website">
                            <el-input type="text" v-model="formRegular.website" placeholder="Nhập website"/>
                        </el-form-item>
                       
                        <el-form-item prop="hotline" label="Hotline">
                            <el-input type="text" v-model="formRegular.hotline" placeholder="Nhập hotline"/>
                        </el-form-item>

                        <el-form-item prop="hotline2" label="Hotline 2">
                            <el-input type="text" v-model="formRegular.hotline2" placeholder="Nhập hotline 2"/>
                        </el-form-item>

                        <el-form-item prop="mail_support" label="Mail chăm sóc khách hàng">
                            <el-input type="text" v-model="formRegular.mail_support" placeholder="Nhập mail chăm sóc khách hàng"/>
                        </el-form-item>
                    </el-form>

                    <!-- --APP MOBILE-- -->
                    <div class="wp-app" v-else-if="keyActive == 2">
                        <div class="item">
                            <div class="text-center">
                                <img src="@/assets/images/google_play.png" alt="">
                            </div>
                            <el-form-item prop="google_play" label="Link download app">
                                <el-input type="text" v-model="formApp.google_play" placeholder="Nhập link download app"/>
                            </el-form-item>
                        </div>
                        <div class="item">
                            <div class="text-center">
                                <img src="@/assets/images/app_store.png" alt="">
                            </div>
                            <el-form-item prop="app_store" label="Link download app">
                                <el-input type="text" v-model="formApp.app_store" placeholder="Nhập link download app"/>
                            </el-form-item>
                        </div>
                    </div>

                    <!-- --Banner-- -->
                    <div class="banner" v-else-if="keyActive == 3">
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Tải ảnh bìa chính</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formBanner','home_banner')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formBanner','home_banner')">
                            <div v-if="!urlImage.home_banner && !formBanner.home_banner">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image <br>
                                    (1724 x 782)
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.home_banner && formBanner.home_banner" :src="$storage + formBanner.home_banner" alt="" style="max-height: 390px; max-width: 860px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.home_banner" alt="" style="max-height: 390px; max-width: 860px;" @error="handleError">
                        </div>
                    </div>

                    <!-- --CATEGORY-- -->
                    <el-row v-else-if="keyActive == 4">
                        <el-col :span="12" style="margin: 0 10px 5px 0; max-width: 49%;">
                            <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Đất nền, đất thổ cư</p>
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile1" @change="changeFile('formCate','land')">
                            <div class="ico_upload" @click="$refs.inputFile1.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formCate','land')">
                                <div v-if="!urlImage.land && !formCate.land">
                                    <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                    <div class="el-upload__text">
                                        Drag and drop image here, or click add image <br>
                                        (232 x 216)
                                    </div>
                                    <button class="add_img" type="button">Add Image</button>
                                </div>
                                <img v-else-if="!urlImage.land && formCate.land" :src="$storage + formCate.land" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                                <img v-else :src="$storage + urlImage.land" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                            </div>
                        </el-col>
                        <el-col :span="12" style="margin-left: 10px; max-width: 49%;">
                            <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Bất động sản đang rao bán</p>
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile2" @change="changeFile('formCate','sale')">
                            <div class="ico_upload" @click="$refs.inputFile2.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formCate','sale')">
                                <div v-if="!urlImage.sale && !formCate.sale">
                                    <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                    <div class="el-upload__text">
                                        Drag and drop image here, or click add image <br>
                                        (232 x 216)
                                    </div>
                                    <button class="add_img" type="button">Add Image</button>
                                </div>
                                <img v-else-if="!urlImage.sale && formCate.sale" :src="$storage + formCate.sale" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                                <img v-else :src="$storage + urlImage.sale" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                            </div>
                        </el-col>
                        <el-col :span="12" style="margin-right: 10px; max-width: 49%;">
                            <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Bất động sản cho thuê</p>
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile3" @change="changeFile('formCate','rent')">
                            <div class="ico_upload" @click="$refs.inputFile3.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formCate','rent')">
                                <div v-if="!urlImage.rent && !formCate.rent">
                                    <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                    <div class="el-upload__text">
                                        Drag and drop image here, or click add image <br>
                                        (232 x 216)
                                    </div>
                                    <button class="add_img" type="button">Add Image</button>
                                </div>
                                <img v-else-if="!urlImage.rent && formCate.rent" :src="$storage + formCate.rent" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                                <img v-else :src="$storage + urlImage.rent" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                            </div>
                        </el-col>
                        <el-col :span="12" style="margin-left: 10px; max-width: 49%;">
                            <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Dự án Bất động sản</p>
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile4" @change="changeFile('formCate','project')">
                            <div class="ico_upload" @click="$refs.inputFile4.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formCate','project')">
                                <div v-if="!urlImage.project && !formCate.project">
                                    <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                    <div class="el-upload__text">
                                        Drag and drop image here, or click add image <br>
                                        (232 x 216)
                                    </div>
                                    <button class="add_img" type="button">Add Image</button>
                                </div>
                                <img v-else-if="!urlImage.project && formCate.project" :src="$storage + formCate.project" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                                <img v-else :src="$storage + urlImage.project" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                            </div>
                        </el-col>
                        <el-col :span="12" style="margin-left: 10px; max-width: 49%;">
                            <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Bất động sản gần bạn</p>
                            <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile5" @change="changeFile('formCate','suggest')">
                            <div class="ico_upload" @click="$refs.inputFile5.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formCate','suggest')">
                                <div v-if="!urlImage.suggest && !formCate.suggest">
                                    <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                    <div class="el-upload__text">
                                        Drag and drop image here, or click add image <br>
                                        (232 x 216)
                                    </div>
                                    <button class="add_img" type="button">Add Image</button>
                                </div>
                                <img v-else-if="!urlImage.suggest && formCate.suggest" :src="$storage + formCate.suggest" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                                <img v-else :src="$storage + urlImage.suggest" alt="" style="max-height: 316px; max-width: 232px;" @error="handleError">
                            </div>
                        </el-col>
                    </el-row>

                    <!-- --Banner Popup-- -->
                    <div class="banner-pop" v-else-if="keyActive == 5">
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Tải ảnh banner popup</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formPopup','popup_banner')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formPopup','popup_banner')">
                            <div v-if="!urlImage.popup_banner && !formPopup.popup_banner">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image <br>
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.popup_banner && formPopup.popup_banner" :src="$storage + formPopup.popup_banner" alt="" style="max-height: 390px; max-width: 860px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.popup_banner" alt="" style="max-height: 390px; max-width: 860px;" @error="handleError">
                        </div>
                        <el-form-item prop="link_url" label="Link">
                            <el-input type="text" v-model="formPopup.link_url" placeholder="Nhập link"/>
                        </el-form-item>
                        <el-form-item prop="time" label="Thời gian tắt banner">
                            <el-input type="text" maxlength="2" v-model="formPopup.time" @input="formPopup.time = formPopup.time.replace(/[^\d.]/g, '')" placeholder="Nhập thời gian tắt"/>
                            <span style="position: absolute; right: 20px; font-size: 15px; font-weight: 500;">Giây</span>
                        </el-form-item>
                    </div>

                    <!-- --Banner Login-- -->
                    <div class="banner-login" v-else-if="keyActive == 6">
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Tải ảnh banner đăng nhập</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formLogin','login_banner')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formLogin','login_banner')">
                            <div v-if="!urlImage.login_banner && !formLogin.login_banner">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image <br>
                                    (720 x 601)
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.login_banner && formLogin.login_banner" :src="$storage + formLogin.login_banner" alt="" style="max-height: 601px; max-width: 720px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.login_banner" alt="" style="max-height: 601px; max-width: 720px;" @error="handleError">
                        </div>
                    </div>

                    <!-- --Banner Contact-- -->
                    <div class="banner-contact" v-else-if="keyActive == 7">
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Tải ảnh banner liên hệ</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formContact','contact_banner')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formContact','contact_banner')">
                            <div v-if="!urlImage.contact_banner && !formContact.contact_banner">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image <br>
                                    (1487 x 536)
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.contact_banner && formContact.contact_banner" :src="$storage + formContact.contact_banner" alt="" style="max-height: 325px; max-width: 901px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.contact_banner" alt="" style="max-height: 325px; max-width: 901px;" @error="handleError">
                        </div>
                    </div>

                    <!-- --Banner partner-- -->
                    <div class="banner-partner" v-else-if="keyActive == 8">
                        <p style="font-weight: 500; font-size: 14px; margin-bottom: 3px;">Tải ảnh banner đối tác</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile('formPartner','partner_banner')">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop('formPartner','partner_banner')">
                            <div v-if="!urlImage.partner_banner && !formPartner.partner_banner">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image <br>
                                    (1724 x 500)
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="!urlImage.partner_banner && formPartner.partner_banner" :src="$storage + formPartner.partner_banner" alt="" style="max-height: 262px; max-width: 903px;" @error="handleError">
                            <img v-else :src="$storage + urlImage.partner_banner" alt="" style="max-height: 262px; max-width: 903px;" @error="handleError">
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyActive: 0,
            listSettings: [
                {id: 1, name: 'Thông tin chung'},
                {id: 2, name: 'App mobile'},
                {id: 3, name: 'Ảnh bìa'},
                {id: 4, name: 'Ảnh danh mục chính'},
                {id: 5, name: 'Banner popup'},
                {id: 6, name: 'Banner đăng nhập'},
                {id: 7, name: 'Banner liên hệ'},
                {id: 8, name: 'Banner đối tác'},
            ],
            ttl: '',
            detailPage: false,
            formRegular: {
                logo: '',
                home_logo: '',
                gov_image: '',
                company_name: '',
                email: '',
                phone: '',
                address: '',
                tax_code: '',
                certificate: '',
                website: '',
                hotline: '',
                hotline2: '',
                mail_support: '',
                gov_url: '',
                construction_registration: '',
            },
            formApp: {
                google_play: '',
                app_store: ''
            },
            formBanner: {
                home_banner: ''
            },
            formCate: {
                land: '',
                sale: '',
                rent: '',
                project: '',
                suggest: ''
            },
            formPopup: {
                popup_banner: '',
                link_url: '',
                time: ''
            },
            formLogin: {
                login_banner: ''
            },
            formContact: {
                contact_banner: ''
            },
            formPartner: {
                partner_banner: ''
            },
            urlImage: {
                logo: '',
                home_logo: '',
                gov_image: '',
                home_banner: '',
                land: '',
                sale: '',
                rent: '',
                project: '',
                suggest: '',
                popup_banner: '',
                login_banner: '',
                contact_banner: '',
                partner_banner: '',
            }
        }
    },
    mounted() {
        this.getListInfor()
    },
    methods: {
        async changeFile(form, type) {
            if(event.target.files[0]) {
                let url_image = await this.uploadFile('system/', event.target.files[0])
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        async handleDrop(form, type) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if(file) {
                let url_image = await this.uploadFile('system/', file)
                this.urlImage[type] = url_image.path_tam
                this[form][type] = url_image.path
            }
        },
        async getListInfor() {
            this.startLoading();
            await this.$axios.get('/system/getInformation')
            .then(res => {
                this.formRegular = res.data.general
                this.formApp = res.data.mobile
                this.formBanner = res.data.home_banner
                this.formCate = res.data.category_image
                this.formPopup = res.data.popup_banner
                this.formLogin = res.data.login_banner
                this.formContact = res.data.contact_banner
                this.formPartner = res.data.partner_banner
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateForm() {
            if(this.keyActive == 1) {
                var data = this.formRegular
                var type = 'logo'
                var form = 'formRegular'
            } else if (this.keyActive == 2) {
                var data = this.formApp
                var type = ''
                var form = ''
            } else if (this.keyActive == 3) {
                var data = this.formBanner
                var type = 'home_banner'
                var form = 'formBanner'
            } else if (this.keyActive == 4) {
                var data = this.formCate
                var type = ['land', 'sale', 'rent','project']
                var form = 'formCate'
            } else if (this.keyActive == 5) {
                var data = this.formPopup
                var type = 'popup_banner'
                var form = 'formPopup'
            } else if (this.keyActive == 6) {
                var data = this.formLogin
                var type = 'login_banner'
                var form = 'formLogin'
            } else if (this.keyActive == 7) {
                var data = this.formContact
                var type = 'contact_banner'
                var form = 'formContact'
            } else if (this.keyActive == 8) {
                var data = this.formPartner
                var type = 'partner_banner'
                var form = 'formPartner'
            }
            this.startLoading();
            await this.$axios.put('/system/updateSystemInfomation', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Chỉnh sửa thông tin thành công!");
                this.getListInfor()
                if(this.keyActive != 4) {
                    if(type && form) {
                        this.urlImage[type] = this[form][type] = ''
                    }
                } else {
                    type.forEach(element => {
                        this.urlImage[element] = this[form][element] = ''
                    });
                }
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        }
    }
}
</script>
<style lang="css" scoped>
    .regular {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-detail button.btn_delete {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #ee3439;
        margin-left: 10px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }

    .ico_upload {
        border-radius: 20px;
        border: 1px dashed var(--neutral-gray-gray-100, #E0E2E7);
        background: #EBEBEB;
        padding: 24px 12px;
        box-shadow: none;
        text-align: center;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .el-icon--upload {
        border-radius: 8px;
        background: #F58E16;
        padding: 8px;
        width: 44px;
        height: 44px;
        margin-bottom: 0 !important;
    }

    .el-upload__text {
        margin: 16px 0;
    }

    .add_img {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
        display: unset !important;
    }

    .wp-app {
        width: 700px;
        margin: 0 auto;
    }

    .wp-app .item {
        border-radius: 20px;
        background: #F6F6F6;
        width: 100%;
        padding: 20px;
    }

    .wp-app img {
        width: 290px;
        height: 95px;
        margin-bottom: 10px;
    }

    .wp-app .item:first-child {
        margin-bottom: 30px;
    }
</style>

<style>
    .regular .el-input__wrapper,
    .regular .el-input__wrapper:hover,
    .regular .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        border-radius: 15px;
        background: #F6F6F6;
    }

    .regular .el-form-item{
        margin-bottom: 10px;
    }

    .regular .wp-app .item .el-input__wrapper {
        border-radius: 65px;
        background: #FFF;
        height: 50px;
    }
</style>