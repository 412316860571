<template>
    <div class="wp-confirm">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết tin tức</h5>
                <div class="breadcrumb">
                    <span>Quản lý tin tức</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách tin tức</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết tin tức</span>
                </div>
            </div>
            <div class="wp-btn">
                <span class="show_web">Hiển thị trên web
                    <el-switch
                        v-model="dataDetail.view_status"
                        :active-value="1"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                        @change="updateViewStatus"
                    />
                </span>
                <button @click="$router.push('/quan-ly-tin-tuc/danh-sach/chinh-sua/' + encode(id))">Chỉnh sửa tin tức</button>
                <button class="btn_delete" @click="dialogVisible = true">Xóa tin tức</button>
            </div>
        </div>
        <el-row>
            <el-col :span="18" class="wp-detail-news">
                <div class="detail">
                    <h5 style="font-size: 34px; font-weight: 600; margin-bottom: 15px; line-height: normal;">{{ dataDetail.title }}</h5>
                    <div v-html="dataDetail.news_content" class="ck-content"></div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="news_img">
                    <h5>Hình ảnh hiển thị</h5>
                    <div class="wp-img">
                        <img :src="$storage + dataDetail.image" alt="" @error="handleError">
                    </div>
                </div>
                <div class="news-info">
                    <h5>Thông tin tin tức</h5>
                    <p>Danh mục tin tức</p>
                    <p class="info_txt">{{ dataDetail.category_name }}</p>
                    <p>Người đăng</p>
                    <p class="info_txt">{{ dataDetail.created_by }}</p>
                    <p>Thời gian đăng tin tức</p>
                    <p class="info_txt">{{ this.$moment.utc(dataDetail.create_at).format("DD/MM/YYYY hh:mm") }}</p>
                    <p>Cập nhật gần nhất</p>
                    <p class="info_txt">{{ this.$moment.utc(dataDetail.update_at).format("DD/MM/YYYY hh:mm") }}</p>
                    <p>Link nguồn tin tức</p>
                    <p class="info_txt"><a :href="dataDetail.link" target="_blank" class="lnk">{{ dataDetail.link }}</a></p>
                    <p>Mô tả</p>
                    <p class="info_txt">{{ dataDetail.description }}</p>
                </div>
            </el-col>
        </el-row>
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="deleteNews"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ tin tức"
            content_success="Bạn đã xóa tin tức thành công"
        />
    </div>
</template>

<script>
import Dialog from '../components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            id: '',
            dataDetail: []
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async deleteNews(successDialog) {
           this.startLoading();
            await this.$axios.put('/news/deleteNews', {id: this.id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa tin tức thành công!");
                this.$router.push('/quan-ly-tin-tuc/danh-sach')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/news/getNewsDetail',{
                params: {
                    id: this.id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
                document.title = `Chi tiết tin tức - ${this.dataDetail.title}`;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateViewStatus() {
            if(!this.dataDetail.view_status) {
                this.dataDetail.view_status = 0
            }
            const data = {
                'id': this.id,
                'view_status': this.dataDetail.view_status
            }
            this.startLoading();
            await this.$axios.put('/news/updateNewsViewStatus', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi trạng thái thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-btn button {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #515151;
        background: none;
    }

    .wp-btn button.btn_delete {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #EE3439;
        margin-left: 16px;
    }

    .news_img img {
        max-width: 219px;
        max-height: 141px;
    }

    .news_img .wp-img {
        text-align: center;
        border-radius: 20px;
        background: #F8F8F8;
        margin-top: 22px;
    }

    .news_img p {
        margin-bottom: 0;
        color: #ABABAB;
        font-size: 14px;
    }

    .news_img p:last-child {
        color: #515151;
        font-size: 18px;
        font-weight: 500; 
    }

    .news-info, .news_img {
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .news-info h5, .news_img h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .news-info p {
        margin: 0;
        font-size: 14px;
        color: #686868;
    }

    .news-info {
        margin-bottom: 0;
    }

    p.info_txt {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 9px 13px;
        margin-bottom: 10px;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
    }

    .detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    .show_web {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        margin-right: 16px;
    }

    .show_web .el-switch {
        margin-left: 8px;
    }

    .lnk {
        color: #515151;
        text-decoration: none;
    }
</style>

<style>
    .wp-detail-news .detail img {
        max-width: 100%;
        height: auto;
    }
</style>