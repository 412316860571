<template>
    <div class="wp-confirm">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Chi tiết chi nhánh</h5>
                <div class="breadcrumb">
                    <span>Chi nhánh</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Danh sách chi nhánh</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Chi tiết chi nhánh</span>
                </div>
            </div>
            <div class="wp-btn">
                <span class="show_web">Hiển thị trên web
                    <el-switch
                        v-model="dataDetail.view_status"
                        :active-value="1"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                        @change="updateViewStatus"
                    />
                </span>
                <button @click="$router.push('/quan-ly-chi-nhanh/chinh-sua/' + this.encode(id))">Chỉnh sửa chi nhánh</button>
                <button class="btn_delete" @click="dialogVisible = true" v-if="dataDetail.id != 1">Xóa chi nhánh</button>
            </div>
        </div>
        <el-row>
            <el-col :span="18">
                <div class="detail">
                    <h5>{{ dataDetail.agency_name }}</h5>
                    <p class="ttl">{{ dataDetail.title }}</p>
                    <img v-if="dataDetail.image" :src="$storage + dataDetail.image" alt="" style="margin-bottom: 10px;" @error="handleError">
                    <p v-html="dataDetail.description" class="data_editor ck-content"></p>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="director">
                    <img src="@/assets/icons/avatar.png" alt="">
                    <div>
                        <p>Giám đốc chi nhánh</p>
                        <p>Ông Nguyễn Văn A</p>
                    </div>
                </div>
                <div class="agency-info">
                    <h5>Thông tin chi nhánh</h5>
                    <p>Mã chi nhánh</p>
                    <p class="info_txt" style="color: #3FA5FF;">{{ dataDetail.agency_code }}</p>
                    <p>Tên chi nhánh</p>
                    <p class="info_txt">{{ dataDetail.agency_name }}</p>
                    <p>Số lượng nhân viên của chi nhánh</p>
                    <p class="info_txt">{{ dataDetail.count_staff }}</p>
                    <p>Số điện thoại liên hệ</p>
                    <p class="info_txt">{{ dataDetail.phone }}</p>
                    <p>Email</p>
                    <p class="info_txt">{{ dataDetail.email }}</p>
                    <p>Địa chỉ chi nhánh</p>
                    <p class="info_txt">{{ dataDetail.address }}</p>
                    <p>Ngày tạo</p>
                    <p class="info_txt">{{ this.formateDate(dataDetail.create_at) }}</p>
                </div>
            </el-col>
        </el-row>
        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ chi nhánh"
            content_success="Bạn đã xóa chi nhánh thành công"
        />
    </div>
</template>

<script>
import Dialog from '../components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            id: '',
            dataDetail: []
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async confirm(successDialog) {
            // this.successDialog = successDialog;
            const data = {
                'agency_id': this.id,
            }
            this.startLoading();
            await this.$axios.put('/agency/deleteAgency', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa chi nhánh thành công!");
                this.$router.push('/quan-ly-chi-nhanh')
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/agency/getAgencyDetail',{
                params: {
                    agency_id: this.id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
                document.title = `Chi tiết chi nhánh - ${this.dataDetail.agency_name}`;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updateViewStatus() {
            if(!this.dataDetail.view_status) {
                this.dataDetail.view_status = 0
            }
            const data = {
                'agency_id': this.id,
                'view_status': this.dataDetail.view_status
            }
            this.startLoading();
            await this.$axios.put('/agency/updateViewStatusAgency', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi trạng thái thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    .wp-btn button {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        padding: 8.5px 14px;
        border-radius: 15px;
        border: 1px solid #515151;
        background: none;
    }

    .wp-btn button.btn_delete {
        color: #EE3439;
        font-size: 14px;
        font-weight: 600;
        border: 1px solid #EE3439;
        margin-left: 16px;
    }

    /* --- */
    .ttl {
        color: #515151;
        font-weight: 600;
    }

    .detail p {
        font-size: 15px;
        text-align: justify;
    }
    /* --- */
    
    .director {
        border-radius: 69px;
        background: #FFF;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 78px;
    }

    .director img {
        width: 69px;
        height: 69px;
        margin-right: 12px;
        margin-left: 5px;
        border-radius: 69px;
    }

    .director p {
        margin-bottom: 0;
        color: #ABABAB;
        font-size: 14px;
    }

    .director p:last-child {
        color: #515151;
        font-size: 18px;
        font-weight: 500; 
    }

    .agency-info {
        margin-bottom: 20px;
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .agency-info h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .agency-info p {
        margin: 0;
        font-size: 14px;
        color: #686868;
    }

    .agency-info {
        margin-bottom: 0;
    }

    p.info_txt {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        border-radius: 20px;
        background: #F4F4F4;
        padding: 9px 13px;
        margin-bottom: 10px;
    }

    .law-info ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .detail {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        height: 100%;
        margin-right: 20px;
    }

    .detail h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    .detail img {
        max-width: 100%;
    }

    .show_web {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Public Sans', sans-serif;
        margin-right: 16px;
    }

    .show_web .el-switch {
        margin-left: 8px;
    }
</style>

<style>
    .data_editor img {
        max-width: 100%;
        height: auto;
    }
</style>