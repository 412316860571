<template>
    <div class="wp-detail">
        <h5>Chi tiết khách hàng</h5>
        <div class="breadcrumb">
            <span>Công việc</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Khách hàng quản lý</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chi tiết khách hàng</span>
        </div>
        <div class="wp-content">
            <div class="d-flex align-items-center justify-content-between" style="margin-bottom: 20px;">
                <div>
                    <img src="@/assets/icons/arrow.png" alt="" style="rotate: 180deg; margin-right: 20px; cursor: pointer;" @click="$router.go(-1)">
                    <span class="ttl">Chi tiết nhu cầu: 
                        <span v-if="dataDetail.type == 1">Rao bán BĐS</span>
                        <span v-else-if="dataDetail.type == 2">Cho thuê BĐS</span>
                        <span v-else-if="dataDetail.type == 3">Mua BĐS</span>
                        <span v-else>Thuê BĐS</span>
                    </span>
                </div>
                <div>
                    <span style="font-size: 15px; margin-right: 8px;">Ưu tiên</span>
                    <el-switch
                        v-model="dataDetail.prioritize"
                        :active-value="1"
                        class="ml-2"
                        style="--el-switch-on-color: #ee3439; --el-switch-off-color: #d9d9d9"
                        @change="updatePrioritize"
                    />
                    <button class="btn-del" @click="dialogVisible = true">Xóa nhu cầu</button>
                    <button class="btn-add" @click="redirectEdit">Chỉnh sửa</button>
                </div>
            </div>
            <ul>
                <li>Ngày nhập: <span>{{ $moment(dataDetail.create_at).format("DD/MM/YYYY") }}</span></li>
                <li>Ngày cập nhập mới nhất: <span>{{ $moment(dataDetail.update_at).format("DD/MM/YYYY") }}</span></li>
            </ul>
            <div class="list-btn">
                <button :class="{'active' : dataDetail.type == 1}">Rao bán</button>
                <button :class="{'active' : dataDetail.type == 2}">Cho thuê</button>
                <button :class="{'active' : dataDetail.type == 3}">Mua</button>
                <button :class="{'active' : dataDetail.type == 4}">Thuê</button>
            </div>
            <button class="view" @click="getList" v-if="dataDetail.type == 3 ||dataDetail.type == 4 ">Danh sách bất động sản phù hợp</button>
            <p class="label">Tiêu đề</p>
            <p class="txt">{{ dataDetail.title }}</p>
            <div v-if="dataDetail.type == 1 || dataDetail.type == 2">
                <p class="label">Ảnh và video</p>
                <div class="list-img">
                    <div class="wp-img" v-for="(item, index) in dataDetail.image" :key="index">
                        <img :src="$storage + item.path" alt="" @error="handleError">
                        <span v-show="index == 0">Ảnh đại diện</span>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 15px;">
                <p class="label">Loại bất động sản</p>
                <span class="tag" v-for="item in dataDetail.category">{{ item.category_name }}</span>
            </div>
            <div style="margin-bottom: 15px;" v-if="dataDetail.type == 1 || dataDetail.type == 2">
                <p class="label">Khu vực</p>
                <span class="tag">
                    <span v-if="dataDetail.ward[0]">{{ dataDetail.ward[0].name }}&nbsp;</span>
                    <span v-if="dataDetail.district[0]">{{ dataDetail.district[0].name }}&nbsp;</span>
                    <span v-if="dataDetail.province[0]">{{ dataDetail.province[0].name }}</span>
                </span>
                <p class="txt" style="height: 46px; margin-top: 15px;">{{ dataDetail.address }}</p>
            </div>
            <div style="margin-bottom: 15px;" v-else>
                <p class="label">Khu vực mong muốn</p>
                <p class="txt" style="height: 46px;">Tỉnh / Thành phố</p>
                <span class="tag" v-if="dataDetail.province">{{ dataDetail.province[0].name }}</span>
                <p class="txt" style="height: 46px; margin-top: 15px;">Quận / Huyện</p>
                <span class="tag" v-for="district in dataDetail.district">{{ district.name }}</span>
                <p class="txt" style="height: 46px; margin-top: 15px;">Phường / Xã</p>
                <span class="tag" v-for="ward in dataDetail.ward">{{ ward.name }}</span>
            </div>
            <p class="label">Nhu cầu</p>
            <p class="txt">{{ dataDetail.content }}</p>
            <hr>
            <div class="wp-txt">
                <p class="label">Diện tích</p>
                <span class="tag" v-if="dataDetail.type == 1 || dataDetail.type == 2">{{ dataDetail.acreage }}</span>
                <span class="tag" v-else v-for="acreage in dataDetail.acreage">{{ acreage.text }}</span>
            </div>
            <div class="wp-txt">
                <p class="label">Khoảng giá</p>
                <span class="tag" v-if="dataDetail.type == 1 || dataDetail.type == 2">{{ dataDetail.price }}</span>
                <span class="tag" v-else v-for="item in dataDetail.price">{{ item.text }}</span>
            </div>
            <div class="wp-txt">
                <p class="label">Hoa hồng</p>
                <span class="tag">{{ dataDetail.commission }}%</span>
            </div>
            <div class="wp-txt" v-if="dataDetail.type == 3 || dataDetail.type == 4">
                <p class="label">Hướng nhà</p>
                <span class="tag" v-for="direction in dataDetail.direction">{{ direction.name }}</span>
            </div>
            <div class="wp-txt" v-if="dataDetail.type == 3 || dataDetail.type == 4">
                <p class="label">Số phòng ngủ</p>
                <span class="tag" v-for="bedroom in dataDetail.bedroom">{{ bedroom.name }}</span>
            </div>
            <div class="wp-txt">
                <p class="label">Mô tả BĐS</p>
                <p class="txt" style="flex-basis: 100%; height: 100%;">{{ dataDetail.description }}</p>
            </div>
        </div>
    </div>

    <Dialog 
        :dialogVisible="dialogVisible"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        title="Xóa"
        content="Bạn đang yêu cầu xóa nhu cầu"
        content_success="Bạn đã xóa nhu cầu thành công"
    />
</template>
<script>
import Dialog from '@/pages/components/dialog.vue'
export default {
    components: {
        Dialog
    },
    data() {
        return {
            dataDetail: [],
            demand_id: '',
            dialogVisible: false
        }
    },
    mounted() {
        this.demand_id = this.decode(this.$route.params.demandId)
        this.getDetail()
    },
    methods: {
        redirectEdit() {
            this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/nhu-cau/chinh-sua/' + this.$route.params.demandId)
        },
        getList() {
            this.$router.push('/cong-viec/khach-hang-quan-ly/chi-tiet/' + this.$route.params.cusId +'/nhu-cau/' + this.$route.params.demandId + '/bds-phu-hop')
        },
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/job/getDetailCustomerNeeds',{
                params: {
                    id: this.demand_id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async updatePrioritize() {
            if(!this.dataDetail.prioritize) {
                this.dataDetail.prioritize = '0'
            } 
            const data = {
                id: this.demand_id,
                prioritize: this.dataDetail.prioritize
            }
            this.startLoading();
            await this.$axios.put('/job/updateCustomerNeeds', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi trạng thái ưu tiên thành công!");
                this.getDetail()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirm() {
            this.startLoading();
            await this.$axios.put('/job/deleteCustomerNeeds', {id: this.demand_id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa nhu cầu thành công!");
                this.$router.go(-1)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    },
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .wp-content {
        width: 842px;
        padding: 20px;
        border-radius: 20px;
        background: #FFF;
        margin: 0 auto;
    }

    h6 {
        color: #515151;
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.18px;
        margin-bottom: 20px;
    }

    .btn-act {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .btn-act .wp-img {
        width: 46px;
        height: 46px;
        border-radius: 15px;
        border: 1px solid #B4B4B4;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn-act:first-child {
        margin-right: 30px;
    }

    .btn-act img {
        width: 30px;
        height: 30px;
    }

    .btn-act p {
        margin: 5px 0 0;
        color: #515151;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    span.ttl {
        color: #515151;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.18px; 
    }

    .btn-add {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        background: #F58E16;
        width: 132px;
        height: 40px;
    }

    .btn-del {
        color: #EE3439;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        width: 132px;
        height: 40px;
        border: 1px solid #EE3439;
        margin: 0 10px;
    }

    ul {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    ul li span {
        color: #515151;
        font-weight: 500;
        line-height: 180%;
    }

    .list-btn {
        margin-bottom: 20px;
        border-radius: 55px;
        border: 1px solid #B4B4B4;
        width: max-content;
        padding: 5px;
    }

    .list-btn button {
        color: #515151;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.075px;
        padding: 6px 23px;
    }

    .list-btn button.active {
        color: #F58E16;
        border-radius: 41px;
        background: #FCE3C5;
    }

    button.view {
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        width: 228px;
        height: 40px;
        border-radius: 15px;
        background: #F58E16;
        margin-bottom: 20px;
    }

    p {
        color: #515151;
        font-weight: 500;
        line-height: 180%;
        margin-bottom: 5px;
    }

    p.txt {
        border-radius: 15px;
        background: #F6F6F6;
        height: 70px;
        padding: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }

    .list-img {
        padding: 20px 18px;
        border-radius: 20px;
        background: #F6F6F6;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .wp-img {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 2.119px 0px rgba(0, 0, 0, 0.25);
        padding: 5px;
        margin: 0 8px 8px 0;
        height: 204px;
    }

    .wp-img:nth-child(3n+3) {
        margin-right: 0;
    }

    .wp-img img {
        width: 240px;
        height: 152px;
        border-radius: 5px;
        object-fit: cover;
    }

    .wp-img span {
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 180%;
        border-radius: 11.211px;
        background: rgba(89, 89, 89, 0.66);
        width: 114px;
        height: 22.33px;
        display: block;
        margin: 10px 0;
    }

    span.tag {
        color: #515151;
        font-size: 15px;
        font-weight: 500;
        line-height: 150%;
        padding: 9px 12px;
        border-radius: 20px;
        background: #FCE3C5;
        width: max-content;
        margin-right: 5px;
    }

    .wp-txt {
        display: flex;
        margin-bottom: 15px;
    }

    .wp-txt p.label {
        display: block;
        flex-basis: 20%;
    }
</style>