<template>
    <div class="wp-confirm">
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <h5>Quản lý nhân sự</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý nhân sự</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Danh sách chi nhánh</span>
                </div>
            </el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-end" style="position: relative;">
                <button class="btn_approve" @click="showDialog('add')">Tạo mảng BĐS mới</button>
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="demo-ruleForm"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        
        <el-row>
            <el-col :span="24">
                <p class="ttl">Quản lý nhân sự chi nhánh</p>
                <el-table :data="leader" class="el-tbl--manager">
                    <el-table-column header-align="center" align="center" prop="group_name" label="Tên chi nhánh" min-width="280" />
                    <el-table-column header-align="center" align="center" label="Quản lý chi nhánh" width="250">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.full_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="count_person" label="Tổng số nhân viên" min-width="230"/>
                    <el-table-column header-align="center" align="center" prop="count_group" label="Số mảng BĐS" min-width="231" />
                    <el-table-column header-align="center" align="center" prop="count_team" label="Số team sale" width="230"/>
                    <el-table-column header-align="center" align="center" prop="count_sale" label="Sale đại lý" width="230"/>
                </el-table>
            </el-col>
        </el-row>
    
        <el-row>
            <el-col :span="24">
                <el-table :data="tableData" style="width: 100%;" height="700" class="history_tbl" @row-click="redirectDetail">
                    <el-table-column header-align="center" align="center" type="index" label="Stt" min-width="70" />
                    <el-table-column header-align="center" align="center" prop="name" label="Mảng BĐS" min-width="280" />
                    <el-table-column header-align="center" align="center" label="Quản lý chi nhánh" width="250">
                        <template #default="scope">
                            <div class="table_code">{{ scope.row.leader_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column header-align="center" align="center" prop="count_person" label="Tổng số nhân viên" min-width="230"/>
                    <el-table-column header-align="center" align="center" prop="count_team" label="Số team sale" width="230"/>
                    <el-table-column header-align="center" align="center" prop="count_sale" label="Sale đại lý" width="230"/>
                    <el-table-column header-align="center" align="center" label="Action" width="124">
                        <template #default="scope">
                            <div class="d-flex align-items-center justify-content-center">
                                <button><img src="@/assets/icons/pencil.png" alt="" @click.stop="dialogStatus = true, showDialog('edit', scope.row)"></button>
                                <button><img src="@/assets/icons/trash.png" alt="" @click.stop="dialogVisible = true, del_id = scope.row.id"></button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="wp-paginate">
                    <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                    <Pagination
                        :page="page"
                        @set-page="setPage"
                        @setPerPage="setPerPage"
                    />
                </div>
            </el-col>
        </el-row>
    </div>
    <FormAdd
        :status="dialogStatus"
        :data="formData"
        :type="typeDialog"
        @handleCloseDialog="listenDialog"
        @addSuccess="getUserAgency"
    />
    <Dialog 
        :dialogVisible="dialogVisible"
        :success="false"
        @handleCloseDialog="listenDialogDelete"
        @confirm="confirm"
        title="Xóa"
        content="Bạn đang yêu cầu xóa bỏ mảng BĐS"
        content_success="Bạn đã xóa mảng BĐS thành công"
    />
</template>

<script>
import Pagination from '../../components/pagination.vue'
import FormAdd from './modal/formAddCate.vue'
import Dialog from '../../components/dialog.vue'
export default {
    components: {
        Pagination,
        FormAdd,
        Dialog
    },
    data() {
        return {
            keySearch: '',
            tableData: [],
            leader: [],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            dialogStatus: false,
            dialogVisible: false,
            del_id: '',
            formData: {},
            typeDialog: ''
        }
    },
    mounted() {
        this.getUserAgency()
    },
    methods: {
        showDialog(type, data) {
            this.dialogStatus = true
            if(type == 'add') {
                this.typeDialog = 'add'
                this.formData = {
                    id: '',
                    leader_id: '',
                    leader_name: '',
                    name: '',
                    role_id: '',
                }
            } else {
                this.typeDialog = 'edit'
                this.formData = data 
            }
        },
        listenDialogDelete(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        listenDialog(dialogStatus) {
            this.dialogStatus = dialogStatus;
            this.formData = {}
        },
        setPage(val) {
            this.page.currentPage = val;
            this.getUserAgency()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getUserAgency()
            document.getElementsByClassName('el-scrollbar__wrap')[0].scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        async confirm() {
            this.startLoading();
            await this.$axios.put('/job/deleteUserGroup', {id: this.del_id}, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa mảng BĐS thành công!");
                this.getUserAgency()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getUserAgency() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/job/getUserAgency',{
                params: {
                    name: this.keySearch,
                    id: this.decode(this.$route.params.id),
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.tableData = res.data.result;
                this.leader = []
                this.leader.push(res.data.leader)
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", err.response.data.error);
                        this.$router.go(-1)
                    }
                }
            })
            this.endLoading();
        },
        redirectDetail(row) {
            if(this.$route.path.includes('quan-ly-nhan-vien/')) {
                this.$router.push('/quan-ly-nhan-vien/quan-ly-nhan-su/quan-ly-nhan-su-mang/' + this.encode(row.id))
            } else {
                this.$router.push('/cong-viec/quan-ly-nhan-su/quan-ly-nhan-su-mang/' + this.encode(row.id))
            }
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .el-form {
        position: relative;
        width: 200px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .el-table {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .table_code {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

    .btn_approve {
        border-radius: 15px;
        background: #F58E16;
        padding: 10px 8px;
        color: #FFF;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin-left: 5px;
        width: 200px;
        margin-right: 10px;
    }

    .el-tbl--manager {
        width: 100%;
        border-radius: 20px;
        margin: 15px 0;
        /* border: 1px solid #515151; */
    }

    p.ttl {
        color: #515151;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.2px;
        margin-bottom: 0;
    }
</style>