<template>
    <div class="wp-func">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h5>Danh sách chức năng</h5>
                <div class="breadcrumb">
                    <span>Quản lý nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span>Phân quyền nhân viên</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Danh sách chức năng</span>
                </div>
            </div>
            <div class="d-flex">
                <el-form
                    ref="ruleFormRef"
                    status-icon
                    class="form-seacrh"
                    @submit.prevent="setPage(1)"
                >
                    <img src="@/assets/icons/search.png" alt="">
                    <el-form-item>
                        <el-input type="text" v-model="keySearch" placeholder="Search"/>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="wp-list">
            <div class="d-flex align-items-center justify-content-between">
                <h5>Danh sách chức năng</h5>
                <button class="add_role" @click="showDialog('add', '')">+ Thêm chức năng mới</button>
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in list_detail_au" :key="index">
                    <p>{{ item.resource_name }}</p>
                    <div class="d-flex align-items-center justify-content-between">
                        <ul class="list-role">
                            <li :class="{'active' : act.status == 1}" v-for="(act, index) in item.action" :key="index">{{ act.action_name }}</li>
                        </ul>
                        <div class="d-flex align-items-center">
                            <button style="padding: 0 12px;" @click="showDialog('edit', item.resource_id)"><img src="@/assets/icons/pencil.png" alt=""></button>
                            <button @click="dialogDelete = true, id = item.resource_id"><img src="@/assets/icons/trash.png" alt=""></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wp-paginate">
                <p>Showing {{ (page.currentPage - 1) * page.pageSize + 1 }}-{{ page.currentPage * page.pageSize > page.totalData ? page.totalData : page.currentPage * page.pageSize }} from {{ page.totalData }}</p>
                <Pagination
                    :page="page"
                    @set-page="setPage"
                    @setPerPage="setPerPage"
                />
            </div>
        </div>
        <AddFunc
            :data="dataDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirmFunc"
        />
        <Dialog 
            :dialogVisible="dialogDelete"
            @handleCloseDialog="listenDialogDelete"
            @confirm="deleteFunc"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ chức năng"
            content_success="Bạn đã xóa chức năng thành công"
        />
    </div>
</template>

<script>
import Pagination from '../components/pagination.vue'
import AddFunc from './dialog/form_func'
import Dialog from '../components/dialog.vue'
export default {
    components: {
        Pagination,
        AddFunc,
        Dialog
    },
    data() {
        return {
            list_detail_au: [
                'Quản lý chi nhánh',
                'Quản lý bất động sản',
                'Quản lý nhân viên',
                'Quản lý khách hàng',
                'Quản lý tin tức',
                'Quản lý tài chính',
            ],
            page: {
                totalPage: 0,
                totalData: null,
                pageSize: 10,
                currentPage: this.$route.query.page || 1,
            },
            keySearch: '',
            dataDialog: {
                dialogVisible: false,
                type: '',
                id: ''
            },
            dialogDelete: false,
            id: ''
        }
    },
    mounted() {
        this.getListResource()
    },
    methods: {
        setPage(val) {
            this.page.currentPage = val;
            this.getListResource()
        },
        setPerPage(val) {
            this.page.currentPage = 1;
            this.page.pageSize = val;
            this.getListResource()
        },
        showDialog(type, id) {
            if(type == 'add') {
                this.dataDialog = {
                    dialogVisible: true,
                    type: 'add',
                    id: id
                }
            } else {
                this.dataDialog = {
                    dialogVisible: true,
                    type: 'edit',
                    id: id
                }
            }
        },
        listenDialog(dialogStatus) {
            this.dataDialog.dialogVisible = dialogStatus;
        },
        listenDialogDelete(dialogStatus) {
            this.dialogDelete = dialogStatus;
        },
        async getListResource() {
            const data = {
                page : this.page.currentPage,
                per_page: this.page.pageSize
            };
            this.startLoading();
            await this.$axios.get('/permission/getListResource',{
                params: {
                    resource_name: this.keySearch,
                    page: data.page,
                    per_page: data.per_page
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_detail_au = res.data.result
                this.page.totalPage = res.data.pagination.total_page;
                this.page.totalData = res.data.pagination.total_element;
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async confirmFunc(form, type) {
            this.startLoading();
            if(type == 'add') {
                await this.$axios.post('/permission/createResource', form, {
                    headers: {
                        'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Thêm chức năng thành công!");
                    this.getListResource()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            } else {
                await this.$axios.put('/permission/updateResource', form, {
                    headers: {
                        'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Sửa chức năng thành công!");
                    this.getListResource()
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
            }
            this.endLoading();
        },
        async deleteFunc() {
            const data = {
                'resource_id': this.id,
            }
            this.startLoading();
            await this.$axios.post('/permission/destroyResource', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa chức năng thành công!");
                this.getListResource()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else if (err.response.status === 403){
                        this.showNotify("error", "Thất bại", err.response.data.message);
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .wp-confirm {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .dropdown button {
        display: block;
        font-family: 'Public Sans', sans-serif;
        width: 193px;
        height: 40px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0 4px;
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.07px;
    }

    .dropdown:last-child button {
        margin-left: 16px;
    }

    .dropdown-toggle::after {
        margin-left: 2.255em;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
        color: #515151 !important;
        background: #FFF !important;
    }

    .dropdown-menu {
        border-radius: 20px;
        background: #FFF;
        width: 320px;
        padding: 10px 12px 15px;
        margin-top: 5px !important;
    }

    .dropdown-menu li {
        border-radius: 32px;
        background: #F4F4F4;
        font-size: 15px;
        text-align: center;
        margin-bottom: 8px;
        height: 36px;
    }

    .dropdown-menu li a {
        font-weight: 500;
        height: 100%;
        color: #515151;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        background-color: transparent;
    }

    .el-form {
        position: relative;
        width: 200px;
        margin: 0 16px;
    }

    .el-form img {
        z-index: 2;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    .wp-list {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
        width: 842px;
        margin: 0 auto;
    }

    .wp-list h5 {
        color: #515151;
        font-size: 18px;
        font-weight: 500;
    }

    button.add_role {
        border-radius: 15px;
        border: 1px solid #F58E16;
        color: #F58E16;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .list {
        margin-top: 25px;
    }

    .item {
        margin-bottom: 15px;
    }

    .item p {
        margin-bottom: 0;
        color: #515151;
        font-weight: 500;
    }

    .item > div {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 0 19px;
    }

    ul.list-role {
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
    }

    ul.list-role li {
        position: relative;
        min-width: 65px;
        font-size: 15px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }

    ul.list-role li::before {
        content: '';
        position: absolute;
        border: 1px solid #B4B4B4;
        width: 10px;
        height: 10px;
        border-radius: 4px;
        left: 0;
    }

    ul.list-role li.active::before {
        background-color: #F58E16;
        border: none;
    }

    .wp-paginate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 18px;
        background-color: #FFF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .wp-paginate p {
        font-size: 14px;
        font-weight: 500;
        color: #667085;
        margin-bottom: 0;
        font-family: 'Public Sans', sans-serif;
    }

</style>

<style>
    .wp-func .form-seacrh .el-input__wrapper,
    .wp-func .form-seacrh .el-input__wrapper:hover,
    .wp-func .form-seacrh .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }
</style>