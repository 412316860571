<template>
    <el-dialog
        v-model="dialogVisibleStatus"
        width="574"
        class="dialog_staff_manager dialog_staff"
        @close="emitDialogVisible(false)"
    >
        <div class="wp-form">
            <h5>Sửa thành viên team</h5>
            <el-form
                :model="formAdd"
                ref="formAdd"
                status-icon
            >
            <div class="wp-search">
                <el-form-item prop="keySearch" style="width: 100%;">
                    <el-input id="name" type="text" v-model="formAdd.keySearch" placeholder="Search"/>
                    <img src="@/assets/icons/search.png" alt="">
                </el-form-item>
            </div>
            </el-form>
            <div class="wp-list">
                <div class="item" v-for="(item, index) in filteredItems" :key="index">
                    <label class="wp-lable">
                        <input type="checkbox" class="input-style" :value="item.user_id" v-model="list_id">
                        <span class="checkmark"></span>
                    </label>
                    <div class="info">
                        <img :src="$storage + item.avatar" alt="" @error="handleError">
                        <div class="d-flex justify-content-between" style="flex-basis: 100%;">
                            <p>{{ item.full_name }}</p>
                            <p>{{ item.user_code }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wp-btn" style="margin-top: 22px;">
                <button class="btn_cancel" @click="emitDialogVisible(false)">Hủy bỏ</button>
                <button class="btn_send" @click="addStaff">Xác nhận</button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: ['status', 'dataProps'],
    data() {
        return {
            dialogVisibleStatus: false,
            formAdd: {
                keySearch: '',
            },
            list_role: [],
            list_employ: [],
            list_id: [],
            group_id: ''
        };
    },
    computed: {
        filteredItems() {
            return this.list_employ.filter(item => 
                item.full_name.toLowerCase().includes(this.formAdd.keySearch.toLowerCase()) || item.user_code.toLowerCase().includes(this.formAdd.keySearch.toLowerCase()));
        },
    },
    mounted() {
        this.dialogVisibleStatus = this.status
        this.list_id = this.dataProps
        this.group_id = this.decode(this.$route.params.id)
        this.getlistPersonForLeader()
    },
    watch: {
        status() {
            this.dialogVisibleStatus = this.status;
        },
        dataProps() {
            this.list_id = this.dataProps;
        },
    },
    methods: {
        emitDialogVisible(dialogStatus) {
            this.$emit("handleCloseDialog", dialogStatus);
            this.$refs.formAdd.clearValidate()
            this.$refs.formAdd.resetFields()
        },
        async getlistPersonForLeader() {
            this.startLoading();
            await this.$axios.get('/job/getlistPersonForLeader',{
                params: {
                    group_id: this.group_id,
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.list_employ = res.data;
            })
            this.endLoading();
        },
        async addStaff() {
            const data = {
                group_id: this.decode(this.$route.params.id),
                ids: this.list_id
            }
            this.startLoading()
            await this.$axios.put('/job/updateUserGroupPerson', data, {
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Thay đổi nhân viên team thành công!");
                this.dialogVisibleStatus = false
                this.$emit("addSuccess")
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading()
        },
    }
}
</script>
<style lang="css" scoped>
    .wp-btn {
        text-align: right;
    }
    .wp-btn button {
        width: 200px;
        height: 38px;
        border-radius: 15px;
        border: 1px solid #F58E16;
        background: #FFF;
        color: #F58E16;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
    }

    .wp-btn button.btn_send {
        background: #F58E16;
        border: none;
        color: #fff;
        margin-left: 15px;
    }

    .wp-search {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 10px
    }

    .wp-search img {
        position: absolute;
        left: 12px;
    }

    .wp-lable {
        color: #515151;
        display: block;
        position: relative;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;
    }

    .wp-lable input.input-style {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: #FFFFFF;
        border: 1px solid #B4B4B4;
        border-radius: 5px;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .wp-lable input:checked ~ .checkmark:after {
        display: block;
    }

    .wp-lable .checkmark:after {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url('@/assets/icons/Done_check.png');
    }

    .item {
        border-radius: 20px;
        border: 1px solid #B4B4B4; 
        padding: 5px 15px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    .item > label {
        flex-basis: 8%;
    }

    .item .info {
        display: flex;
        align-items: center;
        flex-basis: 90%;
    }

    .item img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        object-fit: cover;
        margin-right: 33px;
    }

    .item p {
        color: #515151;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.07px;
        margin: 0
    }

    .item p:last-child {
        color: #3FA5FF;
        font-weight: 500;
    }

    .wp-list {
        max-height: 500px;
        overflow: auto;
    }

    .wp-list::-webkit-scrollbar {
        width: 0;
    }
</style>

<style>
    .dialog_staff .el-input__wrapper {
        background: #fff;
        padding-left: 40px;
    }
</style>