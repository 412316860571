<template>
    <div class="rugulation">
        <h5>Quy chế và điều khoản</h5>
        <div class="breadcrumb">
            <span>Hệ thống</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Quy chế và điều khoản</span>
        </div>
        <el-row>
            <el-col :span="9">
                <div class="wp-cate">
                    <div class="d-flex align-items-center justify-content-between">
                        <p class="ttl">Quy chế và điều khoản</p>
                        <button class="add-cate" @click="showForm('add','')">+ Thêm mục mới</button>
                    </div>
                    <ul class="list-cate">
                        <li 
                            :class="{'active' : keyActive == item.id}"
                            v-for="(item, index) in listRegulation"
                            :key="index"
                            @click="keyActive = item.id, detail = true, getDetail(item.id)"
                        >
                            {{ item.name }}
                            <div class="wp-btn">
                                <button @click.stop="showForm('edit', item)"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="dialogVisible = true, id = item.id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="15" v-if="detail">
                <div class="wp-detail">
                    <div class="d-flex align-items-center justify-content-between mb-3">
                        <p class="ttl">{{ dataDetail.name }}</p>
                        <div class="d-flex">
                            <button class="add-cate" @click="detail = false, keyActive = 0">Hủy bỏ</button>
                            <button class="btn_save" @click="editDesc">Lưu chỉnh sửa</button>
                        </div>
                    </div>
                    <p class="lb">Mô tả chi tiết <span style="color: #FF5A4F;">*</span></p>
                    <CKeditor
                        :editorData="dataDetail.description"
                        @getData="getData"
                    />
                </div>
            </el-col>
        </el-row>

        <el-dialog
            v-model="dialogAddCate"
            width="553"
            class="dialog_add_cate"
            @close="dialogAddCate = false"
        >
            <p class="ttl" v-if="typeDialog == 'add'">Tạo điều khoản mới</p>
            <p class="ttl" v-else>Sửa điều khoản</p>
            <el-form
                ref="formAdd"
                :model="formAdd"
                :rules="rules"
                status-icon
                @submit.prevent="typeDialog == 'add' ? addRegular('formAdd') : editRegular('formAdd')"
            >
                <el-form-item :label="typeDialog == 'add' ? 'Nhập tên điều khoản mới' : 'Tên điều khoản mới'" prop="name">
                    <el-input v-model="formAdd.name" type="text" resize="none" placeholder="Nhập tên điều khoản" />
                </el-form-item>
            </el-form>
            <div class="wp-btn" style="margin-top: 40px;">
                <button class="btn_cancel" @click="dialogAddCate = false">Hủy bỏ</button>
                <button class="btn_add" @click="addRegular('formAdd')" v-if="typeDialog == 'add'">Xác nhận</button>
                <button class="btn_add" @click="editRegular('formAdd')" v-else>Xác nhận</button>
            </div>
        </el-dialog>

        <Dialog 
            :dialogVisible="dialogVisible"
            :success="successDialog"
            @handleCloseDialog="listenDialog"
            @confirm="confirm"
            title="Xóa"
            content="Bạn đang yêu cầu xóa bỏ quy chế"
            content_success="Bạn đã xóa danh mục thành công"
        />
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
import Dialog from '../components/dialog.vue'
export default {
    components: { CKeditor, Dialog },
    data() {
        return {
            formAdd: {
                name: ''
            },
            rules: {
                name: [{required: true, message: "Vui lòng nhập tên danh mục", trigger: ["blur", "change"]}],
            },
            dialogAddCate: false,
            typeDialog: '',
            dataEdit: [],
            dataDetail: [],
            listRegulation: [],
            keyActive: 0,
            dialogVisible: false,
            successDialog: false,
            id: '',
            detail: false
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        async getList() {
            this.startLoading();
            await this.$axios.get('/system/getListRegulation')
            .then(res => {
               this.listRegulation = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        getData(data) {
            this.dataDetail.description = data
        },
        showForm(type, data) {
            this.dialogAddCate = true
            if(type == 'add') {
                this.typeDialog = 'add'
                if(this.$refs.formAdd) {
                    this.turnOffErrorMessage('formAdd')
                }
                this.formAdd.name = ''
            } else {
                this.typeDialog = 'edit'
                this.formAdd.name = data.name
                this.dataEdit = data
            }
        },
        async addRegular(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const res = await this.$axios.post('/system/createRegulation',this.formAdd,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Thêm quy chế thành công!");
                        this.dialogAddCate = false
                        this.turnOffErrorMessage('formAdd')
                        this.getList()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async editRegular(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    this.startLoading();
                    const data = {
                        'name': this.formAdd.name,
                        'id': this.dataEdit.id
                    }
                    const res = await this.$axios.put('/system/updateRegulation',data,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Sửa tên quy chế thành công!");
                        this.dialogAddCate = false
                        this.turnOffErrorMessage('formAdd')
                        this.getList()
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                } else {
                    return false;
                }
            });
        },
        async confirm() {
            const data = {
                'id': this.id
            }
            this.startLoading();
            await this.$axios.put('/system/deleteRegulation',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Xóa quy chế thành công!");
                this.getList()
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async getDetail(id) {
            this.startLoading();
            await this.$axios.get('/system/getRegulationDetail',{
                params: {
                    id: id
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataDetail = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
        async editDesc() {
            const data = {
                id: this.keyActive,
                description: this.dataDetail.description
            }
            this.startLoading();
            await this.$axios.put('/system/updateRegulation',data,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.showNotify("success", "Thành công", "Sửa nội dung quy chế thành công!");
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        }
    }
}
</script>
<style lang="css" scoped>
    .rugulation {
        color: #515151;
    }

    h5 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
        outline: none;
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        padding: 10px 14px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .wp-cate{
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .wp-cate {
        margin-right: 20px;
    }

    .wp-cate p.ttl, .wp-detail p.ttl {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .wp-detail p.ttl {
        margin-bottom: 10px;
    }

    .wp-detail p.lb {
        font-weight: 500;
        margin-bottom: 5px;
    }

    .add-cate {
        border: 1px solid #F58E16;
        color: #F58E16;
    }

    .wp-cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .wp-cate .wp-btn button {
        padding: 0;
    }

    .wp-cate .wp-btn button:last-child {
        margin-left: 10px;
    }

    .wp-detail {
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    ul.list-cate {
        padding: 0;
        list-style: none;
        margin-top: 25px;
    }

    ul.list-cate li {
        border-radius: 20px;
        background: #F3F3F3;
        padding: 9.5px 12px;
        margin-bottom: 10px;
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
    }

    ul.list-cate li.active {
        background: #FCE3C5;
    }

    ul.list-cate li:last-child {
        margin-bottom: 0;
    }

    .wp-detail button.btn_save {
        color: #FFF;
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        background: #F58E16;
        margin-left: 10px;
    }

    .wp-detail button {
        width: 160px;
        display: flex;
        justify-content: center;
    }
</style>

<style>
    .rugulation .el-input__wrapper,
    .rugulation .el-input__wrapper:hover,
    .rugulation .el-input__wrapper.is-focus {
        box-shadow: none;
        height: 40px;
        padding-left: 40px;
        border-radius: 15px;
        border: 1px solid #E0E2E7;
    }

    .rugulation .el-form-item{
        margin-bottom: 0;
    }

    .rugulation .el-table th.el-table__cell {
        height: 56px;
        color: #515151;
        font-size: 15px;
        font-weight: 500;
    }

    .rugulation .el-table td {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .dialog_add_cate {
        border-radius: 20px !important;
    }

    .dialog_add_cate .el-dialog__header {
        display: none;
    }

    .dialog_add_cate p.ttl {
        color: #515151;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .dialog_add_cate .wp-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dialog_add_cate .el-form {
        margin: 0;
        width: 100%;
    }

    .dialog_add_cate .el-input__wrapper {
        border-radius: 15px;
        background: #F6F6F6;
        padding: 12px 10px !important;
        width: 100%;
        height: 70px !important;
    }

    .dialog_add_cate button {
        font-size: 14px;
        font-weight: 500;
        border-radius: 15px;
        width: 49%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dialog_add_cate .btn_cancel {
        color: #F58E16;
        border: 1px solid #F58E16;
    }

    .dialog_add_cate .btn_add {
        color: #FFF;
        background: #F58E16;
    }
</style>