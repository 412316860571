<template>
    <div class="wp-add-cate wp-add-agency">
        <h5>Chỉnh sửa chi nhánh</h5>
        <div class="breadcrumb">
            <span>Quản lý bất động sản</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span>Danh sách chi nhánh</span>
            <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
            <span class="active">Chỉnh sửa chi nhánh</span>
        </div>
        <div class="text-end">
            <button class="btn_cancel" @click="$router.go(-1)"><img src="@/assets/icons/cross.png" alt=""> Hủy bỏ</button>
            <button class="btn_add" @click="editAgency('formEdit')"><img src="@/assets/icons/plus.png" alt=""> Chỉnh sửa</button>
        </div>
        <el-form
            ref="formEdit"
            :model="formEdit"
            :rules="rules"
            label-width="120px"
            status-icon
        >
            <el-row style="margin-top: 18px;">
                <el-col :span="10">
                    <div class="basic_info">
                        <h5>Thông tin cơ bản</h5>
                        <el-form-item prop="agency_name" label="Tên chi nhánh">
                            <el-input id="name" type="text" v-model="formEdit.agency_name" placeholder="Nhập tên chi nhánh"/>
                        </el-form-item>

                        <p class="img_req">Hình ảnh chi nhánh</p>
                        <input type="file" class="d-none" accept="image/png, image/gif, image/jpeg, image/jpg" ref="inputFile" @change="changeFile">
                        <div class="ico_upload" @click="$refs.inputFile.click()" @dragover.prevent @dragenter.prevent @drop="handleDrop">
                            <div v-if="!formEdit.image">
                                <el-icon class="el-icon--upload"><img src="@/assets/icons/picture.png" alt=""></el-icon>
                                <div class="el-upload__text">
                                    Drag and drop image here, or click add image
                                </div>
                                <button class="add_img" type="button">Add Image</button>
                            </div>
                            <img v-else-if="image_url" :src="$storage + image_url" alt="" style="border-radius: 10px; height: 152px; width: 205px;" @error="handleError">
                            <img v-else :src="$storage + formEdit.image" alt="" style="border-radius: 10px; height: 152px; width: 205px;" @error="handleError">
                        </div>
                        <span v-if="!formEdit.image && showErr" class="error">Vui lòng thêm hình ảnh chi nhánh</span>

                        <el-form-item prop="phone" label="Số điện thoại">
                            <el-input id="phone" type="text" v-model="formEdit.phone" maxlength="10" placeholder="Nhập số điện thoại" @input="formEdit.phone = formEdit.phone.replace(/[^0-9]/g, '')"/>
                        </el-form-item>

                        <el-form-item prop="email" label="Email">
                            <el-input id="email" type="email" v-model="formEdit.email" placeholder="Nhập email"/>
                        </el-form-item>

                        <el-form-item prop="address" label="Địa chỉ">
                            <el-input id="address" type="text" v-model="formEdit.address" @blur="getMaps" placeholder="Nhập địa chỉ chi tiết"/>
                        </el-form-item>
                        <p>Vị trí trên bản đồ</p>
                        <GMAPS :lng="formEdit.longtitude" :lat="formEdit.latitude"/>
                    </div>
                </el-col>
                <el-col :span="14">
                    <div class="description">
                        <h5>Mô tả chi nhánh</h5>
                        <el-form-item prop="title" label="Tiêu đề">
                            <!-- <el-input id="title" type="textarea" v-model="formEdit.title" placeholder="Nhập tiêu đề" resize="none" /> -->
                            <textarea id="title" class="form-control" v-model="formEdit.title" placeholder="Nhập tiêu đề" resize="none" @input="this.adjustTextareaHeight"></textarea>
                        </el-form-item>
                        <p class="des_req">Mô tả chi tiết</p>
                        <CKeditor
                            :editorData="formEdit.description"
                            @getData="getDataEditor"
                        />
                        <span v-if="!formEdit.description && showErr" class="error">Vui lòng thêm mô tả chi tiết</span>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import CKeditor from '../components/ckeditor.vue'
import GMAPS from "../components/goong_maps.vue"
import axios from 'axios';
export default {
    components: {CKeditor, GMAPS},
    data() {
        return {
            formEdit: {
                agency_name: '',
                phone: '',
                email: '',
                address: '',
                title: '',
                image: '',
                description: '',
                longtitude: '',
                latitude: '',
            },
            image_url: '',
            showErr: false,
            id: '',
            rules: {
                agency_name: [{required: true, message: "Vui lòng nhập tên chi nhánh", trigger: ["blur", "change"]}],
                phone: [
                    {required: true, message: "Vui lòng nhập số điện thoại", trigger: ["blur", "change"]},
                    {min: 10, message: "Số điện thoại tối thiểu 10 ký tự", trigger: ["blur", "change"]},
                ],
                email: [
                    {required: true, message: "Vui lòng nhập email", trigger: ["blur", "change"]},
                    { type: "email", message: "Vui lòng nhập email đúng định dạng", trigger: ["blur", "change"] },
                ],
                address: [{required: true, message: "Vui lòng nhập địa chỉ", trigger: ["blur", "change"]}],
                title: [{required: true, message: "Vui lòng nhập tiêu đề", trigger: ["blur", "change"]}],
            },
        }
    },
    mounted() {
        this.id = this.decode(this.$route.params.id)
        this.getDetail()
    },
    methods: {
        getDataEditor(data) {
            this.formEdit.description = data
        },
        async changeFile(event) {
            if(event.target.files[0]) {
                let url_image = await this.uploadFile('agency/image/', event.target.files[0])
                this.image_url = url_image.path_tam
                this.formEdit.image = url_image.path
            }
        },
        async handleDrop(e) {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            let url_image = await this.uploadFile('agency/image/', file)
            this.image_url = url_image.path_tam
            this.formEdit.image = url_image.path
        },
        async getDetail() {
            this.startLoading();
            await this.$axios.get('/agency/getAgencyDetail?agency_id=' + this.id,{
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.formEdit = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }else {
                    this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                }
            })
            this.endLoading();
        },
        async editAgency(formName) {
            this.$refs[formName].validate(async (valid) => {
                if(!this.formEdit.image || !valid || !this.formEdit.description) {
                    this.showErr = true
                    this.showNotify("warning", "Lưu ý", "Vui lòng kiểm tra lại thông tin!");
                } else {
                    this.startLoading();
                    const res = await this.$axios.put('/agency/updateAgency',this.formEdit,{
                        headers: {
                            'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                        }
                    })
                    if (res.status === 200) {
                        this.showNotify("success", "Thành công", "Chỉnh sửa chi nhánh thành công!");
                        this.$router.push('/quan-ly-chi-nhanh')
                    } else if(res.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                    this.endLoading();
                }
            });
        },
        async getMaps() {
            if(this.formEdit.address) {
                await this.$axios.get('/common/callAPIGoong',{
                    params: {
                        address: this.formEdit.address,
                    },
                }).then(res => {
                    this.formEdit.longtitude = res.data.results[0].geometry.location.lng
                    this.formEdit.latitude = res.data.results[0].geometry.location.lat
                })
            }
        },
    }
}
</script>
<style lang="css" scoped>
    h5 {
        color: #515151;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
    }

    .breadcrumb .active {
        color: #F58E16;
    }

    button {
        background: none;
        border: none;
    }

    .btn_cancel, .btn_add {
        font-family: 'Public Sans', sans-serif;
        font-size: 14px;
        font-weight: 600;
        color: #515151;
        line-height: 18px;
        padding: 10px 14px;
        border-radius: 15px;
    }

    .btn_cancel {
        border: 1px solid #515151;
        margin-right: 16px;
    }

    .btn_cancel img {
        filter: brightness(0.5);
        margin-right: 4px;
    }

    .btn_add {
        color: #FFF;
        background: #F58E16;
        line-height: 20px;
    }

    .btn_add img {
        margin-right: 4px;
        filter: brightness(10);
    }

    .basic_info, .description {
        border-radius: 20px;
        background: #FFF;
        padding: 20px;
    }

    .basic_info h5, .description h5 {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .basic_info label, .basic_info p, .description p, .description label {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .description {
        height: 100%;
        margin-left: 20px;
    }

    .description textarea {
        resize: none;
        border-radius: 8px;
        border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
        background: var(--neutral-gray-gray-25, #F9F9FC);
        box-shadow: none;
        height: 72px;
    }

    .description textarea::-webkit-scrollbar {
        width: 0;
    }

    .el-icon--upload {
        border-radius: 8px;
        background: #F58E16;
        padding: 8px;
        width: 44px;
        height: 44px;
        margin-bottom: 0 !important;
    }

    .el-upload__text {
        margin: 16px 0;
    }

    .add_img {
        color: #F58E16;
        font-size: 14px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
    }

    .ico_upload {
        border-radius: 20px;
        border: 1px dashed var(--neutral-gray-gray-100, #E0E2E7);
        background: #EBEBEB;
        padding: 20px 10px;
        box-shadow: none;
        text-align: center;
        cursor: pointer;
        margin-bottom: 15px;
    }

    .error {
        color: var(--el-color-danger);
        font-size: 12px;
        line-height: 1;
        padding: 5px 0;
        display: block;
    }
</style>

<style>
    .wp-add-agency .el-textarea .el-textarea__inner {
        border-radius: 8px;
        border: 1px solid var(--neutral-gray-gray-100, #E0E2E7);
        background: var(--neutral-gray-gray-25, #F9F9FC);
        box-shadow: none;
        height: 72px;
    }

    .el-form-item, .el-form-item__label {
        display: block !important;
    }

    .el-form-item__label  {
        color: #515151;
        font-size: 14px;
        font-weight: 500;
    }

    .img_req::before, .des_req::before {
        content: "*";
        color: var(--el-color-danger);
        margin-right: 4px;
    }
</style>