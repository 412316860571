<template>
    <div class="wp-kpi">
        <el-row class="align-items-center" style="margin-bottom: 18px;">
            <el-col :span="12">
                <h5>Quản lý KPI</h5>
                <div class="breadcrumb mb-0">
                    <span>Quản lý công việc</span>
                    <span style="margin: 0 8px;"><img src="@/assets/icons/caret-right.png" alt=""></span>
                    <span class="active">Quản lý KPI</span>
                </div>
            </el-col>
            <el-col :span="12" class="wp-btn">
                <button class="btn-add" @click="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-chi-nhanh/' + $route.params.agency_id)">+ Add KPI </button>
                <button @click="checkDialog('delete'), type = 'delete_all', KPI_id = dataKPI.KPI_id"><img src="@/assets/icons/trash.png" alt=""></button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="17">
                <div class="wp-head">
                    <h5>{{ dataGroup.text }}</h5>
                    <div class="text-end" style="margin-bottom: 20px;">
                        <button class="reject" @click="rejectVisible = true">Từ chối KPI</button>
                        <button class="accept" @click="checkDialog('confirm'), type = 'confirm'">Chấp nhận KPI</button>
                    </div>
                    <el-row>
                        <el-col :span="8" class="text-center" v-if="dataKPI">
                            <h6>{{ dataGroup.name }}</h6>
                            <el-progress type="circle" :percentage="dataKPI.complete_percent" :color="colors">
                                <template #default="{ percentage }">
                                    <span class="percentage-label">Hoàn thành</span>
                                    <span class="percentage-value">{{ percentage }}%</span>
                                    <span class="percentage-label">{{ $moment.utc().format('DD/MM/YYYY') }}</span>
                                </template>
                            </el-progress>
                            <p class="time mt-3" v-if="dataKPI.start_date">Bắt đầu: {{ $moment.utc(dataKPI.start_date).format('DD/MM/YYYY') }}</p>
                            <p class="time" v-if="dataKPI.end_date">Kết thúc: {{ $moment.utc(dataKPI.end_date).format('DD/MM/YYYY') }}</p>
                        </el-col>
                        <el-col :span="16">
                            <h6>KPI và tiến độ hiện tại</h6>
                            <div class="item" v-if="dataKPI" v-for="(item_kpi, index) in dataKPI.KPI" :key="index">
                                <div class="ico">
                                    <img src="@/assets/icons/bar_chart.png" alt="">
                                    <p>{{ item_kpi.text }}: </p>
                                </div>
                                <div class="content">
                                    <p v-for="item in item_kpi.listKPI">
                                        <span :class="item.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ parseInt(item.complete).toLocaleString('vi') }}</span> / {{ item.target.toLocaleString('vi') }}
                                        <span v-if="item.KPI_type == 1">VNĐ</span>
                                        <span v-else>{{ item.active_name }}</span>
                                    </p>
                                </div>
                                <p class="stt" :class="item_kpi.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ item_kpi.complete_percent }}%</p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="7">
                <div class="wp-doughnut">
                    <DoughtNut :dataValue="dataProps"/>
                </div>
            </el-col>
        </el-row>
        <h6 class="ttl">Tiến độ hoàn thành KPI từng mảng</h6>
        <div class="kpi-item" v-for="(item, index) in listItemKpi" @click="() => index != 0 && item.KPI_detail.team_id && $router.push('/cong-viec/quan-ly-kpi-mang/' + encode(item.KPI_detail.team_id))">
            <el-row>
                <el-col :span="9">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <p class="agency_name">{{ item.group_name }}</p>
                            <div class="d-flex align-items-center">
                                <img class="avatar" :src="$storage + item.avatar" alt="" @error="handleError">
                                <div class="info">
                                    <p>{{ item.full_name }}</p>
                                    <p>{{ item.role_name }}</p>
                                </div>
                            </div>
                            <div class="wp-btn">
                                <button @click.stop="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-mang/' + encode(item.KPI_detail.team_id))" class="btn-add-kpi" v-if="index != 0">+ Add KPI</button>
                                <button @click.stop="$router.push('/cong-viec/quan-ly-kpi/tao-moi-kpi-chi-nhanh/' + $route.params.agency_id)" class="btn-add-kpi" v-else>+ Add KPI</button>
                                <button class="btn-edit" @click.stop="() => item.KPI_detail.KPI_id && $router.push('/cong-viec/quan-ly-kpi/chinh-sua-kpi/' + encode(item.KPI_detail.KPI_id))" :disabled="!item.KPI_detail.KPI_id"><img src="@/assets/icons/pencil.png" alt=""></button>
                                <button @click.stop="checkDialog('delete'), type = 'delete', KPI_id = item.KPI_detail.KPI_id" :disabled="!item.KPI_detail.KPI_id"><img src="@/assets/icons/trash.png" alt=""></button>
                            </div>
                            <button class="complain" @click.stop="complainDialog = true">
                                <img src="@/assets/icons/warning.png" alt="">
                                <span>Khiếu nại mức KPI</span>
                            </button>
                        </div>
                        <el-progress type="circle" v-if="item.KPI_detail" :percentage="item.KPI_detail.complete_percent" :color="colors" style="margin: 0 100px 0 30px;">
                            <template #default="{ percentage }">
                                <span class="percentage-label">Hoàn thành</span>
                                <span class="percentage-value">{{ percentage }}%</span>
                            </template>
                        </el-progress>
                    </div>
                </el-col>
                <el-col :span="15">
                    <div class="item" v-if="item.KPI_detail" v-for="item_detail in item.KPI_detail.KPI">
                        <div class="ico">
                            <img src="@/assets/icons/bar_chart.png" alt="">
                            <p>{{ item_detail.text }}: </p>
                        </div>
                        <div class="content">
                            <p v-for="content in item_detail.listKPI">
                                <span :class="content.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ parseInt(content.complete).toLocaleString('vi') }}</span> / {{ content.target.toLocaleString('vi') }}
                                <span v-if="content.KPI_type == 1">VNĐ</span>
                                <span v-else>{{ content.active_name }}</span>
                            </p>
                        </div>
                        <p class="stt" :class="item_detail.complete_percent >= 100 ? 'stt--success' : 'stt--fail'">{{ item_detail.complete_percent }}%</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>

    <Dialog 
        :dialogVisible="dialogVisible"
        :success="successDialog"
        @handleCloseDialog="listenDialog"
        @confirm="confirm"
        :title="titleDialog"
        :content="contentDialog"
        content_success=""
    />
    
    <RejectDialog 
        :rejectVisible="rejectVisible"
        @handleCloseDialog="rejectDialog"
        @confirm="rejectKPI"
        :title="'Xác nhận không chấp nhận KPI'"
        :content="'Bạn xác nhận không chấp nhận mức KPI trên?'"
        :customer="'cấp trên'"
    />

    <ComplainDialog 
        :complainDialog="complainDialog"
        @handleCloseDialog="complainStatus"
        @confirm="confirmComplain"
    />
</template>
<script>
import DoughtNut from './components/doughnut'
import Dialog from '../../components/dialog.vue'
import RejectDialog from '../../components/rejectDialog.vue'
import ComplainDialog from './components/complain.vue'
export default {
    components: {
        DoughtNut,
        RejectDialog,
        Dialog,
        ComplainDialog
    },
    data() {
        return {
            dialogVisible: false,
            successDialog: false,
            rejectVisible: false,
            complainDialog: false,
            titleDialog: '',
            contentDialog: '',
            type: '',
            dataKPI: [],
            dataGroup: [],
            colors: [
                { color: '#ee3439', percentage: 40 },
                { color: '#f58e16', percentage: 99 },
                { color: '#00b569', percentage: 100 },
            ],
            dataProps: {
                labels: [],
                data: []
            },
            listItemKpi: [],
            KPI_id: ''
        }
    },
    mounted() {
        this.getKPI()
    },
    methods: {
        listenDialog(dialogStatus) {
            this.dialogVisible = dialogStatus;
        },
        rejectDialog(rejectStatus) {
            this.rejectVisible = rejectStatus;
        },
        complainStatus(dialogStatus) {
            this.complainDialog = dialogStatus;
        },
        checkDialog(type) {
            if(type != 'confirm') {
                this.titleDialog = 'Xóa KPI'
                this.contentDialog = 'Bạn đang yêu cầu xóa KPI'
            } else {
                this.titleDialog = 'Xác nhận KPI'
                this.contentDialog = 'Bạn xác nhận chấp nhận mức KPI trên?'
            }
            this.dialogVisible = true
        },
        async rejectKPI(reason) {
            console.log('Từ chối KPI>>>', reason);
        },
        async confirm() {
            if(this.type != 'confirm') {
                this.startLoading();
                await this.$axios.put('/job/deleteKPI', {id: this.KPI_id}, {
                    headers: {
                        'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                    }
                }).then(res => {
                    this.showNotify("success", "Thành công", "Xóa KPI thành công!");
                    if(this.type == 'delete_all') {
                        this.getKPI()
                    } else {
                        this.getListKpiForTeam()
                    }
                }).catch(err => {
                    if(err.response) {
                        if(err.response.status === 401) {
                            this.handleCheckTokenAuth();
                        } else {
                            this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                        }
                    }
                })
                this.endLoading();
            } else {
                console.log('Xác nhận KPI');
            }
        },
        async confirmComplain() {
            console.log('Xác nhận khiếu nại KPI');
        },
        async getKPI() {
            this.startLoading();
            await this.$axios.get('/job/getKPI', {
                params: {
                    type: 2,
                    id: this.decode(this.$route.params.agency_id)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.dataKPI = res.data.KPI
                this.dataGroup = res.data.group
                this.dataProps.labels = res.data.KPI.KPI.map(item => item.text)
                this.dataProps.data = res.data.KPI.KPI.map(item => item.percent)
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
            this.getListKpiForTeam()
        },
        async getListKpiForTeam() {
            this.startLoading();
            await this.$axios.get('/job/getListKPIForTeam', {
                params: {
                    type: 2,
                    id: this.decode(this.$route.params.agency_id)
                },
                headers: {
                    'x-access-token': JSON.parse(localStorage.getItem('admin')).accessToken
                }
            }).then(res => {
                this.listItemKpi = res.data
            }).catch(err => {
                if(err.response) {
                    if(err.response.status === 401) {
                        this.handleCheckTokenAuth();
                    } else {
                        this.showNotify("error", "Thất bại", "Vui lòng thử lại!");
                    }
                }
            })
            this.endLoading();
        },
    }
}
</script>
<style lang="css" scoped src="./assets/style.css"></style>

<style>
    .wp-kpi .el-progress-circle {
        width: 220px !important;
        height: 100% !important;
    }

    .wp-kpi .kpi-item .el-progress-circle {
        width: 120px !important;
        height: 100% !important;
    }

</style>